import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Avatar, {
  AvatarSize,
  getRandomAvatarColor,
} from "@app/components/atoms/Avatar/Avatar";
import Loading from "@app/components/atoms/Loading/Loading";
import Modal from "@app/components/atoms/Modal/Modal";
import { Subtitle, Title } from "@app/components/atoms/Typography/Typography";
import { ThemeEnum } from "@app/constants/theme.constants";
import { ClassesDef } from "@app/features/classes/classes";
import { getProductById } from "@app/features/ecommerce/ecommerce";
import { getFullName } from "@app/helpers/util.helpers";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";

import styles from "./AttendeesModal.module.scss";

interface AttendeesModalProps {
  visible: boolean;
  sessionId?: ClassesDef["id"];
  onClose: () => void;
}
const AttendeesModal = ({
  visible,
  sessionId,
  onClose,
}: AttendeesModalProps) => {
  const dispatch = useAppDispatch();
  const { product: session, loading } = useSelector(
    (state: RootState) => state.productDetail
  );

  useEffect(() => {
    if (sessionId) {
      dispatch(getProductById(sessionId));
    }
  }, [dispatch, sessionId]);

  const { t } = useTranslation();

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      showCloseButton
      className={styles.modal}
    >
      <div className={styles.content}>
        <Title level={3}>{t("practitioner.attendesModalTitle")}</Title>
        <Loading isLoading={loading} theme={ThemeEnum.WHITE}>
          {session && (
            <>
              <Subtitle level={3}>
                {t("practitioner.attendesModalSubTitle", {
                  count: session.attendees?.length,
                  total: session.maxAttendees,
                })}
              </Subtitle>
              <div className={styles.attendeesList}>
                {session.attendees?.map(attendee => (
                  <div className={styles.attendee} key={attendee.id}>
                    <Avatar
                      avatarColor={getRandomAvatarColor()}
                      avatarSize={AvatarSize.SMALL}
                      className={styles.avatar}
                    />
                    <Subtitle level={3}>{getFullName(attendee)}</Subtitle>
                  </div>
                ))}
              </div>
            </>
          )}
        </Loading>
      </div>
    </Modal>
  );
};

export default AttendeesModal;
