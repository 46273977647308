import * as React from "react";

function SvgStarActive(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M12 0l2.694 8.292h8.719l-7.054 5.124 2.694 8.292L12 16.584l-7.053 5.124 2.694-8.292L.587 8.292h8.719L12 0z"
        fill="url(#StarActive_svg__paint0_linear)"
      />
      <defs>
        <linearGradient
          id="StarActive_svg__paint0_linear"
          x1={0}
          y1={12}
          x2={24}
          y2={12}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B29164" />
          <stop offset={1} stopColor="#FFDA8A" />
        </linearGradient>
      </defs>
    </svg>
  );
}

const MemoSvgStarActive = React.memo(SvgStarActive);
export default MemoSvgStarActive;
