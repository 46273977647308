import * as React from "react";

function SvgAudio(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2a4 4 0 00-4 4v4a4 4 0 008 0V6a4 4 0 00-4-4zm-2 4a2 2 0 114 0v4a2 2 0 11-4 0V6zm-6 4a8.001 8.001 0 007 7.938V20H8v2h8v-2h-3v-2.062A8.001 8.001 0 0020 10h-2a6 6 0 01-12 0H4z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgAudio = React.memo(SvgAudio);
export default MemoSvgAudio;
