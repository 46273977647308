import React, { memo } from "react";

import DefaultLayout from "@app/components/layouts/DefaultLayout/DefaultLayout";
import ProductDetails from "@app/components/renderings/ProductDetails/ProductDetails";
import { ClassTypesEnum } from "@app/constants/classes";
import { ClassesDef } from "@app/features/classes/classes";
import { PractitionerDef } from "@app/features/practitioners/practitioners";
import { ContentfulMedia } from "@app/types/contentful.types";

import {
  ExperienceField,
  mapExperiences,
  PractitionerContentModel,
} from "../PractitionerPreview/PractitionerPreview";

type PractitionerField = {
  fields: PractitionerContentModel;
};

type ClassContentModel = Pick<
  ClassesDef,
  | "description"
  | "duration"
  | "maxAttendees"
  | "level"
  | "preparationTips"
  | "price"
  | "shortDescription"
  | "startAt"
  | "whatToBring"
> & {
  title: string;
  experiences: ExperienceField[];
  image: ContentfulMedia;
  pdf: ContentfulMedia;
  practitioner: PractitionerField;
  classType: string;
};

interface ClassPreviewProps {
  data: ClassContentModel;
}

export const mapPractitioner = (
  practitioner: PractitionerField
): PractitionerDef => ({
  id: 0,
  image: practitioner?.fields?.image?.fields?.file?.url,
  firstName: practitioner?.fields?.firstName,
  lastName: practitioner?.fields?.lastName,
  description: practitioner?.fields?.description,
});

const ClassPreview = memo(({ data: product }: ClassPreviewProps) => {
  const mapProduct = (productModel: ClassContentModel): ClassesDef => ({
    ...productModel,
    id: 0,
    name: productModel.title,
    image: productModel?.image?.fields?.file?.url,
    contentfulId: "",
    type: productModel.classType as ClassTypesEnum,
    experiences: mapExperiences(productModel.experiences),
    practitioner: mapPractitioner(productModel.practitioner),
    pdf: productModel?.pdf?.fields?.file?.url,
  });

  return (
    <DefaultLayout>
      <ProductDetails product={mapProduct(product)} />
    </DefaultLayout>
  );
});

export default ClassPreview;
