import React, { FC, memo } from "react";

import cx from "classnames";
import { useField, FieldHookConfig } from "formik";
import Rate from "rc-rate";

import { IconStarActive } from "../Icon/Icon";
import { Caption } from "../Typography/Typography";
import styles from "./StarRating.module.scss";

export type StarRatingProps = {
  className?: string;
  label?: string;
} & FieldHookConfig<number>;

const StarRating: FC<StarRatingProps> = memo(
  ({ className, label, ...rest }) => {
    const [, meta, helpers] = useField(rest);

    const { setValue } = helpers;

    const handleChange = (value: number) => {
      setValue(value);
    };

    return (
      <div className={cx(styles.wrapper, className)}>
        {!!label && <Caption>{label}</Caption>}
        <div>
          <Rate
            character={<IconStarActive />}
            value={meta.value}
            onChange={handleChange}
          />
        </div>
      </div>
    );
  }
);

export default StarRating;
