import { useEffect } from "react";

import { useMedia, QueryResults } from "react-media";
import { useDispatch, useSelector } from "react-redux";
import { useMount } from "react-use";

import { GLOBAL_MEDIA_QUERIES } from "@app/constants/breakpoints";
import { RootState } from "@app/redux/root-reducer";
import { GlobalMediaQueriesDef } from "@app/types/breakpoint.types";

import { GridViewSettingsEnum } from "../constants/video.enums";
import {
  setPagination,
  setGridViewSetting,
} from "../redux/video-settings.slice";

const fullGridViewSettings = (
  matches: QueryResults<Partial<GlobalMediaQueriesDef>>
) => {
  if (matches.desktopSmall) return 9;
  if (matches.tabletLandscape) return 6;
  return 0;
};

const bottomGridViewSettings = (
  matches: QueryResults<Partial<GlobalMediaQueriesDef>>
) => {
  if (matches.desktopSmall) return 6;
  if (matches.tabletLandscape) return 4;
  return 0;
};

/**
 * A hook to control the number of attendees to be visible on the screen
 * based on the selected grid view
 * @param gridView
 */
function useGridViewAttendees(isUserModerator: boolean) {
  const gridView = useSelector(
    (state: RootState) => state.videoSettings.gridView
  );
  const { desktopSmall, tabletLandscape } = useMedia({
    queries: GLOBAL_MEDIA_QUERIES,
  });

  const dispatch = useDispatch();

  useMount(() => {
    // If user is moderator, then default grid view should be FULL GRID VIEW
    if (isUserModerator) {
      dispatch(
        setGridViewSetting({ gridView: GridViewSettingsEnum.FULL_GRID_VIEW })
      );
    }
  });

  useEffect(() => {
    let perPage = 0;
    switch (gridView) {
      case GridViewSettingsEnum.FULL_GRID_VIEW:
        perPage = fullGridViewSettings({ desktopSmall, tabletLandscape });
        break;
      default:
      case GridViewSettingsEnum.BOTTOM_GRID_VIEW:
        perPage = bottomGridViewSettings({ desktopSmall, tabletLandscape });
        break;
    }
    dispatch(
      setPagination({
        pagination: { perPage, page: 1 },
      })
    );
  }, [gridView, desktopSmall, tabletLandscape, dispatch]);
}

export default useGridViewAttendees;
