import { RouteItemDef } from "@app/types/route.types";

import { ClassesPathEnum } from "../constants/classes.paths";
import ScheduleScreen from "../screens/ScheduleScreen/ScheduleScreen";

const SCHEDULE_SCREEN: RouteItemDef = {
  id: "schedule",
  path: ClassesPathEnum.SCHEDULE,
  component: ScheduleScreen,
  metaTitle: "schedule.metaTitle",
};

const CLASSES_ROUTES = [SCHEDULE_SCREEN];

export default CLASSES_ROUTES;
