import React, { memo } from "react";

import Avatar from "@app/components/atoms/Avatar/Avatar";
import { Subtitle, Title } from "@app/components/atoms/Typography/Typography";
import Section from "@app/components/layouts/Section/Section";
import { ThemeEnum } from "@app/constants/theme.constants";
import { getTheme } from "@app/helpers/util.helpers";
import { BasicUserDef } from "@app/types/api.types";

import styles from "./FeedbackModalHeader.module.scss";

export interface FeedbackModalHeaderProps {
  avatarImage?: BasicUserDef["image"];
  sessionTitle: string | null;
  practitioner: string | null;
}

const FeedbackModalHeader = memo(
  ({ avatarImage, sessionTitle, practitioner }: FeedbackModalHeaderProps) => {
    return (
      <Section theme={ThemeEnum.WHITE} className={styles.wrapper}>
        <Avatar
          className={getTheme(ThemeEnum.KHAKI)}
          avatarImage={avatarImage}
        />
        <div className={styles.text}>
          {!!sessionTitle && <Subtitle level={2}>{sessionTitle}</Subtitle>}
          {!!practitioner && (
            <Title level={3} className={styles.title}>
              {practitioner}
            </Title>
          )}
        </div>
      </Section>
    );
  }
);

export default FeedbackModalHeader;
