export enum GtmEvents {
  PAGE_VIEW = "pageview",
  SIGN_UP = "sign_up",
  LOGIN = "login",
  VIEW_ITEM = "view_item",
  ADD_TO_CART = "add_to_cart",
  BEGIN_CHECKOUT = "begin_checkout",
  PURCHASE = "purchase",
  RATED_FIRST = "rated_first",
  RATED_SECOND = "rated_second",
}
