import { useEffect, useState } from "react";

import { ContentModel } from "@app/constants/contentful.constants";

import { contentfulApi } from "../api/contentful.api";

function useEntriesByType<T = any>(contentType: ContentModel) {
  const [entries, setEntries] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);

  async function fetchEntries(type: ContentModel) {
    const data = await contentfulApi.getEntries({ type });
    return data;
  }

  useEffect(() => {
    fetchEntries(contentType)
      .then((result: any) => {
        if (result) {
          setEntries(result.items);
        }
      })
      .catch(error => console.error(error))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentType]);

  return { entries, loading };
}

export default useEntriesByType;
