import React, { memo } from "react";

import { Formik, Form } from "formik";
import qs from "query-string";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { useMount } from "react-use";
import * as yup from "yup";

import { ReactComponent as Logo } from "@app/assets/images/logo.svg";
import Button, { ButtonSizeEnum } from "@app/components/atoms/Button/Button";
import FormItem from "@app/components/atoms/FormItem/FormItem";
import FormMessage, {
  FormMessageType,
} from "@app/components/atoms/FormMessage/FormMessage";
import { IconArrowRight } from "@app/components/atoms/Icon/Icon";
import { Title } from "@app/components/atoms/Typography/Typography";
import { RootState } from "@app/redux/root-reducer";
import { AppPathEnums } from "@app/routes/routes.constants";

import AuthFormButton from "../../components/AuthFormButton/AuthFormButton";
import TermsAndConditions from "../../components/TermsAndConditions/TermsAndConditions";
import { AuthPathsEnum } from "../../constants/auth.paths";
import AuthBoxLayout from "../../layouts/AuthBoxLayout/AuthBoxLayout";
import { login, resetError } from "../../redux/auth.slice";
import styles from "./SignInScreen.module.scss";

interface FormValuesDef {
  email: string;
  password: string;
}

const validationScheme = yup.object({
  email: yup
    .string()
    .email("authInputs.emailErrorInvalid")
    .required("authInputs.emailErrorRequired"),
  password: yup.string().required("authInputs.passwordErrorRequired"),
});

const SignInScreen = memo(() => {
  const location = useLocation<{ prevPath: string }>();

  const { isAuthenticated, error } = useSelector(
    (state: RootState) => state.auth
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const params = qs.parse(location.search);

  const initialValues: FormValuesDef = {
    email: "",
    password: "",
  };

  useMount(() => {
    dispatch(resetError());
  });

  const handleSubmit = async (values: FormValuesDef) => {
    await dispatch(login(values));
  };

  const handleForgotPassword = () => {
    history.push(AuthPathsEnum.REQUEST_RESET_PASSWORD);
  };

  if (isAuthenticated) {
    if (params?.redirect) {
      return <Redirect to={params.redirect} />;
    }

    return (
      <Redirect to={location.state?.prevPath || AppPathEnums.ROOT_ROUTE} />
    );
  }

  return (
    <AuthBoxLayout
      header={
        <>
          <Logo className={styles.logo} />
          <Title className={styles.title}>{t("signIn.title")}</Title>
          <Button
            label={t("signIn.createAccountButton")}
            size={ButtonSizeEnum.TEXT}
            to={AuthPathsEnum.CREATE_SEEKER}
            endIcon={<IconArrowRight />}
          />
        </>
      }
      footer={<TermsAndConditions />}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationScheme}
      >
        {({ isSubmitting }) => (
          <Form noValidate>
            {error && (
              <FormMessage
                type={FormMessageType.ERROR}
                message={t("signIn.apiErrorGeneral")}
              />
            )}
            <FormItem
              id="email"
              name="email"
              label={t("authInputs.emailLabel")}
              type="email"
              placeholder={t("authInputs.emailPlaceholder")}
              autoComplete="off"
            />
            <FormItem
              id="password"
              name="password"
              label={t("authInputs.passwordLabel")}
              type="password"
              placeholder={t("authInputs.passwordPlaceholder")}
              autoComplete="off"
              action={t("signIn.passwordForgetButton")}
              actionOnClick={handleForgotPassword}
            />
            <AuthFormButton isSubmitting={isSubmitting} />
          </Form>
        )}
      </Formik>
    </AuthBoxLayout>
  );
});

export default SignInScreen;
