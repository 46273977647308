import * as React from "react";

function SvgEmail(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.584 6H4.416L12 10.815 19.584 6zM4 8.105V18h16V8.105l-7.464 4.74-.536.34-.536-.34L4 8.104zM2 4h20v16H2V4z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgEmail = React.memo(SvgEmail);
export default MemoSvgEmail;
