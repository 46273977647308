import * as React from "react";

function SvgRewatch(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.771 4.945A8 8 0 0012 4V2a10 10 0 11-7.071 17.071l1.414-1.414a8 8 0 109.428-12.712zm-11.768 6.85a8 8 0 00.99 4.065l-1.752.965a10 10 0 01-1.238-5.082l2 .051zm.55-2.716a8 8 0 01.81-1.545l-1.66-1.116A10 10 0 002.69 8.349l1.862.73zM8.97 15.5l7.722-3.75L8.97 8v7.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgRewatch = React.memo(SvgRewatch);
export default MemoSvgRewatch;
