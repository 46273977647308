import * as React from "react";

function SvgCalendar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeWidth={2}
        d="M4 4h16v16H4zM8 1.5V7M16 1.5V7"
      />
    </svg>
  );
}

const MemoSvgCalendar = React.memo(SvgCalendar);
export default MemoSvgCalendar;
