import React, { memo, ReactNode, useCallback } from "react";

import { useTranslation } from "react-i18next";

import Button, { ButtonTypeEnum } from "@app/components/atoms/Button/Button";
import Loading from "@app/components/atoms/Loading/Loading";
import { PaginationDef } from "@app/types/pagination.types";

import styles from "./List.module.scss";

export interface ListProps {
  onLoadMore?: () => void;
  pagination?: PaginationDef;
  isLoading?: boolean;
  isLoadingMore?: boolean;
  showPagination?: boolean;
  children?: ReactNode;
}

const List = memo(
  ({
    children,
    onLoadMore,
    pagination,
    isLoading = false,
    isLoadingMore,
    showPagination = true,
  }: ListProps) => {
    const { t } = useTranslation();
    const hasMore =
      pagination && pagination.current_page < pagination.last_page;

    const handleLoadMore = useCallback(() => {
      onLoadMore?.();
    }, [onLoadMore]);

    return (
      <Loading isLoading={isLoading && !isLoadingMore}>
        <div className={styles.container}>
          {children}
          {showPagination && hasMore && (
            <Button
              label={t("default.showMore")}
              buttonType={ButtonTypeEnum.PRIMARY}
              className={styles.button}
              onClick={handleLoadMore}
              loading={isLoadingMore}
            />
          )}
        </div>
      </Loading>
    );
  }
);

export default List;
