export enum DateFormats {
  SHORT = "dd MMM yyyy",
  MEDIUM = "MMM dd h:mma",
  LONG = "EEE dd MMM - h:mma",
  LONG_WITH_YEAR = "dd MMM yyyy - h:mma",
  LONG_MODAL = "h:mm a - EEE dd MMM",
}

export enum TimeFormats {
  LONG = "hh:mm a",
}
