import React, { memo } from "react";

import Loading from "@app/components/atoms/Loading/Loading";
import { useSingleEntry } from "@app/features/contentful/contentful";
import { ContentfulMedia } from "@app/types/contentful.types";

import Newsletter from "./Newsletter";

interface NewsletterContentfulProps {
  entryID: string;
}

interface NewsletterDataModel {
  title: string;
  subtitle: string;
  background: ContentfulMedia;
  thankYouText: string;
}

const NewsletterContentful = memo(({ entryID }: NewsletterContentfulProps) => {
  const { entry, loading } = useSingleEntry<NewsletterDataModel>(entryID);

  return (
    <Loading isLoading={loading}>
      {entry && (
        <Newsletter
          title={entry.title}
          subtitle={entry.subtitle}
          backgroundImage={entry.background?.fields.file.url}
          thankYouText={entry.thankYouText}
        />
      )}
    </Loading>
  );
});

export default NewsletterContentful;
