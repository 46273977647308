import * as React from "react";

function SvgPyramid(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.5 16L9.917 5l6.416 11H3.5z"
        stroke="#D9B677"
        strokeWidth={1.5}
      />
    </svg>
  );
}

const MemoSvgPyramid = React.memo(SvgPyramid);
export default MemoSvgPyramid;
