import React from "react";

import { Col, Row } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMount } from "react-use";

import List from "@app/components/atoms/List/List";
import { Paragraph, Title } from "@app/components/atoms/Typography/Typography";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import DefaultLayout from "@app/components/layouts/DefaultLayout/DefaultLayout";
import Section from "@app/components/layouts/Section/Section";
import ExperienceList from "@app/components/renderings/ExperienceList/ExperienceList";
import { ThemeEnum } from "@app/constants/theme.constants";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";

import {
  getExperiences,
  getExperiencesAndAppend,
} from "../../redux/experiences.slice";
import styles from "./ExperiencesScreen.module.scss";

const ExperiencesScreen = () => {
  const { t } = useTranslation();
  const { experiences, loading, loadingMore } = useSelector(
    (state: RootState) => state.experiences
  );
  const dispatch = useAppDispatch();

  const handleLoadMore = () => {
    dispatch(
      getExperiencesAndAppend({ page: experiences.pagination.current_page + 1 })
    );
  };

  useMount(() => {
    dispatch(getExperiences({ page: 1 }));
  });

  return (
    <DefaultLayout>
      <Section theme={ThemeEnum.KHAKI} className={styles.container}>
        <ContentWrapper className={styles.titleContainer}>
          <Title level={1} isGoldGradient className={styles.title}>
            {t("experiencesPage.pageTitle")}
          </Title>
          <Row justify="center" className={styles.subTitle}>
            <Col xs={12} lg={10} xl={8}>
              <Paragraph level={1}>
                {t("experiencesPage.pageSubTitle")}
              </Paragraph>
            </Col>
          </Row>
        </ContentWrapper>
        <List
          isLoading={loading}
          onLoadMore={handleLoadMore}
          pagination={experiences.pagination}
          isLoadingMore={loadingMore}
        >
          <ExperienceList items={experiences.data} />
        </List>
      </Section>
    </DefaultLayout>
  );
};

export default ExperiencesScreen;
