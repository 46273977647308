import React, { FC, memo } from "react";

import cx from "classnames";
import { FieldHookConfig, useField } from "formik";
import { useTranslation } from "react-i18next";

import Input from "@app/components/atoms/Input/Input";
import Select, { SelectOptionDef } from "@app/components/atoms/Select/Select";
import Textarea from "@app/components/atoms/Textarea/Textarea";
import { Caption, Subtitle } from "@app/components/atoms/Typography/Typography";
import { FormFieldDef } from "@app/types/form.types";

import Checkbox from "../Checkbox/Checkbox";
import FormMessage, { FormMessageType } from "../FormMessage/FormMessage";
import RadioButton from "../RadioButton/RadioButton";
import styles from "./FormItem.module.scss";

export type FormItemProps = {
  /**
   * The label for the input
   */
  label?: string;
  /**
   * A caption shown below for the input (optional)
   */
  caption?: string;
  /**
   * An action shown in the top right (optional)
   */
  action?: string;
  /**
   * Callback for when action is clicked (optional)
   */
  actionOnClick?: () => void;
  /**
   * Options List for the select dropdown
   */
  selectOptions?: SelectOptionDef[];
  /**
   * Hide the error message for the form item
   */
  hideError?: boolean;
  /**
   * Pass in an icon for the Radio item
   */
  radioIcon?: JSX.Element;
  /**
   * Custom class name for the wrapper
   */
  className?: string;
  /**
   * Custom class name for the item
   */
  itemClassName?: string;
  /**
   * On click event for the item
   */
  onClick?: () => void;
} & FieldHookConfig<string>;

/**
 * Custom formik input with label
 */
const FormItem: FC<FormItemProps> = ({
  id,
  label,
  caption,
  action,
  selectOptions,
  actionOnClick,
  hideError = false,
  radioIcon,
  className,
  itemClassName,
  ...rest
}) => {
  const [field, meta] = useField(rest);
  const { t } = useTranslation();

  const inputClasses = cx(itemClassName, {
    [styles.spacingUntilErrorShown]:
      !caption && (!meta.touched || !meta.error) && !hideError,
    [styles.fieldError]: meta.touched && meta.error,
  });

  const inputField = () => {
    if (rest.type === "select") {
      return selectOptions && selectOptions.length > 0 ? (
        <Select
          field={field as FormFieldDef}
          className={inputClasses}
          selectOptions={selectOptions}
          {...(rest as JSX.IntrinsicElements["select"])}
        />
      ) : null;
    }
    if (rest.type === "textarea") {
      return (
        <Textarea
          field={field as FormFieldDef}
          className={inputClasses}
          {...(rest as JSX.IntrinsicElements["textarea"])}
        />
      );
    }
    if (rest.type === "checkbox") {
      return (
        <Checkbox
          field={field as FormFieldDef}
          className={inputClasses}
          label={label}
          checked={field.checked}
          {...(rest as JSX.IntrinsicElements["input"])}
        />
      );
    }
    if (rest.type === "radio") {
      return (
        <RadioButton
          id={id}
          label={label}
          icon={radioIcon}
          field={field as FormFieldDef}
          className={itemClassName}
          {...(rest as JSX.IntrinsicElements["input"])}
        />
      );
    }
    return (
      <Input
        field={field as FormFieldDef}
        className={inputClasses}
        {...(rest as JSX.IntrinsicElements["input"])}
      />
    );
  };

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.labels}>
        {!!label && rest?.type !== "checkbox" && (
          <label className={styles.label} htmlFor={id}>
            <Subtitle level={3}>{label}</Subtitle>
          </label>
        )}
        {action && (
          <Subtitle
            onClick={actionOnClick}
            className={styles.action}
            level={3}
            isGold
          >
            {action}
          </Subtitle>
        )}
      </div>
      {inputField()}
      {meta.touched && meta.error && (
        <FormMessage type={FormMessageType.ERROR} message={t(meta.error)} />
      )}
      {caption && <Caption className={styles.caption}>{caption}</Caption>}
    </div>
  );
};

export default memo(FormItem);
