import * as React from "react";

function SvgSearch(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 10a7 7 0 1114 0 7 7 0 01-14 0zm7-9a9 9 0 105.618 16.032l4.675 4.675 1.414-1.414-4.675-4.675A9 9 0 0011 1z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgSearch = React.memo(SvgSearch);
export default MemoSvgSearch;
