import {
  SubscriberProperties,
  PublisherProperties,
  WidgetProperties,
} from "@opentok/client";

const VIDEO_PROPERTIES_WIDGET: WidgetProperties = {
  showControls: false,
  width: "100%",
  height: "100%",
};

export const VIDEO_SUBSCRIBER_PROPERTIES: SubscriberProperties = {
  preferredResolution: {
    width: 320,
    height: 240,
  },
  ...VIDEO_PROPERTIES_WIDGET,
};

export const VIDEO_PRACTITIONER_PROPERTIES: SubscriberProperties = {
  ...VIDEO_PROPERTIES_WIDGET,
};

export const VIDEO_PUBLISHER_PROPERTIES: PublisherProperties = {
  ...VIDEO_PROPERTIES_WIDGET,
};
