import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { GridViewSettingsEnum } from "../constants/video.enums";
import { AttendeesPaginationDef } from "../types/session.types";

interface VideoSettingsState {
  pagination: AttendeesPaginationDef;
  gridView: GridViewSettingsEnum;
}

const initialState: VideoSettingsState = {
  pagination: { page: 1, perPage: 1, totalAttendees: 1 },
  gridView: GridViewSettingsEnum.BOTTOM_GRID_VIEW,
};

const videoSettingsSlice = createSlice({
  name: "videoSettings",
  initialState,
  reducers: {
    setPagination(
      state,
      action: PayloadAction<{ pagination: Partial<AttendeesPaginationDef> }>
    ) {
      state.pagination = {
        ...state.pagination,
        ...action.payload.pagination,
      };
    },
    setGridViewSetting(
      state,
      action: PayloadAction<{ gridView: GridViewSettingsEnum }>
    ) {
      state.gridView = action.payload.gridView;
    },
  },
});

export const { setGridViewSetting, setPagination } = videoSettingsSlice.actions;

export default videoSettingsSlice.reducer;
