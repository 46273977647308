import React, { useRef } from "react";

import { use100vh } from "react-div-100vh";

import Accordian from "@app/components/atoms/Accordian/Accordian";
import Modal from "@app/components/atoms/Modal/Modal";
import { Title } from "@app/components/atoms/Typography/Typography";
import { FaqSectionDef } from "@app/types/faq.types";

import { accordionMapper } from "../FaqContainer";
import styles from "./FaqSectionModal.module.scss";

interface FaqSectionModalProps {
  visible: boolean;
  activeSection: FaqSectionDef;
  onClose: () => void;
}

const HEADER_OFFSET_PADDING = 16;
const MODAL_BUTTON_HEIGHT = 40;

const FaqSectionModal = ({
  visible,
  activeSection,
  onClose,
}: FaqSectionModalProps) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const height = use100vh();
  const headerHeight = headerRef?.current?.clientHeight ?? 24;

  const contentHeight = height
    ? height - (headerHeight + HEADER_OFFSET_PADDING + MODAL_BUTTON_HEIGHT)
    : "100%";

  return (
    <Modal
      visible={visible}
      className={styles.modal}
      showCloseButton
      onClose={onClose}
    >
      <div className={styles.header} ref={headerRef}>
        <Title level={3}>{activeSection.title}</Title>
      </div>
      <div className={styles.content} style={{ height: contentHeight }}>
        <Accordian
          items={accordionMapper(activeSection.entries)}
          itemClassName={styles.entry}
          accordian={false}
          defaultActiveKey={activeSection.entries?.[0].id}
          headerClassName={styles.question}
          contentClassName={styles.answer}
          iconClassName={styles.icon}
        />
      </div>
    </Modal>
  );
};

export default FaqSectionModal;
