import * as React from "react";

function SvgCheckmark1(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.631 10.238L4.197 7.803l-.707.707 2.787 2.788.354.354.354-.354 5.656-5.657-.707-.707-5.303 5.304z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgCheckmark1 = React.memo(SvgCheckmark1);
export default MemoSvgCheckmark1;
