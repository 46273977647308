import React, { memo } from "react";

import { IMAGE_TYPES } from "@app/constants/image";
import { useSingleEntry } from "@app/features/contentful/contentful";
import { getImageUrl } from "@app/helpers/image.helpers";
import { ContentfulMedia } from "@app/types/contentful.types";

import HeroVideo from "./HeroVideo";

interface HeroVideoContentfulProps {
  entryID: string;
}

interface TitleModel {
  sys: {
    id: string;
  };
  fields?: {
    text: string;
  };
}
interface HeroVideoDataModel {
  imageBackground: ContentfulMedia;
  titles: TitleModel[];
  title: string;
}

const HeroVideoContentful = memo(({ entryID }: HeroVideoContentfulProps) => {
  const { entry } = useSingleEntry<HeroVideoDataModel>(entryID);

  const getTitles = () => {
    if (!entry?.titles?.length && entry?.title) return [entry.title];
    return (
      entry?.titles
        // Only published items will have fields
        ?.filter(item => !!item.fields)
        .map(title => title.fields?.text ?? "")
    );
  };

  if (!entry) return null;

  return (
    <HeroVideo
      backgroundImage={
        entry.imageBackground?.fields.file &&
        getImageUrl(entry.imageBackground?.fields.file.url, IMAGE_TYPES.BANNER)
      }
      titles={getTitles()}
    />
  );
});

export default HeroVideoContentful;
