import * as React from "react";

function SvgLogout(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 3h9v2H4v14h7v2H2V3zm14.15 10H8v-2h8.15l-2.775-2.22 1.25-1.56 5 4 .976.78-.976.78-5 4-1.25-1.56L16.15 13z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgLogout = React.memo(SvgLogout);
export default MemoSvgLogout;
