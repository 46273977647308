import * as React from "react";

function SvgNavigation(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 7H3V5h18v2zm0 6H3v-2h18v2zM3 19h18v-2H3v2z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgNavigation = React.memo(SvgNavigation);
export default MemoSvgNavigation;
