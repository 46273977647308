import axios, { AxiosRequestConfig, AxiosError } from "axios";

import { ApiStatusCodes } from "@app/constants/api.constants";
import { ENV } from "@app/constants/config";
import { ArticlesEndpointsEnum } from "@app/features/articles/articles";
import {
  authApi,
  AuthEndpointsEnum,
  clearTokens,
  clearUser,
  getTokens,
  saveTokens,
} from "@app/features/auth/auth";
import { EcommerceEndpointsEnum } from "@app/features/ecommerce/ecommerce";
import { ExperiencesEndpointsEnum } from "@app/features/experiences/experiences";
import { PractitionersEndpointsEnum } from "@app/features/practitioners/practitioners";
import { SearchEndpointsEnum } from "@app/features/search/search";
import { ClassesEndpointsEnum } from "@app/features/users/users";
import { bugsnag } from "@app/helpers/bugsnag.helpers";
import store from "@app/redux/store";

/**
 * All the endpoint that do not require an access token
 */
const anonymousEndpoints = [
  AuthEndpointsEnum.LOGIN.toString(),
  AuthEndpointsEnum.REGISTER_SEEKER.toString(),
  AuthEndpointsEnum.REQUEST_RESET_PASSWORD.toString(),
  AuthEndpointsEnum.RESET_PASSWORD.toString(),
  EcommerceEndpointsEnum.PRODUCTS.toString(),
  EcommerceEndpointsEnum.PRODUCT_DETAILS.toString(),
  PractitionersEndpointsEnum.PRACTITIONERS.toString(),
  ExperiencesEndpointsEnum.EXPERIENCES.toString(),
  SearchEndpointsEnum.SEARCH.toString(),
  ClassesEndpointsEnum.ALL_CLASSES.toString(),
  ArticlesEndpointsEnum.ARTICLES.toString(),
];

let isRefreshing = false;
let subscribers: any[] = [];

function onAccessTokenFetched(token: string) {
  subscribers.forEach(callback => {
    callback(token);
  });
}

function addSubscriber(callback: (token: string) => void) {
  subscribers.push(callback);
}

export const getRefreshedToken = () => {
  const { accessToken, isTokenExpired, tokenType } = getTokens();

  if (isTokenExpired && !isRefreshing) {
    isRefreshing = true;
    authApi
      .refreshAccessToken()
      .then(response => {
        if (response.data?.token) {
          saveTokens(response.data.token);
          isRefreshing = false;
          onAccessTokenFetched(response.data.token.accessToken);
          subscribers = [];
        }
      })
      .catch(() => {
        clearTokens();
        store.dispatch(clearUser());
      });
  }

  return { accessToken, tokenType, isTokenExpired };
};

/**
 * Adds authorization headers to API calls
 * @param {AxiosRequestConfig} request
 */
const authInterceptor = async (request: AxiosRequestConfig) => {
  const isAnonymous = anonymousEndpoints.some(endpoint =>
    request.url?.startsWith(endpoint)
  );

  const isRefreshTokenRequest =
    request.url === AuthEndpointsEnum.REFRESH_TOKEN.toString();

  const { accessToken, tokenType, isTokenExpired } = getRefreshedToken();

  if (isTokenExpired && !isRefreshTokenRequest) {
    const retryOriginalRequest = new Promise(resolve => {
      addSubscriber(token => {
        request.headers.Authorization = `${tokenType} ${token}`;
        resolve(request);
      });
    });
    return retryOriginalRequest as AxiosRequestConfig;
  }

  if (accessToken) {
    request.headers.Authorization = `${tokenType} ${accessToken}`;
    return request;
  }

  if (!accessToken && !isAnonymous) {
    console.error("UNAUTHORIZED");
    return Promise.reject(ApiStatusCodes.UNAUTHORIZED);
  }

  return request;
};

/**
 * Axios error interceptor
 * @param {AxiosError} axiosError
 */
const errorInterceptor = (axiosError: AxiosError) => {
  if (axiosError?.response?.data?.message) {
    // Handle error here
    const { response, request } = axiosError;
    bugsnag.notify(
      new Error(axiosError.response.data.message as string),
      event => {
        event.addMetadata("request", request ?? {});
        event.addMetadata("response", response ?? {});
      }
    );
  }
  return Promise.reject(axiosError);
};

/** Setup an API instance */
export const api = axios.create({
  baseURL: ENV.API_HOST,
  headers: { "Content-Type": "application/json" },
});

/** Add interceptor */
api.interceptors.request.use(authInterceptor);
api.interceptors.response.use(res => res, errorInterceptor);
