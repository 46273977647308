import React, { memo } from "react";

import BlankLayout from "@app/components/layouts/BlankLayout/BlankLayout";
import DefaultLayout from "@app/components/layouts/DefaultLayout/DefaultLayout";
import {
  StaticPageComponents,
  StaticPageModel,
} from "@app/components/renderings/StaticPageLoader/StaticPageLoader";
import { LayoutsEnum } from "@app/constants/layout";

type StaticPagePreviewProps = {
  data: StaticPageModel;
};

const StaticPagePreview = memo(({ data }: StaticPagePreviewProps) => {
  const Layout =
    data?.layout === LayoutsEnum.BLANK ? BlankLayout : DefaultLayout;

  return (
    <Layout>
      <StaticPageComponents components={data?.components} />
    </Layout>
  );
});

export default StaticPagePreview;
