import * as React from "react";

function SvgDirect(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.53 6H5.47L12 11.805 18.53 6zM4 7.37V18h16V7.37l-7.336 6.52-.664.59-.664-.59L4 7.37zM2 4h20v16H2V4z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgDirect = React.memo(SvgDirect);
export default MemoSvgDirect;
