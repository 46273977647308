import React, { ReactNode } from "react";

import { useTranslation } from "react-i18next";

import Button, { ButtonTypeEnum } from "@app/components/atoms/Button/Button";
import { IconError } from "@app/components/atoms/Icon/Icon";
import { Caption } from "@app/components/atoms/Typography/Typography";

import Modal, { ModalProps } from "../../Modal";
import styles from "./ConfirmationModal.module.scss";

export interface ConfirmationModalProps extends Omit<ModalProps, "subtitle"> {
  title: string;
  subtitle: ReactNode;
  visible: boolean;
  onConfirm?: () => void;
  hideCancel?: boolean;
  confirmButtonLabel?: string;
}

const ConfirmationModal = ({
  title,
  subtitle,
  visible,
  onConfirm,
  onClose,
  hideCancel,
  confirmButtonLabel,
  ...rest
}: ConfirmationModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={title}
      visible={visible}
      headerCenter
      icon={
        <div className={styles.iconWrapper}>
          <IconError className={styles.icon} />
        </div>
      }
      onClose={onClose}
      {...rest}
    >
      <div className={styles.container}>
        <Caption className={styles.caption}>{subtitle}</Caption>
        <div className={styles.buttons}>
          {!hideCancel && (
            <Button
              buttonType={ButtonTypeEnum.SECONDARY}
              label={t("default.cancel")}
              className={styles.button}
              onClick={onClose}
              fullWidth
            />
          )}
          <Button
            buttonType={ButtonTypeEnum.PRIMARY}
            label={confirmButtonLabel ?? t("default.continue")}
            className={styles.button}
            onClick={onConfirm}
            fullWidth
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
