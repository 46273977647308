import React, { memo } from "react";

import { useTranslation } from "react-i18next";

import DefaultLayout from "@app/components/layouts/DefaultLayout/DefaultLayout";
import ExperienceAbout from "@app/components/renderings/ExperienceAbout/ExperienceAbout";
import ExperienceHero from "@app/components/renderings/ExperienceHero/ExperienceHero";
import ExperienceModalities from "@app/components/renderings/ExperienceModalities/ExperienceModalities";
import QuotesCarousel from "@app/components/renderings/QuotesCarousel/QuotesCarousel";
import { ThemeEnum } from "@app/constants/theme.constants";
import {
  ExperienceDef,
  ModalityDef,
} from "@app/features/experiences/experiences";
import { ContentfulMedia } from "@app/types/contentful.types";

import {
  mapTestimonials,
  TestimonialField,
} from "../PractitionerPreview/PractitionerPreview";

type ModalityField = {
  fields: Pick<ModalityDef, "description" | "quote" | "shortDescription"> & {
    image: ContentfulMedia;
    modality: string;
  };
};
type ExperienceContentModel = Pick<
  ExperienceDef,
  "name" | "shortDescription" | "description" | "tagLine"
> & {
  heroImage: ContentfulMedia;
  image: ContentfulMedia;
  modalities: ModalityField[];
  testimonials: TestimonialField[];
};

interface ExperiencePreviewProps {
  data: ExperienceContentModel;
}
const ExperiencePreview = memo(
  ({ data: experienceModel }: ExperiencePreviewProps) => {
    const { t } = useTranslation();

    const mapModalities = (
      data: ModalityField[]
    ): ExperienceDef["modalities"] =>
      data?.map(modality => ({
        ...modality.fields,
        name: modality.fields.modality,
        image: modality?.fields?.image?.fields?.file?.url,
        id: 0,
      }));

    const mapExperience = (data: ExperienceContentModel): ExperienceDef => ({
      ...data,
      heroImage: data?.heroImage?.fields?.file?.url,
      image: data?.image?.fields?.file?.url,
      modalities: mapModalities(data.modalities),
      id: 0,
      testimonials: mapTestimonials(data.testimonials),
    });

    const experience = mapExperience(experienceModel);

    return (
      <DefaultLayout>
        {experience.heroImage && (
          <ExperienceHero name={experience.name} image={experience.heroImage} />
        )}
        <ExperienceAbout experience={experience} />
        {experience.modalities && experience.modalities.length > 0 && (
          <ExperienceModalities
            modalities={experience.modalities}
            title={t("experience.modalitiesTitle", {
              name: experience.name,
            })}
          />
        )}
        {experience.testimonials && (
          <QuotesCarousel
            slideItems={experience.testimonials}
            theme={ThemeEnum.RED_ON_ORANGE}
          />
        )}
      </DefaultLayout>
    );
  }
);

export default ExperiencePreview;
