import * as React from "react";

function SvgDownload(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.452 9.584l2.437 2.572-.012-9.153 2-.003.012 9.163 2.444-2.58 1.452 1.376-4.167 4.397-.726.766-.725-.766L7 10.959l1.452-1.375zM3 15v5h18v-5h-2v3H5v-3H3z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgDownload = React.memo(SvgDownload);
export default MemoSvgDownload;
