import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useUnmount } from "react-use";

import Loading from "@app/components/atoms/Loading/Loading";
import DefaultLayout from "@app/components/layouts/DefaultLayout/DefaultLayout";
import Section from "@app/components/layouts/Section/Section";
import ExperienceAbout from "@app/components/renderings/ExperienceAbout/ExperienceAbout";
import ExperienceHero from "@app/components/renderings/ExperienceHero/ExperienceHero";
import ExperienceModalities from "@app/components/renderings/ExperienceModalities/ExperienceModalities";
import Experiences from "@app/components/renderings/Experiences/Experiences";
import QuotesCarousel from "@app/components/renderings/QuotesCarousel/QuotesCarousel";
import SessionsByFilter from "@app/components/renderings/SessionsByFilter/SessionsByFilter";
import { ClassTypesEnum } from "@app/constants/classes";
import { ThemeEnum } from "@app/constants/theme.constants";
import {
  carouselItemsMapper,
  testimonialsMapper,
} from "@app/helpers/practitioners.helpers";
import { isValidId } from "@app/helpers/util.helpers";
import useMetaTitle from "@app/hooks/useMetaTitle";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";
import NoMatch from "@app/routes/components/NoMatch/NoMatch";

import {
  getExperienceById,
  clearExperience,
} from "../../redux/experiences.slice";

const ExperienceScreen = () => {
  const { t } = useTranslation();
  const { experienceId } = useParams<{ experienceId: string }>();
  const { experience, loading, error } = useSelector(
    (state: RootState) => state.experiences
  );
  const dispatch = useAppDispatch();

  useMetaTitle(experience?.name, !!experience);

  useEffect(() => {
    if (isValidId(experienceId)) {
      dispatch(getExperienceById(Number(experienceId)));
    }
  }, [dispatch, experienceId]);

  useUnmount(() => {
    dispatch(clearExperience());
  });

  if (error) return <NoMatch />;

  return (
    <DefaultLayout>
      <Section theme={ThemeEnum.KHAKI} fullHeight>
        <Loading isLoading={loading}>
          {experience && (
            <>
              {experience.heroImage && (
                <ExperienceHero
                  name={experience.name}
                  image={experience.heroImage}
                />
              )}
              <ExperienceAbout experience={experience} />
              {experience.modalities && experience.modalities.length > 0 && (
                <ExperienceModalities
                  modalities={experience.modalities}
                  title={t("experience.modalitiesTitle", {
                    name: experience.name,
                  })}
                />
              )}
              <SessionsByFilter
                title={t("experience.sessionsTitle", {
                  name: experience.name,
                })}
                filter={{ experiences: [experienceId] }}
              />
              {!!experience.practitioners?.length && (
                <Experiences
                  title={t("experience.carouselTitle", {
                    name: experience.name,
                  })}
                  slideItems={carouselItemsMapper(experience.practitioners)}
                />
              )}
              <SessionsByFilter
                title={t("experience.sessions1On1Title")}
                filter={{
                  experiences: [experienceId],
                  classTypes: [ClassTypesEnum.PRIVATE_SESSION],
                }}
              />
              {/* TODO: Add articles carousel */}
              <QuotesCarousel
                slideItems={testimonialsMapper(experience.testimonials)}
                theme={ThemeEnum.RED_ON_ORANGE}
              />
            </>
          )}
        </Loading>
      </Section>
    </DefaultLayout>
  );
};

export default ExperienceScreen;
