export interface ShippingAddressDef {
  name: string;
  surname: string;
  streetAndNumber: string;
  postalCode: string;
  country: string;
  city: string;
  region?: any;
}

export interface BillingAddressDef {
  name: string;
  surname: string;
  streetAndNumber: string;
  postalCode: string;
  country: string;
  city: string;
  region?: any;
}

export interface ItemDef {
  name: string;
  unitPrice: number;
  quantity: number;
  type: string;
  discountAmount: number;
  rateOfTaxIncludedInPrice: number;
  amount: number;
}

export interface InvoiceDef {
  shippingAddress: ShippingAddressDef;
  billingAddress: BillingAddressDef;
  email: string;
  language: string;
  currency: string;
  amount: number;
  targetId: string;
  items: ItemDef[];
}

export interface AvailablePaymentMethodDef {
  id: string;
  flow: string;
  name: string;
  checkoutUrl: string;
}

export interface AuthorizationDef {
  flow: string;
  confirmationSynchronicity: string;
  state: string;
  stateDescriptorCode?: any;
  url: string;
  card?: any;
}

export interface PaymentSessionDef {
  invoice: InvoiceDef;
  state: string;
  availablePaymentMethods: AvailablePaymentMethodDef[];
  id: string;
  paymentMethod: string;
  paymentAuthorizationRedirectUrl: string;
  authorization: AuthorizationDef;
}

export enum Status {
  Loading = "loading",
  Loaded = "loaded",
  Failed = "Failed",
}

export interface PaymentCardDef {
  id: string;
  brand: string;
  endingDigits: number;
  expiry: string;
}

export interface CreatePaymentIntentDef {
  publicToken: string;
  paymentMethodId?: string;
  paymentIntentId?: string;
}

export enum StripePaymentStatus {
  CANCELLED = "canceled",
  PROCESSING = "processing",
  SUCCEEDED = "succeeded",
}

export interface ConfirmSnipcartPaymentDef {
  publicToken: string;
  paymentIntentId: string;
}
