import { useEffect } from "react";

import { setMetaTitle } from "@app/helpers/router.helpers";

function useMetaTitle(title?: string | null, ready = true) {
  useEffect(() => {
    ready && setMetaTitle(title);
  }, [title, ready]);
}

export default useMetaTitle;
