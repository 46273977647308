import React, { memo, useEffect, useState } from "react";

import { Formik, Form, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useMount } from "react-use";

import { signUp } from "@app/api/newsletter.api";
import FormMessage, {
  FormMessageType,
} from "@app/components/atoms/FormMessage/FormMessage";
import { Title } from "@app/components/atoms/Typography/Typography";
import { NEWSLETTER_TAG } from "@app/components/renderings/Newsletter/Newsletter";
import { mapErrorFields } from "@app/helpers/util.helpers";
import { RootState } from "@app/redux/root-reducer";
import { AppPathEnums } from "@app/routes/routes.constants";

import AuthFormButton from "../../components/AuthFormButton/AuthFormButton";
import TermsAndConditions from "../../components/TermsAndConditions/TermsAndConditions";
import AuthBoxLayout from "../../layouts/AuthBoxLayout/AuthBoxLayout";
import { createSeeker, updateSeeker, resetError } from "../../redux/auth.slice";
import SeekerAccountForm, {
  seekerAccountValidation,
} from "./components/SeekerAccountForm/SeekerAccountForm";
import SeekerCredentialsForm, {
  seekerCredentialsValidation,
} from "./components/SeekerCredentialsForm/SeekerCredentialsForm";
import SeekerWelcome from "./components/SeekerWelcome/SeekerWelcome";
import SeekerWelcomeHeader from "./components/SeekerWelcomeHeader/SeekerWelcomeHeader";

interface FormValuesDef {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  practitionerReferenceCode: string;
}

enum StepsEnum {
  ACCOUNT = 1,
  CREDENTIALS = 2,
  WELCOME = 3,
}

const validationSchemes = [
  seekerAccountValidation,
  seekerCredentialsValidation,
];

const CreateSeekerScreen = memo(() => {
  const [activeStep, setActiveStep] = useState(StepsEnum.ACCOUNT);

  const { isAuthenticated, error, user } = useSelector(
    (state: RootState) => state.auth
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const initialValues: FormValuesDef = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    practitionerReferenceCode: "",
  };

  useMount(() => {
    dispatch(resetError());
  });

  useEffect(() => {
    if (isAuthenticated) {
      setActiveStep(StepsEnum.WELCOME);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (user?.receiveMarketingNews) {
      history.push(AppPathEnums.USER_HOME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.receiveMarketingNews]);

  const handleSubmit = async (
    values: FormValuesDef,
    actions: FormikHelpers<FormValuesDef>
  ) => {
    if (activeStep === StepsEnum.WELCOME) {
      await signUp({
        // eslint-disable-next-line @typescript-eslint/camelcase
        first_name: values.firstName,
        email: values.email,
        tags: NEWSLETTER_TAG,
      });
      await dispatch(updateSeeker({ receiveMarketingNews: true }));
    } else if (activeStep === StepsEnum.CREDENTIALS) {
      await dispatch(
        createSeeker(values, errors => {
          if (errors) {
            actions.setErrors(mapErrorFields(errors));

            if (errors.practitionerReferenceCode) {
              setActiveStep(StepsEnum.ACCOUNT);
            }
          }
        })
      );
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <AuthBoxLayout
      header={
        {
          [StepsEnum.ACCOUNT]: (
            <Title>{t("createAccount.titlePersonalInformation")}</Title>
          ),
          [StepsEnum.CREDENTIALS]: (
            <Title>{t("createAccount.titleUserCredentials")}</Title>
          ),
          [StepsEnum.WELCOME]: <SeekerWelcomeHeader user={user} />,
        }[activeStep]
      }
      footer={activeStep !== StepsEnum.WELCOME && <TermsAndConditions />}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchemes[activeStep - 1]}
      >
        {({ isSubmitting, errors }) => (
          <Form noValidate>
            {error && !(errors.email ?? errors.password) && (
              <FormMessage
                type={FormMessageType.ERROR}
                message={t("createAccount.apiErrorGeneral")}
              />
            )}
            {
              {
                [StepsEnum.ACCOUNT]: (
                  <>
                    <SeekerAccountForm
                      hasCodeError={!!errors.practitionerReferenceCode}
                    />
                    <AuthFormButton />
                  </>
                ),
                [StepsEnum.CREDENTIALS]: (
                  <>
                    <SeekerCredentialsForm />
                    <AuthFormButton isSubmitting={isSubmitting} />
                  </>
                ),
                [StepsEnum.WELCOME]: (
                  <SeekerWelcome isSubmitting={isSubmitting} />
                ),
              }[activeStep]
            }
          </Form>
        )}
      </Formik>
    </AuthBoxLayout>
  );
});

export default CreateSeekerScreen;
