import React, { ReactNode } from "react";

import cx from "classnames";
import { useSelector } from "react-redux";

import { RootState } from "@app/redux/root-reducer";

import { GridViewSettingsEnum } from "../../constants/video.enums";
import styles from "./ModeratorChatLayout.module.scss";

interface ModeratorChatLayoutProps {
  /** Hide the video in view, but still subscribe to their audio */
  isHidden?: boolean;
  children?: ReactNode;
}

const ModeratorChatLayout = ({
  isHidden,
  children,
}: ModeratorChatLayoutProps) => {
  const gridView = useSelector(
    (state: RootState) => state.videoSettings.gridView
  );

  return (
    <div
      className={cx({
        [styles.bottomGridView]:
          gridView === GridViewSettingsEnum.BOTTOM_GRID_VIEW,
        [styles.fullGridView]: gridView === GridViewSettingsEnum.FULL_GRID_VIEW,
        [styles.isHidden]: isHidden,
      })}
    >
      <div className={styles.innerContainer}>{children}</div>
    </div>
  );
};

export default ModeratorChatLayout;
