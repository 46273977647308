import { TagColorsDef, TagProps } from "@app/components/atoms/Tags/Tags";
import { ExperienceItemProps } from "@app/components/renderings/Experiences/components/ExperienceItem/ExperienceItem";
import { ExperienceDef } from "@app/features/experiences/experiences";
import {
  PractitionerDef,
  PRACTITIONER_PATH,
} from "@app/features/practitioners/practitioners";
import { TestimonialDef } from "@app/types/testimonials.types";

import { getExperienceLink } from "./experiences.helpers";
import { randomNumber, convertToSlug, getFullName } from "./util.helpers";

/**
 * Get practitioner link and append slug if present
 * @param {string} id id of practitioner from api
 * @param {string} slug slug url if present
 */
export const getPractitionerLink = (id: number, slug?: string) => {
  let link = "";
  link = `${PRACTITIONER_PATH}/${id}`;

  if (slug) {
    link = `${link}/${convertToSlug(slug)}`;
  }

  return link;
};

/**
 * Get experience tags for the card view
 * @param {ExperienceDef[]} experiences array from api
 */
export const getExperienceTags = (experiences?: ExperienceDef[] | null) => {
  const tags: TagProps[] = [];
  const EXPERIENCE_COLORS_KEY = "experiences-colors";
  let colors: Record<number, number> = {};
  const colorsLocal = localStorage.getItem(EXPERIENCE_COLORS_KEY);
  if (colorsLocal != null) {
    colors = JSON.parse(colorsLocal) as Record<number, number>;
  }

  const getRandomKey = (): number => {
    const number = randomNumber(0, 13);
    if (colors[number]) {
      return getRandomKey();
    }
    return number;
  };

  const getTagColor = (id: number) => {
    if (colors[id]) {
      return colors[id];
    }

    const randomColorId = getRandomKey();
    localStorage.setItem(
      EXPERIENCE_COLORS_KEY,
      JSON.stringify({ ...colors, [id]: randomColorId })
    );
    return randomColorId;
  };

  experiences?.forEach(experience => {
    tags.push({
      color: getTagColor(experience.id) as TagColorsDef,
      label: experience.name,
      link: getExperienceLink(experience.id, experience.slug),
    });
  });
  return tags;
};

function instanceOfPractitioner(object: any): object is PractitionerDef {
  return "firstName" in object;
}

/**
 * Maps list of practitioners/experiences for carousel items
 * @param {PractitionerDef[] | ExperienceDef[]} data array of practitioners from api
 */
export const carouselItemsMapper = (
  data: (PractitionerDef | ExperienceDef)[]
): ExperienceItemProps[] =>
  data.map(
    item =>
      ({
        id: item.id,
        image: item.image,
        description: item.carouselDescription,
        title: getFullName(item),
        link: instanceOfPractitioner(item)
          ? getPractitionerLink(item.id, item.slug)
          : getExperienceLink(item.id, item.slug),
      } as ExperienceItemProps)
  );

/**
 * Maps list of testimonies for quotes carousel
 * @param {TestimonialDef[]} data array of testimonials from api
 */
export const testimonialsMapper = (
  testimonials?: TestimonialDef[]
): TestimonialDef[] => {
  return testimonials
    ? testimonials.map(testimonial => ({
        id: testimonial.id,
        title: testimonial.title,
        quote: testimonial.quote,
        author: testimonial.author,
      }))
    : [];
};
