import * as React from "react";

function SvgClose(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.582 12l-4.47 4.448 1.41 1.418L12 13.41l4.478 4.455 1.41-1.418L13.419 12l4.47-4.448-1.41-1.418L12 10.59 7.522 6.134l-1.41 1.418L10.581 12z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgClose = React.memo(SvgClose);
export default MemoSvgClose;
