import * as React from "react";

function SvgFullScreen(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4H4v6h2V6h4V4H5zm14 16h1v-6h-2v4h-4v2h5zm1-16h-6v2h4v4h2V4zM5 20H4v-6h2v4h4v2H5z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgFullScreen = React.memo(SvgFullScreen);
export default MemoSvgFullScreen;
