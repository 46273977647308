import React from "react";

import { Col, Container, Row } from "react-grid-system";
import { useTranslation } from "react-i18next";

import Button from "@app/components/atoms/Button/Button";
import {
  Paragraph,
  Subtitle,
  Title,
} from "@app/components/atoms/Typography/Typography";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import DefaultLayout from "@app/components/layouts/DefaultLayout/DefaultLayout";
import Section from "@app/components/layouts/Section/Section";
import { ThemeEnum } from "@app/constants/theme.constants";

import styles from "./NoMatch.module.scss";

const NoMatch = () => {
  const { t } = useTranslation();
  return (
    <DefaultLayout>
      <Section
        theme={ThemeEnum.KHAKI}
        withSeparatorSpacing
        className={styles.wrapper}
      >
        <ContentWrapper>
          <Container fluid>
            <div className={styles.illustration} />
            <Row justify="center">
              <Col xs={12} lg={8}>
                <Title level={1} className={styles.title} isGold>
                  {t("pageNotFound.title")}
                </Title>
                <Subtitle level={1} className={styles.subTitle}>
                  {t("pageNotFound.subtitle")}
                </Subtitle>
                <Paragraph className={styles.subTitle}>
                  {t("pageNotFound.body")}
                </Paragraph>
                <Button label={t("default.backToHome")} to="/" />
              </Col>
            </Row>
          </Container>
        </ContentWrapper>
      </Section>
    </DefaultLayout>
  );
};

export default NoMatch;
