import React, { memo } from "react";

import { ButtonTypeEnum } from "@app/components/atoms/Button/Button";
import { IMAGE_TYPES } from "@app/constants/image";
import {
  ThemeEnum,
  BackgroundRatioEnum,
  AlignmentEnum,
} from "@app/constants/theme.constants";
import { useSingleEntry } from "@app/features/contentful/contentful";
import { getImageUrl } from "@app/helpers/image.helpers";
import { validateEnum } from "@app/helpers/util.helpers";
import { ContentfulMedia } from "@app/types/contentful.types";

import Hero from "./Hero";

interface HeroContentfulProps {
  entryID: string;
}

interface HeroDataModel {
  theme: string;
  title: string;
  subtitle: string;
  description: string;
  ctaText: string;
  ctaLink: string;
  ctaType: string;
  backgroundImage: ContentfulMedia;
  backgroundRatio: string;
  contentAlignment: string;
}

const HeroContentful = memo(({ entryID }: HeroContentfulProps) => {
  const { entry: heroData } = useSingleEntry<HeroDataModel>(entryID);

  if (!heroData) {
    return null;
  }

  const {
    theme,
    title,
    subtitle,
    description,
    ctaText,
    ctaLink,
    ctaType,
    backgroundImage,
    backgroundRatio,
    contentAlignment,
  } = heroData;

  return (
    <>
      <Hero
        theme={
          validateEnum(ThemeEnum, theme)
            ? (theme as ThemeEnum)
            : ThemeEnum.KHAKI
        }
        title={title}
        subTitle={subtitle}
        description={description}
        CTALabel={ctaText}
        CTALink={ctaLink}
        CTAType={
          validateEnum(ButtonTypeEnum, ctaType)
            ? (ctaType as ButtonTypeEnum)
            : ButtonTypeEnum.PRIMARY
        }
        heroImage={
          backgroundImage?.fields.file &&
          getImageUrl(backgroundImage?.fields.file.url, IMAGE_TYPES.BANNER)
        }
        backgroundRatio={
          validateEnum(BackgroundRatioEnum, backgroundRatio)
            ? (backgroundRatio as BackgroundRatioEnum)
            : BackgroundRatioEnum.HALF
        }
        contentAlignment={
          validateEnum(AlignmentEnum, contentAlignment)
            ? (contentAlignment as AlignmentEnum)
            : AlignmentEnum.CENTER
        }
      />
    </>
  );
});

export default HeroContentful;
