import * as React from "react";

function SvgGridHalf(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4h16v10H4V4zm0 12v4h4v-4H4zm10 4h-4v-4h4v4zm2 0h4v-4h-4v4zM2 2h20v20H2V2z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgGridHalf = React.memo(SvgGridHalf);
export default MemoSvgGridHalf;
