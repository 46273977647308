import * as React from "react";

function SvgBagwithcount(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 19L19.875 6H4.125L3 19"
        stroke="currentColor"
        strokeWidth={2}
      />
      <path
        d="M9 9V4.294C9 2.474 10.343 1 12 1s3 1.475 3 3.294V9"
        stroke="currentColor"
        strokeWidth={2}
      />
    </svg>
  );
}

const MemoSvgBagwithcount = React.memo(SvgBagwithcount);
export default MemoSvgBagwithcount;
