import { AxiosResponse } from "axios";
import { generatePath } from "react-router-dom";

import { api } from "@app/api/api";
import { ClassesDef, ClassesFilterDef } from "@app/features/classes/classes";

import { ClassesEndpointsEnum } from "../constants/classes.endpoints";

const getSeekerClasses = (
  params?: ClassesFilterDef
): Promise<AxiosResponse> => {
  return api.get(ClassesEndpointsEnum.SEEKER_CLASSES, { params });
};

const getPractitionerClasses = (
  params?: ClassesFilterDef
): Promise<AxiosResponse> => {
  return api.get(ClassesEndpointsEnum.PRACTITIONER_CLASSES, { params });
};

const getRecommendedClasses = (
  params?: ClassesFilterDef
): Promise<AxiosResponse> => {
  // TODO: update endpoint when implemented
  return api.get(ClassesEndpointsEnum.ALL_CLASSES, { params });
};

const getAllClasses = (params?: ClassesFilterDef): Promise<AxiosResponse> => {
  return api.get(ClassesEndpointsEnum.ALL_CLASSES, { params });
};

const cancelBooking = (id: ClassesDef["id"]): Promise<AxiosResponse> => {
  const url = generatePath(ClassesEndpointsEnum.CANCEL_CLASS, { classId: id });
  return api.patch(url);
};

export const classesApi = {
  getSeekerClasses,
  getPractitionerClasses,
  getRecommendedClasses,
  getAllClasses,
  cancelBooking,
};
