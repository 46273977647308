import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { SessionRoleEnum } from "@app/constants/video-session.constants";
import { SessionDef } from "@app/types/api.types";

import { sessionApi } from "../api/session.api";
import { TokenErrorEnum } from "../constants/session.errors";
import { SessionTokenErrorDef } from "../types/session-token.types";

interface SessionTokenState {
  token: string | null;
  role: SessionRoleEnum | null;
  loading: boolean;
  error: TokenErrorEnum | null;
}

interface SessionTokenDef {
  id: string;
  user: unknown;
  role: SessionRoleEnum;
}

const initialState: SessionTokenState = {
  token: null,
  role: null,
  loading: false,
  error: null,
};

export const createToken = createAsyncThunk(
  "sessionToken/createToken",
  async (sessionId: SessionDef["id"], { rejectWithValue }) => {
    try {
      const response = await sessionApi.createToken(sessionId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const sessionTokenSlice = createSlice({
  name: "sessionToken",
  initialState,
  reducers: {
    clearSessionToken: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(
      createToken.fulfilled,
      (state, action: PayloadAction<SessionTokenDef>) => {
        state.token = action.payload.id;
        state.role = action.payload.role;
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(createToken.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(createToken.rejected, (state, action) => {
      const { statusCode } = action.payload as SessionTokenErrorDef;
      state.error = statusCode;
      state.loading = false;
    });
  },
});

export const { clearSessionToken } = sessionTokenSlice.actions;

export default sessionTokenSlice.reducer;
