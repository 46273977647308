import * as React from "react";

function SvgBag(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.125 7h15.75L21 20H3L4.125 7z"
        stroke="currentColor"
        strokeWidth={2}
      />
      <path
        d="M9 10V5.294C9 3.474 10.343 2 12 2s3 1.475 3 3.294V10"
        stroke="currentColor"
        strokeWidth={2}
      />
    </svg>
  );
}

const MemoSvgBag = React.memo(SvgBag);
export default MemoSvgBag;
