import React, { memo } from "react";

import cx from "classnames";

import styles from "./LoadingSpinner.module.scss";
import { ReactComponent as Spinner } from "./Spinner.svg";

export interface LoadingSpinnerProps {
  className?: string;
  isSmall?: boolean;
}

/**
 * Loading Spinner
 */
const LoadingSpinner = memo(({ className, isSmall }: LoadingSpinnerProps) => {
  return (
    <div
      className={cx(styles.container, className, {
        [styles.isFullscreen]: !isSmall,
        [styles.isSmall]: isSmall,
      })}
    >
      <Spinner className={styles.spinner} />
    </div>
  );
});

export default LoadingSpinner;
