import React from "react";

import cx from "classnames";
import { useSelector } from "react-redux";

import {
  IconChat,
  IconChatOff,
  IconUser,
} from "@app/components/atoms/Icon/Icon";
import Tabs from "@app/components/atoms/Tabs/Tabs";
import { resetPrivateChatToUser } from "@app/features/video/video";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";

import { VideoSessionUserDef } from "../../../../types/session.types";
import SidebarAttendeesListTab from "../SidebarAttendeesListTab/SidebarAttendeesListTab";
import SidebarChatTab from "../SidebarChatTab/SidebarChatTab";
import styles from "./SidebarModerator.module.scss";

interface SidebarModeratorProps {
  className: string;
  attendees?: VideoSessionUserDef[];
}

const SidebarModerator = ({
  attendees = [],
  className,
}: SidebarModeratorProps) => {
  const { disableChat } = useSelector((state: RootState) => state.video);
  const dispatch = useAppDispatch();

  const handleTabChange = () => {
    dispatch(resetPrivateChatToUser());
  };

  return (
    <Tabs
      fullWidth
      withIndicatorLine
      className={className}
      tabPanelClassName={className}
      onSelect={handleTabChange}
      items={[
        {
          id: 0,
          title: (
            <>
              <IconUser className={styles.iconAttendees} />
              {attendees.length}
            </>
          ),
          content: <SidebarAttendeesListTab attendees={attendees} />,
        },
        {
          id: 1,
          title: !disableChat ? (
            <IconChat className={styles.iconChat} />
          ) : (
            <IconChatOff
              className={cx(styles.iconChat, {
                [styles.disabled]: disableChat,
              })}
            />
          ),
          content: <SidebarChatTab isModerator />,
          disabled: disableChat,
        },
      ]}
    />
  );
};

export default SidebarModerator;
