import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AvatarColorsDef } from "@app/components/atoms/Avatar/Avatar";
import {
  ClassCommentDef,
  CreateCommentDef,
  SessionDef,
  BasicUserDef,
} from "@app/types/api.types";

import { videoApi } from "../api/video.chat.api";
import { GetCommentsDef } from "../types/session.types";

interface VideoState {
  messages: ClassCommentDef[];
  attendeesAvatarColors: {
    [userId: string]: AvatarColorsDef;
  };
  loading: boolean;
  error: boolean;
  privateChatToUser: BasicUserDef | null;
  disableChat: boolean;
  createMessageLoading: boolean;
}

const initialState: VideoState = {
  messages: [],
  attendeesAvatarColors: {},
  loading: false,
  error: false,
  privateChatToUser: null,
  disableChat: false,
  createMessageLoading: false,
};

export const getComments = createAsyncThunk(
  "video/getComments",
  async (payload: GetCommentsDef, { rejectWithValue }) => {
    try {
      const response = await videoApi.getComments(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createComment = createAsyncThunk(
  "video/createComment",
  async (
    data: { sessionId: SessionDef["id"]; comment: CreateCommentDef },
    { rejectWithValue }
  ) => {
    const { sessionId, comment } = data;
    try {
      const response = await videoApi.createComment(sessionId, comment);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteCommentById = createAsyncThunk(
  "video/deleteCommentById",
  async (commentId: ClassCommentDef["id"], { rejectWithValue }) => {
    try {
      const response = await videoApi.deleteCommentById(commentId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const toggleChatBySessionId = createAsyncThunk(
  "video/toggleChatBySessionId",
  async (
    payload: {
      sessionId: SessionDef["id"];
      disableChat: SessionDef["disableChat"];
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await videoApi.toggleChatBySessionId(
        payload.sessionId,
        payload.disableChat
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const videoSlice = createSlice({
  name: "video",
  initialState,
  reducers: {
    setAttendeesAvatarColors(
      state,
      action: PayloadAction<{ userId: number; avatarColor: AvatarColorsDef }>
    ) {
      const { userId, avatarColor } = action.payload;
      state.attendeesAvatarColors[userId] = avatarColor;
    },
    setPrivateChatToUser(state, action: PayloadAction<{ user: BasicUserDef }>) {
      const { user } = action.payload;
      state.privateChatToUser = user;
      state.messages = [];
    },
    resetPrivateChatToUser(state) {
      state.privateChatToUser = null;
      state.messages = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(
      getComments.fulfilled,
      (state, action: PayloadAction<SessionDef>) => {
        state.messages = action.payload.comments ?? [];
        state.disableChat = action.payload.disableChat;
        state.loading = false;
        state.error = false;
      }
    );
    builder.addCase(getComments.pending, state => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getComments.rejected, state => {
      state.error = true;
      state.loading = false;
    });
    builder.addCase(deleteCommentById.pending, state => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(deleteCommentById.rejected, state => {
      state.error = true;
      state.loading = false;
    });
    builder.addCase(createComment.pending, state => {
      state.createMessageLoading = true;
      state.error = false;
    });
    builder.addCase(
      createComment.fulfilled,
      (state, action: PayloadAction<SessionDef>) => {
        state.messages = action.payload.comments ?? [];
        state.createMessageLoading = false;
      }
    );
    builder.addCase(toggleChatBySessionId.pending, state => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(toggleChatBySessionId.rejected, state => {
      state.error = true;
      state.loading = false;
    });
    builder.addCase(
      toggleChatBySessionId.fulfilled,
      (state, action: PayloadAction<SessionDef>) => {
        state.error = false;
        state.loading = false;
        state.disableChat = action.payload.disableChat;
      }
    );
  },
});

export const {
  setAttendeesAvatarColors,
  setPrivateChatToUser,
  resetPrivateChatToUser,
} = videoSlice.actions;

export default videoSlice.reducer;
