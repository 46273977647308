import React, { FC, memo } from "react";

import cx from "classnames";
import { Link, useLocation } from "react-router-dom";

import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import styles from "./CircularButton.module.scss";

export interface CircularButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  /**
   * Whether the button is floating, which will apply shadow and dark colors
   */
  isFloating?: boolean;
  className?: string;
  borderless?: boolean;
  onClick?: () => void;
  isLoading?: boolean;
  /**
   * Turn button into link (optional)
   */
  to?: string;
}

const CircularButton: FC<CircularButtonProps> = memo(
  ({
    isFloating,
    borderless = false,
    className,
    onClick,
    children,
    isLoading,
    to,
    ...rest
  }) => {
    const location = useLocation();
    const button = (
      <button
        className={cx(styles.circularButton, className, {
          [styles.isFloating]: isFloating,
          [styles.borderless]: borderless,
        })}
        onClick={onClick}
        {...rest}
      >
        {isLoading ? <LoadingSpinner isSmall /> : children}
      </button>
    );

    if (to) {
      return (
        <Link
          className={cx(styles.link)}
          to={{
            pathname: to,
            state: { prevPath: location.pathname },
          }}
        >
          {button}
        </Link>
      );
    }

    return button;
  }
);

export default CircularButton;
