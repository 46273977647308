import React, { memo } from "react";

import { ThemeEnum } from "@app/constants/theme.constants";
import { useSingleEntry } from "@app/features/contentful/contentful";

import Footer, { FooterSections } from "./Footer";
import { FooterLinkItemProps } from "./components/FooterLinkItem/FooterLinkItem";

interface FooterContentfulProps {
  entryID: string;
}

interface SectionsDataModel {
  fields?: {
    title: string;
    sectionLinks: FooterLinks[];
  };
}

interface FooterLinks {
  fields?: {
    label: string;
    link: string;
  };
}

interface FooterDataModel {
  theme: string;
  bottomLinks: FooterLinks[];
  sections: SectionsDataModel[];
  facebookLink: string;
  instagramLink: string;
  linkedInLink: string;
}

const FooterContentful = memo(({ entryID }: FooterContentfulProps) => {
  const { entry: footerData } = useSingleEntry<FooterDataModel>(entryID);

  if (!footerData) return null;

  const {
    theme,
    bottomLinks,
    sections,
    facebookLink,
    instagramLink,
    linkedInLink,
  } = footerData;

  const mapBottomLinks = (items: FooterLinks[]) => {
    const mappedItems = items
      // Only published items will have fields
      .filter(item => !!item.fields)
      .map((item: FooterLinks) => ({
        label: item.fields?.label,
        link: item.fields?.link,
      }));
    return mappedItems as FooterLinkItemProps[];
  };

  const mapSectionLinks = (sectionsList: SectionsDataModel[]) => {
    const mappedSections = sectionsList
      // Only published items will have fields
      .filter(item => !!item.fields)
      .map((section: SectionsDataModel) => {
        return {
          title: section.fields?.title,
          links: section.fields?.sectionLinks
            // Only published items will have fields
            .filter(item => !!item.fields)
            .map((item: FooterLinks) => ({
              label: item.fields?.label,
              link: item.fields?.link,
            })),
        };
      });
    return mappedSections as FooterSections[];
  };

  return (
    <Footer
      theme={theme as ThemeEnum}
      bottomLinks={mapBottomLinks(bottomLinks)}
      sections={mapSectionLinks(sections)}
      socialLinks={{ facebookLink, instagramLink, linkedInLink }}
    />
  );
});

export default FooterContentful;
