import * as React from "react";

function SvgShowSessionInfoMobile(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 5V4h-6v2h4v4h2V5zm0 9v-1H4v7h16v-6zM6 18v-3h12v3H6zM4 4v6h2V6h4V4H4zm11.207 6.293l-2.5-2.5L12 7.086l-.707.707-2.5 2.5 1.414 1.414L12 9.914l1.793 1.793 1.414-1.414z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgShowSessionInfoMobile = React.memo(SvgShowSessionInfoMobile);
export default MemoSvgShowSessionInfoMobile;
