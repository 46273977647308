import * as React from "react";

function SvgArrowRightUp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7.509l-3.274 3.455-1.452-1.376 5-5.276.726-.766.726.766 5 5.276-1.452 1.376L14 7.509V20H5v-2h7V7.509z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgArrowRightUp = React.memo(SvgArrowRightUp);
export default MemoSvgArrowRightUp;
