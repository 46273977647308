import * as React from "react";

function SvgPreferences(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#Preferences_svg__clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 19h9v2H2v-2zm13 2h7v-2h-7v-3h-2v8h2v-3zm-3-10h-1v2h11v-2H12zM2 11h5V8h2v8H7v-3H2v-2zm19-8h-4V0h-2v8h2V5h5V3h-1zM2 3h11v2H2V3z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="Preferences_svg__clip0">
          <path fill="currentColor" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

const MemoSvgPreferences = React.memo(SvgPreferences);
export default MemoSvgPreferences;
