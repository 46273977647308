import React, { FC, ReactElement, ReactNode, useState } from "react";

import { useTranslation } from "react-i18next";

import { ConfirmationModal } from "../Modal/Modal";

enum EventTypeEnum {
  ON_CLICK = "onClick",
}
export interface ConfirmableProps {
  onConfirm: Function;
  modalTitleText?: string;
  modalBodyText?: ReactNode;
  eventType?: EventTypeEnum;
  /**
   * Skip the confirmation modal
   * can be used to conditionaly trigger confirmation
   */
  shouldSkip?: boolean;
}

/**
 * Re usable component to add confirmation before any action
 * Can be used on button, checkbox, switcher
 * default handler onClick for type button
 */
const Confirmable: FC<ConfirmableProps> = ({
  onConfirm,
  children,
  modalTitleText,
  modalBodyText,
  eventType = "onClick", // Enum not working in cloneElement
  shouldSkip = false,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation();

  const handleModalOpen = (e: Event) => {
    e?.stopPropagation?.();
    e?.preventDefault?.();
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleConfirm = () => {
    const confirmation = onConfirm();
    Promise.resolve(confirmation).then(() => {
      setModalVisible(false);
    });
  };

  return (
    <span>
      {React.cloneElement(children as ReactElement, {
        [eventType]: shouldSkip ? onConfirm : handleModalOpen,
      })}
      <ConfirmationModal
        visible={modalVisible}
        title={modalTitleText ?? t("default.confirmTitle")}
        subtitle={modalBodyText ?? null}
        onConfirm={handleConfirm}
        onClose={handleCancel}
      />
    </span>
  );
};

export default Confirmable;
