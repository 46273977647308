import React, { memo, useMemo, useState } from "react";

import { MenuInfo } from "rc-menu/lib/interface";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Avatar, { AvatarSize } from "@app/components/atoms/Avatar/Avatar";
import Dropdown from "@app/components/atoms/Dropdown/Dropdown";
import { IconOptions } from "@app/components/atoms/Icon/Icon";
import { RootState } from "@app/redux/root-reducer";

import GridViewSettingsModal from "../GridViewSettingsModal/GridViewSettingsModal";
import styles from "./ProfileControl.module.scss";

enum MenuKeys {
  GRID = "grid",
}

const ProfileControl = memo(() => {
  const [showModal, setShowModal] = useState<MenuKeys | null>(null);
  const userId = useSelector((state: RootState) => state.auth.user?.id);
  const image = useSelector((state: RootState) => state.auth.user?.image);
  const attendeesAvatarColors = useSelector(
    (state: RootState) => state.video.attendeesAvatarColors
  );
  const { t } = useTranslation();

  const menu = useMemo(
    () => [
      { id: MenuKeys.GRID, value: t("videoSession.menuGridViewSettings") },
    ],
    [t]
  );

  const handleClick = (e: MenuInfo) => {
    setShowModal(e.key as MenuKeys);
  };

  const handleCloseModal = () => {
    setShowModal(null);
  };

  return (
    <div>
      <Dropdown
        items={menu}
        placement="bottomRight"
        onClickMenuItem={handleClick}
      >
        <div className={styles.profile}>
          <IconOptions className={styles.icon} />
          <Avatar
            avatarColor={userId && attendeesAvatarColors[userId]}
            avatarImage={image}
            avatarSize={AvatarSize.MEDIUM}
          />
        </div>
      </Dropdown>

      <GridViewSettingsModal
        visible={showModal === MenuKeys.GRID}
        onClose={handleCloseModal}
      />
    </div>
  );
});

export default ProfileControl;
