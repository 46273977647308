import React, { useEffect, useMemo, useState } from "react";

import OT, { OTError, Session, PublisherProperties } from "@opentok/client";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-media";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { getRandomAvatarColor } from "@app/components/atoms/Avatar/Avatar";
import ConditionalWrapper from "@app/components/atoms/ConditionalWrapper/ConditionalWrapper";
import { ErrorModal } from "@app/components/atoms/Modal/Modal";
import { GLOBAL_MEDIA_QUERIES } from "@app/constants/breakpoints";
import { RootState } from "@app/redux/root-reducer";

import {
  SessionEventsEnum,
  PublisherEventsEnum,
} from "../../constants/opentok.enums";
import { GridViewSettingsEnum } from "../../constants/video.enums";
import { VIDEO_PUBLISHER_PROPERTIES } from "../../constants/video.properties";
import useStreamSettings from "../../hooks/useStreamSettings";
import ModeratorChatLayout from "../../layouts/ModeratorChatLayout/ModeratorChatLayout";
import { setAttendeesAvatarColors } from "../../redux/video.slice";
import VideoChat from "../VideoChat/VideoChat";
import styles from "./PublisherChat.module.scss";
import PublisherActions from "./components/PublisherActions/PublisherActions";

interface PublisherChatProps {
  session: Session;
  isUserModerator: boolean;
  onError?: (error?: OTError) => void;
  /** Hide the subscriber in view, but still subscribe to their audio */
  isHidden?: boolean;
}

const PublisherChat = ({
  session,
  isUserModerator,
  onError,
  isHidden,
}: PublisherChatProps) => {
  const initialConfig = useMemo(
    () => ({
      initialAudioEnabled: isUserModerator,
      initialVideoEnabled: isUserModerator,
    }),
    [isUserModerator]
  );

  const {
    streamCreated,
    audioEnabled,
    videoEnabled,
    updateStreamSettings,
    setStreamCreated,
  } = useStreamSettings(initialConfig);
  const userId = useSelector((state: RootState) => state.auth.user?.id);
  const name = useSelector((state: RootState) => state.auth.user?.pseudonym);
  const image = useSelector((state: RootState) => state.auth.user?.image);
  const attendeesAvatarColors = useSelector(
    (state: RootState) => state.video.attendeesAvatarColors
  );
  const gridView = useSelector(
    (state: RootState) => state.videoSettings.gridView
  );
  const [accessDenied, setAccessDenied] = useState(false);
  const [divRef, setDivRef] = useState<HTMLDivElement | null>(null);
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const publisherProps: PublisherProperties = {
    insertMode: "replace",
    name,
    publishAudio: initialConfig.initialAudioEnabled,
    publishVideo: initialConfig.initialVideoEnabled,
  };

  const publisher = useMemo(
    () =>
      divRef
        ? OT.initPublisher(
            divRef,
            {
              ...VIDEO_PUBLISHER_PROPERTIES,
              ...publisherProps,
            },
            onError
          )
        : undefined,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [divRef]
  );

  const sessionConnectedHandler = () => {
    if (publisher) {
      session.publish(publisher, onError);
    }
  };

  useEffect(() => {
    userId &&
      dispatch(
        setAttendeesAvatarColors({
          userId,
          avatarColor: getRandomAvatarColor(),
        })
      );
  }, [dispatch, userId]);

  useEffect(() => {
    if (publisher) {
      // check if the session connection is established
      if (session.connection) {
        sessionConnectedHandler();
      } else {
        // otherwise subscribe to event when session is connected
        session.once(SessionEventsEnum.SESSION_CONNECTED, () =>
          sessionConnectedHandler()
        );
      }

      publisher.once(PublisherEventsEnum.STREAM_CREATED, () => {
        setStreamCreated(true);
      });
      publisher.on(PublisherEventsEnum.ACCESS_DENIED, () => {
        setAccessDenied(true);
      });
      publisher.on(PublisherEventsEnum.ACCESS_ALLOWED, () => {
        setAccessDenied(false);
      });

      // listen to event for changes to audio, video, etc from publisher
      session.on(SessionEventsEnum.STREAM_PROPERTY_CHANGED, event => {
        if (event.stream.streamId === publisher.stream?.streamId) {
          updateStreamSettings(event);
        }
      });
    }

    return () => {
      if (publisher) {
        session.unpublish(publisher);
        publisher.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publisher]);

  const handleToggleAudio = () => {
    publisher?.publishAudio(!audioEnabled);
  };
  const handleToggleVideo = () => {
    publisher?.publishVideo(!videoEnabled);
  };

  const handleAccessDeniedRefresh = () => {
    history.go(0);
  };

  return (
    <>
      <ConditionalWrapper
        condition={isUserModerator}
        wrapper={children => (
          <ModeratorChatLayout isHidden={isHidden}>
            {children}
          </ModeratorChatLayout>
        )}
      >
        <VideoChat
          ref={setDivRef}
          className={cx({
            [styles.fullGridView]:
              gridView === GridViewSettingsEnum.FULL_GRID_VIEW,
          })}
          name={name ?? ""}
          hasVideo={videoEnabled}
          avatarColor={userId && attendeesAvatarColors[userId]}
          avatarWithShadow
          avatarImage={image}
          isHidden={isHidden || (!isUserModerator && !matches.tabletLandscape)}
          fixedAspectRatio={
            isUserModerator &&
            gridView === GridViewSettingsEnum.BOTTOM_GRID_VIEW
          }
        />
      </ConditionalWrapper>
      {streamCreated && (
        <PublisherActions
          audioEnabled={audioEnabled}
          videoEnabled={videoEnabled}
          handleToggleAudio={handleToggleAudio}
          handleToggleVideo={handleToggleVideo}
        />
      )}
      <ErrorModal
        title={t("videoSessionError.camAndMicDeniedTitle")}
        subtitle={t("videoSessionError.camAndMicDeniedText")}
        buttonLabel={t("videoSessionError.buttonRefresh")}
        visible={accessDenied}
        onConfirm={handleAccessDeniedRefresh}
      />
    </>
  );
};

export default PublisherChat;
