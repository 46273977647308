import React from "react";

import cx from "classnames";
import RCDropdown from "rc-dropdown";
import { DropdownProps as RCDropdownProps } from "rc-dropdown/lib/Dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import { MenuInfo } from "rc-menu/lib/interface";
import "./Dropdown.scss";

import { ThemeEnum } from "@app/constants/theme.constants";
import { getTheme } from "@app/helpers/util.helpers";

import { Subtitle } from "../Typography/Typography";

export interface DropdownProps extends Omit<RCDropdownProps, "arrow"> {
  items: { id: string; value: string; url?: string }[];
  onClickMenuItem: (event: MenuInfo) => void;
  theme?: ThemeEnum;
  activeKey?: string;
  overlayClassName?: string;
  activeClassName?: string;
}

const Dropdown = ({
  children,
  items,
  onClickMenuItem,
  theme = ThemeEnum.KHAKI,
  overlayClassName,
  activeClassName = "",
  activeKey,
  ...rest
}: DropdownProps) => {
  const handleItemClick = (event: MenuInfo) => {
    event.domEvent.stopPropagation();
    event.domEvent.preventDefault();
    onClickMenuItem(event);
  };

  const menu = (
    <Menu onClick={handleItemClick}>
      {items.map(item => (
        <React.Fragment key={`fragment-${item.id}`}>
          <MenuItem key={item.id}>
            <Subtitle
              level={3}
              className={cx({ [activeClassName]: item.id === activeKey })}
            >
              {item.value}
            </Subtitle>
          </MenuItem>
        </React.Fragment>
      ))}
    </Menu>
  );

  return (
    <RCDropdown
      trigger={["click"]}
      animation="slide-up"
      overlay={menu}
      overlayClassName={cx(getTheme(theme), overlayClassName)}
      {...rest}
      arrow={false}
    >
      {children}
    </RCDropdown>
  );
};

export default Dropdown;
