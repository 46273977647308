import { RouteItemDef } from "@app/types/route.types";

import { ARTICLE_ROUTE_PATH } from "../constants/articles.path";
import ArticleScreen from "../screens/ArticleScreen/ArticleScreen";

const ARTICLE_SCREEN: RouteItemDef = {
  id: "article",
  path: ARTICLE_ROUTE_PATH,
  component: ArticleScreen,
};

export const ARTICLES_ROUTES = [ARTICLE_SCREEN];
