import qs from "query-string";

import { IMAGE_FOCUS, IMAGE_SIZES, IMAGE_TYPES } from "@app/constants/image";

/**
 * Image util function to generate optimized url for images from contentful
 * @param {string} url url from contentful
 * @param {IMAGE_TYPES} type image type enum
 * @param {IMAGE_FOCUS} focus image focus position
 */
export const getImageUrl = (
  url: string | null,
  type: IMAGE_TYPES,
  focus?: IMAGE_FOCUS
) => {
  if (url == null) {
    return "";
  }
  const settings = {
    w: IMAGE_SIZES[type].width,
    h: IMAGE_SIZES[type].height,
    fm: "jpg",
    fl: "progressive",
    ...(focus && { f: focus, fit: "thumb" }),
  };

  return `${url}?${qs.stringify(settings)}`;
};
