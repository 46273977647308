import i18next from "i18next";
import _isNumber from "lodash/isNumber";

import { ThemeEnum } from "@app/constants/theme.constants";
import { ApiFormErrorsDef } from "@app/types/api.types";

export const validateEnum = (enumName: any, enumValue: string) =>
  Object.values(enumName).includes(enumValue);

/**
 * Check if a string looks like an external or internal link
 */
export const isURL = (str: string) => {
  return /http|www|\./.test(str);
};

export const convertToSlug = (str: string) => {
  return str
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-") // collapse dashes
    .replace(/^-+/, "") // trim - from start of text
    .replace(/-+$/, ""); // trim - from end of text
};

export function getPublishedData<T = any>(data: T[]): T[] {
  return data ? data.filter((item: any) => !!item.fields) : [];
}

export function randomNumber(min: number, max: number) {
  return Math.floor(Math.random() * (max - min) + min);
}

export function isValidId(id: string) {
  return !!id && _isNumber(Number(id));
}

export function removeUndefined(
  values: Record<string, any>,
  ignoreKeys: string[] = []
) {
  Object.keys(values).forEach(
    key =>
      (values[key] == null || values[key].length === 0) &&
      !ignoreKeys.includes(key) &&
      delete values[key]
  );
}

export const getTheme = (theme: ThemeEnum) => `${theme}-theme`;

export function getFullName<T = any>(data?: T): string {
  const obj = data as any;

  if (obj?.name) {
    return obj.name;
  }

  if (obj?.firstName && obj?.lastName) {
    return `${obj.firstName} ${obj.lastName}`;
  }

  return "";
}

export function isEmpty(word: string) {
  return !word?.trim();
}

/**
 * Lookup error message keys with translation keys
 */
export function mapErrorFields(errors: ApiFormErrorsDef) {
  const modifiedErrors: Record<string, string> = {};

  Object.keys(errors).forEach(fieldName => {
    // Take the first available error to show against the field
    modifiedErrors[fieldName] = i18next.t(
      `errorMessages.${fieldName}${errors[fieldName][0]}`
    );
  });

  return modifiedErrors;
}
