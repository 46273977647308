import React, { memo } from "react";

import { Link } from "react-router-dom";

import Image from "@app/components/atoms/Image/Image";
import { Subtitle } from "@app/components/atoms/Typography/Typography";

import styles from "./ArticleItem.module.scss";

export interface ArticleItemProps {
  id: number;
  image: string;
  title: string;
  link: string;
}

const ArticleItem = memo(({ image, title, link }: ArticleItemProps) => {
  const itemContent = (
    <div className={styles.itemWrapper}>
      <div className={styles.imageWrapper}>
        <Image className={styles.image} url={image} alt={title} />
      </div>
      <div className={styles.itemContent}>
        <Subtitle level={1}>{title}</Subtitle>
      </div>
    </div>
  );

  return link && link !== "" ? (
    <Link className={styles.link} to={link}>
      {itemContent}
    </Link>
  ) : (
    itemContent
  );
});

export default ArticleItem;
