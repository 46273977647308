import { SessionTypeEnum } from "@app/constants/video-session.constants";
import { ClassesDef } from "@app/features/classes/classes";

export const convertConnectionDataToObject = (connectionData: string) => {
  const connectionDataObj: { [key: string]: string | undefined } = {};
  const dataEntries = connectionData.split(",");
  dataEntries.forEach(entry => {
    const [entryKey, entryValue] = entry.split("=");
    connectionDataObj[entryKey] = entryValue;
  });
  return connectionDataObj;
};

export const isStreamModerator = (connectionData: string) => {
  const connectionDataObj = convertConnectionDataToObject(connectionData);

  return connectionDataObj.role === "moderator";
};

export const getUserIdFromStream = (connectionData: string) => {
  const connectionDataObj = convertConnectionDataToObject(connectionData);

  return Number(connectionDataObj.Id) || undefined;
};

export const getClassSessions = (classType: ClassesDef | null) => {
  const mainSession = classType?.sessions?.find(
    session => session.type === SessionTypeEnum.MAIN
  );
  return {
    mainSession,
  };
};
