import React, { memo, ReactNode } from "react";

import cx from "classnames";

import styles from "./ExternalLink.module.scss";

interface ExternalLinkProps {
  url: string;
  className?: string;
  children?: ReactNode;
}

const ExternalLink = memo(({ children, url, className }: ExternalLinkProps) => (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    className={cx(styles.link, className)}
  >
    {children}
  </a>
));

export default ExternalLink;
