import React, { useMemo, useState, useEffect, ReactNode } from "react";

import { OTError, Session } from "@opentok/client";
import { useMedia } from "react-media";
import { useSelector } from "react-redux";

import { GLOBAL_MEDIA_QUERIES } from "@app/constants/breakpoints";
import { RootState } from "@app/redux/root-reducer";

import { GridViewSettingsEnum } from "../../constants/video.enums";
import { VIDEO_PRACTITIONER_PROPERTIES } from "../../constants/video.properties";
import ModeratorChatLayout from "../../layouts/ModeratorChatLayout/ModeratorChatLayout";
import { VideoSessionUserDef } from "../../types/session.types";
import VideoChat from "../VideoChat/VideoChat";

interface ModeratorChatProps {
  session: Session;
  practitioner: VideoSessionUserDef | null;
  onError?: (error?: OTError) => void;
  /** Hide the moderator in view, but still subscribe to their audio */
  isHidden?: boolean;
  children?: ReactNode;
}

const ModeratorChat = ({
  session,
  practitioner,
  onError,
  isHidden,
}: ModeratorChatProps) => {
  const [divRef, setDivRef] = useState<HTMLDivElement | null>(null);
  const gridView = useSelector(
    (state: RootState) => state.videoSettings.gridView
  );
  const { tabletLandscape } = useMedia({
    queries: GLOBAL_MEDIA_QUERIES,
  });

  const isBottomGridView = gridView === GridViewSettingsEnum.BOTTOM_GRID_VIEW;

  const subscriber = useMemo(
    () =>
      divRef && practitioner?.stream
        ? session.subscribe(
            practitioner.stream,
            divRef,
            {
              ...VIDEO_PRACTITIONER_PROPERTIES,
              insertMode: "append",
              subscribeToVideo: !isHidden,
            },
            onError
          )
        : undefined,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [divRef, practitioner?.stream]
  );

  useEffect(() => {
    subscriber?.subscribeToVideo(!isHidden);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHidden]);

  return (
    <ModeratorChatLayout isHidden={isHidden}>
      <VideoChat
        ref={setDivRef}
        name={practitioner?.user?.pseudonym ?? practitioner?.stream.name}
        hasAudio={practitioner?.stream.hasAudio}
        hasVideo={practitioner?.stream.hasVideo}
        avatarImage={practitioner?.user?.image}
        avatarWithShadow
        showLargeOverlay={isBottomGridView || !tabletLandscape}
        isHidden={isHidden}
        fixedAspectRatio
        loading={!practitioner?.stream}
      />
    </ModeratorChatLayout>
  );
};

export default ModeratorChat;
