import { AxiosResponse } from "axios";
import { generatePath } from "react-router-dom";

import { api } from "@app/api/api";
import { SessionEndpointsEnum } from "@app/features/session/session";
import {
  ClassCommentDef,
  CreateCommentDef,
  SessionDef,
} from "@app/types/api.types";

import { VideoEndpointsEnum } from "../constants/video.endpoints";
import { GetCommentsDef } from "../types/session.types";

const getComments = (payload: GetCommentsDef): Promise<AxiosResponse> => {
  const url = generatePath(VideoEndpointsEnum.COMMENTS, {
    sessionId: payload.sessionId,
  });
  return api.get(url, { params: payload.filters });
};

const createComment = (
  sessionId: SessionDef["id"],
  data: CreateCommentDef
): Promise<AxiosResponse> => {
  const url = generatePath(VideoEndpointsEnum.COMMENTS, { sessionId });
  return api.post(url, data);
};

const deleteCommentById = (
  commentId: ClassCommentDef["id"]
): Promise<AxiosResponse> => {
  const url = generatePath(VideoEndpointsEnum.DELETE_COMMENTS, { commentId });
  return api.delete(url);
};

const toggleChatBySessionId = (
  sessionId: SessionDef["id"],
  disableChat: boolean
): Promise<AxiosResponse> => {
  const url = generatePath(SessionEndpointsEnum.SESSIONS, { sessionId });
  return api.patch(url, { disableChat });
};

export const videoApi = {
  getComments,
  createComment,
  deleteCommentById,
  toggleChatBySessionId,
};
