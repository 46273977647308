import { useEffect, useState } from "react";

import { contentfulApi } from "../api/contentful.api";
import { ContentfulOptionsDef } from "../types/contentful.types";

function useSingleEntry<T = any>(
  entryID: string,
  options?: ContentfulOptionsDef
) {
  const [entry, setEntry] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);

  async function fetchEntry(id: string) {
    const data = await contentfulApi.getEntryById(id, { include: 2 }, options);
    return data;
  }

  useEffect(() => {
    fetchEntry(entryID)
      .then((result: any) => {
        if (result) {
          setEntry(options?.includeMeta ? result : result.fields);
        }
      })
      .catch(error => console.error(error))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entryID]);

  return { entry, loading };
}
export default useSingleEntry;
