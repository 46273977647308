import React, { memo, Suspense } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";

import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import Section from "@app/components/layouts/Section/Section";
import { ThemeEnum } from "@app/constants/theme.constants";
import { useSnipcartWrapper } from "@app/features/ecommerce/ecommerce";
import { Permission } from "@app/features/permissions/permissions";
import { setMetaTitle } from "@app/helpers/router.helpers";
import { RootState } from "@app/redux/root-reducer";
import { RouteItemDef } from "@app/types/route.types";

import Restricted from "./components/Restricted/Restricted";
import { PUBLIC_LIST, PRIVATE_LIST } from "./routes.config";
import { AppPathEnums } from "./routes.constants";

const Routes = () => {
  const { t } = useTranslation();
  useSnipcartWrapper();
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );

  const user = useSelector((state: RootState) => state.auth.user);

  const routeWrapper = (route: RouteItemDef) => {
    return (
      <Route
        key={route.id}
        exact
        path={route.path}
        render={(props): React.ReactElement => {
          const Component = route.component;
          const renderComponent = <Component {...props} />;

          setMetaTitle(route.metaTitle && t(route.metaTitle));

          return (
            (route.permissions && (
              <Permission
                fallback={<Restricted />}
                requiredPermissions={route.permissions}
              >
                {renderComponent}
              </Permission>
            )) ||
            renderComponent
          );
        }}
      />
    );
  };

  return (
    <Suspense
      fallback={
        <Section theme={ThemeEnum.KHAKI} fullHeight>
          <LoadingSpinner />
        </Section>
      }
    >
      <Switch>
        {user && <Redirect exact from="/" to={AppPathEnums.USER_HOME} />}
        {!user && <Redirect exact from={AppPathEnums.USER_HOME} to="/" />}
        {isAuthenticated
          ? PRIVATE_LIST.map(route => routeWrapper(route))
          : PUBLIC_LIST.map(route => routeWrapper(route))}
      </Switch>
    </Suspense>
  );
};

export default memo(Routes);
