import React, { memo, useMemo } from "react";

import cx from "classnames";
import { Container, Row, Col } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-media";

import SessionCard from "@app/components/atoms/SessionCard/SessionCard";
import SwiperCarousel, {
  SwiperSlide,
  SwiperBreakpoints,
} from "@app/components/atoms/SwiperCarousel/SwiperCarousel";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import Section from "@app/components/layouts/Section/Section";
import { GLOBAL_MEDIA_QUERIES, ScreenSizes } from "@app/constants/breakpoints";
import { ThemeEnum } from "@app/constants/theme.constants";
import { ClassesDef } from "@app/features/classes/classes";

import { Title } from "../../atoms/Typography/Typography";
import styles from "./Sessions.module.scss";

export interface SessionsProps {
  theme?: ThemeEnum;
  subtitle?: string;
  title?: string;
  sessions: ClassesDef[];
}
const Sessions = memo(
  ({ theme = ThemeEnum.KHAKI, title, subtitle, sessions }: SessionsProps) => {
    const { t } = useTranslation();
    const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

    const slidesNumber = 3;

    const swiperBreakpoints: SwiperBreakpoints = {
      [ScreenSizes.LARGE]: {
        slidesPerView: slidesNumber,
      },
    };

    const isSliderEnabled = useMemo(
      () => sessions.length > slidesNumber,
      [sessions.length]
    );

    const getSession = (session: ClassesDef) => {
      return (
        <SessionCard
          session={session}
          className={cx({ [styles.sessionSlide]: matches.tabletLandscape })}
        />
      );
    };

    return (
      <Section theme={theme} withSeparatorSpacing noBackgroundOverlap>
        <ContentWrapper>
          <Container fluid>
            {!!subtitle && (
              <Title level={3} className={styles.subtitle} isGoldGradient>
                {subtitle}
              </Title>
            )}
            <Title level={1} className={styles.title}>
              {title ?? t("recommendedSessions.defaultTitle")}
            </Title>
          </Container>
          {!matches.tabletLandscape ? (
            <Container fluid>
              <Row>
                {sessions?.map(session => (
                  <Col xs={12} md={6} key={session.id}>
                    {getSession(session)}
                  </Col>
                ))}
              </Row>
            </Container>
          ) : (
            <SwiperCarousel
              swiperClassname={cx(styles.sessionsContainer, {
                [styles.sessionsSwiper]: isSliderEnabled,
              })}
              swiperBreakpoints={swiperBreakpoints}
              showNavArrows={isSliderEnabled}
              showPagination={isSliderEnabled}
            >
              {sessions.map(session => (
                <SwiperSlide key={session.id}>
                  {getSession(session)}
                </SwiperSlide>
              ))}
            </SwiperCarousel>
          )}
        </ContentWrapper>
      </Section>
    );
  }
);

export default Sessions;
