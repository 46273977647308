import React, { memo } from "react";

import { useSelector } from "react-redux";
import { useMount } from "react-use";

import Loading from "@app/components/atoms/Loading/Loading";
import { ThemeEnum } from "@app/constants/theme.constants";
import { ArticleDef, mapCarouselItems } from "@app/features/articles/articles";
import {
  searchContent,
  SearchFiltersDef,
  SearchTypeEnum,
} from "@app/features/search/search";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";

import Articles from "../Articles/Articles";

type Props = {
  title: string;
  subtitle?: string;
  filter?: Omit<SearchFiltersDef, "type">;
  theme?: ThemeEnum;
};

const ArticlesByFilter = memo(({ filter, title, subtitle, theme }: Props) => {
  const filterObj = {
    ...filter,
    types: SearchTypeEnum.ARTICLES,
    label: "featured",
  };
  const data = useSelector((state: RootState) => state.search);
  const dispatch = useAppDispatch();
  const { results } = data;
  const articles = results[JSON.stringify(filterObj)] as ArticleDef[];

  useMount(() => {
    dispatch(searchContent(filterObj));
  });

  return (
    <Loading isLoading={data.loading}>
      {articles?.length > 0 && (
        <Articles
          theme={theme as ThemeEnum}
          title={title}
          subtitle={subtitle}
          slideItems={mapCarouselItems(articles)}
        />
      )}
    </Loading>
  );
});

export default ArticlesByFilter;
