import React, { memo } from "react";

import { useTranslation } from "react-i18next";
import * as yup from "yup";

import FormItem from "@app/components/atoms/FormItem/FormItem";

import { passwordRegex } from "../../../../constants/auth.regex";

export const seekerCredentialsValidation = yup.object({
  email: yup
    .string()
    .email("authInputs.emailErrorInvalid")
    .required("authInputs.emailErrorRequired"),
  password: yup
    .string()
    .required("authInputs.passwordErrorRequired")
    .matches(passwordRegex, "authInputs.passwordErrorMatchRequirements"),
});

const SeekerCredentialsForm = memo(() => {
  const { t } = useTranslation();
  return (
    <>
      <FormItem
        id="email"
        name="email"
        label={t("authInputs.emailLabel")}
        type="email"
        placeholder={t("authInputs.emailPlaceholder")}
        autoComplete="off"
      />
      <FormItem
        id="password"
        name="password"
        label={t("authInputs.passwordSelectLabel")}
        type="password"
        placeholder={t("authInputs.passwordSelectPlaceholder")}
        autoComplete="off"
        caption={t("authInputs.passwordHelpCaption")}
      />
    </>
  );
});

export default SeekerCredentialsForm;
