import * as React from "react";

function SvgArrowRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.491 11l-3.455-3.274 1.376-1.452 5.276 5 .766.726-.766.726-5.276 5-1.376-1.452L17.491 13H0v-2h17.491z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgArrowRight = React.memo(SvgArrowRight);
export default MemoSvgArrowRight;
