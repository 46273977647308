import * as React from "react";

function SvgArrowDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.026 17.491l-3.274-3.455L6.3 15.412l5 5.276.726.766.726-.766 5-5.276-1.452-1.376-3.274 3.455V0h-2v17.491z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgArrowDown = React.memo(SvgArrowDown);
export default MemoSvgArrowDown;
