import { i18next } from "@app/features/localization/localization";

/* eslint-disable @typescript-eslint/camelcase */
const windowObj = window as any;

export const setLocalization = async () => {
  if (windowObj?.Snipcart) {
    try {
      // TODO: Add localization from contentful
      await windowObj.Snipcart.api.session.setLanguage("en", {
        header: {
          title_cart_summary: i18next.t("snipcart.cartHeader"),
        },
        discount_box: {
          promo_code: i18next.t("snipcart.promoLabel"),
          promo_code_placeholder: i18next.t("snipcart.promoInputPlaceholder"),
        },
        errors: {
          promo_code_is_invalid: i18next.t("snipcart.promoInvalidError"),
        },
        confirmation: {
          thank_you_for_your_order: i18next.t("snipcart.bookingThankYou"),
        },
        cart_summary: {
          total: i18next.t("snipcart.cartTotalLabel"),
          promo_code_label: i18next.t("snipcart.promoCodeLabel"),
        },
        address_form: {
          province: i18next.t("snipcart.provinceLabel"),
        },
        billing: {
          title: i18next.t("snipcart.billingTitle"),
          address: i18next.t("snipcart.billingAddress"),
        },
        customer: {
          information: i18next.t("snipcart.customerInformation"),
        },
        cart: {
          summary: i18next.t("snipcart.cartSummary"),
          subtotal: i18next.t("snipcart.cartSubtotal"),
          secured_by: i18next.t("snipcart.securedBySnipcart"),
        },
        payment: {
          title: i18next.t("snipcart.paymentTitle"),
          place_order: i18next.t("snipcart.placeOrder"),
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
};
