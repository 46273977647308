/* eslint-disable @typescript-eslint/camelcase */
import React, { useCallback, useEffect, useState } from "react";

// eslint-disable-next-line import/order
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import "./styles/stripe.scss";
import { PaymentIntent } from "@stripe/stripe-js";
import { Col, Row } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Button, {
  ButtonSizeEnum,
  ButtonTypeEnum,
} from "@app/components/atoms/Button/Button";
import Checkbox from "@app/components/atoms/Checkbox/Checkbox";
import { IconArrowRight } from "@app/components/atoms/Icon/Icon";
import Loading from "@app/components/atoms/Loading/Loading";
import { ErrorModal } from "@app/components/atoms/Modal/Modal";
import RadioButton from "@app/components/atoms/RadioButton/RadioButton";
import { Caption, Subtitle } from "@app/components/atoms/Typography/Typography";
import { ThemeEnum } from "@app/constants/theme.constants";
import {
  confirmSnipcartPayment,
  createPaymentIntent,
  getPaymentCards,
  PaymentCardDef,
  PaymentSessionDef,
  StripePaymentStatus,
} from "@app/features/ecommerce/ecommerce";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";

import styles from "./PaymentForm.module.scss";

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: "16px",
      color: "#54685c",
      fontWeight: "300",
      "::placeholder": {
        color: "rgba(84, 104, 93, 0.5)",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};

interface PaymentFormProps {
  paymentSession: PaymentSessionDef;
  snipcartPublicToken: string;
}

const PaymentForm = ({ snipcartPublicToken }: PaymentFormProps) => {
  const { t } = useTranslation();
  const loadingCards = useSelector(
    (state: RootState) => state.payment.loadingCards
  );
  const loadingIntent = useSelector(
    (state: RootState) => state.payment.loadingIntent
  );
  const paymentCards = useSelector(
    (state: RootState) => state.payment.paymentCards
  );
  const clientSecret = useSelector(
    (state: RootState) => state.payment.clientSecret
  );
  const errorStripe = useSelector(
    (state: RootState) => state.payment.errorIntent
  );
  const paymentIntentId = useSelector(
    (state: RootState) => state.payment.paymentIntentId
  );
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState(false);
  const [paymentMethodId, setPaymentMethodId] = useState<string | null>(null);
  const [useCard, setUseCard] = useState(false);
  const [saveCard, setSaveCard] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    if (paymentCards.length) {
      setPaymentMethodId(paymentCards[0].id);
      setUseCard(true);
      setSaveCard(true);
    } else {
      setPaymentMethodId(null);
      setUseCard(false);
    }
  }, [paymentCards]);

  useEffect(() => {
    if (snipcartPublicToken)
      dispatch(
        createPaymentIntent({
          publicToken: snipcartPublicToken,
          ...(paymentMethodId && { paymentMethodId }),
          ...(paymentIntentId && { paymentIntentId }),
        })
      );
  }, [dispatch, paymentIntentId, paymentMethodId, snipcartPublicToken]);

  useEffect(() => {
    if (paymentIntentId) dispatch(getPaymentCards());
  }, [dispatch, paymentIntentId]);

  const confirmPayment = useCallback(
    async (paymentIntent: PaymentIntent) => {
      const response = await dispatch(
        confirmSnipcartPayment({
          paymentIntentId: paymentIntent.id,
          publicToken: snipcartPublicToken,
        })
      );
      if (response.payload.returnUrl) {
        window.location.href = response.payload.returnUrl;
      }
    },
    [dispatch, snipcartPublicToken]
  );

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setError(null);

    setProcessing(true);
    const cardElement = elements?.getElement(CardNumberElement);

    if (stripe && clientSecret) {
      const payload = await stripe.confirmCardPayment(clientSecret, {
        ...(!paymentMethodId &&
          cardElement && {
            payment_method: {
              card: cardElement,
            },
          }),
        ...(saveCard && { setup_future_usage: "on_session" }),
      });

      if (payload?.error) {
        setError(`Payment failed: ${payload.error.message}`);
        setProcessing(false);
      } else if (
        payload?.paymentIntent?.status === StripePaymentStatus.SUCCEEDED
      ) {
        setError(null);
        setProcessing(false);
        setSucceeded(true);
        confirmPayment(payload.paymentIntent);
      }
    }
  };

  const getCardLabel = (card: PaymentCardDef) => {
    return (
      <div className={styles.card}>
        <Subtitle level={3} className={styles.label}>
          {t("payment.cardEnding")}
        </Subtitle>{" "}
        <Caption>{card.endingDigits}</Caption>
        <Subtitle level={3} className={styles.expiry}>
          {t("payment.expiry")}
        </Subtitle>
        <Caption>{card.expiry}</Caption>
      </div>
    );
  };

  const handleCardSelect = (e: any) => {
    setPaymentMethodId(e.target.value);
  };

  const handleToggleSavedCards = () => {
    if (useCard) {
      setPaymentMethodId(null);
    } else if (paymentCards.length) {
      setPaymentMethodId(paymentCards[0].id);
      setSaveCard(true);
    }

    setError(null);
    setUseCard(!useCard);
  };

  return (
    <Loading isLoading={loadingCards} theme={ThemeEnum.WHITE}>
      <form onSubmit={handleSubmit} className={styles.cardWrapper}>
        <div className={styles.cardsFormTop}>
          {!useCard ? (
            <>
              <Subtitle level={3}>{t("snipcart.cardNumber")}</Subtitle>
              <CardNumberElement
                id="cardNumber"
                options={{
                  ...ELEMENT_OPTIONS,
                  iconStyle: "solid",
                  showIcon: true,
                }}
              />

              <Row>
                <Col xs={6}>
                  <Subtitle level={3}>{t("snipcart.expiry")}</Subtitle>
                  <CardExpiryElement id="expiry" options={ELEMENT_OPTIONS} />
                </Col>
                <Col xs={6}>
                  <Subtitle level={3}>{t("snipcart.cvv")}</Subtitle>
                  <CardCvcElement id="cvc" options={ELEMENT_OPTIONS} />
                </Col>
              </Row>
              <Checkbox
                label={t("payment.saveCards")}
                checked={saveCard}
                onChange={() => setSaveCard(!saveCard)}
                className={styles.checkbox}
              />
            </>
          ) : (
            <>
              {paymentCards.map(card => (
                <div key={card.id}>
                  <RadioButton
                    id={card.id}
                    value={card.id}
                    name="radio"
                    checked={paymentMethodId === card.id}
                    field={{
                      name: "radio",
                      onChange: handleCardSelect,
                    }}
                    label={getCardLabel(card)}
                  />
                </div>
              ))}
            </>
          )}
          {!!paymentCards.length && (
            <Button
              size={ButtonSizeEnum.TEXT}
              label={
                useCard ? t("payment.addNewCard") : t("payment.chooseCard")
              }
              className={styles.addNewButton}
              onClick={handleToggleSavedCards}
              endIcon={<IconArrowRight />}
            />
          )}
        </div>
        {error && (
          <div className={styles.error} role="alert">
            {error}
          </div>
        )}
        <Button
          type="submit"
          isDisabled={processing || succeeded || loadingIntent}
          buttonType={ButtonTypeEnum.PRIMARY}
          label={t("snipcart.placeOrder")}
          fullWidth
          loading={processing}
        />
      </form>
      <ErrorModal
        visible={errorStripe}
        title={t("payment.paymentFailed")}
        subtitle={t("payment.paymentError")}
        onConfirm={() => history.go(0)}
      />
    </Loading>
  );
};

export default PaymentForm;
