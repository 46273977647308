import { AxiosResponse } from "axios";

import { api } from "@app/api/api";

import {
  PAYMENT_CARDS_URL,
  PAYMENT_CONFIRM_URL,
  PAYMENT_INTENTS_URL,
  PAYMENT_SESSION_URL,
} from "../constants/payment.endpoints";
import {
  ConfirmSnipcartPaymentDef,
  CreatePaymentIntentDef,
} from "../types/payment.types";

const getPaymentSession = (publicToken: string): Promise<AxiosResponse> => {
  return api.get(PAYMENT_SESSION_URL, { params: { publicToken } });
};

const getPaymentCards = (): Promise<AxiosResponse> => {
  return api.get(PAYMENT_CARDS_URL);
};

const createPaymentIntent = (
  payload: CreatePaymentIntentDef
): Promise<AxiosResponse> => {
  return api.post(PAYMENT_INTENTS_URL, payload);
};

const confirmSnipcartPayment = (
  payload: ConfirmSnipcartPaymentDef
): Promise<AxiosResponse> => {
  return api.post(PAYMENT_CONFIRM_URL, payload);
};

export const paymentApi = {
  getPaymentSession,
  getPaymentCards,
  createPaymentIntent,
  confirmSnipcartPayment,
};
