import * as React from "react";

function SvgAdd(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 13v7h2v-7h7v-2h-7V4h-2v7H4v2h7z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgAdd = React.memo(SvgAdd);
export default MemoSvgAdd;
