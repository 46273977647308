import React, { memo } from "react";

import FormItem from "@app/components/atoms/FormItem/FormItem";
import { IconBad, IconGood } from "@app/components/atoms/Icon/Icon";
import { Subtitle, Caption } from "@app/components/atoms/Typography/Typography";

import styles from "./FeedbackSimple.module.scss";

export interface FeedbackSimpleProps {
  title: string;
  subtitle: string;
  onClick: () => void;
}

const FeedbackSimple = memo(
  ({ title, subtitle, onClick }: FeedbackSimpleProps) => {
    return (
      <>
        <Subtitle level={3} className={styles.title}>
          {title}
        </Subtitle>
        <Caption className={styles.subtitle}>{subtitle}</Caption>

        <div className={styles.itemWrapper}>
          <FormItem
            type="radio"
            name="like"
            id="good"
            value={1}
            radioIcon={<IconGood />}
            onClick={onClick}
            className={styles.itemContainer}
            itemClassName={styles.item}
          />
          <FormItem
            type="radio"
            name="like"
            id="bad"
            value={0}
            radioIcon={<IconBad />}
            onClick={onClick}
            itemClassName={styles.item}
          />
        </div>
      </>
    );
  }
);

export default FeedbackSimple;
