import { RouteItemDef } from "@app/types/route.types";

import { SearchPathsEnum } from "../constants/search.paths";
import SearchScreen from "../screens/SearchScreen/SearchScreen";

const SEARCH_SCREEN: RouteItemDef = {
  id: "search",
  path: SearchPathsEnum.SEARCH,
  component: SearchScreen,
  metaTitle: "search.metaTitle",
};

export const SEARCH_ROUTES = [SEARCH_SCREEN];
