import * as React from "react";

function SvgChatOff(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.993 20.707l-18-18 1.414-1.414L6.114 3H21v14.886l1.407 1.407-1.414 1.414zM19 15.885V5H8.114L19 15.886zM3 20V5.514l2 2v10.72l3.486-2.092.237-.142h4.763l2 2H9.277l-4.763 2.858L3 21.766V20z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgChatOff = React.memo(SvgChatOff);
export default MemoSvgChatOff;
