import * as React from "react";

function SvgGiftcard(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.91 3.91a1.273 1.273 0 000 2.545h2.733a7.239 7.239 0 00-.372-.791c-.566-1.03-1.327-1.755-2.362-1.755zM4.635 5.181c0 .441.09.874.258 1.273H1.909V13h1.818v9.09h16.546V13h1.818V6.455H19.106a3.272 3.272 0 00-3.015-4.546c-2.122 0-3.402 1.512-4.091 2.748-.69-1.236-1.969-2.748-4.09-2.748a3.273 3.273 0 00-3.274 3.273zm8.72 1.273h2.735a1.273 1.273 0 100-2.546c-1.035 0-1.796.725-2.363 1.755-.147.267-.27.538-.371.79zm-2.576 2H3.91V11H11V8.455h-.22zm2.22 0V11H20.091V8.455H13zm0 11.636h5.273V13H13v7.09zM11 13v7.09H5.727V13H11z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgGiftcard = React.memo(SvgGiftcard);
export default MemoSvgGiftcard;
