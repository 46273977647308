import React, { memo } from "react";

import { Link } from "react-router-dom";

import { Subtitle } from "@app/components/atoms/Typography/Typography";

import styles from "./FooterLinkItem.module.scss";

export interface FooterLinkItemProps {
  label: string;
  link: string;
}

const FooterLinkItem = memo(({ label, link }: FooterLinkItemProps) => {
  return (
    <li className={styles.footerLinkItem}>
      <Link to={link} className={styles.link}>
        <Subtitle className={styles.subtitle} level={3}>
          {label}
        </Subtitle>
      </Link>
    </li>
  );
});

export default FooterLinkItem;
