import React, { memo } from "react";

import {
  IconFacebook,
  IconInstagram,
  IconLinkedIn,
} from "@app/components/atoms/Icon/Icon";

import styles from "./SocialLinks.module.scss";
import SocialLink from "./components/SocialLink/SocialLink";

interface SocialLinksProps {
  links: {
    facebookLink: string;
    instagramLink: string;
    linkedInLink: string;
  };
}

const SocialLinks = memo(({ links }: SocialLinksProps) => {
  return (
    <ul className={styles.socialLinks}>
      {links.instagramLink && (
        <SocialLink link={links.instagramLink} icon={<IconInstagram />} />
      )}
      {links.facebookLink && (
        <SocialLink link={links.facebookLink} icon={<IconFacebook />} />
      )}
      {links.linkedInLink && (
        <SocialLink link={links.linkedInLink} icon={<IconLinkedIn />} />
      )}
    </ul>
  );
});

export default SocialLinks;
