import React, { useCallback, useEffect } from "react";

import qs from "query-string";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { useMount } from "react-use";

import List from "@app/components/atoms/List/List";
import Loading from "@app/components/atoms/Loading/Loading";
import SearchFilters from "@app/components/atoms/SearchFilters/SearchFilters";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import DefaultLayout from "@app/components/layouts/DefaultLayout/DefaultLayout";
import Section from "@app/components/layouts/Section/Section";
import PractitionersList from "@app/components/renderings/PractitionersList/PractitionersList";
import SessionsList from "@app/components/renderings/SessionsList/SessionsList";
import { ThemeEnum } from "@app/constants/theme.constants";
import { ClassesDef } from "@app/features/classes/classes";
import { PractitionerDef } from "@app/features/practitioners/practitioners";
import { removeUndefined } from "@app/helpers/util.helpers";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";

import {
  getSearchFilters,
  searchAndAppend,
  searchContent,
} from "../../redux/search.slice";
import { SearchTypeEnum } from "../../search";
import { SearchFiltersDef } from "../../types/search.types";
import NoResults from "./components/NoResults/NoResults";
import SearchBar from "./components/SearchBar/SearchBar";

const SearchScreen = ({ location, history }: RouteComponentProps) => {
  const currentSearch = qs.parse(location.search, { arrayFormat: "bracket" });

  const { results, pagination, loading, filters, loadingMore } = useSelector(
    (state: RootState) => state.search
  );
  const dispatch = useAppDispatch();

  const getActiveType = useCallback(() => {
    const typeFromUrl = qs.parse(location.search)?.types;
    return typeFromUrl ?? SearchTypeEnum.CLASSES;
  }, [location.search]);

  useMount(() => {
    if (!filters) {
      dispatch(getSearchFilters());
    }
  });

  useEffect(() => {
    dispatch(
      searchContent({
        ...qs.parse(location.search, { arrayFormat: "bracket" }),
        types: getActiveType() as SearchTypeEnum,
      })
    );
  }, [dispatch, getActiveType, location.search]);

  const onChangeParams = (values: SearchFiltersDef) => {
    const pageFilters = {
      ...currentSearch,
      ...values,
    };

    removeUndefined(pageFilters);

    history.push({
      pathname: location.pathname,
      search: qs.stringify(pageFilters, { arrayFormat: "bracket" }),
    });
  };

  const handleSearch = (search: string) => {
    onChangeParams({ name: search });
  };

  const handleUpdateFilters = (search: SearchFiltersDef) => {
    onChangeParams({ ...search });
  };

  const handleLoadMore = () => {
    dispatch(
      searchAndAppend({
        ...qs.parse(location.search, { arrayFormat: "bracket" }),
        types: getActiveType() as SearchTypeEnum,
        page: `${pagination.current_page + 1}`,
      })
    );
  };

  const renderResults = () => {
    const searchResults =
      results[
        JSON.stringify({
          ...currentSearch,
          types: getActiveType() as SearchTypeEnum,
        })
      ];

    if (!searchResults?.length) {
      return <NoResults />;
    }

    return (
      <List
        isLoading={loading}
        isLoadingMore={loadingMore}
        pagination={pagination}
        onLoadMore={handleLoadMore}
      >
        {
          {
            [SearchTypeEnum.PRACTITIONERS]: (
              <PractitionersList items={searchResults as PractitionerDef[]} />
            ),
            [SearchTypeEnum.CLASSES]: (
              <SessionsList sessions={searchResults as ClassesDef[]} />
            ),
            [SearchTypeEnum.ARTICLES]: <div />,
          }[getActiveType() as SearchTypeEnum]
        }
      </List>
    );
  };

  return (
    <DefaultLayout>
      <Loading isLoading={loading}>
        <Section theme={ThemeEnum.KHAKI}>
          <ContentWrapper>
            <SearchBar
              onSearch={handleSearch}
              defaultValue={currentSearch?.name as string}
            />
            {filters && (
              <SearchFilters
                onChangeFilters={handleUpdateFilters}
                selected={currentSearch as Record<string, any>}
                filters={filters}
              />
            )}
          </ContentWrapper>
          {renderResults()}
        </Section>
      </Loading>
    </DefaultLayout>
  );
};

export default SearchScreen;
