import * as React from "react";

function SvgInstagram(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 2.712c2.536 0 2.836.01 3.838.055.927.042 1.43.197 1.764.327.444.172.76.378 1.093.71.332.333.538.65.71 1.093.13.335.285.838.327 1.764.046 1.001.056 1.302.056 3.839 0 2.536-.01 2.836-.056 3.838-.042.927-.197 1.43-.327 1.764-.172.444-.378.76-.71 1.093a2.938 2.938 0 01-1.093.71c-.334.13-.838.285-1.764.327-1.001.046-1.302.056-3.838.056-2.537 0-2.838-.01-3.84-.056-.925-.042-1.428-.197-1.763-.327a2.947 2.947 0 01-1.093-.71 2.937 2.937 0 01-.71-1.093c-.13-.334-.285-.838-.327-1.764-.046-1.001-.055-1.302-.055-3.838 0-2.537.01-2.837.055-3.84.042-.925.197-1.428.327-1.763.172-.444.378-.76.71-1.093a2.938 2.938 0 011.093-.71c.335-.13.838-.285 1.764-.327 1.002-.046 1.302-.055 3.839-.055zM9.5 1c-2.58 0-2.904.01-3.917.057-1.011.046-1.702.207-2.306.442a4.653 4.653 0 00-1.683 1.096A4.653 4.653 0 00.5 4.277C.264 4.88.103 5.572.057 6.583.011 7.596 0 7.92 0 10.5s.01 2.903.057 3.916c.046 1.011.207 1.702.442 2.306a4.652 4.652 0 001.096 1.683A4.655 4.655 0 003.277 19.5c.604.235 1.295.395 2.306.441C6.596 19.99 6.92 20 9.5 20s2.904-.011 3.917-.058c1.01-.046 1.702-.206 2.306-.441a4.651 4.651 0 001.682-1.096 4.655 4.655 0 001.096-1.683c.235-.604.396-1.295.442-2.306.046-1.013.057-1.336.057-3.916s-.01-2.904-.057-3.917c-.046-1.011-.207-1.702-.442-2.306a4.652 4.652 0 00-1.096-1.683A4.655 4.655 0 0015.723 1.5c-.605-.235-1.296-.396-2.307-.442C12.403 1.011 12.08 1 9.5 1zm0 4.622a4.878 4.878 0 100 9.755 4.878 4.878 0 000-9.755zm0 8.044a3.166 3.166 0 110-6.333 3.166 3.166 0 010 6.333zm6.21-8.237a1.14 1.14 0 11-2.28 0 1.14 1.14 0 012.28 0z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgInstagram = React.memo(SvgInstagram);
export default MemoSvgInstagram;
