import { UserRole } from "@app/constants/user";
import { UserDef } from "@app/types/api.types";

export const isSeeker = (roles: UserDef["roles"]) => {
  return roles.some(role => role.name === UserRole.SEEKER);
};

export const isPractitioner = (roles: UserDef["roles"]) => {
  return roles.some(role => role.name === UserRole.PRACTITIONER);
};
