import React, { memo } from "react";

import ExternalLink from "@app/components/atoms/ExternalLink/ExternalLink";

import styles from "./SocialLink.module.scss";

interface SocialLinkProps {
  link: string;
  icon: React.ReactElement;
}

const SocialLink = memo(({ link, icon }: SocialLinkProps) => {
  return (
    <li className={styles.socialItem}>
      <ExternalLink url={link} className={styles.socialItemLink}>
        {icon}
      </ExternalLink>
    </li>
  );
});

export default SocialLink;
