import React, { memo } from "react";

import { useLocation } from "react-router-dom";

import { NavigationItemType } from "@app/types/navigation.types";

import NavigationItem from "../NavigationItem/NavigationItem";
import styles from "./SubNavigation.module.scss";

interface SubNavigationProps {
  navItems: NavigationItemType[];
}

const SubNavigation = memo(({ navItems = [] }: SubNavigationProps) => {
  const location = useLocation();

  const isActive = (item: NavigationItemType) => {
    return (
      item.path === location.pathname ||
      item.activeFor?.some(otherLink => location.pathname.includes(otherLink))
    );
  };

  return navItems.length > 0 ? (
    <ul className={styles.wrapper}>
      {navItems.map(item => (
        <NavigationItem
          id={item.id}
          key={item.id}
          label={item.label}
          path={item.path}
          isActive={isActive(item)}
          navItemClassName={styles.navItem}
        />
      ))}
    </ul>
  ) : null;
});

export default SubNavigation;
