import React, { memo } from "react";

import { format } from "date-fns";
import { Col, Container, Row } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-media";

import ExternalLink from "@app/components/atoms/ExternalLink/ExternalLink";
import { IconDownload, IconRewatch } from "@app/components/atoms/Icon/Icon";
import Image from "@app/components/atoms/Image/Image";
import {
  Caption,
  Subtitle,
  Title,
} from "@app/components/atoms/Typography/Typography";
import { GLOBAL_MEDIA_QUERIES } from "@app/constants/breakpoints";
import { DateFormats } from "@app/constants/date.constants";
import { IMAGE_TYPES } from "@app/constants/image";
import { ClassesDef } from "@app/features/classes/classes";
import { getImageUrl } from "@app/helpers/image.helpers";
import { getFullName } from "@app/helpers/util.helpers";

import styles from "./PastClasses.module.scss";

interface PastClassesProps {
  sessions: ClassesDef[];
}

const PastClasses = memo(({ sessions }: PastClassesProps) => {
  const { t } = useTranslation();
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

  const getIcons = (session: ClassesDef) => {
    return (
      <>
        {session.archiveUrls?.[0] && (
          <ExternalLink url={session.archiveUrls[0]}>
            <IconRewatch className={styles.icon} />
          </ExternalLink>
        )}
        {session.pdf && (
          <ExternalLink url={session.pdf}>
            <IconDownload className={styles.icon} />
          </ExternalLink>
        )}
      </>
    );
  };

  return matches.tabletLandscape ? (
    <Container className={styles.sessions} fluid>
      <Row className={styles.row}>
        <Col md={6} className={styles.sessionName}>
          <Title level={3}>{t("default.session")}</Title>
        </Col>
        <Col md={3}>
          <Title level={3}>{t("default.practitioner")}</Title>
        </Col>
        <Col md={2}>
          <Title level={3}>{t("default.date")}</Title>
        </Col>
        <Col md={1} />
      </Row>
      {sessions.map(session => (
        <Row key={session.id} className={styles.row}>
          <Col md={6} className={styles.titleContainer}>
            <div>
              <Image
                className={styles.image}
                url={getImageUrl(session.image, IMAGE_TYPES.SESSION)}
              />
            </div>
            <div>
              <Title level={3} className={styles.title}>
                {session.experiences?.[0]?.name}
              </Title>
              <Subtitle level={2}>{session.name}</Subtitle>
              {session.cancelled && (
                <Subtitle level={3} className={styles.cancelled}>
                  {t("sessionCard.cancelled")}
                </Subtitle>
              )}
            </div>
          </Col>
          <Col md={3}>
            <Subtitle level={3}>{getFullName(session.practitioner)}</Subtitle>
          </Col>
          <Col md={2}>
            <Subtitle level={3}>
              {format(new Date(session.startAt), DateFormats.SHORT)}
            </Subtitle>
          </Col>
          <Col md={1}>{getIcons(session)}</Col>
        </Row>
      ))}
    </Container>
  ) : (
    <div>
      {sessions.map(session => (
        <div className={styles.sessionCardMobile} key={session.id}>
          <Row>
            <Col>
              <Title level={3} className={styles.title}>
                {session.experiences?.[0]?.name}
              </Title>
            </Col>
            <Col className={styles.col2}>
              <Title level={3} className={styles.date}>
                {format(new Date(session.startAt), DateFormats.SHORT)}
              </Title>
            </Col>
          </Row>
          <Subtitle level={2} className={styles.sessionName}>
            {session.name}
          </Subtitle>
          <Caption>{getFullName(session.practitioner)}</Caption>
          {session.cancelled && (
            <Caption className={styles.cancelled}>
              {t("sessionCard.cancelled")}
            </Caption>
          )}
          <div className={styles.actions}>{getIcons(session)}</div>
        </div>
      ))}
    </div>
  );
});

export default PastClasses;
