import React, { memo, useMemo } from "react";

import { Container } from "react-grid-system";
import { useMedia } from "react-media";

import SwiperCarousel, {
  SwiperSlide,
  SwiperBreakpoints,
} from "@app/components/atoms/SwiperCarousel/SwiperCarousel";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import Section from "@app/components/layouts/Section/Section";
import { GLOBAL_MEDIA_QUERIES, ScreenSizes } from "@app/constants/breakpoints";
import { ThemeEnum, BackgroundRatioEnum } from "@app/constants/theme.constants";

import { Title } from "../../atoms/Typography/Typography";
import styles from "./Articles.module.scss";
import ArticleItem, {
  ArticleItemProps,
} from "./components/ArticleItem/ArticleItem";

export interface ArticlesProps {
  /**
   * Set theme for section, which will control section backgrounds color and nested components
   */
  theme: ThemeEnum;
  /**
   * Set the subTitle of the hero content
   */
  subtitle?: string;
  /**
   * Set the main title of the component
   */
  title: string;
  slideItems: ArticleItemProps[];
}

const Articles = memo(
  ({ theme, title, subtitle, slideItems }: ArticlesProps) => {
    const SLIDES_PER_VIEW = 4;
    const SLIDES_PER_VIEW_MOBILE = 1.5;
    const SLIDES_PER_VIEW_TABLET = 3;
    const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

    const swiperBreakpoints: SwiperBreakpoints = {
      [ScreenSizes.XSMALL]: {
        slidesPerView: 1.15,
      },
      [ScreenSizes.SMALL]: {
        slidesPerView: 1.5,
      },
      [ScreenSizes.MEDIUM]: {
        slidesPerView: 3,
      },
      [ScreenSizes.XLARGE]: {
        slidesPerView: SLIDES_PER_VIEW,
      },
    };

    const slidesPerView = useMemo(() => {
      if (matches.tabletPortrait) return SLIDES_PER_VIEW_TABLET;
      return SLIDES_PER_VIEW_MOBILE;
    }, [matches.tabletPortrait]);

    const isMultipleSlides = useMemo(
      () => slideItems.length > slidesPerView,
      [slideItems.length, slidesPerView]
    );

    return (
      <Section
        theme={theme}
        backgroundRatio={BackgroundRatioEnum.HALF}
        withSeparatorSpacing
      >
        <ContentWrapper>
          <Container fluid>
            <Title level={3} className={styles.subtitle} isGoldGradient>
              {subtitle}
            </Title>
            <Title level={1} className={styles.title}>
              {title}
            </Title>
          </Container>
          <SwiperCarousel
            swiperClassname={styles.articlesSwiper}
            swiperBreakpoints={swiperBreakpoints}
            enableLoop={isMultipleSlides}
            showNavArrows={isMultipleSlides}
            showPagination={isMultipleSlides}
          >
            {slideItems?.map(item => (
              <SwiperSlide key={item.id}>
                <ArticleItem
                  id={item.id}
                  title={item.title}
                  link={item.link}
                  image={item.image}
                />
              </SwiperSlide>
            ))}
          </SwiperCarousel>
        </ContentWrapper>
      </Section>
    );
  }
);

export default Articles;
