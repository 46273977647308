/* eslint-disable @typescript-eslint/camelcase */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { PaginationDef } from "@app/types/pagination.types";

import { experiencesApi } from "../api/experiences.api";
import {
  ExperienceDef,
  ExperiencesFilterDef,
} from "../types/experiences.types";

interface ExperiencesState {
  experiences: { data: ExperienceDef[]; pagination: PaginationDef };
  experience: ExperienceDef | null;
  loading: boolean;
  loadingMore: boolean;
  error: boolean;
}

const initialState: ExperiencesState = {
  experiences: {
    data: [],
    pagination: {
      current_page: 1,
      last_page: 1,
      per_page: 15,
      total: 1,
    },
  },
  experience: null,
  loading: false,
  loadingMore: false,
  error: false,
};

export const getExperiences = createAsyncThunk(
  "experiences/getExperiences",
  async (filter: ExperiencesFilterDef, { rejectWithValue }) => {
    try {
      const response = await experiencesApi.getExperiences(filter);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getExperiencesAndAppend = createAsyncThunk(
  "experiences/getExperiencesAndAppend",
  async (filter: ExperiencesFilterDef, { rejectWithValue }) => {
    try {
      const response = await experiencesApi.getExperiences(filter);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getExperienceById = createAsyncThunk(
  "experiences/getExperienceById",
  async (id: ExperienceDef["id"], { rejectWithValue }) => {
    try {
      const response = await experiencesApi.getExperienceById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const experiencesSlice = createSlice({
  name: "experiences",
  initialState,
  reducers: {
    clearExperience: state => {
      state.experience = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(getExperiences.pending, state => {
      state.loading = true;
    });
    builder.addCase(getExperiences.fulfilled, (state, action) => {
      state.loading = false;
      state.experiences.data = action.payload.data;
      state.experiences.pagination = action.payload.meta;
    });
    builder.addCase(getExperiencesAndAppend.pending, state => {
      state.loadingMore = true;
    });
    builder.addCase(getExperiencesAndAppend.fulfilled, (state, action) => {
      state.loadingMore = false;
      state.experiences.data = [
        ...state.experiences.data,
        ...action.payload.data,
      ];
      state.experiences.pagination = action.payload.meta;
    });
    builder.addCase(getExperienceById.fulfilled, (state, action) => {
      state.loading = false;
      state.experience = action.payload;
      state.error = false;
    });
    builder.addCase(getExperienceById.pending, state => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getExperienceById.rejected, state => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { clearExperience } = experiencesSlice.actions;

export default experiencesSlice.reducer;
