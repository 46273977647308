import React, { memo, useMemo, useState } from "react";

import cx from "classnames";
import { format, isToday } from "date-fns";
import { MenuInfo } from "rc-menu/lib/interface";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";

import { IconCheckmark1, IconOptions } from "@app/components/atoms/Icon/Icon";
import Image from "@app/components/atoms/Image/Image";
import {
  Caption,
  Subtitle,
  Title,
} from "@app/components/atoms/Typography/Typography";
import Section from "@app/components/layouts/Section/Section";
import { DateFormats, TimeFormats } from "@app/constants/date.constants";
import { IMAGE_TYPES } from "@app/constants/image";
import { ThemeEnum } from "@app/constants/theme.constants";
import {
  canCancel,
  ClassesDef,
  isLiveSession,
} from "@app/features/classes/classes";
import { EcommercePathsEnum } from "@app/features/ecommerce/ecommerce";
import { SessionPathsEnum } from "@app/features/session/session";
import { cancelBooking } from "@app/features/users/users";
import { getImageUrl } from "@app/helpers/image.helpers";
import { getFullName } from "@app/helpers/util.helpers";
import { useAppDispatch } from "@app/redux/store";

import Dropdown from "../Dropdown/Dropdown";
import ExternalLink from "../ExternalLink/ExternalLink";
import { ErrorModal } from "../Modal/Modal";
import styles from "./SessionCard.module.scss";
import CancelSessionModal from "./components/CancelSessionModal/CancelSessionModal";

export interface SessionCardProps {
  session: ClassesDef;
  smallOnMobile?: boolean;
  className?: string;
  allowCancel?: boolean;
  isHomePage?: boolean;
}

enum OptionItems {
  CANCEL = "cancel",
}

const SessionCard = memo(
  ({
    session,
    smallOnMobile = true,
    className,
    allowCancel = false,
    isHomePage,
  }: SessionCardProps) => {
    const { t } = useTranslation();
    const [cancelModalVisible, setCancelModalVisible] = useState(false);
    const [errorModalVisible, setErrorModalVisible] = useState(false);
    const dispatch = useAppDispatch();

    const isStarted = !!(session.booked && isLiveSession(session));
    const showCancelledLabel = session.cancelled && allowCancel;

    const getDateLabel = () => {
      const sessionDate = new Date(session.startAt);
      return isToday(sessionDate)
        ? t("sessionCard.todaysDate", {
            time: format(sessionDate, TimeFormats.LONG),
          })
        : format(sessionDate, DateFormats.LONG);
    };

    const getCTALabel = () => {
      if (showCancelledLabel) {
        return t("sessionCard.cancelled");
      }

      if (isStarted) {
        return t("sessionCard.joinSession");
      }

      return session.booked ? t("sessionCard.booked") : t("sessionCard.book");
    };

    const getCTA = () => {
      const ctaContent = (
        <div
          className={cx(styles.sessionCTA, {
            [styles.liveSession]: isStarted,
            [styles.bookedSession]: session.booked && !isStarted,
            [styles.cancelledSession]: showCancelledLabel,
          })}
        >
          <Title level={3}>{getCTALabel()}</Title>
        </div>
      );

      // if session started
      if (isStarted) {
        // then wrap in zoom link
        if (session.zoomLink) {
          return (
            <ExternalLink url={session.zoomLink}>{ctaContent}</ExternalLink>
          );
        }
        // or link to session screen
        return (
          <Link
            to={generatePath(SessionPathsEnum.SESSION, {
              id: session.id,
            })}
            className={styles.link}
          >
            {ctaContent}
          </Link>
        );
      }
      // otherwise just return content
      return ctaContent;
    };

    const handleOptionClick = (event: MenuInfo) => {
      if (event.key === OptionItems.CANCEL) {
        if (canCancel(session)) {
          setCancelModalVisible(true);
        } else {
          setErrorModalVisible(true);
        }
      }
    };

    const menu = useMemo(
      () => [{ id: OptionItems.CANCEL, value: t("sessionCard.cancelButton") }],
      [t]
    );

    const handleCancelSession = async () => {
      const response = await dispatch(
        cancelBooking({
          id: session.id,
          classAttendeeId: session.classAttendeeId,
        })
      );
      if (cancelBooking.fulfilled.match(response)) {
        setCancelModalVisible(false);
      }
    };

    const sessionCardContent = (
      <div
        className={cx(styles.sessionCard, className, {
          [styles.smallSessionCard]: smallOnMobile,
          [styles.isHome]: isHomePage,
        })}
      >
        <Section theme={ThemeEnum.IMAGERY} className={styles.imageWrapper}>
          <div className={styles.topWidget}>
            <Title level={3} className={styles.sessionTime}>
              {isStarted ? t("sessionCard.liveSession") : getDateLabel()}
            </Title>
            {getCTA()}
          </div>
          {!isStarted && session.booked && !session.cancelled && (
            <div className={styles.bookedIcon}>
              <IconCheckmark1 />
            </div>
          )}
          <Image
            url={getImageUrl(session.image, IMAGE_TYPES.SESSION)}
            className={styles.image}
            alt={session.name}
          />
        </Section>
        <div className={styles.textWrapper}>
          <div className={styles.titleWrapper}>
            <Title level={3} className={styles.title}>
              {session.experiences?.[0]?.name}
            </Title>
            <Caption className={styles.caption}>
              {t("product.durationInMinutes", {
                duration: session.duration,
              })}
            </Caption>
          </div>
          <Subtitle level={1} className={styles.subtitle}>
            {session.name}
          </Subtitle>
          <Caption className={styles.caption}>
            {t("sessionCard.author", {
              authorName: getFullName(session.practitioner),
            })}
          </Caption>
          <Caption className={styles.timeMobile}>
            {format(new Date(session.startAt), DateFormats.LONG)}
          </Caption>
          {showCancelledLabel && (
            <Caption className={styles.cancelledMobile}>
              {t("sessionCard.cancelled")}
            </Caption>
          )}
          {allowCancel && !session.cancelled && (
            <>
              <Dropdown
                items={menu}
                placement="bottomRight"
                onClickMenuItem={handleOptionClick}
                theme={ThemeEnum.WHITE}
              >
                <IconOptions className={styles.optionsToggle} />
              </Dropdown>
            </>
          )}
        </div>
      </div>
    );

    return (
      <>
        {isStarted ? (
          sessionCardContent
        ) : (
          <Link
            to={generatePath(EcommercePathsEnum.PRODUCT_DETAILS, {
              id: session.id,
              slug: session.slug !== "" ? session.slug : undefined,
            })}
            className={styles.link}
          >
            {sessionCardContent}
          </Link>
        )}
        {allowCancel && (
          <>
            <CancelSessionModal
              visible={cancelModalVisible}
              onConfirm={handleCancelSession}
              onDismiss={() => setCancelModalVisible(false)}
              session={session}
            />
            <ErrorModal
              visible={errorModalVisible}
              title={t("sessionCard.cancelErrorTitle")}
              subtitle={t("sessionCard.cancelErrorSubTitle")}
              hideConfirm
              showCloseButton
              onClose={() => setErrorModalVisible(false)}
              maskClosable
            />
          </>
        )}
      </>
    );
  }
);

export default SessionCard;
