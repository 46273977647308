import * as React from "react";

function SvgFullMoon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={10.5} cy={10.5} r={6.5} stroke="#D9B677" strokeWidth={1.5} />
    </svg>
  );
}

const MemoSvgFullMoon = React.memo(SvgFullMoon);
export default MemoSvgFullMoon;
