import React, { memo } from "react";

import { IMAGE_TYPES } from "@app/constants/image";
import { useSingleEntry } from "@app/features/contentful/contentful";
import { getImageUrl } from "@app/helpers/image.helpers";
import { ContentfulMedia } from "@app/types/contentful.types";

import Banner from "./Banner";

interface BannerContentfulProps {
  entryID: string;
}

interface BannerDataModel {
  title: string;
  ctaLink: string;
  image: ContentfulMedia;
  fullwidth: boolean;
}

const BannerContentful = memo(({ entryID }: BannerContentfulProps) => {
  const { entry: bannerData } = useSingleEntry<BannerDataModel>(entryID);

  if (!bannerData) return null;

  const { title, ctaLink, image, fullwidth } = bannerData;

  return (
    image && (
      <Banner
        title={title}
        CTALink={ctaLink}
        imageUrl={getImageUrl(image.fields.file.url, IMAGE_TYPES.BANNER)}
        fullwidth={fullwidth}
      />
    )
  );
});

export default BannerContentful;
