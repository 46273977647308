import * as React from "react";

function SvgStarInactive(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M12 0l2.694 8.292h8.719l-7.054 5.124 2.694 8.292L12 16.584l-7.053 5.124 2.694-8.292L.587 8.292h8.719L12 0z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgStarInactive = React.memo(SvgStarInactive);
export default MemoSvgStarInactive;
