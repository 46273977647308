/* eslint-disable @typescript-eslint/camelcase */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { PaginationDef } from "@app/types/pagination.types";

import { practitionersApi } from "../api/practitioners.api";
import {
  PractitionerDef,
  PractitionersFilterDef,
} from "../types/practitioners.types";

interface PractitionersState {
  practitioners: { data: PractitionerDef[]; pagination: PaginationDef };
  practitioner: PractitionerDef | null;
  loading: boolean;
  loadingMore: boolean;
  error: boolean;
}

const initialState: PractitionersState = {
  practitioners: {
    data: [],
    pagination: {
      current_page: 1,
      last_page: 1,
      per_page: 15,
      total: 1,
    },
  },
  practitioner: null,
  loading: false,
  loadingMore: false,
  error: false,
};

export const getPractitioners = createAsyncThunk(
  "practitioners/getPractitioners",
  async (filter: PractitionersFilterDef, { rejectWithValue }) => {
    try {
      const response = await practitionersApi.getPractitioners(filter);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data as string);
    }
  }
);

export const getPractitionersAppend = createAsyncThunk(
  "practitioners/getPractitionersAppend",
  async (filter: PractitionersFilterDef, { rejectWithValue }) => {
    try {
      const response = await practitionersApi.getPractitioners(filter);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data as string);
    }
  }
);

export const getPractitionerById = createAsyncThunk(
  "practitioners/getPractitionerById",
  async (id: PractitionerDef["id"], { rejectWithValue }) => {
    try {
      const response = await practitionersApi.getPractitionerById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data as string);
    }
  }
);

const practitionersSlice = createSlice({
  name: "practitioners",
  initialState,
  reducers: {
    clearPractitioner: state => {
      state.practitioner = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(getPractitioners.fulfilled, (state, action) => {
      state.loading = false;
      state.practitioners.data = action.payload.data;
      state.practitioners.pagination = action.payload.meta;
    });
    builder.addCase(getPractitionerById.fulfilled, (state, action) => {
      state.loading = false;
      state.practitioner = action.payload;
      state.error = false;
    });
    builder.addCase(getPractitionersAppend.fulfilled, (state, action) => {
      state.loadingMore = false;
      state.practitioners.data = [
        ...state.practitioners.data,
        ...action.payload.data,
      ];
      state.practitioners.pagination = action.payload.meta;
    });
    builder.addCase(getPractitioners.pending, state => {
      state.loading = true;
    });
    builder.addCase(getPractitionerById.pending, state => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getPractitionerById.rejected, state => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(getPractitionersAppend.pending, state => {
      state.loadingMore = true;
    });
  },
});

export const { clearPractitioner } = practitionersSlice.actions;

export default practitionersSlice.reducer;
