import React, { memo } from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Avatar, { AvatarSize } from "@app/components/atoms/Avatar/Avatar";
import { Paragraph, Title } from "@app/components/atoms/Typography/Typography";
import {
  PractitionerDef,
  PRACTITIONER_PATH,
} from "@app/features/practitioners/practitioners";
import { getFullName } from "@app/helpers/util.helpers";

import styles from "./PractitionerInfo.module.scss";

export interface PractitionerInfoProps {
  practitioner: PractitionerDef;
}

const PractitionerInfo = memo(({ practitioner }: PractitionerInfoProps) => {
  const { t } = useTranslation();

  if (!practitioner) return null;

  const practitionerURL = `${PRACTITIONER_PATH}/${practitioner.id}`;

  return (
    <>
      <Title level={3} className={styles.practitionerSubheader} isGoldGradient>
        {t("product.guidedBy")}
      </Title>
      <div className={styles.practitioner}>
        {practitioner.image && (
          <Avatar
            className={styles.avatar}
            avatarImage={practitioner.image}
            avatarSize={AvatarSize.LARGE}
          />
        )}
        <div className={styles.practitionerText}>
          <Paragraph level={1}>{getFullName(practitioner)}</Paragraph>
          {practitioner.description && (
            <Paragraph level={2} className={styles.practitionerDescription}>
              {practitioner.description}
            </Paragraph>
          )}
          <Paragraph level={2} isGold>
            <Link to={practitionerURL} className={styles.link}>
              {t("product.practitionerLinkLabel", {
                firstName: practitioner.firstName,
              })}
            </Link>
          </Paragraph>
        </div>
      </div>
    </>
  );
});

export default PractitionerInfo;
