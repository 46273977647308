import React, { memo, ReactNode } from "react";

import cx from "classnames";
import Collapse from "rc-collapse";

import MemoSvgChevronDown from "../Icon/icons/ChevronDown";
import MemoSvgChevronUp from "../Icon/icons/ChevronUp";
import styles from "./Accordian.module.scss";
import motion from "./util/accordian.motion";

const { Panel } = Collapse;
export interface AccordianItem {
  id: number | string;
  title: ReactNode;
  content: ReactNode;
}
export interface AccordianProps {
  items: AccordianItem[];
  headerClassName?: string;
  titleClassName?: string;
  contentClassName?: string;
  itemClassName?: string;
  iconClassName?: string;
  accordian?: boolean;
  defaultActiveKey?: AccordianItem["id"];
}

const Accordian = memo(
  ({
    items,
    headerClassName,
    titleClassName,
    contentClassName,
    itemClassName,
    accordian = true,
    defaultActiveKey,
    iconClassName,
  }: AccordianProps) => {
    // FIXME: Add correct type
    const renderIcon = ({ isActive }: any) => {
      return isActive ? (
        <MemoSvgChevronUp className={cx(styles.icon, iconClassName)} />
      ) : (
        <MemoSvgChevronDown className={cx(styles.icon, iconClassName)} />
      );
    };

    return (
      <Collapse
        accordion={accordian}
        defaultActiveKey={defaultActiveKey}
        className={styles.container}
        expandIcon={renderIcon}
        openMotion={motion}
      >
        {items.map(item => (
          <Panel
            key={item.id}
            header={
              <div className={cx(styles.title, titleClassName)}>
                {item.title}
              </div>
            }
            headerClass={cx(styles.headerClass, headerClassName)}
            className={itemClassName}
          >
            <div className={cx(styles.content, contentClassName)}>
              {item.content}
            </div>
          </Panel>
        ))}
      </Collapse>
    );
  }
);

export default Accordian;
