import React, { memo, ReactNode } from "react";

import ReactDOM from "react-dom";

import styles from "./SidebarBottom.module.scss";

const SIDEBAR_BOTTOM_ELEMENT = "sidebar-bottom";

/**
 * Sidebar component with element id used in the React Portal below
 */
const SidebarBottom = memo(() => {
  return <div id={SIDEBAR_BOTTOM_ELEMENT} className={styles.container} />;
});

/**
 * Wrap component around content that should go into the SidebarBottom
 */
export const SidebarBottomPortal = ({ children }: { children?: ReactNode }) => {
  const sidebarBottomElement = document.getElementById(SIDEBAR_BOTTOM_ELEMENT);

  if (!sidebarBottomElement) {
    return null;
  }

  return ReactDOM.createPortal(children, sidebarBottomElement);
};

export default SidebarBottom;
