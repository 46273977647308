import * as React from "react";

function SvgPassword(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.989 10c.043-.928.24-2.013.695-2.932C9.27 5.882 10.252 5 12 5c1.748 0 2.73.882 3.316 2.068.454.92.652 2.004.696 2.932H7.989zm-2.002 0c.044-1.165.287-2.57.904-3.818C7.746 4.452 9.348 3 12 3s4.254 1.452 5.109 3.182c.617 1.249.86 2.653.904 3.818H20v12H4V10h1.987zM6 12h12v8H6v-8z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgPassword = React.memo(SvgPassword);
export default MemoSvgPassword;
