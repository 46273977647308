/* eslint-disable @typescript-eslint/camelcase */
import React from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMount } from "react-use";

import List from "@app/components/atoms/List/List";
import { Title } from "@app/components/atoms/Typography/Typography";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import DefaultLayout from "@app/components/layouts/DefaultLayout/DefaultLayout";
import Section from "@app/components/layouts/Section/Section";
import { ClassTypesEnum } from "@app/constants/classes";
import { SortDirection } from "@app/constants/search";
import { ThemeEnum } from "@app/constants/theme.constants";
import { getAllClasses, getClassesAndAppend } from "@app/features/users/users";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";

import styles from "./ScheduleScreen.module.scss";
import ClassesSchedule from "./components/ClassesSchedule/ClassesSchedule";

const ScheduleScreen = () => {
  const { t } = useTranslation();
  const { classes, loading, classesPagination, loadingMore } = useSelector(
    (state: RootState) => state.classes
  );
  const dispatch = useAppDispatch();

  useMount(() => {
    dispatch(
      getAllClasses({
        sort_direction: SortDirection.ASC,
        sort_field: "start_at",
        excludeTypes: [ClassTypesEnum.PRIVATE_SESSION],
      })
    );
  });

  const handleLoadMore = () => {
    dispatch(
      getClassesAndAppend({
        sort_direction: SortDirection.ASC,
        sort_field: "start_at",
        page: classesPagination.current_page + 1,
        excludeTypes: [ClassTypesEnum.PRIVATE_SESSION],
      })
    );
  };

  return (
    <DefaultLayout>
      <Section theme={ThemeEnum.KHAKI}>
        <ContentWrapper>
          <Title level={1} className={styles.title}>
            {t("schedule.title")}
          </Title>
          <List
            isLoading={loading}
            pagination={classesPagination}
            onLoadMore={handleLoadMore}
            isLoadingMore={loadingMore}
          >
            <ClassesSchedule sessions={classes} />
          </List>
        </ContentWrapper>
      </Section>
    </DefaultLayout>
  );
};

export default ScheduleScreen;
