import React, { memo } from "react";

import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import Button, { ButtonTypeEnum } from "@app/components/atoms/Button/Button";
import { IMAGE_TYPES } from "@app/constants/image";
import { ClassesDef, getFirstExperience } from "@app/features/classes/classes";
import { getImageUrl } from "@app/helpers/image.helpers";
import { getFullName } from "@app/helpers/util.helpers";

export interface ProductAttributes {
  /** Snipcart name for product */
  productName: string;
  /** Snipcart description for product */
  productDescription: string;
  /** Snipcart id for product */
  productId: string;
  /** Price of product to be used with Snipcart buy button */
  price: number;
  /** String to URL location of product so Snipcart can verify product price, name, etc */
  productURL: string;
  /** Source for the image to be displayed in the cart (smaller than
   * typical full product image) */
  image: string;
}

export interface AddToCartButtonProps {
  label: string;
  product?: ClassesDef;
  /** Class names passed to buy button */
  buttonClassNames?: string;
  /** on click function passed to buy button */
  onClick?: () => void;
  fullWidth?: boolean;
  isDisabled?: boolean;
}

const SNIPCART_PRODUCTS_URL = "/snipcart/products";
const CART_DATE_FORMAT = "EEE, MMM dd, h:mm a";

const AddToCartButton = memo(
  ({
    label,
    product,
    buttonClassNames,
    fullWidth,
    isDisabled,
    onClick,
  }: AddToCartButtonProps) => {
    if (!product) return null;
    const { t } = useTranslation();

    const productAttributes = isDisabled
      ? {}
      : {
          "data-item-id": product.contentfulId,
          "data-item-price": product.price,
          "data-item-url": SNIPCART_PRODUCTS_URL,
          "data-item-name": product.name,
          "data-item-image": getImageUrl(product.image, IMAGE_TYPES.SESSION),
          "data-item-max-quantity": 1,
          "data-item-stackable": "always",
          "data-item-custom1-name": "practitioner",
          "data-item-custom1-type": "readonly",
          "data-item-custom1-value": t("sessionCard.author", {
            authorName: getFullName(product.practitioner),
          }),
          "data-item-custom2-name": "date",
          "data-item-custom2-type": "readonly",
          "data-item-custom2-value": `${format(
            new Date(product.startAt),
            CART_DATE_FORMAT
          )} - ${t("product.durationInMinutes", {
            duration: product.duration,
          })}`,
          "data-item-custom3-name": "productId",
          "data-item-custom3-type": "hidden",
          "data-item-custom3-value": product.id,
          "data-item-custom4-name": "experience",
          "data-item-custom4-type": "hidden",
          "data-item-custom4-value": getFirstExperience(product)?.name,
          "data-item-custom5-name": "type",
          "data-item-custom5-type": "hidden",
          "data-item-custom5-value": product.type,
          "data-item-custom6-name": "practitionerName",
          "data-item-custom6-type": "hidden",
          "data-item-custom6-value": getFullName(product.practitioner),
        };

    return (
      <Button
        isDisabled={isDisabled}
        fullWidth={fullWidth}
        className={buttonClassNames}
        label={label}
        buttonType={ButtonTypeEnum.PRIMARY}
        onClick={onClick}
        {...productAttributes}
      />
    );
  }
);

export default AddToCartButton;
