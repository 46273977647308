import React from "react";

import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import {
  IconChevronLeft,
  IconChevronRight,
} from "@app/components/atoms/Icon/Icon";
import { Caption } from "@app/components/atoms/Typography/Typography";
import { RootState } from "@app/redux/root-reducer";

import { setPagination } from "../../../../redux/video-settings.slice";
import styles from "./VideoPagination.module.scss";

const VideoPagination = () => {
  const { t } = useTranslation();
  const { page, perPage, totalAttendees } = useSelector(
    (state: RootState) => state.videoSettings.pagination
  );
  const dispatch = useDispatch();

  // How many attendees for current page
  const pageAttendees = page * perPage;

  const isFirstPage = page === 1;
  const isLastPage = pageAttendees >= totalAttendees;

  const currentPageStart = pageAttendees - (perPage - 1);
  const currentPageEnd = isLastPage ? totalAttendees : pageAttendees;

  const handleClickPrevPage = () => {
    dispatch(setPagination({ pagination: { page: page - 1 } }));
  };

  const handleClickNextPage = () => {
    dispatch(setPagination({ pagination: { page: page + 1 } }));
  };

  return (
    <div className={styles.container}>
      <IconChevronLeft
        className={cx(styles.icon, { [styles.disabled]: isFirstPage })}
        onClick={handleClickPrevPage}
      />
      <Caption className={styles.text}>
        {t("videoSession.pagination", {
          page: `${currentPageStart}-${currentPageEnd}`,
          totalAttendees,
        })}
      </Caption>
      <IconChevronRight
        className={cx(styles.icon, { [styles.disabled]: isLastPage })}
        onClick={handleClickNextPage}
      />
    </div>
  );
};

export default VideoPagination;
