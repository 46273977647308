import React, { memo } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMount } from "react-use";

import { ButtonTypeEnum } from "@app/components/atoms/Button/Button";
import Loading from "@app/components/atoms/Loading/Loading";
import { ThemeEnum } from "@app/constants/theme.constants";
import {
  getPractitioners,
  PRACTITIONERS_LIST,
} from "@app/features/practitioners/practitioners";
import { carouselItemsMapper } from "@app/helpers/practitioners.helpers";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";

import Experiences from "../Experiences/Experiences";

interface PractitionersCarouselProps {
  theme?: string;
  ctaLabel?: string;
  ctaLink?: string;
  subtitle?: string;
  title?: string;
  ctaType?: ButtonTypeEnum;
}

const PractitionersCarousel = memo(
  ({
    subtitle,
    title,
    theme = ThemeEnum.KHAKI,
    ctaLabel,
    ctaLink,
    ctaType,
  }: PractitionersCarouselProps) => {
    const { practitioners, loading } = useSelector(
      (state: RootState) => state.practitioners
    );
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    useMount(() => {
      dispatch(getPractitioners({ limit: 10 }));
    });

    return (
      <Loading isLoading={loading}>
        {practitioners.data?.length > 0 && (
          <Experiences
            theme={theme as ThemeEnum}
            title={title ?? t("practitioner.defaultCarouselTitle")}
            isUppercase={false}
            subtitle={subtitle ?? t("practitioner.defaultCarouselSubTitle")}
            CTALabel={ctaLabel ?? t("practitioner.defaultCtaLabel")}
            CTALink={ctaLink ?? PRACTITIONERS_LIST}
            CTAType={ctaType ?? ButtonTypeEnum.SECONDARY}
            slideItems={carouselItemsMapper(practitioners.data)}
          />
        )}
      </Loading>
    );
  }
);

export default PractitionersCarousel;
