/* eslint-disable @typescript-eslint/camelcase */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  CancelClassDef,
  ClassesDef,
  ClassesFilterDef,
} from "@app/features/classes/classes";
import { PaginationDef } from "@app/types/pagination.types";

import { classesApi } from "../api/classes.api";

interface ClassesState {
  classes: ClassesDef[];
  recommendedClasses: ClassesDef[];
  teachings: ClassesDef[];
  loading: boolean;
  loadingMore: boolean;
  cancelLoading: boolean;
  error: string | undefined | null;
  classesPagination: PaginationDef;
  teachingsPagination: PaginationDef;
}

const defaultPagination = {
  current_page: 1,
  last_page: 1,
  per_page: 15,
  total: 1,
};

const initialState: ClassesState = {
  classes: [],
  teachings: [],
  classesPagination: defaultPagination,
  teachingsPagination: defaultPagination,
  recommendedClasses: [],
  loading: false,
  loadingMore: false,
  cancelLoading: false,
  error: null,
};

export const getSeekerClasses = createAsyncThunk(
  "classes/getSeekerClasses",
  async (filter: ClassesFilterDef, { rejectWithValue }) => {
    try {
      const response = await classesApi.getSeekerClasses(filter);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSeekerClassesAndAppend = createAsyncThunk(
  "classes/getSeekerClassesAndAppend",
  async (filter: ClassesFilterDef, { rejectWithValue }) => {
    try {
      const response = await classesApi.getSeekerClasses(filter);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPractitionerClasses = createAsyncThunk(
  "classes/getPractitionerClasses",
  async (filter: ClassesFilterDef, { rejectWithValue }) => {
    try {
      const response = await classesApi.getPractitionerClasses(filter);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPractitionerClassesAndAppend = createAsyncThunk(
  "classes/getPractitionerClassesAndAppend",
  async (filter: ClassesFilterDef, { rejectWithValue }) => {
    try {
      const response = await classesApi.getPractitionerClasses(filter);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getRecommendedClasses = createAsyncThunk(
  "classes/getRecommendedClasses",
  async (filter: ClassesFilterDef, { rejectWithValue }) => {
    try {
      const response = await classesApi.getRecommendedClasses(filter);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAllClasses = createAsyncThunk(
  "classes/getAllClasses",
  async (filter: ClassesFilterDef, { rejectWithValue }) => {
    try {
      const response = await classesApi.getAllClasses(filter);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getClassesAndAppend = createAsyncThunk(
  "classes/getClassesAndAppend",
  async (filter: ClassesFilterDef, { rejectWithValue }) => {
    try {
      const response = await classesApi.getAllClasses(filter);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const cancelBooking = createAsyncThunk(
  "classes/cancelBooking",
  async (payload: CancelClassDef, { rejectWithValue }) => {
    try {
      const response = await classesApi.cancelBooking(payload.id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const classesSlice = createSlice({
  name: "classes",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getSeekerClasses.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getSeekerClasses.fulfilled, (state, action) => {
      state.loading = false;
      state.classes = action.payload.data;
      state.classesPagination = action.payload.meta;
      state.error = null;
    });
    builder.addCase(getPractitionerClasses.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getPractitionerClasses.fulfilled, (state, action) => {
      state.loading = false;
      state.teachings = action.payload.data;
      state.teachingsPagination = action.payload.meta;
      state.error = null;
    });
    builder.addCase(getRecommendedClasses.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getRecommendedClasses.fulfilled, (state, action) => {
      state.loading = false;
      state.recommendedClasses = action.payload.data;
      state.error = null;
    });
    builder.addCase(getPractitionerClassesAndAppend.pending, state => {
      state.loadingMore = true;
      state.error = null;
    });
    builder.addCase(
      getPractitionerClassesAndAppend.fulfilled,
      (state, action) => {
        state.loadingMore = false;
        state.teachings = [...state.teachings, ...action.payload.data];
        state.teachingsPagination = action.payload.meta;
        state.error = null;
      }
    );
    builder.addCase(getSeekerClassesAndAppend.pending, state => {
      state.loadingMore = true;
      state.error = null;
    });
    builder.addCase(getSeekerClassesAndAppend.fulfilled, (state, action) => {
      state.loadingMore = false;
      state.classes = [...state.classes, ...action.payload.data];
      state.classesPagination = action.payload.meta;
      state.error = null;
    });
    builder.addCase(getAllClasses.fulfilled, (state, action) => {
      state.loading = false;
      state.classes = action.payload.data;
      state.classesPagination = action.payload.meta;
      state.error = null;
    });
    builder.addCase(getClassesAndAppend.fulfilled, (state, action) => {
      state.loadingMore = false;
      state.classes = [...state.classes, ...action.payload.data];
      state.classesPagination = action.payload.meta;
      state.error = null;
    });
    builder.addCase(getAllClasses.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getClassesAndAppend.pending, state => {
      state.loadingMore = true;
      state.error = null;
    });
    builder.addCase(cancelBooking.pending, state => {
      state.cancelLoading = true;
      state.error = null;
    });
    builder.addCase(cancelBooking.fulfilled, (state, action) => {
      state.cancelLoading = false;
      const cancelledSessionIndex = state.classes.findIndex(
        session => session.classAttendeeId === action.meta.arg.classAttendeeId
      );
      state.error = null;

      if (cancelledSessionIndex !== -1) {
        state.classes[cancelledSessionIndex] = {
          ...state.classes[cancelledSessionIndex],
          cancelled: true,
        };
      }
    });
  },
});

export default classesSlice.reducer;
