import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ApiStatusCodes } from "@app/constants/api.constants";
import { clearCart } from "@app/features/ecommerce/ecommerce";
import { GtmEvents, registerEvent } from "@app/features/gtm/gtm";
import {
  PermissionEnum,
  setPermissions,
} from "@app/features/permissions/permissions";
import { isPractitioner } from "@app/helpers/user";
import { AppThunk } from "@app/redux/store";
import {
  ApiFormErrorsDef,
  CreateUserDef,
  UpdateUserDef,
  UserDef,
} from "@app/types/api.types";

import { authApi } from "../api/auth.api";
import { clearTokens, saveTokens } from "../helpers/auth.helpers";
import {
  LoginDataDef,
  RequestResetPasswordDataDef,
  ResetPasswordDataDef,
} from "../types/auth-login.types";

interface AuthState {
  user: UserDef | null;
  isAuthenticated: boolean;
  error: boolean;
  resetPasswordState: "error" | "success" | null;
}

const initialState: AuthState = {
  user: null,
  isAuthenticated: false,
  error: false,
  resetPasswordState: null,
};

export const requestResetPassword = createAsyncThunk(
  "auth/requestResetPassword",
  async (values: RequestResetPasswordDataDef, { rejectWithValue }) => {
    try {
      const response = await authApi.requestResetPassword(values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (values: ResetPasswordDataDef, { rejectWithValue }) => {
    try {
      const response = await authApi.resetPassword(values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserDef>) {
      const user = action.payload;
      // FIXME: set permissions from api
      if (user.roles && isPractitioner(user.roles)) {
        setPermissions([
          PermissionEnum.SEEKER_DASHBOARD,
          PermissionEnum.PRACTITIONERS_DASHBOARD,
        ]);
      } else {
        setPermissions([PermissionEnum.SEEKER_DASHBOARD]);
      }
      state.user = user;
      state.isAuthenticated = true;
      state.error = false;
    },
    setUserFailed(state) {
      state.error = true;
      state.user = null;
      state.isAuthenticated = false;
      clearTokens();
    },
    clearUser: () => initialState,
    resetError(state) {
      state.error = false;
      state.resetPasswordState = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(requestResetPassword.fulfilled, state => {
      state.resetPasswordState = "success";
    });
    builder.addCase(requestResetPassword.rejected, state => {
      state.resetPasswordState = "error";
    });
    builder.addCase(resetPassword.pending, state => {
      state.resetPasswordState = null;
    });
    builder.addCase(resetPassword.fulfilled, state => {
      state.resetPasswordState = "success";
    });
    builder.addCase(resetPassword.rejected, state => {
      state.resetPasswordState = "error";
    });
  },
});

export const {
  setUser,
  setUserFailed,
  clearUser,
  resetError,
} = authSlice.actions;

export default authSlice.reducer;

export const login = (values: LoginDataDef): AppThunk => async dispatch => {
  let response;
  try {
    dispatch(resetError());
    response = await authApi.login(values);
  } catch (err) {
    dispatch(setUserFailed());
    return;
  }
  if (response?.status === ApiStatusCodes.SUCCESS) {
    const { data } = response;
    saveTokens(data.token);
    dispatch(setUser(data.user));
    registerEvent(GtmEvents.LOGIN);
  } else {
    dispatch(setUserFailed());
  }
};

export const logout = (): AppThunk => async dispatch => {
  let response;
  try {
    response = await authApi.logout();
  } catch (err) {
    dispatch(setUserFailed());
    return;
  }
  if (response?.status === ApiStatusCodes.NO_CONTENT) {
    clearTokens();
    clearCart();
    dispatch(clearUser());
  }
};

export const getUser = (): AppThunk => async dispatch => {
  let response;
  try {
    response = await authApi.getUser();
  } catch (err) {
    dispatch(setUserFailed());
    return;
  }
  if (response?.status === ApiStatusCodes.SUCCESS) {
    const { data } = response;
    dispatch(setUser(data));
  }
};

export const createSeeker = (
  values: CreateUserDef,
  callback?: (errors: ApiFormErrorsDef) => void
): AppThunk => async dispatch => {
  let response;
  try {
    dispatch(resetError());
    response = await authApi.createSeeker(values);
  } catch (err) {
    dispatch(setUserFailed());
    callback?.(err.response?.data?.errors);
    return;
  }
  if (response?.status === ApiStatusCodes.CREATED) {
    const { data } = response;
    saveTokens(data.token);
    dispatch(setUser(data.user));
    registerEvent(GtmEvents.SIGN_UP);
  } else {
    dispatch(setUserFailed());
  }
};

export const updateSeeker = (
  values: UpdateUserDef
): AppThunk => async dispatch => {
  let response;
  try {
    dispatch(resetError());
    response = await authApi.updateUser(values);
  } catch (err) {
    dispatch(setUserFailed());
    return;
  }
  if (response?.status === ApiStatusCodes.SUCCESS) {
    const { data } = response;
    dispatch(setUser(data));
  } else {
    dispatch(setUserFailed());
  }
};
