import * as React from "react";

function SvgMediumCombined(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 320 320"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.579 106.686c0-58.057 47.064-105.12 105.12-105.12 57.825 0 104.745 46.69 105.117 104.426C247.919 98.02 230.897 93.5 212.819 93.5c-19.329 0-37.45 5.167-53.058 14.196-15.609-9.029-33.73-14.196-53.058-14.196-19.765 0-38.268 5.403-54.112 14.813a107.42 107.42 0 01-.012-1.627zM157.699.566c58.558 0 106.039 47.43 106.119 105.97 32.852 18.037 55.121 52.96 55.121 93.084 0 58.608-47.512 106.12-106.12 106.12-19.329 0-37.45-5.168-53.058-14.196-15.609 9.028-33.73 14.196-53.058 14.196-58.609 0-106.12-47.512-106.12-106.12 0-38.431 20.428-72.09 51.019-90.712-.016-.739-.023-1.48-.023-2.222C51.579 48.077 99.09.566 157.699.566zm55.12 93.934c18.099 0 35.129 4.574 49.999 12.629-.156 37.679-20.135 70.68-50.048 89.114-1.171-37.455-21.75-70.017-52.015-87.965C176.104 99.51 193.876 94.5 212.819 94.5zm-106.116 0c18.942 0 36.714 5.01 52.064 13.778-30.907 18.329-51.712 51.897-52.064 90.352-31.523-17.522-53.092-50.783-54.088-89.166 15.802-9.5 34.307-14.964 54.088-14.964zm53.058 14.353c-31.027 18.176-51.902 51.805-52.061 90.323 14.869 8.055 31.9 12.629 49.999 12.629 19.781 0 38.286-5.463 54.088-14.964-.975-37.539-21.628-70.18-52.026-87.988zm-108.13 1.21c1.21 38.7 23.141 72.177 55.068 89.707.054 38.806 20.936 72.73 52.068 91.192-15.35 8.767-33.122 13.778-52.064 13.778-58.056 0-105.12-47.064-105.12-105.12 0-37.863 20.018-71.051 50.049-89.557zm56.07 90.25c.248 38.414 21.1 71.937 52.06 90.074 31.145-18.246 52.062-52.064 52.062-90.767 0-.544-.005-1.086-.013-1.628-15.844 9.41-34.347 14.813-54.111 14.813-18.078 0-35.1-4.52-49.998-12.492zm105.122-.693c0 38.868-20.897 72.856-52.068 91.342 15.349 8.767 33.121 13.778 52.064 13.778 58.056 0 105.12-47.064 105.12-105.12 0-39.552-21.844-74.002-54.125-91.944-.348 38.018-20.687 71.26-51.014 89.721.015.739.023 1.48.023 2.223zM157 55a3 3 0 110-6 3 3 0 010 6zm100 172a3 3 0 106 0 3 3 0 00-6 0zm-195 3a3 3 0 110-6 3 3 0 010 6z"
        fill="currentColor"
        fillOpacity={0.35}
      />
    </svg>
  );
}

const MemoSvgMediumCombined = React.memo(SvgMediumCombined);
export default MemoSvgMediumCombined;
