import React, { memo } from "react";

import cx from "classnames";
import Select, { Option, SelectProps } from "rc-select";
import { useTranslation } from "react-i18next";

import {
  IconCheckmark,
  IconChevronDown,
} from "@app/components/atoms/Icon/Icon";
import { Subtitle } from "@app/components/atoms/Typography/Typography";
import { ThemeEnum } from "@app/constants/theme.constants";
import { getTheme } from "@app/helpers/util.helpers";

import styles from "./MultiSelect.module.scss";

export type MultiOptionDef = { value: string; label: string };

export interface MultiSelectProps extends SelectProps {
  className?: string;
  placeholder?: string;
  options: MultiOptionDef[];
}

const MultiSelect = memo(
  ({
    className,
    placeholder,
    children,
    options,
    value,
    defaultValue,
    onChange,
  }: MultiSelectProps) => {
    const { t } = useTranslation();
    return (
      <div>
        <Select
          mode="multiple"
          placeholder={
            <Subtitle level={3}>
              {placeholder ?? t("default.selectPlaceholder")}
            </Subtitle>
          }
          className={cx(styles.select, className)}
          dropdownClassName={styles.dropdown}
          maxTagCount={1}
          showSearch={false}
          dropdownMatchSelectWidth={false}
          showArrow
          inputIcon={<IconChevronDown className={styles.arrowIcon} />}
          menuItemSelectedIcon={
            <IconCheckmark className={styles.checkedIcon} />
          }
          removeIcon={null}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
        >
          {options.map(option => (
            <Option
              key={option.value}
              value={option.value}
              className={cx(styles.option, getTheme(ThemeEnum.WHITE))}
            >
              <Subtitle level={3} className={styles.optionTitle}>
                {option.label}
              </Subtitle>
            </Option>
          ))}
          {children}
        </Select>
      </div>
    );
  }
);

export default MultiSelect;
