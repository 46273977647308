import * as React from "react";

function SvgUser(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 9a3 3 0 116 0 3 3 0 01-6 0zm3-5a5 5 0 100 10 5 5 0 000-10zm0 17a9.002 9.002 0 01-8.252-5.4l-1.832.8A11.002 11.002 0 0012 23c4.512 0 8.388-2.717 10.084-6.6l-1.832-.8A9.002 9.002 0 0112 21z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgUser = React.memo(SvgUser);
export default MemoSvgUser;
