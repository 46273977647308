import * as React from "react";

function SvgShowSessionInfo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4H4v6h2V6h4V4H5zm9 0h-1v16h7V4h-6zm4 14h-3V6h3v12zM4 20h6v-2H6v-4H4v6zm6.293-11.207l-2.5 2.5-.707.707.707.707 2.5 2.5 1.414-1.414L9.914 12l1.793-1.793-1.414-1.414z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgShowSessionInfo = React.memo(SvgShowSessionInfo);
export default MemoSvgShowSessionInfo;
