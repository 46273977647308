import React, { memo, useState } from "react";

import cx from "classnames";
import { format, isToday } from "date-fns";
import _camelCase from "lodash/camelCase";
import { Col, Container, Row } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-media";
import { useSelector } from "react-redux";
import { generatePath } from "react-router-dom";
import { useMount } from "react-use";

import Button, { ButtonSizeEnum } from "@app/components/atoms/Button/Button";
import List from "@app/components/atoms/List/List";
import Loading from "@app/components/atoms/Loading/Loading";
import { Subtitle, Title } from "@app/components/atoms/Typography/Typography";
import { GLOBAL_MEDIA_QUERIES } from "@app/constants/breakpoints";
import { ClassesFilterEnum } from "@app/constants/classes";
import { DateFormats } from "@app/constants/date.constants";
import { ClassesDef } from "@app/features/classes/classes";
import { SessionPathsEnum } from "@app/features/session/session";
import {
  getPractitionerClasses,
  getPractitionerClassesAndAppend,
} from "@app/features/users/users";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";

import styles from "./PractitionerSessions.module.scss";
import AttendeesModal from "./components/AttendeesModal/AttendeesModal";

interface PractitionerSessionsProps {
  title: string;
  todayOnly?: boolean;
  showPagination?: boolean;
  filter?: ClassesFilterEnum;
  className?: string;
  showAttendeesModal?: boolean;
}

const PractitionerSessions = memo(
  ({
    title,
    todayOnly = false,
    showPagination = false,
    filter = ClassesFilterEnum.UPCOMING,
    className,
    showAttendeesModal,
  }: PractitionerSessionsProps) => {
    const { t } = useTranslation();
    const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
    const {
      teachings,
      teachingsPagination,
      loading,
      loadingMore,
    } = useSelector((state: RootState) => state.classes);
    const [attendeesModalVisible, setAttendeesModalVisible] = useState(false);
    const [activeSession, setActiveSession] = useState<ClassesDef | null>(null);

    const dispatch = useAppDispatch();
    const showJoinSession = filter === ClassesFilterEnum.UPCOMING;

    useMount(() => {
      dispatch(getPractitionerClasses({ filter }));
    });

    const getSessionLink = (session: ClassesDef) =>
      session.zoomLink ??
      generatePath(SessionPathsEnum.SESSION, {
        id: session.id,
      });

    const handleLoadMoreTeachings = () => {
      dispatch(
        getPractitionerClassesAndAppend({
          filter,
          page: teachingsPagination.current_page + 1,
        })
      );
    };

    const getSessions = () => {
      return todayOnly
        ? teachings?.filter(session => isToday(new Date(session.startAt)))
        : teachings;
    };

    const handleAttendeesClick = (session: ClassesDef) => {
      if (showAttendeesModal) {
        setAttendeesModalVisible(true);
        setActiveSession(session);
      }
    };

    const handleCloseModal = () => {
      setAttendeesModalVisible(false);
      setActiveSession(null);
    };

    const Attendees = ({ session }: { session: ClassesDef }) => (
      <Subtitle
        level={3}
        className={cx({
          [styles.cursor]: showAttendeesModal,
        })}
        onClick={() => handleAttendeesClick(session)}
      >
        {session.attendeesNo}
        {!matches.tabletLandscape && ` ${t("todaySessions.participantsTitle")}`}
      </Subtitle>
    );

    const getFormattedDate = (session: ClassesDef) => {
      return format(
        new Date(session.startAt),
        filter === ClassesFilterEnum.UPCOMING
          ? DateFormats.MEDIUM
          : DateFormats.LONG_WITH_YEAR
      );
    };

    return (
      <>
        <Loading isLoading={loading}>
          {!!getSessions().length && (
            <List
              isLoadingMore={loadingMore}
              pagination={teachingsPagination}
              onLoadMore={handleLoadMoreTeachings}
              showPagination={showPagination}
            >
              <div className={className}>
                {!!title && (
                  <Title level={1} className={styles.title}>
                    {title}
                  </Title>
                )}
                {matches.tabletLandscape ? (
                  <Container className={styles.sessions} fluid>
                    <Row className={styles.row}>
                      <Col
                        md={showJoinSession ? 4 : 5}
                        className={styles.sessionName}
                      >
                        <Title level={3}>
                          {t("todaySessions.sessionTitle")}
                        </Title>
                      </Col>
                      <Col md={3}>
                        <Title level={3}>{t("todaySessions.dateTitle")}</Title>
                      </Col>
                      <Col md={1}>
                        <Title level={3}>{t("todaySessions.timeTitle")}</Title>
                      </Col>
                      <Col md={2}>
                        <Title level={3}>
                          {t("todaySessions.participantsTitle")}
                        </Title>
                      </Col>
                      {showJoinSession && <Col md={2} />}
                    </Row>
                    {getSessions().map(session => (
                      <Row key={session.id} className={styles.row}>
                        <Col md={1.5}>
                          <Title level={3}>
                            {t(`classType.${_camelCase(session.type)}`)}
                          </Title>
                        </Col>
                        <Col md={showJoinSession ? 2.5 : 3.5}>
                          <Subtitle level={3}>{session.name}</Subtitle>
                        </Col>
                        <Col md={3}>
                          <Subtitle level={3}>
                            {getFormattedDate(session)}
                          </Subtitle>
                        </Col>
                        <Col md={1}>
                          <Subtitle level={3}>
                            {t("product.durationInMinutes", {
                              duration: session.duration,
                            })}
                          </Subtitle>
                        </Col>
                        <Col md={2}>
                          <Attendees session={session} />
                        </Col>
                        {showJoinSession && (
                          <Col md={2} className={styles.action}>
                            <Button
                              to={getSessionLink(session)}
                              label={t("todaySessions.startNow")}
                              size={ButtonSizeEnum.MEDIUM}
                            />
                          </Col>
                        )}
                      </Row>
                    ))}
                  </Container>
                ) : (
                  <div>
                    {getSessions().map(session => (
                      <div
                        className={styles.sessionCardMobile}
                        key={session.id}
                      >
                        <Row>
                          <Col>
                            <Title level={3}>
                              {t(`classType.${_camelCase(session.type)}`)}
                            </Title>
                          </Col>
                          <Col className={styles.col2}>
                            <Title level={3} className={styles.date}>
                              {getFormattedDate(session)}
                            </Title>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Subtitle level={3}>{session.name}</Subtitle>
                          </Col>
                          <Col className={styles.col2} />
                        </Row>
                        <Row>
                          <Col>
                            <Subtitle level={3}>
                              {t("product.durationInMinutes", {
                                duration: session.duration,
                              })}
                            </Subtitle>
                          </Col>
                          <Col className={styles.col2}>
                            <Attendees session={session} />
                          </Col>
                        </Row>
                        {showJoinSession && (
                          <Button
                            className={styles.button}
                            to={getSessionLink(session)}
                            label={t("todaySessions.startNow")}
                            size={ButtonSizeEnum.MEDIUM}
                            fullWidth
                          />
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </List>
          )}
        </Loading>
        <AttendeesModal
          sessionId={activeSession?.id}
          visible={attendeesModalVisible}
          onClose={handleCloseModal}
        />
      </>
    );
  }
);

export default PractitionerSessions;
