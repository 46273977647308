import { AxiosResponse } from "axios";

import { api } from "@app/api/api";
import { CreateUserDef, UpdateUserDef, UserDef } from "@app/types/api.types";

import { AuthEndpointsEnum } from "../constants/auth.endpoints";
import {
  LoginDataDef,
  RequestResetPasswordDataDef,
  ResetPasswordDataDef,
} from "../types/auth-login.types";

const login = (data: LoginDataDef): Promise<AxiosResponse> => {
  return api.post(AuthEndpointsEnum.LOGIN, data);
};

const logout = (): Promise<AxiosResponse> => {
  return api.post(AuthEndpointsEnum.LOGOUT);
};

const createSeeker = (data: CreateUserDef): Promise<AxiosResponse> => {
  return api.post(AuthEndpointsEnum.REGISTER_SEEKER, data);
};

const updateUser = (data: UpdateUserDef): Promise<AxiosResponse> => {
  return api.patch(AuthEndpointsEnum.PROFILE, data);
};

const getUser = (): Promise<AxiosResponse> => {
  return api.get(AuthEndpointsEnum.PROFILE);
};

const requestResetPassword = (
  data: RequestResetPasswordDataDef
): Promise<AxiosResponse> => {
  return api.post(AuthEndpointsEnum.REQUEST_RESET_PASSWORD, data);
};

const resetPassword = (data: ResetPasswordDataDef): Promise<AxiosResponse> => {
  return api.patch(AuthEndpointsEnum.RESET_PASSWORD, data);
};

const refreshAccessToken = (): Promise<AxiosResponse<UserDef>> => {
  return api.post(AuthEndpointsEnum.REFRESH_TOKEN);
};

export const authApi = {
  login,
  logout,
  createSeeker,
  updateUser,
  getUser,
  requestResetPassword,
  resetPassword,
  refreshAccessToken,
};
