import * as React from "react";

function SvgAudioMuted(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2a4 4 0 00-4 4v.186L4.607 2.793 3.193 4.207l.014-.014 16 16 1.4-1.4-3.044-3.044A7.977 7.977 0 0020 10h-2c0 1.704-.71 3.242-1.851 4.335l-1.415-1.415A3.99 3.99 0 0016 10V6a4 4 0 00-4-4zm1.947 15.761l-1.764-1.764A6 6 0 016 10H4a8.001 8.001 0 007 7.938V20H8v2h8v-2h-3v-2.062c.322-.04.638-.1.947-.177zM10 8.186l3.318 3.318C13.736 11.137 14 10.6 14 10V6a2 2 0 10-4 0v2.186z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgAudioMuted = React.memo(SvgAudioMuted);
export default MemoSvgAudioMuted;
