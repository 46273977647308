import React, { useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import CircularButton from "@app/components/atoms/CircularButton/CircularButton";
import { IconSendMessage } from "@app/components/atoms/Icon/Icon";
import { Subtitle } from "@app/components/atoms/Typography/Typography";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";

import { getClassSessions } from "../../../../helpers/video.helper";
import { createComment } from "../../../../redux/video.slice";
import { SidebarBottomPortal } from "../SidebarBottom/SidebarBottom";
import styles from "./SidebarChatTab.module.scss";
import ChatList from "./components/ChatList/ChatList";

interface SidebarChatTabProps {
  isModerator?: boolean;
}

const SidebarChatTab = ({ isModerator }: SidebarChatTabProps) => {
  const classType = useSelector(
    (state: RootState) => state.sessionClass.classType
  );
  const { privateChatToUser, createMessageLoading } = useSelector(
    (state: RootState) => state.video
  );
  const { disableChat } = useSelector((state: RootState) => state.video);
  const [value, setValue] = useState("");
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { mainSession } = useMemo(() => getClassSessions(classType), [
    classType,
  ]);

  const handleSendMessage = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const newMessage = value.trim();
    if (newMessage && mainSession) {
      const createCommentResponse = await dispatch(
        createComment({
          sessionId: mainSession.id,
          comment: {
            message: newMessage,
            ...(privateChatToUser && { toUserId: privateChatToUser.id }),
          },
        })
      );
      if (createComment.fulfilled.match(createCommentResponse)) {
        setValue("");
      }
    }
  };

  return (
    <>
      <ChatList isModerator={isModerator} />
      <SidebarBottomPortal>
        {!disableChat ? (
          <form onSubmit={handleSendMessage}>
            <div className={styles.controls}>
              <input
                value={value}
                onChange={event => setValue(event.target.value)}
                className={styles.input}
                placeholder={t(
                  "videoSession.sidebarChatInputPlaceholderEveryone"
                )}
              />
              <CircularButton
                className={styles.actionButton}
                type="submit"
                borderless
                isLoading={createMessageLoading}
              >
                <IconSendMessage />
              </CircularButton>
            </div>
          </form>
        ) : (
          <Subtitle level={3} className={styles.disabledText}>
            {t("videoSession.chatDisabledText")}
          </Subtitle>
        )}
      </SidebarBottomPortal>
    </>
  );
};

export default SidebarChatTab;
