import React, { memo } from "react";

import { Col, Row } from "react-grid-system";

import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import ExperienceItem from "@app/components/renderings/Experiences/components/ExperienceItem/ExperienceItem";
import { PractitionerDef } from "@app/features/practitioners/practitioners";
import {
  getExperienceTags,
  getPractitionerLink,
} from "@app/helpers/practitioners.helpers";
import { getFullName } from "@app/helpers/util.helpers";

import styles from "./PractitionersList.module.scss";

export interface PractitionersListProps {
  items: PractitionerDef[];
}

const PractitionersList = memo(({ items }: PractitionersListProps) => {
  return (
    <ContentWrapper className={styles.container}>
      <div className={styles.itemsContainer}>
        <Row gutterWidth={0}>
          {items.map(practitioner => (
            <Col xs={6} lg={4} key={practitioner.id}>
              <ExperienceItem
                image={practitioner.image}
                title={getFullName(practitioner)}
                description={practitioner.carouselDescription}
                link={getPractitionerLink(practitioner.id, practitioner.slug)}
                key={practitioner.id}
                className={styles.item}
                id={practitioner.id}
                tags={getExperienceTags(practitioner.experiences)}
              />
            </Col>
          ))}
        </Row>
      </div>
    </ContentWrapper>
  );
});

export default PractitionersList;
