import React, { memo } from "react";

import cx from "classnames";
import { Col, Row } from "react-grid-system";

import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import { ExperienceDef } from "@app/features/experiences/experiences";
import { getExperienceLink } from "@app/helpers/experiences.helpers";

import ExperienceItem from "../Experiences/components/ExperienceItem/ExperienceItem";
import styles from "./ExperienceList.module.scss";

export interface ExperienceListProps {
  items: ExperienceDef[];
}

const ExperienceList = memo(({ items }: ExperienceListProps) => {
  return (
    <ContentWrapper className={cx(styles.container)}>
      <Row gutterWidth={0} className={styles.itemsContainer}>
        {items.map(experience => (
          <Col xs={6} lg={4} key={experience.id}>
            <ExperienceItem
              image={experience.image}
              title={experience.name}
              description={experience.carouselDescription}
              link={getExperienceLink(experience.id, experience.slug)}
              key={experience.id}
              className={styles.item}
              id={experience.id}
              isListPage
            />
          </Col>
        ))}
      </Row>
    </ContentWrapper>
  );
});

export default ExperienceList;
