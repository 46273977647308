import * as React from "react";

function SvgError(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12 3v12M12 17v4" stroke="currentColor" strokeWidth={2} />
    </svg>
  );
}

const MemoSvgError = React.memo(SvgError);
export default MemoSvgError;
