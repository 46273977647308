import { RouteItemDef } from "@app/types/route.types";

import { PreviewPathEnum } from "../constants/preview.path";
import PreviewScreen from "../screens/PreviewScreen/PreviewScreen";

const PREVIEW_SCREEN: RouteItemDef = {
  id: "preview",
  path: PreviewPathEnum.PREVIEW,
  component: PreviewScreen,
};

export const PREVIEW_ROUTES = [PREVIEW_SCREEN];
