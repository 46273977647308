import React, { useMemo } from "react";

import cx from "classnames";
import { MenuInfo } from "rc-menu/lib/interface";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Avatar, { AvatarSize } from "@app/components/atoms/Avatar/Avatar";
import CircularButton from "@app/components/atoms/CircularButton/CircularButton";
import Dropdown from "@app/components/atoms/Dropdown/Dropdown";
import {
  IconArrowLeft,
  IconAudio,
  IconAudioMuted,
  IconChat,
  IconChatOff,
  IconOptions,
  IconVideo,
  IconVideoOff,
} from "@app/components/atoms/Icon/Icon";
import { Subtitle, Title } from "@app/components/atoms/Typography/Typography";
import { ThemeEnum } from "@app/constants/theme.constants";
import {
  getClassSessions,
  resetPrivateChatToUser,
  setPrivateChatToUser,
  toggleChatBySessionId,
} from "@app/features/video/video";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";
import { BasicUserDef } from "@app/types/api.types";

import { VideoSessionUserDef } from "../../../../types/session.types";
import { SidebarBottomPortal } from "../SidebarBottom/SidebarBottom";
import SidebarChatTab from "../SidebarChatTab/SidebarChatTab";
import styles from "./SidebarAttendeesListTab.module.scss";

interface SidebarAttendeesListTabProps {
  attendees: VideoSessionUserDef[];
}

enum OptionItems {
  CHAT = "chat",
}

const SidebarAttendeesListTab = ({
  attendees = [],
}: SidebarAttendeesListTabProps) => {
  const { t } = useTranslation();
  const classType = useSelector(
    (state: RootState) => state.sessionClass.classType
  );
  const {
    disableChat,
    loading,
    attendeesAvatarColors,
    privateChatToUser,
  } = useSelector((state: RootState) => state.video);
  const dispatch = useAppDispatch();
  const { mainSession } = useMemo(() => getClassSessions(classType), [
    classType,
  ]);

  const handleChatToggle = () => {
    mainSession &&
      dispatch(
        toggleChatBySessionId({
          sessionId: mainSession.id,
          disableChat: !disableChat,
        })
      );
  };

  const menu = useMemo(
    () => [{ id: OptionItems.CHAT, value: t("videoSession.chatActionTitle") }],
    [t]
  );

  const handleOptionClick = (event: MenuInfo, user?: BasicUserDef) => {
    if (event.key === OptionItems.CHAT) {
      if (user) {
        dispatch(setPrivateChatToUser({ user }));
      }
    }
  };

  const handleResetChat = () => {
    dispatch(resetPrivateChatToUser());
  };

  // TODO: Add chat notification for private chat
  return (
    <>
      <div className={styles.content}>
        {privateChatToUser ? (
          <>
            <div className={styles.privateChatHeader}>
              <button
                onClick={handleResetChat}
                className={styles.chatBackButton}
              >
                <IconArrowLeft className={styles.chatBackIcon} />
              </button>
              <Subtitle level={3}>
                {t("videoSession.chatTitlePrivatePractitioner")}
              </Subtitle>
            </div>
            <SidebarChatTab isModerator />
          </>
        ) : (
          attendees.map(attendee => (
            <div
              className={styles.userContainer}
              key={attendee.stream.streamId}
            >
              <div className={styles.user}>
                <Avatar
                  avatarColor={
                    attendee.user && attendeesAvatarColors[attendee.user?.id]
                  }
                  avatarImage={attendee.user?.image}
                  className={styles.avatar}
                  avatarSize={AvatarSize.SMALL}
                />
                <Subtitle level={3}>
                  {attendee.user?.pseudonym ?? attendee.stream.name}
                </Subtitle>
              </div>
              <div className={styles.userControls}>
                <button
                  className={cx(styles.userControlButton, {
                    // TODO: Add back when we will support muting audio/video for all participants
                    [styles.buttonDisabled]: true, // !attendee.stream.hasAudio,
                  })}
                >
                  {attendee.stream.hasAudio ? (
                    <IconAudio />
                  ) : (
                    <IconAudioMuted />
                  )}
                </button>
                <button
                  className={cx(styles.userControlButton, {
                    // TODO: Add back when we will support muting audio/video for all participants
                    [styles.buttonDisabled]: true, //! attendee.stream.hasVideo,
                  })}
                >
                  {attendee.stream.hasVideo ? <IconVideo /> : <IconVideoOff />}
                </button>
                {!disableChat && (
                  <Dropdown
                    items={menu}
                    placement="bottomRight"
                    onClickMenuItem={event =>
                      handleOptionClick(event, attendee.user)
                    }
                    theme={ThemeEnum.WHITE}
                    overlayClassName={styles.dropdown}
                  >
                    <IconOptions className={styles.userControlButton} />
                  </Dropdown>
                )}
              </div>
            </div>
          ))
        )}
      </div>

      {!privateChatToUser && (
        <SidebarBottomPortal>
          <div className={styles.controls}>
            <Title level={3} className={styles.controlsTitle}>
              {t("videoSession.sidebarAttendeesControlsTitle")}
            </Title>
            {/* <CircularButton
            onClick={handleToggleMuteAll}
            className={cx(styles.actionButton, {
              [styles.isOff]: !audioEnabled,
            })}
          >
            {audioEnabled ? <IconAudio /> : <IconAudioMuted />}
          </CircularButton>
          <CircularButton
            onClick={handleToggleVideoAll}
            className={cx(styles.actionButton, {
              [styles.isOff]: !videoEnabled,
            })}
          >
            {videoEnabled ? <IconVideo /> : <IconVideoOff />}
          </CircularButton> */}
            <CircularButton
              onClick={handleChatToggle}
              className={cx(styles.actionButton, {
                [styles.isOff]: disableChat && !loading,
              })}
              isLoading={loading}
            >
              {disableChat ? <IconChatOff /> : <IconChat />}
            </CircularButton>
          </div>
        </SidebarBottomPortal>
      )}
    </>
  );
};

export default SidebarAttendeesListTab;
