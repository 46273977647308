import * as React from "react";

function SvgMediumRipple(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 320 320"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={160}
        cy={160}
        r={71}
        stroke="currentColor"
        strokeOpacity={0.35}
        strokeMiterlimit={10}
      />
      <circle
        opacity={0.5}
        cx={160}
        cy={160}
        r={109}
        stroke="currentColor"
        strokeOpacity={0.35}
        strokeMiterlimit={10}
      />
      <circle
        opacity={0.2}
        cx={160}
        cy={160}
        r={141}
        stroke="currentColor"
        strokeOpacity={0.35}
        strokeMiterlimit={10}
      />
      <circle
        opacity={0.1}
        cx={160}
        cy={160}
        r={156}
        stroke="currentColor"
        strokeOpacity={0.35}
        strokeMiterlimit={10}
      />
    </svg>
  );
}

const MemoSvgMediumRipple = React.memo(SvgMediumRipple);
export default MemoSvgMediumRipple;
