import React, { memo } from "react";

import cx from "classnames";
import { useMedia } from "react-media";
import { useLocation } from "react-router-dom";

import { Subtitle, Title } from "@app/components/atoms/Typography/Typography";
import { GLOBAL_MEDIA_QUERIES } from "@app/constants/breakpoints";
import { NavigationItemType } from "@app/types/navigation.types";

import styles from "./NavigationItems.module.scss";
import NavigationItem from "./components/NavigationItem/NavigationItem";
import stylesi from "./components/NavigationItem/NavigationItem.module.scss";

export interface NavigationItemsProps {
  navItems: NavigationItemType[];
}

const NavigationItems = memo(({ navItems }: NavigationItemsProps) => {
  const location = useLocation();
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

  const pathMatches = (link: string, activeFor?: string[]) => {
    return (
      link === location.pathname ||
      activeFor?.some(otherLink => location.pathname.includes(otherLink))
    );
  };

  const isActiveMenuItem = (menuItem: NavigationItemType) => {
    return (
      pathMatches(menuItem.path, menuItem.activeFor) ??
      menuItem.subMenu?.some(item => pathMatches(item.path, item.activeFor))
    );
  };

  return (
    <nav className={styles.navWrapper}>
      <ul className={styles.navLinks}>
        {navItems.length > 0 &&
          navItems.map(item => (
            <NavigationItem
              id={item.id}
              key={item.id}
              label={item.label}
              path={item.path}
              subMenu={item.subMenu}
              isActive={isActiveMenuItem(item)}
            />
          ))}
        <li className={cx(stylesi.navItem)}>
          <a
            className={cx(stylesi.navItemLink)}
            href="https://sessions.kaylolife.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            {matches.tabletLandscape ? (
              <Title level={3} className={stylesi.linkText} isMobile>
                Private Sessions
              </Title>
            ) : (
              <Subtitle level={3} className={stylesi.linkText}>
                Privates
              </Subtitle>
            )}
          </a>
        </li>
      </ul>
    </nav>
  );
});

export default NavigationItems;
