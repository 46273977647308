import * as React from "react";

function SvgArrowLeftAlt(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 42 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.50888 10L13.2396 1.72583L11.8638 0.274172L1.31208 10.2742L0.546211 11L1.31208 11.7258L11.8638 21.7258L13.2396 20.2742L4.50888 12H42V10H4.50888Z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgArrowLeftAlt = React.memo(SvgArrowLeftAlt);
export default MemoSvgArrowLeftAlt;
