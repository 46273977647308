import React, { memo } from "react";

import cx from "classnames";
import ReactDOM from "react-dom";

import CircularButton from "@app/components/atoms/CircularButton/CircularButton";
import {
  IconAudio,
  IconAudioMuted,
  IconVideo,
  IconVideoOff,
} from "@app/components/atoms/Icon/Icon";

import { bottomToolbarLeftElement } from "../../../../layouts/VideoLayout/VideoLayout";
import styles from "./PublisherActions.module.scss";

interface PublisherActionsProps {
  audioEnabled?: boolean;
  videoEnabled?: boolean;
  handleToggleAudio: () => void;
  handleToggleVideo: () => void;
}

const PublisherActions = memo(
  ({
    audioEnabled,
    videoEnabled,
    handleToggleAudio,
    handleToggleVideo,
  }: PublisherActionsProps) => {
    const bottomToolbarLeft = document.getElementById(bottomToolbarLeftElement);

    if (!bottomToolbarLeft) {
      throw Error("missing bottomToolbarLeft");
    }

    return ReactDOM.createPortal(
      <>
        <CircularButton
          isFloating
          onClick={handleToggleAudio}
          className={cx(styles.actionButton, {
            [styles.isOff]: !audioEnabled,
          })}
        >
          {audioEnabled ? <IconAudio /> : <IconAudioMuted />}
        </CircularButton>
        <CircularButton
          isFloating
          onClick={handleToggleVideo}
          className={cx(styles.actionButton, {
            [styles.isOff]: !videoEnabled,
          })}
        >
          {videoEnabled ? <IconVideo /> : <IconVideoOff />}
        </CircularButton>
      </>,
      bottomToolbarLeft
    );
  }
);

export default PublisherActions;
