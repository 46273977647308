/* eslint-disable @typescript-eslint/camelcase */
import { useCallback, useEffect } from "react";

import { useSelector } from "react-redux";
import { generatePath, useHistory, useLocation } from "react-router-dom";

import { AuthPathsEnum } from "@app/features/auth/auth";
import {
  getGtmEcommerceItems,
  GtmEvents,
  registerEvent,
} from "@app/features/gtm/gtm";
import {
  getTranslation,
  i18next,
} from "@app/features/localization/localization";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";
import { WindowDef } from "@app/types/external.types";

import {
  EcommercePathsEnum,
  SnipcartPathsEnum,
} from "../../constants/ecommerce.path";
import { SnipcartEvents } from "../../constants/ecommerce.snipcart";
import { setSnipcartError } from "../../ecommerce";
import { CartStateDef } from "../../types/snipcart.types";
import {
  closeCart,
  gtmItemsMapper,
  openCart,
  registerSnipcartGtm,
  setSnipcartUser,
} from "../helpers/snipcart.helpers";
import { setLocalization } from "../helpers/snipcart.local";

function useSnipcartWrapper() {
  const history = useHistory();
  const location = useLocation();
  const user = useSelector((state: RootState) => state.auth.user);
  const windowObj: WindowDef = window;
  const translations = getTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (location.hash === `#${SnipcartPathsEnum.CART}`) {
      setTimeout(() => {
        openCart();
      }, 0);
    }
  }, [location.hash]);

  // Get items with productId
  const getCartItemsWithProductId = useCallback((cart?: CartStateDef) => {
    const items = cart?.items?.items?.filter(item =>
      item.customFields?.some(field => field.name === "productId")
    );

    return items;
  }, []);

  const onRouteChange = useCallback(
    (routesChange: { from: string; to: string }) => {
      if (routesChange.to === SnipcartPathsEnum.CHECKOUT && !user) {
        history.push(AuthPathsEnum.SIGN_IN, {
          prevPath: `${location.pathname}#${SnipcartPathsEnum.CART}`,
        });
        setTimeout(() => {
          closeCart();
        }, 0);
      }

      if (
        routesChange.from === SnipcartPathsEnum.CART &&
        routesChange.to === SnipcartPathsEnum.CHECKOUT &&
        user
      ) {
        const items = getCartItemsWithProductId(
          windowObj?.Snipcart?.store.getState().cart
        );
        if (items?.length) {
          registerEvent(
            GtmEvents.BEGIN_CHECKOUT,
            getGtmEcommerceItems(gtmItemsMapper(items))
          );
        }
      }
    },
    [getCartItemsWithProductId, history, location.pathname, user, windowObj]
  );

  useEffect(() => {
    const routeChangeUnsubscribe = windowObj?.Snipcart?.events.on(
      SnipcartEvents.ROUTE_CHANGED,
      onRouteChange
    );
    const cartConfirmedUnsubscribe = windowObj?.Snipcart?.events.on(
      SnipcartEvents.CART_CONFIRMED,
      (cartConfirmResponse: CartStateDef) => {
        const items = getCartItemsWithProductId(cartConfirmResponse);
        if (items?.length) {
          registerSnipcartGtm({
            orderId: cartConfirmResponse.invoiceNumber,
            items,
            total: cartConfirmResponse.total,
            currency: cartConfirmResponse.currency,
            coupon: cartConfirmResponse.discounts?.items?.[0]?.code,
          });
          windowObj?.fbq("track", "CompleteRegistration", {
            currency: cartConfirmResponse.currency,
            value: cartConfirmResponse.total,
          });
        }
        window.location.href = generatePath(EcommercePathsEnum.CONFIRMATION, {
          orderNumber: cartConfirmResponse.invoiceNumber,
        });
      }
    );

    const cartConfirmErrorUnSubscribe = windowObj?.Snipcart?.events.on(
      SnipcartEvents.CART_CONFIRM_ERROR,
      () => {
        dispatch(setSnipcartError());
      }
    );

    return () => {
      routeChangeUnsubscribe?.();
      cartConfirmedUnsubscribe?.();
      cartConfirmErrorUnSubscribe?.();
    };
  }, [dispatch, getCartItemsWithProductId, history, onRouteChange, windowObj]);

  useEffect(() => {
    if (user) {
      setSnipcartUser(user);
    }
  }, [user]);

  useEffect(() => {
    if (translations && i18next.isInitialized) {
      setLocalization();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translations, i18next.isInitialized]);

  return null;
}

export default useSnipcartWrapper;
