import React, { ReactNode, useRef } from "react";

import { use100vh } from "react-div-100vh";

import Button, { ButtonSizeEnum } from "@app/components/atoms/Button/Button";
import { Title } from "@app/components/atoms/Typography/Typography";

import styles from "./ModalContent.module.scss";

interface ModalContentProps {
  title: string;
  clearButtonLabel: string;
  onClear: () => void;
  children?: ReactNode;
}

const HEADER_OFFSET_PADDING = 16;
const MODAL_BUTTON_HEIGHT = 40;

const ModalContent = ({
  title,
  clearButtonLabel,
  onClear,
  children,
}: ModalContentProps) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const height = use100vh();
  const headerHeight = headerRef?.current?.clientHeight ?? 24;

  const contentHeight = height
    ? height - (headerHeight + HEADER_OFFSET_PADDING + MODAL_BUTTON_HEIGHT)
    : "100%";

  return (
    <div>
      <div className={styles.header} ref={headerRef}>
        <Title level={3}>{title}</Title>
        <Button
          size={ButtonSizeEnum.TEXT}
          label={clearButtonLabel}
          className={styles.clearButton}
          onClick={onClear}
        />
      </div>
      <div className={styles.content} style={{ height: contentHeight }}>
        {children}
      </div>
    </div>
  );
};

export default ModalContent;
