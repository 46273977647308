import React, { memo, useState } from "react";

import { Formik, Form } from "formik";
import qs from "query-string";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, RouteComponentProps } from "react-router-dom";
import * as yup from "yup";

import FormItem from "@app/components/atoms/FormItem/FormItem";
import FormMessage, {
  FormMessageType,
} from "@app/components/atoms/FormMessage/FormMessage";
import { ConfirmationModal } from "@app/components/atoms/Modal/Modal";
import { Title } from "@app/components/atoms/Typography/Typography";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";

import AuthFormButton from "../../components/AuthFormButton/AuthFormButton";
import { AuthPathsEnum } from "../../constants/auth.paths";
import { passwordRegex } from "../../constants/auth.regex";
import AuthBoxLayout from "../../layouts/AuthBoxLayout/AuthBoxLayout";
import { resetPassword } from "../../redux/auth.slice";
import { ResetPasswordDataDef } from "../../types/auth-login.types";

const validationScheme = yup.object({
  password: yup
    .string()
    .required("authInputs.passwordErrorRequired")
    .matches(passwordRegex, "authInputs.passwordErrorMatchRequirements"),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password")], "authInputs.passwordConfirmErrorMatchSame")
    .required("authInputs.passwordConfirmErrorRequired"),
});

const ResetPasswordScreen = memo(({ location }: RouteComponentProps) => {
  const { resetPasswordState } = useSelector((state: RootState) => state.auth);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  let invalidToken = false;
  const { token } = qs.parse(location.search);
  // check if token is missing or invalid
  invalidToken = !token || typeof token !== "string";

  const initialValues: ResetPasswordDataDef = {
    token: token as string,
    password: "",
    passwordConfirmation: "",
  };

  const handleSubmit = async (values: ResetPasswordDataDef) => {
    const response = await dispatch(resetPassword(values));
    if (resetPassword.fulfilled.match(response)) {
      setSuccessModalVisible(true);
    }
  };

  const handleModalconfirm = () => {
    setSuccessModalVisible(false);
    history.push(AuthPathsEnum.SIGN_IN);
  };

  return (
    <AuthBoxLayout header={<Title>{t("resetPassword.title")}</Title>}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationScheme}
      >
        {({ isSubmitting }) => (
          <Form noValidate>
            {resetPasswordState === "error" && (
              <FormMessage
                type={FormMessageType.ERROR}
                message={t("resetPassword.errorMessage")}
              />
            )}
            {invalidToken && (
              <FormMessage
                type={FormMessageType.ERROR}
                message={t("resetPassword.invalidTokenMessage")}
              />
            )}
            {!invalidToken && (
              <>
                <FormItem
                  id="password"
                  name="password"
                  label={t("authInputs.passwordNewLabel")}
                  type="password"
                  placeholder={t("authInputs.passwordNewPlaceholder")}
                  autoComplete="off"
                  caption={t("authInputs.passwordHelpCaption")}
                />
                <FormItem
                  id="password_confirmation"
                  name="passwordConfirmation"
                  label={t("authInputs.passwordConfirmLabel")}
                  type="password"
                  placeholder={t("authInputs.passwordConfirmPlaceholder")}
                  autoComplete="off"
                />
                <AuthFormButton
                  label={t("resetPassword.submitButton")}
                  isSubmitting={isSubmitting}
                />
              </>
            )}
          </Form>
        )}
      </Formik>
      <ConfirmationModal
        title={t("resetPassword.successTitle")}
        subtitle={t("resetPassword.successMessage")}
        visible={successModalVisible}
        maskClosable={false}
        hideCancel
        onClose={() => setSuccessModalVisible(false)}
        onConfirm={handleModalconfirm}
        headerCenter
      />
    </AuthBoxLayout>
  );
});

export default ResetPasswordScreen;
