import { RouteItemDef } from "@app/types/route.types";

import { UserPathsEnum } from "../constants/users.paths";
import MyBookingsScreen from "../screens/MyBookingsScreen/MyBookingsScreen";

const MY_BOOKINGS_SCREEN: RouteItemDef = {
  id: "my-bookings",
  path: UserPathsEnum.MY_BOOKINGS,
  component: MyBookingsScreen,
  metaTitle: "myBookings.metaTitle",
};

const USER_ROUTES = [MY_BOOKINGS_SCREEN];

export default USER_ROUTES;
