import React, { memo, useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useUnmount } from "react-use";

import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import { SessionRoleEnum } from "@app/constants/video-session.constants";
import { getClassSessions, VideoSession } from "@app/features/video/video";
import { getFullName } from "@app/helpers/util.helpers";
import useMetaTitle from "@app/hooks/useMetaTitle";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";
import { AppPathEnums } from "@app/routes/routes.constants";

import {
  SeekerFeedbackModal,
  PractitionerFeedbackModal,
} from "../../components/FeedbackModal/FeedbackModal";
import SessionLayout from "../../layouts/SessionLayout/SessionLayout";
import {
  getClass,
  setShowFeedbackModal,
  clearSessionClass,
} from "../../redux/session-class.slice";
import {
  createToken,
  clearSessionToken,
} from "../../redux/session-token.slice";
import SessionErrorModal from "./components/SessionErrorModal/SessionErrorModal";

const SessionScreen = memo(({ match }: RouteComponentProps<{ id: string }>) => {
  const {
    classType,
    error: classTypeError,
    loading: loadingClassType,
    showFeedbackModal,
  } = useSelector((state: RootState) => state.sessionClass);
  const {
    token,
    error: sessionTokenError,
    loading: loadingToken,
    role,
  } = useSelector((state: RootState) => state.sessionToken);

  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const classTypeId = match.params.id;
  const hasZoomLink = !!classType?.zoomLink;

  const { mainSession } = useMemo(() => getClassSessions(classType), [
    classType,
  ]);

  useEffect(() => {
    if (classTypeId) {
      dispatch(getClass(classTypeId));
    }
  }, [classTypeId, dispatch]);

  useEffect(() => {
    if (mainSession) {
      dispatch(createToken(mainSession.id));
    }
  }, [mainSession, dispatch]);

  useMetaTitle(
    t("videoSession.metaTitle", {
      session: classType?.name,
      practitioner: getFullName(classType?.practitioner),
    }),
    !!classType
  );

  useUnmount(() => {
    dispatch(clearSessionClass());
    dispatch(clearSessionToken());
  });

  const handleFinishFeedback = () => {
    dispatch(setShowFeedbackModal({ visible: false }));
    history.push(AppPathEnums.USER_HOME);
  };

  const hasError = !!(classTypeError ?? sessionTokenError ?? hasZoomLink);

  const isUserModerator = role === SessionRoleEnum.MODERATOR;

  if (loadingClassType || loadingToken || (!classType && !classTypeError)) {
    return <LoadingSpinner />;
  }

  return (
    <SessionLayout>
      <VideoSession
        classType={classType}
        token={token}
        isUserModerator={isUserModerator}
        hasError={hasError}
      />

      {classType &&
        (isUserModerator ? (
          <PractitionerFeedbackModal
            visible={showFeedbackModal}
            onClose={handleFinishFeedback}
            sessionTitle={classType.name}
            practitioner={getFullName(classType.practitioner)}
            avatarImage={classType.practitioner.image}
          />
        ) : (
          <SeekerFeedbackModal
            visible={showFeedbackModal}
            onClose={handleFinishFeedback}
            sessionTitle={classType.name}
            practitioner={getFullName(classType.practitioner)}
            avatarImage={classType.practitioner.image}
          />
        ))}

      <SessionErrorModal zoomLink={classType?.zoomLink} />
    </SessionLayout>
  );
});

export default SessionScreen;
