import * as React from "react";

function SvgMediumHexagon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 320 320"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M160.25 3.567l-.25-.144-.25.144-135.1 78-.25.144v156.577l.25.145 134.599 78 .25.144.25-.144 135.6-78 .251-.144V81.711l-.25-.144-135.1-78zM25.948 81.972l66.466 38.609L159.067 5.116 25.947 81.972zm65.966 39.476L25.4 82.811v153.862l66.514-115.225zM26.366 237l66.365-114.966L158.923 237H26.366zm267.268 0H160.079l67.189-114.968L293.634 237zm.966-.327l-66.514-115.225L294.6 82.811v153.862zM160.933 5.116l133.119 76.856-66.466 38.609L160.933 5.116zM160 5.5L93.327 121h133.346L160 5.5zm-1 309.632V238H25.897L159 315.132zm1 .004V238h134.097L160 315.136zM226.129 122H93.865l65.638 114.003L226.129 122z"
        fill="currentColor"
        fillOpacity={0.35}
      />
    </svg>
  );
}

const MemoSvgMediumHexagon = React.memo(SvgMediumHexagon);
export default MemoSvgMediumHexagon;
