import { RouteItemDef } from "@app/types/route.types";

import { EcommercePathsEnum } from "../constants/ecommerce.path";
import ConfirmationScreen from "../screens/ConfirmationScreen/ConfirmationScreen";

const CONFIRMATION_SCREEN: RouteItemDef = {
  id: "confirmation",
  path: EcommercePathsEnum.CONFIRMATION,
  component: ConfirmationScreen,
  metaTitle: "orderConfirmation.metaTitle",
};

export const CONFIRMATION_ROUTES = [CONFIRMATION_SCREEN];
