import React, { memo } from "react";

import Loading from "@app/components/atoms/Loading/Loading";
import Section from "@app/components/layouts/Section/Section";
import { ThemeEnum } from "@app/constants/theme.constants";
import { useSingleEntry } from "@app/features/contentful/contentful";
import { ContentfulMedia } from "@app/types/contentful.types";

import ImageCarousel from "./ImageCarousel";

interface ImageCarouselContentfulProps {
  entryID: string;
}

interface ImageCarouselContentModel {
  title: string;
  images: ContentfulMedia[];
  theme: string;
}

const ImageCarouselContentful = memo(
  ({ entryID }: ImageCarouselContentfulProps) => {
    const { entry, loading } = useSingleEntry<ImageCarouselContentModel>(
      entryID
    );

    return (
      <Loading isLoading={loading}>
        {!!entry?.images?.length && (
          <Section
            theme={(entry?.theme ?? ThemeEnum.KHAKI) as ThemeEnum}
            noBackgroundOverlap
            withSeparatorSpacing
          >
            <ImageCarousel
              media={entry?.images.map(image => image.fields.file.url)}
              title={entry?.title}
            />
          </Section>
        )}
      </Loading>
    );
  }
);

export default ImageCarouselContentful;
