import * as React from "react";

function SvgSun(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 12 12" fill="none" {...props}>
      <circle cx={6} cy={6} r={3} fill="currentColor" />
      <path
        d="M6 1v.1M6 11v.1M10.373 3.525l-.086.05M1.713 8.525l-.086.05M1.627 3.525l.086.05M10.287 8.525l.086.05"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="square"
      />
    </svg>
  );
}

const MemoSvgSun = React.memo(SvgSun);
export default MemoSvgSun;
