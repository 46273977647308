import React, { memo } from "react";

import cx from "classnames";
import { Link, useLocation } from "react-router-dom";

import ConditionalWrapper from "@app/components/atoms/ConditionalWrapper/ConditionalWrapper";
import Image from "@app/components/atoms/Image/Image";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import Section from "@app/components/layouts/Section/Section";
import { ThemeEnum } from "@app/constants/theme.constants";

import styles from "./Banner.module.scss";

export interface BannerProps {
  title: string;
  imageUrl: string;
  CTALink?: string;
  fullwidth?: boolean;
}

const Banner = ({
  title,
  imageUrl,
  CTALink,
  fullwidth = true,
}: BannerProps) => {
  const location = useLocation();

  const image = (
    <Image
      url={imageUrl}
      alt={title}
      className={cx(styles.imageWrapper, {
        [styles.bannerInContent]: !fullwidth,
      })}
    />
  );

  return (
    <Section theme={ThemeEnum.IMAGERY} withSeparatorSpacing={!fullwidth}>
      <ConditionalWrapper
        condition={!fullwidth}
        wrapper={children => <ContentWrapper>{children}</ContentWrapper>}
      >
        {CTALink ? (
          <Link
            to={{
              pathname: CTALink,
              state: { prevPath: location.pathname },
            }}
          >
            {image}
          </Link>
        ) : (
          image
        )}
      </ConditionalWrapper>
    </Section>
  );
};

export default memo(Banner);
