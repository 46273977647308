import * as React from "react";

function SvgCards(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={0.5}
        y={2.5}
        width={15}
        height={11}
        rx={2.5}
        stroke="currentColor"
      />
      <path d="M0 5.5h16" stroke="currentColor" />
    </svg>
  );
}

const MemoSvgCards = React.memo(SvgCards);
export default MemoSvgCards;
