import i18next from "i18next";

import {
  NavigationPathsEnum,
  NavigationIDsEnum,
} from "@app/components/renderings/Navigation/constants/navigation";
import { ClassesPathEnum } from "@app/features/classes/classes";
import {
  EXPERIENCE_LIST,
  EXPERIENCE_PATH,
} from "@app/features/experiences/experiences";
import {
  PRACTITIONERS_LIST,
  PRACTITIONER_PATH,
} from "@app/features/practitioners/practitioners";
import { SearchPathsEnum } from "@app/features/search/search";
import { AppPathEnums } from "@app/routes/routes.constants";
import { NavigationItemType } from "@app/types/navigation.types";

// TODO: import the label and path from the routeDef
export const getNavigationLinks = (isAuthenticated: boolean) => {
  return [
    {
      id: NavigationIDsEnum.HOME,
      label: i18next.t("navigation.home"),
      path: isAuthenticated ? AppPathEnums.USER_HOME : AppPathEnums.ROOT_ROUTE,
      subMenu: [
        {
          id: NavigationIDsEnum.WELCOME,
          label: isAuthenticated
            ? i18next.t("navigation.forYou")
            : i18next.t("navigation.welcome"),
          path: isAuthenticated
            ? AppPathEnums.USER_HOME
            : AppPathEnums.ROOT_ROUTE,
        },
        {
          id: NavigationIDsEnum.EXPERIENCES,
          label: i18next.t("navigation.experiences"),
          path: EXPERIENCE_LIST,
          activeFor: [EXPERIENCE_PATH],
        },
        {
          id: NavigationIDsEnum.PRACTITIONERS,
          label: i18next.t("navigation.practitioners"),
          path: PRACTITIONERS_LIST,
          activeFor: [PRACTITIONER_PATH],
        },
        {
          id: NavigationIDsEnum.SCHEDULE,
          label: i18next.t("navigation.schedule"),
          path: ClassesPathEnum.SCHEDULE,
        },
      ],
    },
    {
      id: NavigationIDsEnum.ABOUT,
      label: i18next.t("navigation.about"),
      path: NavigationPathsEnum.ABOUT,
    },
    {
      id: NavigationIDsEnum.RETREATS,
      label: i18next.t("navigation.retreats"),
      path: NavigationPathsEnum.RETREATS,
    },
    {
      id: NavigationIDsEnum.JOURNAL,
      label: i18next.t("navigation.journal"),
      path: NavigationPathsEnum.JOURNAL,
    },
    {
      id: NavigationIDsEnum.CLASSES,
      label: i18next.t("navigation.classes"),
      path: SearchPathsEnum.SEARCH,
    },
    // {
    //   id: NavigationIDsEnum.PRIVATES,
    //   label: "Private Sessions",
    //   path: NavigationPathsEnum.PRIVATES,
    // },
  ] as NavigationItemType[];
};
