import React from "react";

import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import Loading from "@app/components/atoms/Loading/Loading";
import { ContentModel } from "@app/constants/contentful.constants";
import { useSingleEntry } from "@app/features/contentful/contentful";

import ClassPreview from "../../components/ClassPreview/ClassPreview";
import ExperiencePreview from "../../components/ExperiencePreview/ExperiencePreview";
import PractitionerPreview from "../../components/PractitionerPreview/PractitionerPreview";
import StaticPagePreview from "../../components/StaticPagePreview/StaticPagePreview";

const AllowedPreviewModels = [
  ContentModel.PRACTITIONER,
  ContentModel.CLASS,
  ContentModel.STATIC_PAGE,
  ContentModel.EXPERIENCE,
];

const PreviewComponents = {
  [ContentModel.PRACTITIONER]: PractitionerPreview,
  [ContentModel.CLASS]: ClassPreview,
  [ContentModel.EXPERIENCE]: ExperiencePreview,
  [ContentModel.STATIC_PAGE]: StaticPagePreview,
};

const PreviewScreen = () => {
  const { contentfulId } = useParams<{ contentfulId: string }>();
  const { entry, loading } = useSingleEntry(contentfulId, {
    includeMeta: true,
    preview: true,
  });
  const contentType = entry?.sys?.contentType?.sys?.id;

  const componentName = AllowedPreviewModels.includes(contentType)
    ? contentType
    : null;

  if (!componentName) return null;

  const SectionComponent = (PreviewComponents as any)[componentName];

  if (!SectionComponent || !entry.fields) return null;

  return (
    <Loading isLoading={loading}>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <SectionComponent data={entry.fields} />
    </Loading>
  );
};

export default PreviewScreen;
