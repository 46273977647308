import * as React from "react";

function SvgGridNone(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path stroke="currentColor" strokeWidth={2} d="M3 3h18v18H3z" />
    </svg>
  );
}

const MemoSvgGridNone = React.memo(SvgGridNone);
export default MemoSvgGridNone;
