export enum IMAGE_TYPES {
  CAROUSEL_ITEM = "carousel-item",
  BANNER = "banner",
  PRACTITIONER = "practitioner",
  MODALITY = "modality",
  AVATAR = "avatar",
  IMAGE_CAROUSEL = "image-carousel",
  SESSION = "session",
  TOPIC = "topic",
}

export const IMAGE_SIZES: Record<
  IMAGE_TYPES,
  { height: number; width: number }
> = {
  [IMAGE_TYPES.CAROUSEL_ITEM]: { height: 1064, width: 800 },
  [IMAGE_TYPES.BANNER]: { height: 1120, width: 2880 },
  [IMAGE_TYPES.PRACTITIONER]: { height: 1064, width: 800 },
  [IMAGE_TYPES.MODALITY]: { height: 1200, width: 1800 },
  [IMAGE_TYPES.AVATAR]: { height: 300, width: 300 },
  [IMAGE_TYPES.IMAGE_CAROUSEL]: { height: 1600, width: 1600 },
  [IMAGE_TYPES.SESSION]: { height: 444, width: 800 },
  [IMAGE_TYPES.TOPIC]: { height: 1600, width: 1600 },
};

/**
 * focus area types
 * You can choose the focus area for resizing when using fit type
 * https://www.contentful.com/developers/docs/references/images-api/#/reference/resizing-&-cropping/specify-focus-area
 */
export enum IMAGE_FOCUS {
  CENTER = "center",
  TOP = "top",
  RIGHT = "right",
  LEFT = "left",
  BOTTOM = "bottom",
  FACE = "face",
}
