import { AxiosResponse } from "axios";

import { api } from "@app/api/api";

import { ExperiencesEndpointsEnum } from "../constants/experiences.endpoints";
import {
  ExperienceDef,
  ExperiencesFilterDef,
} from "../types/experiences.types";

const getExperiences = (
  filter?: ExperiencesFilterDef
): Promise<AxiosResponse> => {
  return api.get(ExperiencesEndpointsEnum.EXPERIENCES, { params: filter });
};

const getExperienceById = (id: ExperienceDef["id"]): Promise<AxiosResponse> => {
  const url = `${ExperiencesEndpointsEnum.EXPERIENCES}/${id}`;
  return api.get(url);
};

export const experiencesApi = {
  getExperiences,
  getExperienceById,
};
