import _camelCase from "lodash/camelCase";

import { ENV } from "@app/constants/config";

import { useSingleEntry } from "../contentful";

type LocalizationDef = {
  [key: string]: { [key: string]: string };
};

interface ResourceModel {
  fields: {
    key: string;
    value: string;
  };
}

interface ResourceSetModel {
  resourceSets: {
    fields: {
      name: string;
      resources: ResourceModel[];
    };
  }[];
}

function useFetchResources() {
  const { entry: data, loading } = useSingleEntry<ResourceSetModel>(
    ENV.CONTENTFUL_LOCALIZATION
  );

  const resourceMapper = () => {
    const localizationTemp: LocalizationDef = {};
    data?.resourceSets.forEach(resourceSet => {
      resourceSet.fields?.resources.forEach(resource => {
        localizationTemp[_camelCase(resourceSet.fields.name)] = {
          ...localizationTemp[_camelCase(resourceSet.fields.name)],
          ...{
            [_camelCase(resource.fields.key)]: resource.fields.value,
          },
        };
      });
    });
    return localizationTemp;
  };

  return { resources: resourceMapper(), loading };
}
export default useFetchResources;
