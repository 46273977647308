import * as React from "react";

function SvgVolumeOff(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 1.92V22.08l-1.625-1.3L6.65 17H2V7h4.65l4.725-3.78L13 1.92zm-2 4.16l-3.375 2.7L7.35 9H4v6h3.35l.275.22L11 17.92V6.08zm8 7.334l-2.293 2.293-1.414-1.414L17.586 12l-2.293-2.293 1.414-1.414L19 10.586l2.293-2.293 1.414 1.414L20.414 12l2.293 2.293-1.414 1.414L19 13.414z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgVolumeOff = React.memo(SvgVolumeOff);
export default MemoSvgVolumeOff;
