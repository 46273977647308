import React, { memo } from "react";

import { ButtonTypeEnum } from "@app/components/atoms/Button/Button";
import { IMAGE_TYPES } from "@app/constants/image";
import { ThemeEnum, AlignmentEnum } from "@app/constants/theme.constants";
import { useSingleEntry } from "@app/features/contentful/contentful";
import { getImageUrl } from "@app/helpers/image.helpers";
import { ContentfulMedia } from "@app/types/contentful.types";

import PromotedEvent from "./PromotedEvent";

interface PromotedEventContentfulProps {
  entryID: string;
}

interface PromotedEventDataModel {
  title: string;
  subtitle: string;
  description: string;
  ctaLabel: string;
  ctaLink: string;
  ctaType: string;
  image?: ContentfulMedia;
  video?: ContentfulMedia;
  theme: string;
  boxTheme: string;
  alignment: string;
  isFullBackground: boolean;
}

const PromotedEventContentful = memo(
  ({ entryID }: PromotedEventContentfulProps) => {
    const { entry: promotedEventData } = useSingleEntry<PromotedEventDataModel>(
      entryID
    );

    if (!promotedEventData) return null;

    const {
      title,
      subtitle,
      description,
      ctaLabel,
      ctaLink,
      ctaType,
      image,
      video,
      theme,
      boxTheme,
      alignment,
      isFullBackground,
    } = promotedEventData;

    return (
      <PromotedEvent
        title={title}
        subtitle={subtitle}
        description={description}
        CTALabel={ctaLabel}
        CTALink={ctaLink}
        CTAType={ctaType as ButtonTypeEnum}
        image={
          image?.fields.file &&
          getImageUrl(image?.fields.file.url, IMAGE_TYPES.BANNER)
        }
        videoLink={video?.fields.file.url}
        theme={theme as ThemeEnum}
        boxTheme={boxTheme as ThemeEnum}
        alignment={alignment as AlignmentEnum}
        isFullBackground={isFullBackground}
      />
    );
  }
);

export default PromotedEventContentful;
