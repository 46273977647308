/**
 * All permission scopes.
 * The scopes should be aligned with backend
 */
export enum PermissionEnum {
  DASHBOARD = "dashboard",
  USERS_CREATE = "users:create",
  USERS_READ = "users:read",
  USERS_WRITE = "users:write",
  USERS_DELETE = "users:delete",

  SEEKER_DASHBOARD = "seekers:dashboard",
  PRACTITIONERS_DASHBOARD = "practitioners:dashboard",
}
