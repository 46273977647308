import * as React from "react";

function SvgVideoOff(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.693 4.707l1.414-1.414L6.814 6H16v9.185l4.107 4.108-1.4 1.4-16-16-.014.014zM13.186 18H3V7.814l2 2V16h6.186l2 2zM14 8v5.185L8.814 8H14zm5 4a3 3 0 013-3V7a5 5 0 000 10v-2a3 3 0 01-3-3z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgVideoOff = React.memo(SvgVideoOff);
export default MemoSvgVideoOff;
