import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import cx from "classnames";

import { Title } from "@app/components/atoms/Typography/Typography";

import styles from "./TextRotator.module.scss";

interface TextType {
  text: string;
}
interface TextRotatorProps {
  content: string[];
  delay?: number;
  initialDelay?: number;
  renderItem?: (item: TextType) => ReactNode;
}

const TRANSITION_TIME = 500; // CSS animation delay

const TextRotator = memo(
  ({
    content,
    delay = 3000,
    initialDelay = 0,
    renderItem,
  }: TextRotatorProps) => {
    const indexRef = useRef(-1);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const enteredRef = useRef<NodeJS.Timeout | null>(null);
    const [entered, setEntered] = useState(false);

    const next = useCallback(() => {
      indexRef.current = content[indexRef.current + 1]
        ? indexRef.current + 1
        : 0;
      setEntered(true);
      enteredRef.current = setTimeout(
        () => setEntered(false),
        delay - TRANSITION_TIME
      );
    }, [content, delay]);

    useEffect(() => {
      const timeout = setTimeout(() => {
        next();
        intervalRef.current = setInterval(next, delay);
      }, initialDelay);

      return () => {
        clearTimeout(timeout);
        intervalRef.current && clearInterval(intervalRef.current);
        enteredRef.current && clearTimeout(enteredRef.current);
      };
    }, [delay, initialDelay, next]);

    if (!content[indexRef.current]) return null;

    return (
      <div className={cx(styles.content, { [styles.active]: entered })}>
        {renderItem ? (
          renderItem({ text: content[indexRef.current] })
        ) : (
          <Title level={2}>{content[indexRef.current]}</Title>
        )}
      </div>
    );
  }
);

export default TextRotator;
