import React, { memo } from "react";

import heroBackground from "@app/assets/images/hero.jpg";
import heroVideo from "@app/assets/videos/gradientHero.mp4";
import { Title } from "@app/components/atoms/Typography/Typography";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import Section from "@app/components/layouts/Section/Section";
import { ThemeEnum } from "@app/constants/theme.constants";

import styles from "./HeroVideo.module.scss";
import TextRotator from "./components/TextRotator/TextRotator";

export interface HeroVideoProps {
  backgroundImage?: string;
  titles?: string[];
}

const HeroVideo = memo(
  ({ backgroundImage = heroBackground, titles }: HeroVideoProps) => {
    return (
      <Section className={styles.wrapper} theme={ThemeEnum.IMAGERY}>
        <div
          className={styles.content}
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          <video muted autoPlay loop className={styles.video} src={heroVideo} />
          <ContentWrapper className={styles.subtitle}>
            {titles &&
              (titles.length > 1 ? (
                <TextRotator content={titles} />
              ) : (
                <Title level={2}>{titles[0]}</Title>
              ))}
          </ContentWrapper>
        </div>
      </Section>
    );
  }
);

export default HeroVideo;
