import React, { memo } from "react";

import CircularButton from "@app/components/atoms/CircularButton/CircularButton";
import {
  IconNavigation,
  IconCloseNavigation,
} from "@app/components/atoms/Icon/Icon";

import styles from "./NavigationMobile.module.scss";

interface NavigationMobileProps {
  menuOpen: boolean;
  toggleMenu: () => void;
}

const NavigationMobile = memo(
  ({ menuOpen, toggleMenu }: NavigationMobileProps) => {
    return (
      <CircularButton
        onClick={() => toggleMenu()}
        className={styles.menuButton}
        borderless
      >
        {menuOpen ? <IconCloseNavigation /> : <IconNavigation />}
      </CircularButton>
    );
  }
);

export default NavigationMobile;
