import React, { memo, useMemo } from "react";

import { Container } from "react-grid-system";

import SwiperCarousel, {
  SwiperSlide,
  SwiperBreakpoints,
  ArrowTypes,
  NavigationTypes,
} from "@app/components/atoms/SwiperCarousel/SwiperCarousel";
import { Title } from "@app/components/atoms/Typography/Typography";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import Section from "@app/components/layouts/Section/Section";
import { ScreenSizes } from "@app/constants/breakpoints";
import { ThemeEnum } from "@app/constants/theme.constants";
import { TestimonialDef } from "@app/types/testimonials.types";

import styles from "./QuotesCarousel.module.scss";

export interface QuotesCarouselProps {
  theme: ThemeEnum;
  slideItems: TestimonialDef[];
}

const QuotesCarousel = memo(({ theme, slideItems }: QuotesCarouselProps) => {
  const SLIDES_PER_VIEW = 1;

  const swiperBreakpoints: SwiperBreakpoints = {
    [ScreenSizes.XSMALL]: {
      slidesPerView: SLIDES_PER_VIEW,
    },
  };

  const isMultipleSlides = useMemo(
    () => slideItems.length > SLIDES_PER_VIEW,
    [slideItems]
  );

  if (!slideItems.length) return null;

  return (
    <Section
      theme={theme}
      className={styles.quotesWrapper}
      noBackgroundOverlap
      withSeparatorSpacing
    >
      <ContentWrapper>
        <Container fluid>
          <SwiperCarousel
            swiperClassname={styles.quotesSwiper}
            swiperBreakpoints={swiperBreakpoints}
            enableLoop={isMultipleSlides}
            showNavArrows={isMultipleSlides}
            centered
            arrowType={ArrowTypes.ALTERNATIVE}
            navigationType={NavigationTypes.CENTER}
          >
            {slideItems.map(item => (
              <SwiperSlide key={item.id}>
                <div className={styles.quoteWrapper}>
                  {!!item.title && (
                    <Title
                      level={3}
                      isGoldGradient
                      className={styles.textBottomSpacing}
                    >
                      {item.title}
                    </Title>
                  )}
                  {!!item.quote && (
                    <Title
                      level={3}
                      className={[
                        styles.textBottomSpacing,
                        styles.quoteContent,
                      ].join(" ")}
                    >
                      {item.quote}
                    </Title>
                  )}
                  {!!item.author && (
                    <Title level={3} className={styles.author}>
                      {item.author}
                    </Title>
                  )}
                </div>
              </SwiperSlide>
            ))}
          </SwiperCarousel>
        </Container>
      </ContentWrapper>
    </Section>
  );
});

export default QuotesCarousel;
