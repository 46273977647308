/* eslint-disable @typescript-eslint/camelcase */
import getSymbolFromCurrency from "currency-symbol-map";
import isUndefined from "lodash/isUndefined";

import {
  getGtmPurchase,
  GtmEvents,
  GtmItemDef,
  registerEvent,
} from "@app/features/gtm/gtm";
import { getFullName } from "@app/helpers/util.helpers";
import { UserDef } from "@app/types/api.types";
import { WindowDef } from "@app/types/external.types";

import { CartItemDef, CustomFieldsDef } from "../../types/snipcart.types";

const windowObj: WindowDef = window;

export const closeCart = (): void => {
  if (windowObj?.Snipcart) windowObj.Snipcart.api.theme.cart.close();
};

export const openCart = (): void => {
  const cartContainer = document.querySelector(".snipcart-modal__container");
  if (!cartContainer) {
    if (windowObj?.Snipcart) windowObj.Snipcart.api.theme.cart.open();
  }
};

export const setSnipcartUser = async (user: UserDef) => {
  if (windowObj?.Snipcart) {
    try {
      await windowObj.Snipcart.api.cart.update({
        email: user.email,
        metadata: {
          loggedInUser: {
            id: user.id,
            email: user.email,
            name: getFullName(user),
            ...(user.practitionersReferenceCodes?.[0] && {
              practitionerReferenceCode: user.practitionersReferenceCodes?.[0],
            }),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
};

export const gtmItemsMapper = (items: CartItemDef[]): GtmItemDef[] => {
  const gtmItems: GtmItemDef[] = [];
  items.forEach(item => {
    const customFields: CustomFieldsDef = item.customFields?.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.name]: curr.value,
      })
    ) as any;

    if (customFields)
      gtmItems.push({
        item_id: Number(customFields.productId),
        item_name: item.name,
        price: item.price,
        item_category: customFields.experience,
        item_type: customFields.type,
        item_owner: customFields.practitionerName,
      });
  });
  return gtmItems;
};

export const amountFormatter = (
  amount?: number | string,
  decimalCount = 2
): string | undefined => {
  return isUndefined(amount)
    ? undefined
    : parseFloat(amount.toString()).toFixed(decimalCount);
};

export const formatCurrency = (currency: string, amount: number) => {
  return `${getSymbolFromCurrency(currency ?? "GBP")}${amountFormatter(
    amount
  )}`;
};

export const registerSnipcartGtm = (payload: {
  orderId: string;
  items?: CartItemDef[];
  total: number;
  currency: string;
  coupon?: string;
}) => {
  if (payload.items?.length) {
    registerEvent(
      GtmEvents.PURCHASE,
      getGtmPurchase({
        transaction_id: payload.orderId,
        items: gtmItemsMapper(payload.items),
        value: payload.total,
        currency: payload.currency.toUpperCase(),
        coupon: payload.coupon,
      })
    );
  }
};

export const clearCart = async () => {
  if (windowObj?.Snipcart) {
    const items = windowObj.Snipcart?.store.getState().cart.items.items;
    if (items?.length) {
      try {
        await Promise.all(
          items.map(async item => {
            await windowObj.Snipcart?.api.cart.items.remove(item.uniqueId);
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  }
};
