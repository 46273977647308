import * as React from "react";

function SvgGridFull(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 4h-4v4h4V4zm2 0v4h4V4h-4zm-2 6h-4v4h4v-4zm2 4v-4h4v4h-4zm-2 2h-4v4h4v-4zm2 4v-4h4v4h-4zM4 4h4v4H4V4zm0 6h4v4H4v-4zm0 6h4v4H4v-4zM4 2H2v20h20V2H4z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgGridFull = React.memo(SvgGridFull);
export default MemoSvgGridFull;
