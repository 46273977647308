import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ApiStatusCodes } from "@app/constants/api.constants";
import { ClassesDef } from "@app/features/classes/classes";
import {
  ClassFeedbackDef,
  ClassForceDisconnectDef,
  SessionDef,
} from "@app/types/api.types";

import { sessionApi } from "../api/session.api";

interface SessionClassState {
  classType: ClassesDef | null;
  loading: boolean;
  error: ApiStatusCodes | null;
  showFeedbackModal: boolean;
}

const initialState: SessionClassState = {
  classType: null,
  loading: false,
  error: null,
  showFeedbackModal: false,
};

export const getClass = createAsyncThunk(
  "sessionClass/getClass",
  async (classId: string, { rejectWithValue }) => {
    try {
      const response = await sessionApi.getClass(classId);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.status);
    }
  }
);

export const createFeedback = createAsyncThunk(
  "sessionClass/createFeedback",
  async (
    data: { classId: ClassesDef["id"]; feedback: ClassFeedbackDef },
    { rejectWithValue }
  ) => {
    const { classId, feedback } = data;
    try {
      const response = await sessionApi.createFeedback(classId, feedback);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const forceDisconnect = createAsyncThunk(
  "sessionClass/forceDisconnect",
  async (
    data: { sessionId: SessionDef["id"] } & ClassForceDisconnectDef,
    { rejectWithValue }
  ) => {
    const { sessionId, connectionIds } = data;
    try {
      const response = await sessionApi.forceDisconnect(sessionId, {
        connectionIds,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const sessionClassSlice = createSlice({
  name: "sessionClass",
  initialState,
  reducers: {
    setShowFeedbackModal(state, action: PayloadAction<{ visible: boolean }>) {
      state.showFeedbackModal = action.payload.visible;
    },
    clearSessionClass: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(
      getClass.fulfilled,
      (state, action: PayloadAction<ClassesDef>) => {
        state.classType = action.payload;
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(getClass.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getClass.rejected, (state, action) => {
      state.error = action.payload as ApiStatusCodes;
      state.loading = false;
    });
  },
});

export const {
  setShowFeedbackModal,
  clearSessionClass,
} = sessionClassSlice.actions;

export default sessionClassSlice.reducer;
