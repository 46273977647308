import { RouteItemDef } from "@app/types/route.types";

import {
  PRACTITIONERS_LIST,
  PRACTITIONER_ROUTE_PATH,
} from "../constants/practitioners.path";
import PractitionerScreen from "../screens/PractitionerScreen/PractitionerScreen";
import PractitionersScreen from "../screens/PractitionersScreen/PractitionersScreen";

const PRACTITIONER_SCREEN: RouteItemDef = {
  id: "practitioner",
  path: PRACTITIONER_ROUTE_PATH,
  component: PractitionerScreen,
};

const PRACTITIONERS_SCREEN: RouteItemDef = {
  id: "practitioners",
  path: PRACTITIONERS_LIST,
  component: PractitionersScreen,
  metaTitle: "practitionersPage.metaTitle",
};

export const PRACTITIONERS_ROUTES = [PRACTITIONER_SCREEN, PRACTITIONERS_SCREEN];
