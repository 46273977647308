import * as React from "react";

function SvgSunOutline(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
      <circle cx={10} cy={10} r={3} stroke="currentColor" strokeWidth={1.5} />
      <path
        d="M10 1v3M10 16v3M17.794 5.5L15.196 7M4.804 13l-2.598 1.5M2.206 5.5L4.804 7M15.196 13l2.598 1.5"
        stroke="currentColor"
        strokeWidth={1.5}
      />
    </svg>
  );
}

const MemoSvgSunOutline = React.memo(SvgSunOutline);
export default MemoSvgSunOutline;
