import React, { memo } from "react";

import { useTranslation } from "react-i18next";

import ExternalLink from "@app/components/atoms/ExternalLink/ExternalLink";
import { IconDownload } from "@app/components/atoms/Icon/Icon";
import {
  Paragraph,
  Subtitle,
  Title,
} from "@app/components/atoms/Typography/Typography";
import { ClassesDef } from "@app/features/classes/classes";

import styles from "./ProductExtras.module.scss";

export interface ProductExtrasProps {
  preparationTips: ClassesDef["preparationTips"];
  whatToBring?: ClassesDef["whatToBring"];
  pdf?: ClassesDef["pdf"];
}

const ProductExtras = memo(
  ({ preparationTips, whatToBring, pdf }: ProductExtrasProps) => {
    const { t } = useTranslation();

    if (!preparationTips && !whatToBring && !pdf) {
      return null;
    }

    return (
      <div className={styles.productExtras}>
        {!!preparationTips && (
          <>
            <Title level={3} className={styles.subheader} isGoldGradient>
              {t("product.preparationTips")}
            </Title>
            <Paragraph level={2}>{preparationTips}</Paragraph>
          </>
        )}
        <div className={styles.additionalInfo}>
          {!!whatToBring && (
            <div className={styles.singleInfo}>
              <Subtitle level={3}>{whatToBring}</Subtitle>
            </div>
          )}
          {!!pdf && (
            <div>
              <ExternalLink className={styles.singleInfo} url={pdf}>
                <IconDownload className={styles.downloadIcon} />
                <Subtitle level={3} isGold>
                  {t("product.download")}
                </Subtitle>
              </ExternalLink>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default ProductExtras;
