import React, { memo } from "react";

import { useTranslation } from "react-i18next";

import { Title } from "@app/components/atoms/Typography/Typography";
import { UserDef } from "@app/types/api.types";

import { ReactComponent as MediumSmall } from "./MediumSmall.svg";
import styles from "./SeekerWelcomeHeader.module.scss";

interface SeekerWelcomeHeaderProps {
  user: UserDef | null;
}

const SeekerWelcomeHeader = memo(({ user }: SeekerWelcomeHeaderProps) => {
  const { t } = useTranslation();
  return (
    <>
      <MediumSmall className={styles.graphic} />
      <Title level={3} className={styles.title} isGold>
        {t("createAccount.subtitleHiUser", { name: user?.firstName })}
      </Title>
      <Title>{t("createAccount.titleWelcome")}</Title>
    </>
  );
});

export default SeekerWelcomeHeader;
