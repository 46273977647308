export enum SnipcartEvents {
  CART_CONFIRMED = "cart.confirmed",
  CART_CONFIRM_ERROR = "cart.confirm.error",
  CART_UPDATED = "cart.updated",
  CART_CREATED = "cart.created",
  CART_RESET = "cart.reset",
  ITEM_ADDED = "item.added",
  ITEM_REMOVED = "item.removed",
  ITEM_ADDING = "item.adding",
  SNIPCART_INIT = "snipcart.initialized",
  SNIPCART_READY = "snipcart.ready",
  ROUTE_CHANGED = "theme.routechanged",
  CHECKOUT_CLICKED = "summary.checkout_clicked",
  CART_CLOSED = "theme.cart.close",
  CART_OPENED = "theme.cart.open",
}

export enum CartStatusEnum {
  UNINITIALIZED = 0,
  CREATING = 1,
  READY = 2,
  COMPLETED = 3,
}
