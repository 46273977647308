import React, { memo } from "react";

import { Container, Row, Col } from "react-grid-system";

import { Title } from "@app/components/atoms/Typography/Typography";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import Section from "@app/components/layouts/Section/Section";
import { ThemeEnum } from "@app/constants/theme.constants";

import styles from "./Footer.module.scss";
import { BottomLinkItemProps } from "./components/BottomLinkItem/BottomLinkItem";
import BottomLinks from "./components/BottomLinks/BottomLinks";
import { FooterLinkItemProps } from "./components/FooterLinkItem/FooterLinkItem";
import FooterLinks from "./components/FooterLinks/FooterLinks";
import SocialLinks from "./components/SocialLinks/SocialLinks";

export interface FooterSections {
  title: string;
  links: FooterLinkItemProps[];
}

export interface FooterProps {
  theme: ThemeEnum;
  sections: FooterSections[];
  bottomLinks: BottomLinkItemProps[];
  socialLinks: {
    facebookLink: string;
    instagramLink: string;
    linkedInLink: string;
  };
}

const Footer = memo(
  ({
    theme = ThemeEnum.WHITE,
    sections,
    bottomLinks,
    socialLinks,
  }: FooterProps) => {
    return (
      <Section className={styles.footerWrapper} theme={theme}>
        <ContentWrapper>
          <Container className={styles.footer} fluid>
            <Row>
              {sections?.map(section => (
                <Col xs={12} lg={4} key={section.title}>
                  <Title className={styles.title} level={3}>
                    {section.title}
                  </Title>
                  <FooterLinks links={section.links} />
                </Col>
              ))}
            </Row>
          </Container>
          {bottomLinks && (
            <Container fluid>
              <div className={styles.bottomFooter}>
                <BottomLinks items={bottomLinks} />
                <SocialLinks links={socialLinks} />
              </div>
            </Container>
          )}
        </ContentWrapper>
      </Section>
    );
  }
);

export default Footer;
