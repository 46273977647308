import * as React from "react";

function SvgArrowRightAlt(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 42 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.4911 10L28.7604 1.72583L30.1362 0.274172L40.6879 10.2742L41.4538 11L40.6879 11.7258L30.1362 21.7258L28.7604 20.2742L37.4911 12H7.62939e-06V10H37.4911Z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgArrowRightAlt = React.memo(SvgArrowRightAlt);
export default MemoSvgArrowRightAlt;
