import { AxiosResponse } from "axios";

import { api } from "@app/api/api";

import { PractitionersEndpointsEnum } from "../constants/practitioners.endpoints";
import {
  PractitionerDef,
  PractitionersFilterDef,
} from "../types/practitioners.types";

const getPractitioners = (
  filter?: PractitionersFilterDef
): Promise<AxiosResponse> => {
  return api.get(PractitionersEndpointsEnum.PRACTITIONERS, {
    params: filter,
  });
};

const getPractitionerById = (
  id: PractitionerDef["id"]
): Promise<AxiosResponse> => {
  const url = `${PractitionersEndpointsEnum.PRACTITIONERS}/${id}`;
  return api.get(url);
};

export const practitionersApi = {
  getPractitioners,
  getPractitionerById,
};
