import * as React from "react";

function SvgArrowUp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.026 6.513L7.752 9.967 6.3 8.592l5-5.276.726-.766.726.766 5 5.276L16.3 9.967l-3.274-3.454v17.49h-2V6.514z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgArrowUp = React.memo(SvgArrowUp);
export default MemoSvgArrowUp;
