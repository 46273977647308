import React, { memo, ReactNode } from "react";

import AuthLayout from "../AuthLayout/AuthLayout";
import styles from "./AuthBoxLayout.module.scss";

interface AuthBoxLayoutProps {
  header: ReactNode;
  footer?: ReactNode;
  children?: ReactNode;
}

const AuthBoxLayout = memo(
  ({ header, children, footer }: AuthBoxLayoutProps) => {
    return (
      <AuthLayout>
        <div className={styles.container}>
          <div className={styles.header}>{header}</div>
          <div className={styles.content}>{children}</div>
          {footer && <div className={styles.footer}>{footer}</div>}
        </div>
      </AuthLayout>
    );
  }
);

export default AuthBoxLayout;
