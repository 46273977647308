import React, { memo, useState } from "react";

import { useTranslation } from "react-i18next";

import Tags, { TagProps } from "@app/components/atoms/Tags/Tags";
import { Paragraph, Title } from "@app/components/atoms/Typography/Typography";

import styles from "./ProductText.module.scss";

export interface ProductTextProps {
  category: string;
  name: string;
  shortDescription: string;
  description: string;
  tags?: TagProps[];
}

const ProductText = memo(
  ({
    category,
    name,
    description,
    tags,
    shortDescription,
  }: ProductTextProps) => {
    const { t } = useTranslation();
    const [showMore, setShowMore] = useState<boolean>(false);

    return (
      <>
        {!!category && (
          <Title level={3} className={styles.category}>
            {category}
          </Title>
        )}
        {!!name && (
          <Title level={1} className={styles.title}>
            {name}
          </Title>
        )}
        {!!description && !!shortDescription && (
          <>
            <Title level={3} className={styles.subheader} isGoldGradient>
              {t("product.descriptionSubheader")}
            </Title>
            <Paragraph level={2} className={styles.description}>
              {showMore ? description : shortDescription}
              <button
                className={styles.link}
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? t("default.readLess") : t("default.readMore")}
              </button>
            </Paragraph>
          </>
        )}
        {tags && tags.length > 0 && (
          <div className={styles.tagsWrapper}>
            {tags.map(tag => (
              <Tags
                key={tag.label}
                className={styles.tag}
                label={tag.label}
                color={tag.color}
                link={tag.link}
              />
            ))}
          </div>
        )}
      </>
    );
  }
);

export default ProductText;
