import i18next from "i18next";
import { initReactI18next } from "react-i18next";

const INITIAL_LANG = "en-US";
export const DEFAULT_NS = "translation";

i18next.use(initReactI18next).init({
  fallbackLng: INITIAL_LANG,
  lng: INITIAL_LANG,
  interpolation: {
    escapeValue: false,
  },
  defaultNS: DEFAULT_NS,
  resources: {},
});

export default i18next;
