import React, { memo } from "react";

import { Col, Row } from "react-grid-system";

import SessionCard from "@app/components/atoms/SessionCard/SessionCard";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import { ClassesDef } from "@app/features/classes/classes";

import styles from "./SessionsList.module.scss";

export interface SessionsListProps {
  sessions: ClassesDef[];
  smallOnMobile?: boolean;
}

const SessionsList = memo(({ sessions, smallOnMobile }: SessionsListProps) => {
  return (
    <ContentWrapper className={styles.container}>
      <Row>
        {sessions.map(session => (
          <Col
            xs={12}
            md={6}
            lg={4}
            key={session.id}
            className={styles.session}
          >
            <SessionCard session={session} smallOnMobile={smallOnMobile} />
          </Col>
        ))}
      </Row>
    </ContentWrapper>
  );
});

export default SessionsList;
