import React, { memo, ReactNode } from "react";

import cx from "classnames";
import {
  Tabs as RCTabs,
  TabList,
  TabPanel,
  Tab,
  TabsProps as RCTabsProps,
} from "react-tabs";

import styles from "./Tabs.module.scss";

export interface TabItem {
  id: string | number;
  title: ReactNode;
  content: ReactNode;
  disabled?: boolean;
}
export interface TabsProps extends RCTabsProps {
  /**
   * The items rendered in the tabs
   */
  items: TabItem[];
  /**
   * Class passed into the TabPanel component when selected (optional)
   */
  tabPanelClassName?: string;
  /**
   * Whether the tabs should take up the full width of container (optional)
   */
  fullWidth?: boolean;
  /**
   * Whether the selected tab should have an indicator line (optional)
   */
  withIndicatorLine?: boolean;
  tabClassName?: string;
}

const Tabs = memo(
  ({
    items,
    className,
    tabPanelClassName,
    fullWidth,
    withIndicatorLine,
    tabClassName,
    selectedTabClassName,
    defaultIndex = 0,
    onSelect,
  }: TabsProps) => {
    return (
      <RCTabs
        className={cx(styles.tabs, className, {
          [styles.fullWidth]: fullWidth,
          [styles.withIndicatorLine]: withIndicatorLine,
        })}
        selectedTabPanelClassName={cx(
          styles.tabPanelSelected,
          tabPanelClassName
        )}
        selectedTabClassName={cx(styles.tabSelected, selectedTabClassName)}
        disabledTabClassName={styles.tabDisabled}
        defaultIndex={defaultIndex}
        onSelect={onSelect}
      >
        <TabList className={styles.tabList}>
          {items.map(({ id, title, disabled }) => (
            <Tab
              className={cx(styles.tab, tabClassName)}
              key={id}
              disabled={disabled}
            >
              {title}
            </Tab>
          ))}
        </TabList>
        {items.map(({ id, content }) => (
          <TabPanel className={styles.tabPanel} key={id}>
            {content}
          </TabPanel>
        ))}
      </RCTabs>
    );
  }
);

export default Tabs;
