import React, { memo } from "react";

import cx from "classnames";

import { Caption } from "../Typography/Typography";
import styles from "./FormMessage.module.scss";

export enum FormMessageType {
  ERROR = "error",
  SUCCESS = "success",
}

export interface FormMessageProps {
  message: string;
  type: FormMessageType;
}

const FormMessage = memo(({ message, type }: FormMessageProps) => {
  return (
    <Caption
      className={cx(styles.message, {
        [styles.error]: type === FormMessageType.ERROR,
        [styles.success]: type === FormMessageType.SUCCESS,
      })}
    >
      {message}
    </Caption>
  );
});

export default FormMessage;
