import React, { ChangeEvent, useState, KeyboardEvent, memo } from "react";

import { useTranslation } from "react-i18next";
import { useMedia } from "react-media";

import Button, { ButtonSizeEnum } from "@app/components/atoms/Button/Button";
import {
  IconArrowRight,
  IconClose,
  IconSearch,
} from "@app/components/atoms/Icon/Icon";
import Input from "@app/components/atoms/Input/Input";
import { GLOBAL_MEDIA_QUERIES } from "@app/constants/breakpoints";

import styles from "./SearchBar.module.scss";

interface SearchBarProps {
  onSearch: (search: string) => void;
  defaultValue?: string;
}

const SearchBar = memo(({ onSearch, defaultValue }: SearchBarProps) => {
  const { t } = useTranslation();
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState(defaultValue ?? "");
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleSearch = () => {
    const search = value.trim();
    search && onSearch(search);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleClear = () => {
    setValue("");
    onSearch("");
  };

  const getButton = () => {
    if (!value) return null;

    if (focused) {
      return (
        <Button
          label={
            matches.tabletLandscape ? t("search.searchBtnLabel") : undefined
          }
          className={styles.button}
          size={ButtonSizeEnum.TEXT}
          endIcon={<IconArrowRight className={styles.icon} />}
          onClick={handleSearch}
        />
      );
    }

    return (
      <Button
        label={
          matches.tabletLandscape ? t("search.clearSearchBtnLabel") : undefined
        }
        className={styles.button}
        size={ButtonSizeEnum.TEXT}
        startIcon={<IconClose className={styles.icon} />}
        onClick={handleClear}
      />
    );
  };

  return (
    <div className={styles.container}>
      <IconSearch className={styles.icon} />
      <div className={styles.inputWrapper}>
        <Input
          className={styles.input}
          placeholder={t("search.searchBarPlaceholder")}
          field={{
            name: "search",
            onChange: handleChange,
            value,
          }}
          onFocus={() => setFocused(true)}
          onKeyDown={handleKeyDown}
        />
      </div>
      <div className={styles.actions}>{getButton()}</div>
    </div>
  );
});

export default SearchBar;
