import React, { memo } from "react";

import { ThemeEnum } from "@app/constants/theme.constants";
import { useSingleEntry } from "@app/features/contentful/contentful";

import ArticlesByFilter from "../ArticlesByFilter/ArticlesByFilter";

interface ArticlesContentfulProps {
  entryID: string;
}

interface ArticlesDataModel {
  theme: string;
  subtitle: string;
  title: string;
}

const ArticlesContentful = memo(({ entryID }: ArticlesContentfulProps) => {
  const { entry: articlesData } = useSingleEntry<ArticlesDataModel>(entryID);

  if (!articlesData) return null;

  const { theme, title, subtitle } = articlesData;

  return (
    <ArticlesByFilter
      theme={theme as ThemeEnum}
      title={title}
      subtitle={subtitle}
    />
  );
});

export default ArticlesContentful;
