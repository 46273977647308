import React, { ReactNode } from "react";

import { useTranslation } from "react-i18next";

import Button, { ButtonTypeEnum } from "@app/components/atoms/Button/Button";
import { IconError } from "@app/components/atoms/Icon/Icon";
import { Caption } from "@app/components/atoms/Typography/Typography";

import Modal, { ModalProps } from "../../Modal";
import styles from "./ErrorModal.module.scss";

export interface ErrorModalProps
  extends Omit<ModalProps, "subtitle" | "headerCenter" | "icon"> {
  title: string;
  subtitle: ReactNode;
  visible: boolean;
  hideConfirm?: boolean;
  buttonLabel?: string;
  onConfirm?: () => void;
}

const ErrorModal = ({
  title,
  subtitle,
  visible,
  onConfirm,
  buttonLabel,
  hideConfirm,
  ...rest
}: ErrorModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={title}
      visible={visible}
      headerCenter
      headerClassName={styles.header}
      icon={
        <div className={styles.iconWrapper}>
          <IconError className={styles.icon} />
        </div>
      }
      {...rest}
    >
      <>
        <div className={styles.container}>
          <Caption>{subtitle}</Caption>
        </div>
        {!hideConfirm && (
          <Button
            buttonType={ButtonTypeEnum.PRIMARY}
            label={buttonLabel ?? t("default.errorTryAgain")}
            onClick={onConfirm}
            fullWidth
            buttonContentClassName={styles.button}
          />
        )}
      </>
    </Modal>
  );
};

export default ErrorModal;
