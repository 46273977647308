import * as React from "react";

function SvgMediumCosmic(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 320 320"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M301.711 296.773l.415.278a.5.5 0 00-.53-.765l.115.487zM284.498 272l.416.278a.5.5 0 00-.53-.765l.114.487zm-251.991.009l.145.478a.5.5 0 00-.13-.978l-.015.5zM47.64 291.38l.49.105a.501.501 0 00-.919-.362l.43.257zM288 16h.5a.5.5 0 00-.5-.5v.5zm0 32v.5a.5.5 0 00.5-.5h-.5zm-256 .5c9.112 0 16.5-7.387 16.5-16.5h-1c0 8.56-6.94 15.5-15.5 15.5v1zM15.5 32c0 9.113 7.387 16.5 16.5 16.5v-1c-8.56 0-15.5-6.94-15.5-15.5h-1zM32 15.5c-9.113 0-16.5 7.387-16.5 16.5h1c0-8.56 6.94-15.5 15.5-15.5v-1zM48.5 32c0-9.113-7.388-16.5-16.5-16.5v1c8.56 0 15.5 6.94 15.5 15.5h1zm249.494 264.701c-8.678 0-15.713-7.036-15.713-15.714h-1c0 9.23 7.482 16.714 16.713 16.714v-1zm3.602-.415a15.711 15.711 0 01-3.602.415v1c1.318 0 2.6-.152 3.831-.441l-.229-.974zm-13.382 8.214c5.804 0 10.917-2.959 13.912-7.449l-.831-.555c-2.818 4.224-7.625 7.004-13.081 7.004v1zM271.5 287.786c0 9.231 7.484 16.714 16.714 16.714v-1c-8.678 0-15.714-7.035-15.714-15.714h-1zm12.884-16.273c-7.384 1.732-12.884 8.359-12.884 16.273h1c0-7.439 5.17-13.671 12.113-15.299l-.229-.974zm-2.103 9.474c0-3.222.969-6.217 2.633-8.709l-.831-.556a16.627 16.627 0 00-2.802 9.265h1zm-259.623 3.632c0 7.553 6.121 13.675 13.674 13.675v-1c-7 0-12.674-5.674-12.674-12.675h-1zm9.704-13.089c-5.616 1.702-9.704 6.917-9.704 13.089h1c0-5.719 3.788-10.554 8.994-12.132l-.29-.957zm-.362.97c.163 0 .325.004.492.009l.03-1A17.69 17.69 0 0032 271.5v1zM16.5 288c0-8.56 6.94-15.5 15.5-15.5v-1c-9.113 0-16.5 7.388-16.5 16.5h1zM32 303.5c-8.56 0-15.5-6.94-15.5-15.5h-1c0 9.112 7.387 16.5 16.5 16.5v-1zm15.152-12.226C45.648 298.263 39.436 303.5 32 303.5v1c7.917 0 14.529-5.576 16.13-13.015l-.978-.211zm-10.82 7.02c4.986 0 9.35-2.67 11.737-6.658l-.858-.513a12.665 12.665 0 01-10.88 6.171v1zM288 15.5c-9.113 0-16.5 7.388-16.5 16.5h1c0-8.56 6.94-15.5 15.5-15.5v-1zm.5 32.5V16h-1v32h1zm-17-16c0 9.112 7.387 16.5 16.5 16.5v-1c-8.56 0-15.5-6.94-15.5-15.5h-1zm-241 32.5v190h1v-190h-1zm257 0v190h1v-190h-1zm-223 224h190v-1h-190v1zm0-257h190v-1h-190v1z"
        fill="currentColor"
        fillOpacity={0.35}
      />
    </svg>
  );
}

const MemoSvgMediumCosmic = React.memo(SvgMediumCosmic);
export default MemoSvgMediumCosmic;
