export enum SearchTypeEnum {
  CLASSES = "classes",
  PRACTITIONERS = "practitioners",
  ARTICLES = "articles",
}

export enum SearchFacetFiltersEnum {
  TIMINGS = "timings",
  EXPERIENCES = "experiences",
  PRACTITIONERS = "practitioners",
  DURATIONS = "durations",
  PRICES = "prices",
  LEVELS = "levels",
  CLASS_TYPE = "classTypes",
  LABELS = "labels",
}

export enum SearchApiFiltersEnum {
  LIMIT = "limit",
  PAGE = "page",
  TYPES = "types",
  NAME = "name",
}
