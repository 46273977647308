import { useState } from "react";

import { StreamPropertyChangedEvent } from "../types/opentok.types";

interface ConfigDef {
  initialStreamCreated?: boolean;
  initialAudioEnabled?: boolean;
  initialVideoEnabled?: boolean;
}

function useStreamSettings(config?: ConfigDef) {
  const [streamCreated, setStreamCreated] = useState(
    config?.initialStreamCreated
  );
  const [audioEnabled, setAudioEnabled] = useState(config?.initialAudioEnabled);
  const [videoEnabled, setVideoEnabled] = useState(config?.initialVideoEnabled);

  const updateStreamSettings = (event: StreamPropertyChangedEvent) => {
    switch (event.changedProperty) {
      case "hasAudio":
        setAudioEnabled(event.newValue);
        break;
      case "hasVideo":
        setVideoEnabled(event.newValue);
        break;
      default:
    }
  };

  return {
    streamCreated,
    audioEnabled,
    videoEnabled,
    updateStreamSettings,
    setStreamCreated,
  };
}

export default useStreamSettings;
