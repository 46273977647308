import * as React from "react";

function SvgChevronUp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7 14.276L12 9l5 5.276" stroke="currentColor" strokeWidth={2} />
    </svg>
  );
}

const MemoSvgChevronUp = React.memo(SvgChevronUp);
export default MemoSvgChevronUp;
