export enum SessionDisconnectedReasonsEnum {
  CLIENT_DISCONNECTED = "clientDisconnected",
  FORCE_DISCONNECTED = "forceDisconnected",
  NETWORK_DISCONNECTED = "networkDisconnected",
}

export enum StreamDestroyedReasonsEnum {
  CLIENT_DISCONNECTED = "clientDisconnected",
  FORCE_DISCONNECTED = "forceDisconnected",
  NETWORK_DISCONNECTED = "networkDisconnected",
  FORCE_UNPUBLISHED = "forceUnpublished",
  MEDIA_STOPPED = "mediaStopped",
}

export enum SessionEventsEnum {
  ARCHIVE_STARTED = "archiveStarted",
  ARCHIVE_STOPPED = "archiveStopped",
  CONNECTION_CREATED = "connectionCreated",
  CONNECTION_DESTROYED = "connectionDestroyed",
  SESSION_CONNECTED = "sessionConnected",
  SESSION_DISCONNECTED = "sessionDisconnected",
  SESSION_RECONNECTED = "sessionReconnected",
  SESSION_RECONNECTING = "sessionReconnecting",
  SIGNAL = "signal",
  STREAM_CREATED = "streamCreated",
  STREAM_DESTROYED = "streamDestroyed",
  STREAM_PROPERTY_CHANGED = "streamPropertyChanged",
}

export enum PublisherEventsEnum {
  ACCESS_ALLOWED = "accessAllowed",
  ACCESS_DENIED = "accessDenied",
  ACCESS_DIALOG_CLOSED = "accessDialogClosed",
  ACCESS_DIALOG_OPENED = "accessDialogOpened",
  AUDIO_LEVEL_UPDATED = "audioLevelUpdated",
  DESTROYED = "destroyed",
  MEDIA_STOPPED = "mediaStopped",
  STREAM_CREATED = "streamCreated",
  STREAM_DESTROYED = "streamDestroyed",
  VIDEO_DIMENSIONS_CHANGED = "videoDimensionsChanged",
  VIDEO_ELEMENT_CREATED = "videoElementCreated",
}
