import * as React from "react";

function SvgGood(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.525 11H4v9h13.56L20 10h-7V5.867L11.322 4h-1.085l-.813 3.467L7.525 11zM8 10v10"
        stroke="currentColor"
        strokeWidth={2}
      />
    </svg>
  );
}

const MemoSvgGood = React.memo(SvgGood);
export default MemoSvgGood;
