import { useEffect } from "react";

import { useLocation } from "react-router-dom";

function useResetScroll() {
  const location = useLocation();

  useEffect(() => {
    try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      // fallback for older browsers
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);
}

export default useResetScroll;
