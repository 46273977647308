import * as React from "react";

function SvgCheckmark(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.586 16.192l-4.172-4.171L4 13.435l4.879 4.879.707.707.707-.707 9.9-9.9L18.777 7l-9.192 9.192z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgCheckmark = React.memo(SvgCheckmark);
export default MemoSvgCheckmark;
