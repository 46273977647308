import * as React from "react";

function SvgPayment(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 6h14a1 1 0 011 1v1.714H4V7a1 1 0 011-1zm-1 4.714V17a1 1 0 001 1h14a1 1 0 001-1v-6.286H4zM2 7a3 3 0 013-3h14a3 3 0 013 3v10a3 3 0 01-3 3H5a3 3 0 01-3-3V7z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgPayment = React.memo(SvgPayment);
export default MemoSvgPayment;
