import React, { memo, useCallback, useState } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";

import Button, {
  ButtonTypeEnum,
  ButtonSizeEnum,
} from "@app/components/atoms/Button/Button";
import { IconEndCall } from "@app/components/atoms/Icon/Icon";
import { ConfirmationModal } from "@app/components/atoms/Modal/Modal";
import {
  forceDisconnect,
  setShowFeedbackModal,
} from "@app/features/session/session";
import { useAppDispatch } from "@app/redux/store";
import { SessionDef } from "@app/types/api.types";

import { VideoSessionUserDef } from "../../types/session.types";
import styles from "./VideoToolbarBottom.module.scss";

interface VideoToolbarBottomProps {
  sessionId: SessionDef["id"];
  attendees: VideoSessionUserDef[];
}

const VideoToolbarBottom = memo(
  ({ sessionId, attendees }: VideoToolbarBottomProps) => {
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [loadingEndCall, setLoadingEndCall] = useState(false);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const handleLeaveSession = useCallback(() => {
      setShowErrorModal(false);
      setLoadingEndCall(true);

      const connectionIds = attendees.map(
        attendee => attendee.stream.connection.connectionId
      );

      dispatch(forceDisconnect({ sessionId, connectionIds }))
        .then(unwrapResult)
        .then(() => {
          dispatch(setShowFeedbackModal({ visible: true }));
        })
        .catch(() => {
          setShowErrorModal(true);
        })
        .finally(() => {
          setLoadingEndCall(false);
        });
    }, [attendees, dispatch, sessionId]);

    return (
      <>
        {!!attendees.length && (
          <Button
            buttonType={ButtonTypeEnum.SECONDARY}
            size={ButtonSizeEnum.TEXT}
            label={t("videoSession.endForAllButton")}
            startIcon={<IconEndCall />}
            onClick={handleLeaveSession}
            hideLabelOnMobile
            className={styles.endCallButton}
            loading={loadingEndCall}
          />
        )}
        <ConfirmationModal
          visible={showErrorModal}
          title={t("videoSessionError.forceDisconnectFailedTitle")}
          subtitle={t("videoSessionError.forceDisconnectFailedText")}
          confirmButtonLabel={t(
            "videoSessionError.forceDisconnectFailedButton"
          )}
          onClose={() => setShowErrorModal(false)}
          onConfirm={handleLeaveSession}
          maskClosable={false}
        />
      </>
    );
  }
);

export default VideoToolbarBottom;
