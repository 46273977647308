import React from "react";

import cx from "classnames";

import { IconUser } from "@app/components/atoms/Icon/Icon";
import Image from "@app/components/atoms/Image/Image";
import { IMAGE_FOCUS, IMAGE_TYPES } from "@app/constants/image";
import { getImageUrl } from "@app/helpers/image.helpers";

import styles from "./Avatar.module.scss";

export type AvatarColorsDef = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export enum AvatarSize {
  DEFAULT = "default",
  LARGE = "large",
  MEDIUM = "medium",
  SMALL = "small",
}

export interface AvatarProps {
  /**
   * The image shown in the avatar
   */
  avatarImage?: string | null;
  /**
   * Different options for background color of avatar when no image
   * 0 = default
   * 1-6 = colors
   */
  avatarColor?: AvatarColorsDef;
  /**
   * Different sizes for the avatar
   */
  avatarSize?: AvatarSize;
  /**
   * Whether there should be a colored shadow around the avatar
   */
  avatarWithShadow?: boolean;
  className?: string;
}

export const getRandomAvatarColor = () => {
  const min = Math.ceil(0);
  const max = Math.floor(6);
  return Math.floor(Math.random() * (max - min + 1) + min) as AvatarColorsDef;
};

const Avatar = ({
  avatarImage,
  avatarColor = 0,
  avatarSize = AvatarSize.DEFAULT,
  avatarWithShadow,
  className,
}: AvatarProps) => {
  return (
    <>
      {avatarImage ? (
        <div className={cx(styles.image, className, styles[avatarSize])}>
          <Image
            className={styles.avatar}
            url={getImageUrl(avatarImage, IMAGE_TYPES.AVATAR, IMAGE_FOCUS.FACE)}
          />
        </div>
      ) : (
        <div
          className={cx(styles.avatar, styles[avatarSize], className, {
            [styles[`avatarColor${avatarColor}`]]: avatarColor != null,
            [styles.avatarWithShadow]: avatarWithShadow,
          })}
        >
          <IconUser className={styles.avatarIcon} />
        </div>
      )}
    </>
  );
};

export default Avatar;
