export enum ThemeEnum {
  WHITE = "white",
  KHAKI = "khaki",
  IMAGERY = "imagery",
  VIOLET_ON_GREEN = "violet-on-green",
  PINK_ON_ORANGE = "pink-on-orange",
  VIOLET_ON_YELLOW = "violet-on-yellow",
  BLUE_ON_YELLOW = "blue-on-yellow",
  RED_ON_ORANGE = "red-on-orange",
  GREEN_ON_ORANGE = "green-on-orange",
}

export enum BackgroundRatioEnum {
  HALF = "half",
  QUARTER = "quarter",
  THIRD = "third",
}

export enum AlignmentEnum {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}

export enum AlignmentSimpleEnum {
  LEFT = "left",
  RIGHT = "right",
}
