import * as React from "react";

function SvgDuration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.229 4.945A8 8 0 0112 4V2a10 10 0 107.071 17.071l-1.414-1.414A8.001 8.001 0 118.229 4.945zm11.768 6.85a8.001 8.001 0 01-.99 4.065l1.752.965a9.999 9.999 0 001.238-5.082l-2 .051zm-.55-2.716a8.006 8.006 0 00-.81-1.545l1.66-1.116c.407.605.747 1.252 1.013 1.931l-1.862.73zM12 14a1.5 1.5 0 001.5-1.5C13.5 11.672 12 6 12 6s-1.5 5.672-1.5 6.5A1.5 1.5 0 0012 14z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgDuration = React.memo(SvgDuration);
export default MemoSvgDuration;
