import React, { ReactNode } from "react";

export interface ConditionalWrapperProps {
  condition: boolean;
  wrapper: (children: React.ReactNode) => JSX.Element;
  children?: ReactNode;
}

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: ConditionalWrapperProps) =>
  condition ? wrapper(children) : <>{children}</>;

export default ConditionalWrapper;
