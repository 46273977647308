/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC, memo } from "react";

import cx from "classnames";

import { FormFieldDef } from "@app/types/form.types";

import { Subtitle } from "../Typography/Typography";
import styles from "./Input.module.scss";

export interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  field: FormFieldDef<HTMLInputElement>;
  label?: string;
}

const Input: FC<InputProps> = memo(({ field, className, label, ...rest }) => {
  return (
    <>
      {label && (
        <label htmlFor={field.name} className={styles.label}>
          <Subtitle level={3}>{label}</Subtitle>
        </label>
      )}
      <input
        {...field}
        className={cx(styles.input, className)}
        {...(rest as JSX.IntrinsicElements["input"])}
      />
    </>
  );
});

export default Input;
