import React, { memo } from "react";

import Loading from "@app/components/atoms/Loading/Loading";
import { LayoutsEnum } from "@app/constants/layout";
import { useSingleEntry } from "@app/features/contentful/contentful";

import StaticPageComponents from "../StaticPageComponents/StaticPageComponents";

interface Props {
  contentfulId: string;
}

export interface StaticPageComponentsDef {
  sys: {
    id: string;
    contentType: { sys: { id: string } };
  };
  fields: {};
}

export interface StaticPageModel {
  title: string;
  components: StaticPageComponentsDef[];
  layout: LayoutsEnum;
}

const StaticPageLoader = memo(({ contentfulId }: Props) => {
  const { entry, loading } = useSingleEntry<StaticPageModel>(contentfulId);

  return (
    <Loading isLoading={loading}>
      {entry && <StaticPageComponents components={entry.components} />}
    </Loading>
  );
});

export default StaticPageLoader;

export { StaticPageComponents };
