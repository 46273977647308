import React, { memo, useMemo } from "react";

import { Container, Row, Col } from "react-grid-system";

import Button, {
  ButtonTypeEnum,
  ButtonSizeEnum,
} from "@app/components/atoms/Button/Button";
import SwiperCarousel, {
  SwiperSlide,
  SwiperBreakpoints,
} from "@app/components/atoms/SwiperCarousel/SwiperCarousel";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import Section from "@app/components/layouts/Section/Section";
import { ScreenSizes } from "@app/constants/breakpoints";
import { ThemeEnum, BackgroundRatioEnum } from "@app/constants/theme.constants";

import { Subtitle, Title } from "../../atoms/Typography/Typography";
import styles from "./Experiences.module.scss";
import ExperienceItem, {
  ExperienceItemProps,
} from "./components/ExperienceItem/ExperienceItem";

export interface ExperiencesProps {
  /**
   * Set theme for section, which will control section backgrounds color and nested components
   */
  theme?: ThemeEnum;
  /**
   * Set the subTitle of the hero content
   */
  subtitle?: string;
  /**
   * Set the main title of the component
   */
  title: string;
  /**
   * Set the main call to action text
   */
  CTALabel?: string;
  /**
   * Set the main call to action link to a specific page
   */
  CTALink?: string;
  /**
   * Set the main call to action Type either primary or secondary
   */
  CTAType?: ButtonTypeEnum;
  isUppercase?: boolean;
  slideItems: ExperienceItemProps[];
}

const Experiences = memo(
  ({
    theme = ThemeEnum.KHAKI,
    title,
    subtitle,
    CTALabel,
    CTALink,
    CTAType = ButtonTypeEnum.PRIMARY,
    slideItems,
    isUppercase = true,
  }: ExperiencesProps) => {
    const SLIDES_PER_VIEW = 3;

    const swiperBreakpoints: SwiperBreakpoints = {
      [ScreenSizes.XSMALL]: {
        slidesPerView: 1.05,
      },
      [ScreenSizes.SMALL]: {
        slidesPerView: 2,
      },
      [ScreenSizes.MEDIUM]: {
        slidesPerView: 2,
      },
      [ScreenSizes.LARGE]: {
        slidesPerView: SLIDES_PER_VIEW,
      },
    };

    const isMultipleSlides = useMemo(
      () => slideItems.length > SLIDES_PER_VIEW,
      [slideItems]
    );

    return (
      <Section
        theme={theme}
        backgroundRatio={BackgroundRatioEnum.THIRD}
        withSeparatorSpacing
      >
        <ContentWrapper>
          <Container fluid>
            <Row>
              <Col xs={12} lg={9}>
                {!!subtitle && (
                  <Title level={3} className={styles.subtitle} isGoldGradient>
                    {subtitle}
                  </Title>
                )}
                {!!title &&
                  (isUppercase ? (
                    <Title level={1} className={styles.title}>
                      {title}
                    </Title>
                  ) : (
                    <Subtitle level={1} className={styles.title}>
                      {title}
                    </Subtitle>
                  ))}
              </Col>
            </Row>

            {!!CTALabel && (
              <Button
                className={styles.button}
                to={CTALink}
                buttonType={CTAType}
                size={ButtonSizeEnum.LARGE}
                label={CTALabel}
              />
            )}
          </Container>
          <SwiperCarousel
            swiperClassname={styles.experiencesSwiper}
            swiperBreakpoints={swiperBreakpoints}
            enableLoop={isMultipleSlides}
            showNavArrows={isMultipleSlides}
            showPagination
          >
            {slideItems.map(item => (
              <SwiperSlide key={item.id}>
                <ExperienceItem
                  title={item.title}
                  description={item.description}
                  image={item.image}
                  link={item.link?.trim()}
                  id={item.id}
                />
              </SwiperSlide>
            ))}
          </SwiperCarousel>
        </ContentWrapper>
      </Section>
    );
  }
);

export default Experiences;
