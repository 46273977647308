export enum ClassTypesEnum {
  LIVE_CLASS = "Live class",
  PRIVATE_SESSION = "Private session",
  WEBINAR = "Webinar",
  WORKSHOP = "Workshop",
  EVENT = "Event",
  ON_DEMAND = "On demand",
  TEACHER_TRAINING = "Teacher training",
  RETREAT = "Retreat",
}

export enum ClassesFilterEnum {
  UPCOMING = "upcoming",
  PAST = "past",
}
