import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { useUnmount } from "react-use";

import Loading from "@app/components/atoms/Loading/Loading";
import DefaultLayout from "@app/components/layouts/DefaultLayout/DefaultLayout";
import ProductDetails from "@app/components/renderings/ProductDetails/ProductDetails";
import SessionsByFilter from "@app/components/renderings/SessionsByFilter/SessionsByFilter";
import { ThemeEnum } from "@app/constants/theme.constants";
import { getFirstExperience } from "@app/features/classes/classes";
import {
  getGtmEcommerceItems,
  getGtmItem,
  GtmEvents,
  registerEvent,
} from "@app/features/gtm/gtm";
import { isValidId } from "@app/helpers/util.helpers";
import useMetaTitle from "@app/hooks/useMetaTitle";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";
import NoMatch from "@app/routes/components/NoMatch/NoMatch";

import {
  getProductById,
  clearProductDetail,
} from "../../redux/product-detail.slice";

const ProductDetailsScreen = ({
  match,
}: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const productId = match.params.id;

  const { product, loading, error } = useSelector(
    (state: RootState) => state.productDetail
  );

  useMetaTitle(product?.name, !!product);

  useEffect(() => {
    if (isValidId(productId)) {
      dispatch(getProductById(Number(productId)));
    }
  }, [dispatch, productId]);

  useEffect(() => {
    if (product) {
      registerEvent(
        GtmEvents.VIEW_ITEM,
        getGtmEcommerceItems([getGtmItem(product)])
      );
    }
  }, [product]);

  useUnmount(() => {
    dispatch(clearProductDetail());
  });

  if (error) return <NoMatch />;

  if (!product) return null;

  return (
    <DefaultLayout>
      <Loading isLoading={loading}>
        <ProductDetails product={product} />
        {getFirstExperience(product) && ( // TODO: Add the main experience instead
          <SessionsByFilter
            theme={ThemeEnum.WHITE}
            subTitle={t("recommendedSessions.defaultSubtitle")}
            title={t("product.sessionsTitle", {
              name: getFirstExperience(product).name,
            })}
            filter={{
              experiences: [getFirstExperience(product).id.toString()],
            }}
          />
        )}
        {product.practitioner && (
          <SessionsByFilter
            theme={ThemeEnum.WHITE}
            title={t("product.practitionerSession", {
              name: product.practitioner.firstName,
            })}
            filter={{
              practitioners: [product.practitioner.id.toString()],
            }}
          />
        )}
      </Loading>
    </DefaultLayout>
  );
};
export default ProductDetailsScreen;
