import React from "react";

import cx from "classnames";
import { useSelector } from "react-redux";

import Section from "@app/components/layouts/Section/Section";
import { ThemeEnum } from "@app/constants/theme.constants";
import { ClassesDef } from "@app/features/classes/classes";
import { RootState } from "@app/redux/root-reducer";

import { VideoSessionUserDef } from "../../types/session.types";
import styles from "./VideoSidebar.module.scss";
import SidebarAttendee from "./components/SidebarAttendee/SidebarAttendee";
import SidebarBottom from "./components/SidebarBottom/SidebarBottom";
import SidebarHeaderAttendee from "./components/SidebarHeaderAttendee/SidebarHeaderAttendee";
import SidebarHeaderModerator from "./components/SidebarHeaderModerator/SidebarHeaderModerator";
import SidebarModerator from "./components/SidebarModerator/SidebarModerator";

interface VideoSidebarProps {
  classType: ClassesDef;
  attendees?: VideoSessionUserDef[];
  isUserModerator: boolean;
  hasError: boolean;
}

const VideoSidebar = ({
  classType,
  attendees = [],
  isUserModerator,
  hasError,
}: VideoSidebarProps) => {
  const { disableChat } = useSelector((state: RootState) => state.video);

  return (
    <Section theme={ThemeEnum.WHITE} className={styles.sidebar}>
      {isUserModerator ? (
        <SidebarHeaderModerator title={classType.name} />
      ) : (
        <SidebarHeaderAttendee classType={classType} />
      )}

      {!hasError && (
        <div
          className={cx(styles.overflow, {
            [styles.hideChat]: !isUserModerator && disableChat,
          })}
        >
          {isUserModerator ? (
            <SidebarModerator
              attendees={attendees}
              className={styles.overflow}
            />
          ) : (
            <SidebarAttendee />
          )}
        </div>
      )}
      <SidebarBottom />
    </Section>
  );
};

export default VideoSidebar;
