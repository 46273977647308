import React, { memo, ReactNode } from "react";

import cx from "classnames";

import Section from "@app/components/layouts/Section/Section";
import { ThemeEnum } from "@app/constants/theme.constants";

import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import styles from "./Loading.module.scss";

interface LoadingProps {
  isLoading: boolean;
  theme?: ThemeEnum;
  isSmall?: boolean;
  children?: ReactNode;
}

const Loading = memo(
  ({
    isLoading,
    children = null,
    theme = ThemeEnum.KHAKI,
    isSmall,
  }: LoadingProps) => {
    return isLoading ? (
      <Section
        theme={theme}
        className={cx(styles.container, { [styles.small]: isSmall })}
      >
        <LoadingSpinner isSmall={isSmall} />
      </Section>
    ) : (
      <>{children}</>
    );
  }
);

export default Loading;
