import React, { memo, useMemo } from "react";

import { Col, Row } from "react-grid-system";

import Image from "@app/components/atoms/Image/Image";
import SwiperCarousel, {
  SwiperBreakpoints,
  SwiperSlide,
} from "@app/components/atoms/SwiperCarousel/SwiperCarousel";
import { Title } from "@app/components/atoms/Typography/Typography";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import { ScreenSizes } from "@app/constants/breakpoints";
import { IMAGE_TYPES } from "@app/constants/image";
import { getImageUrl } from "@app/helpers/image.helpers";

import styles from "./ImageCarousel.module.scss";

export interface ImageCarouselProps {
  media: string[];
  title?: string;
}

const SLIDES_PER_VIEW = 1;

const ImageCarousel = memo(({ media, title }: ImageCarouselProps) => {
  const swiperBreakpoints: SwiperBreakpoints = {
    [ScreenSizes.XSMALL]: {
      slidesPerView: SLIDES_PER_VIEW,
    },
  };

  const isMultipleSlides = useMemo(
    () => media && media.length > SLIDES_PER_VIEW,
    [media]
  );

  if (!media?.length) return null;

  return (
    <ContentWrapper>
      {!!title && (
        <Title level={1} className={styles.title}>
          {title}
        </Title>
      )}
      <Row justify="center">
        <Col xs={12} xl={10}>
          {media && media?.length > 0 && (
            <>
              <SwiperCarousel
                swiperClassname={styles.swiper}
                swiperBreakpoints={swiperBreakpoints}
                enableLoop={isMultipleSlides}
                showNavArrows={isMultipleSlides}
                showPagination
              >
                {media?.map(url => (
                  <SwiperSlide key={url}>
                    <div className={styles.itemWrapper}>
                      <Image
                        className={styles.imageWrapper}
                        url={getImageUrl(url, IMAGE_TYPES.IMAGE_CAROUSEL)}
                        alt=""
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </SwiperCarousel>
            </>
          )}
        </Col>
      </Row>
    </ContentWrapper>
  );
});

export default ImageCarousel;
