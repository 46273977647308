import React, { memo } from "react";

import Section from "@app/components/layouts/Section/Section";
import { ThemeEnum } from "@app/constants/theme.constants";

import styles from "./SessionLayout.module.scss";

const SessionLayout = memo(({ children }) => {
  return (
    <Section theme={ThemeEnum.KHAKI} className={styles.container} fullHeight>
      {children}
    </Section>
  );
});

export default SessionLayout;
