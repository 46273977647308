import React, { memo } from "react";

import { Col, Container, Row } from "react-grid-system";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { ReactComponent as Logo } from "@app/assets/images/logo.svg";
import Button, { ButtonTypeEnum } from "@app/components/atoms/Button/Button";
import {
  Caption,
  Paragraph,
  Title,
} from "@app/components/atoms/Typography/Typography";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import DefaultLayout from "@app/components/layouts/DefaultLayout/DefaultLayout";
import Section from "@app/components/layouts/Section/Section";
import { ThemeEnum } from "@app/constants/theme.constants";
import { RootState } from "@app/redux/root-reducer";

import styles from "./ConfirmationScreen.module.scss";

const ConfirmationScreen = memo(() => {
  const { t } = useTranslation();
  const { orderNumber } = useParams<{ orderNumber: string }>();
  const userEmail = useSelector((state: RootState) => state.auth.user?.email);
  const today = new Date();
  const ctaLabel = t("orderConfirmation.confirmationButton");

  return (
    <DefaultLayout>
      <Section
        theme={ThemeEnum.KHAKI}
        className={styles.wrapper}
        withSeparatorSpacing
      >
        <ContentWrapper>
          <Container fluid>
            <div className={styles.illustration} />
            <Row justify="center">
              <Col xs={12} lg={6} xl={5}>
                <Logo className={styles.logo} />
                <Title className={styles.title}>
                  {t("orderConfirmation.confirmationTitle")}
                </Title>
                <Title level={3} isGoldGradient className={styles.bookingRef}>
                  {t("orderConfirmation.booking", {
                    orderNumber,
                  })}
                </Title>
                <Caption className={styles.date}>
                  {t("orderConfirmation.placedOn", {
                    date: today.toDateString(),
                  })}
                </Caption>
                <Paragraph level={2} className={styles.description}>
                  <Trans
                    i18nKey="orderConfirmation.description"
                    values={{ email: userEmail }}
                    components={{
                      emailLink: <a href={`mailto:${userEmail}`}>value</a>,
                    }}
                  />
                </Paragraph>

                {ctaLabel && ctaLabel !== "" && (
                  <Button
                    to="/"
                    className={styles.button}
                    label={ctaLabel}
                    buttonType={ButtonTypeEnum.PRIMARY}
                  />
                )}
                <Caption className={styles.bottomText}>
                  <Trans
                    i18nKey="orderConfirmation.bottomText"
                    values={{ adminEmail: t("default.adminEmail") }}
                    components={{
                      emailLink: (
                        <a href={`mailto:${t("default.adminEmail")}`}>value</a>
                      ),
                    }}
                  />
                </Caption>
              </Col>
            </Row>
          </Container>
        </ContentWrapper>
      </Section>
    </DefaultLayout>
  );
});

export default ConfirmationScreen;
