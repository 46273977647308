import React, { memo } from "react";

import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";
import { BLOCKS, Document } from "@contentful/rich-text-types";

import ConditionalWrapper from "@app/components/atoms/ConditionalWrapper/ConditionalWrapper";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";

import {
  Heading,
  Paragraph,
  Subtitle,
} from "../../atoms/Typography/Typography";
import styles from "./RichText.module.scss";

export interface RichTextProps {
  text: Document;
  classname?: string;
  wrapContent?: boolean;
}

const RichText = memo(
  ({ text, classname, wrapContent = true }: RichTextProps) => {
    const options: Options = {
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => (
          <Paragraph level={2} className={classname}>
            {children}
          </Paragraph>
        ),
        [BLOCKS.HEADING_1]: (node, children) => (
          <Heading level={1} className={classname}>
            {children}
          </Heading>
        ),
        [BLOCKS.HEADING_2]: (node, children) => (
          <Heading level={2} className={classname}>
            {children}
          </Heading>
        ),
        [BLOCKS.HEADING_3]: (node, children) => (
          <Heading level={3} className={classname}>
            {children}
          </Heading>
        ),
        [BLOCKS.HEADING_4]: (node, children) => (
          <Subtitle level={1} className={classname}>
            {children}
          </Subtitle>
        ),
      },
    };
    return (
      <div className={styles.container}>
        <ConditionalWrapper
          condition={wrapContent}
          wrapper={children => <ContentWrapper>{children}</ContentWrapper>}
        >
          {documentToReactComponents(text, options)}
        </ConditionalWrapper>
      </div>
    );
  }
);

export default RichText;
