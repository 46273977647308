import React, { memo } from "react";

import cx from "classnames";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";

import Button, { ButtonTypeEnum } from "@app/components/atoms/Button/Button";
import {
  IconCalendar,
  IconDuration,
  IconUser,
} from "@app/components/atoms/Icon/Icon";
import { Subtitle } from "@app/components/atoms/Typography/Typography";
import { ClassTypesEnum } from "@app/constants/classes";
import { DateFormats } from "@app/constants/date.constants";
import { ClassesDef } from "@app/features/classes/classes";
import { AddToCartButton } from "@app/features/ecommerce/ecommerce";
import {
  getGtmEcommerceItems,
  getGtmItem,
  GtmEvents,
  registerEvent,
} from "@app/features/gtm/gtm";

import styles from "./ProductInfo.module.scss";

export interface ProductInfoProps {
  product: ClassesDef;
}

const ALERT_THRESHOLD = 10;

const ProductInfo = memo(({ product }: ProductInfoProps) => {
  const { t } = useTranslation();

  const isSoldOut = !!(
    product.attendeesNo != null &&
    product.maxAttendees != null &&
    product.attendeesNo >= product.maxAttendees
  );

  const getAlertLabel = () => {
    const remainingSlots =
      product.attendeesNo != null && product.maxAttendees != null
        ? product.maxAttendees - product.attendeesNo
        : Infinity;

    const spotsRemainingLabel =
      remainingSlots === 1
        ? t("product.slotsRemainingSingle")
        : t("product.slotsRemainingMultiple", { remaining: remainingSlots });

    return remainingSlots <= ALERT_THRESHOLD &&
      product.type !== ClassTypesEnum.PRIVATE_SESSION
      ? spotsRemainingLabel
      : null;
  };

  const handleAddToCart = () => {
    registerEvent(
      GtmEvents.ADD_TO_CART,
      getGtmEcommerceItems([getGtmItem(product)])
    );
  };

  return (
    <>
      {!!getAlertLabel() && !isSoldOut && (
        <div className={styles.productAlert}>
          <Subtitle level={3}>{getAlertLabel()}</Subtitle>
        </div>
      )}
      <div className={styles.productInfo}>
        {!!product.startAt && (
          <div className={styles.infoRow}>
            <IconCalendar className={styles.icon} />
            <Subtitle level={3}>
              {format(new Date(product.startAt), DateFormats.LONG)}
            </Subtitle>
          </div>
        )}
        {!!product.duration && (
          <div className={styles.infoRow}>
            <IconDuration className={styles.icon} />
            <Subtitle level={3}>
              {product.duration} {t("product.durationUnit")}
            </Subtitle>
          </div>
        )}
        {!!product.level && (
          <div className={styles.infoRow}>
            <IconUser className={styles.icon} />
            <Subtitle level={3}>{product.level}</Subtitle>
          </div>
        )}
      </div>
      <div className={styles.productPrice}>
        <div className={styles.price}>
          <Subtitle level={2} className={styles.label}>
            {t("product.price")}
          </Subtitle>
          <Subtitle level={2}>
            {t("product.currency")}
            {product.price}
          </Subtitle>
        </div>
        {!product.booked ? (
          <AddToCartButton
            label={
              isSoldOut
                ? t("product.soldOutLabel")
                : t("product.addToCartLabel")
            }
            fullWidth
            isDisabled={isSoldOut}
            product={product}
            buttonClassNames={cx({ "snipcart-add-item": !isSoldOut })}
            onClick={handleAddToCart}
          />
        ) : (
          <Button
            isDisabled
            fullWidth
            label={t("default.booked")}
            buttonType={ButtonTypeEnum.SECONDARY}
          />
        )}
      </div>
    </>
  );
});

export default ProductInfo;
