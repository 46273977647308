import * as React from "react";

function SvgPlay(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 12L6 18.928V5.072L20 12z"
        stroke="currentColor"
        strokeWidth={2}
      />
    </svg>
  );
}

const MemoSvgPlay = React.memo(SvgPlay);
export default MemoSvgPlay;
