import React, { FC, memo } from "react";

import cx from "classnames";

import styles from "./ContentWrapper.module.scss";

export type ContentWrapperProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const ContentWrapper: FC<ContentWrapperProps> = memo(
  ({ className, children, ...rest }) => {
    return (
      <div className={cx(styles.container, className)} {...rest}>
        {children}
      </div>
    );
  }
);

export default ContentWrapper;
