import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { articlesApi } from "../api/articles.api";
import { ArticleDef } from "../types/articles.types";

interface ArticlesState {
  article: ArticleDef | null;
  loading: boolean;
  error: boolean;
}

const initialState: ArticlesState = {
  article: null,
  loading: false,
  error: false,
};

export const getArticleById = createAsyncThunk(
  "articles/getArticleById",
  async (id: ArticleDef["id"], { rejectWithValue }) => {
    try {
      const response = await articlesApi.getArticleById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const articlesSlice = createSlice({
  name: "articles",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getArticleById.fulfilled, (state, action) => {
      state.loading = false;
      state.article = action.payload;
      state.error = false;
    });
    builder.addCase(getArticleById.pending, state => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getArticleById.rejected, state => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default articlesSlice.reducer;
