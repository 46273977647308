import * as React from "react";

function SvgVideo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 6H3v12h13V6H4zm1 10V8h9v8H5zm14-4a3 3 0 013-3V7a5 5 0 000 10v-2a3 3 0 01-3-3z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgVideo = React.memo(SvgVideo);
export default MemoSvgVideo;
