import * as React from "react";

function SvgLinkedIn(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.635 1H2.365C1.682 1 1.085 1.597 1 2.28v15.355c0 .682.597 1.28 1.365 1.28h15.27c.682 0 1.28-.598 1.365-1.28V2.28C19 1.597 18.403 1 17.635 1zM6.375 16.27H3.73V7.74h2.644v8.53zM5.008 6.545a1.53 1.53 0 01-1.535-1.536 1.53 1.53 0 011.535-1.535 1.53 1.53 0 011.536 1.535 1.53 1.53 0 01-1.536 1.536zm11.346 9.725h-2.644v-4.18c0-1.024 0-2.303-1.365-2.303s-1.62 1.109-1.62 2.218v4.265H8.08V7.74h2.559v1.194c.512-.853 1.45-1.45 2.474-1.365 2.73 0 3.156 1.791 3.156 4.095l.085 4.606z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgLinkedIn = React.memo(SvgLinkedIn);
export default MemoSvgLinkedIn;
