import React, { ReactNode } from "react";

import { useTranslation } from "react-i18next";

import Modal from "@app/components/atoms/Modal/Modal";
import { Subtitle, Title } from "@app/components/atoms/Typography/Typography";

import styles from "./ChatActionModal.module.scss";

interface CommentModalProps {
  visible: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
}

const ChatActionModal = ({
  visible,
  onClose,
  title,
  children,
}: CommentModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal visible={visible} className={styles.modal}>
      <div className={styles.header}>
        <Title className={styles.title} level={3}>
          {title}
        </Title>
        {children}
      </div>
      <button className={styles.cancel} onClick={onClose}>
        <Subtitle level={2} className={styles.cancelText}>
          {t("default.cancel")}
        </Subtitle>
      </button>
    </Modal>
  );
};

export default ChatActionModal;
