import React, { memo, useEffect } from "react";

import { useSelector } from "react-redux";

import { ButtonTypeEnum } from "@app/components/atoms/Button/Button";
import Loading from "@app/components/atoms/Loading/Loading";
import { ThemeEnum } from "@app/constants/theme.constants";
import { useSingleEntry } from "@app/features/contentful/contentful";
import { getExperiences } from "@app/features/experiences/experiences";
import { getPractitioners } from "@app/features/practitioners/practitioners";
import { carouselItemsMapper } from "@app/helpers/practitioners.helpers";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";

import Experiences from "./Experiences";

enum CarouselContentType {
  PRACTITIONERS = "practitioners",
  EXPERIENCES = "experiences",
}

interface ExperiencesContentfulProps {
  entryID: string;
}

interface ExperiencesDataModel {
  theme: string;
  ctaLabel: string;
  ctaLink: string;
  ctaType: string;
  subtitle: string;
  title: string;
  isUppercase: boolean;
  type: CarouselContentType;
}

// TODO : Move to generic name
const ExperiencesContentful = memo(
  ({ entryID }: ExperiencesContentfulProps) => {
    const {
      entry: experienceData,
      loading: loadingContentful,
    } = useSingleEntry<ExperiencesDataModel>(entryID);

    const { practitioners, loading: loadingPractitioners } = useSelector(
      (state: RootState) => state.practitioners
    );
    const { experiences, loading: loadingExperiences } = useSelector(
      (state: RootState) => state.experiences
    );

    const dispatch = useAppDispatch();

    const getItems = () => {
      if (experienceData?.type === CarouselContentType.PRACTITIONERS) {
        return carouselItemsMapper(practitioners.data);
      }
      if (experienceData?.type === CarouselContentType.EXPERIENCES) {
        return carouselItemsMapper(experiences.data);
      }
      return [];
    };

    useEffect(() => {
      if (experienceData?.type === CarouselContentType.PRACTITIONERS) {
        dispatch(getPractitioners({ limit: 10 }));
      } else if (experienceData?.type === CarouselContentType.EXPERIENCES) {
        dispatch(getExperiences({ limit: 10 }));
      }
    }, [dispatch, experienceData]);

    return (
      <Loading
        isLoading={
          loadingContentful || loadingPractitioners || loadingExperiences
        }
      >
        {experienceData && (
          <Experiences
            theme={experienceData.theme as ThemeEnum}
            title={experienceData.title}
            isUppercase={experienceData.isUppercase}
            subtitle={experienceData.subtitle}
            CTALabel={experienceData.ctaLabel}
            CTALink={experienceData.ctaLink}
            CTAType={experienceData.ctaType as ButtonTypeEnum}
            slideItems={getItems()}
          />
        )}
      </Loading>
    );
  }
);

export default ExperiencesContentful;
