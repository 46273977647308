import React, { memo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Button, { ButtonTypeEnum } from "@app/components/atoms/Button/Button";
import FormItem from "@app/components/atoms/FormItem/FormItem";
import { FormModal } from "@app/components/atoms/Modal/Modal";
import { Subtitle } from "@app/components/atoms/Typography/Typography";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";
import { ClassFeedbackDef } from "@app/types/api.types";

import { createFeedback } from "../../../../redux/session-class.slice";
import FeedbackModalHeader, {
  FeedbackModalHeaderProps,
} from "../FeedbackModalHeader/FeedbackModalHeader";
import FeedbackSimple from "../FeedbackSimple/FeedbackSimple";
import styles from "./PractitionerFeedbackModal.module.scss";
import FeedbackIncident from "./components/FeedbackIncident/FeedbackIncident";

export interface PractitionerFeedbackModalProps
  extends FeedbackModalHeaderProps {
  visible: boolean;
  onClose: () => void;
}
type FormValuesDef = Partial<ClassFeedbackDef>;

enum StepsEnum {
  SIMPLE = 1,
  INCIDENT = 2,
}

const initialValues: FormValuesDef = {
  like: undefined,
  comment: undefined,
  incidentType: undefined,
  incidentDescription: undefined,
};

const PractitionerFeedbackModal = memo(
  ({
    avatarImage,
    sessionTitle,
    practitioner,
    visible,
    onClose = () => null,
  }: PractitionerFeedbackModalProps) => {
    const [step, setStep] = useState<StepsEnum>(StepsEnum.SIMPLE);
    const [showFeedbackComment, setShowFeedbackComment] = useState(false);
    const [reportIncident, setReportIncident] = useState<boolean>();
    const classType = useSelector(
      (state: RootState) => state.sessionClass.classType
    );

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const handleSubmit = async (values: FormValuesDef) => {
      // If form is submitted, and user wants to skip the feedback
      if (values.like == null && !reportIncident) {
        onClose();
        return;
      }

      if (step === StepsEnum.SIMPLE && reportIncident) {
        setStep(StepsEnum.INCIDENT);
      } else if (classType) {
        const response = await dispatch(
          createFeedback({
            classId: classType.id,
            feedback: {
              ...values,
              like: Boolean(Number(values.like)),
            },
          })
        );
        if (createFeedback.fulfilled.match(response)) {
          onClose();
        }
      }
    };

    const handleClickSimpleFeedback = () => {
      setShowFeedbackComment(true);
    };

    const getSubmitButtonLabel = () => {
      if (step === StepsEnum.SIMPLE) {
        if (reportIncident === true) {
          return t("videoSessionPractitionerFeedback.nextButtonLabel");
        }
        return t("videoSessionPractitionerFeedback.doneButtonLabel");
      }
      return t("videoSessionPractitionerFeedback.submitButtonLabel");
    };

    return (
      <FormModal
        containerClassName={styles.feedbackModal}
        visible={visible}
        submitButtonLabel={getSubmitButtonLabel()}
        formikProps={{
          initialValues,
          onSubmit: handleSubmit,
        }}
        maskClosable={false}
      >
        <FeedbackModalHeader
          avatarImage={avatarImage}
          sessionTitle={sessionTitle}
          practitioner={t(
            "videoSessionPractitionerFeedback.withPractitionerTitle",
            {
              practitioner,
            }
          )}
        />

        {
          {
            [StepsEnum.SIMPLE]: (
              <>
                <div className={styles.wrapper}>
                  {!showFeedbackComment ? (
                    <FeedbackSimple
                      title={t("videoSessionPractitionerFeedback.title")}
                      subtitle={t("videoSessionPractitionerFeedback.subtitle")}
                      onClick={handleClickSimpleFeedback}
                    />
                  ) : (
                    <FormItem
                      type="textarea"
                      id="comment"
                      name="comment"
                      label={t(
                        "videoSessionPractitionerFeedback.additionalThoughtsLabel"
                      )}
                      placeholder={t(
                        "videoSessionPractitionerFeedback.additionalThoughtsPlaceholder"
                      )}
                      hideError
                    />
                  )}
                </div>
                <div className={styles.separator} />
                <div className={styles.wrapper}>
                  <Subtitle level={3} className={styles.caption}>
                    {t(
                      "videoSessionPractitionerFeedback.anyIncidentsToReportTitle"
                    )}
                  </Subtitle>
                  <div className={styles.buttons}>
                    <Button
                      buttonType={
                        reportIncident === false
                          ? ButtonTypeEnum.PRIMARY
                          : ButtonTypeEnum.SECONDARY
                      }
                      label={t("default.no")}
                      className={styles.button}
                      onClick={() => setReportIncident(false)}
                      fullWidth
                    />
                    <Button
                      buttonType={
                        reportIncident === true
                          ? ButtonTypeEnum.PRIMARY
                          : ButtonTypeEnum.SECONDARY
                      }
                      label={t("default.yes")}
                      className={styles.button}
                      onClick={() => setReportIncident(true)}
                      fullWidth
                    />
                  </div>
                </div>
              </>
            ),
            [StepsEnum.INCIDENT]: (
              <div className={styles.wrapper}>
                <FeedbackIncident />
              </div>
            ),
          }[step]
        }
      </FormModal>
    );
  }
);

export default PractitionerFeedbackModal;
