import React from "react";

const Restricted = () => {
  return (
    <div>
      <h3>Restricted</h3>
    </div>
  );
};

export default Restricted;
