import { RouteItemDef } from "@app/types/route.types";

import {
  EXPERIENCE_LIST,
  EXPERIENCE_ROUTE_PATH,
} from "../constants/experiences.path";
import ExperienceScreen from "../screens/ExperienceScreen/ExperienceScreen";
import ExperiencesScreen from "../screens/ExperiencesScreen/ExperiencesScreen";

const EXPERIENCE_SCREEN: RouteItemDef = {
  id: "experience",
  path: EXPERIENCE_ROUTE_PATH,
  component: ExperienceScreen,
};

const EXPERIENCES_SCREEN: RouteItemDef = {
  id: "experiences",
  path: EXPERIENCE_LIST,
  component: ExperiencesScreen,
  metaTitle: "experiencesPage.metaTitle",
};

export const EXPERIENCES_ROUTES = [EXPERIENCE_SCREEN, EXPERIENCES_SCREEN];
