import React, { memo } from "react";

import { useTranslation } from "react-i18next";

import Button, {
  ButtonSizeEnum,
  ButtonTypeEnum,
} from "@app/components/atoms/Button/Button";
import { Paragraph } from "@app/components/atoms/Typography/Typography";
import { AppPathEnums } from "@app/routes/routes.constants";

import styles from "./SeekerWelcome.module.scss";

interface SeekerWelcomeFormProps {
  isSubmitting: boolean;
}

const SeekerWelcome = memo(({ isSubmitting }: SeekerWelcomeFormProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Paragraph className={styles.text}>
        {t("createAccount.welcomeSubscribeText")}
      </Paragraph>
      <div>
        <Button
          loading={isSubmitting}
          label={t("createAccount.welcomeSubscribeYesButton")}
          submit
          buttonType={ButtonTypeEnum.PRIMARY}
          size={ButtonSizeEnum.LARGE}
        />
        <Button
          label={t("createAccount.welcomeSubscribeNoButton")}
          size={ButtonSizeEnum.TEXT}
          to={AppPathEnums.USER_HOME}
          className={styles.buttonRight}
        />
      </div>
    </>
  );
});

export default SeekerWelcome;
