import { RouteItemDef } from "@app/types/route.types";

import { SessionPathsEnum } from "../constants/session.paths";
import SessionScreen from "../screens/SessionScreen/SessionScreen";

const SESSION_SCREEN: RouteItemDef = {
  id: "session",
  path: SessionPathsEnum.SESSION,
  component: SessionScreen,
};

const SESSION_ROUTES = [SESSION_SCREEN];

export default SESSION_ROUTES;
