import { useEffect, useState } from "react";

import { useFetchResources } from "@app/features/contentful/contentful";

import {
  getLocale,
  getTranslation,
  setLocalization,
  updateLocalization,
} from "../helpers/localization.helpers";

function useInitLocalization() {
  const [loading, setLoading] = useState(true);
  const { resources, loading: resourcesLoading } = useFetchResources();
  const locale = getLocale();

  // FIRST
  // Set localization if already stored
  useEffect(() => {
    if (locale) {
      const existingLocalization = getTranslation();
      if (existingLocalization) {
        setLocalization(locale, existingLocalization);
        setLoading(false);
      }
    }
  }, [locale]);

  // SECOND
  // While setting existing localization
  // the newest localization is fetched in background
  // and added to i18next when done
  useEffect(() => {
    if (resources) {
      // TODO: Add to localStorage and only fetch when new localization is available
      updateLocalization("en-US", resources);
    }
  }, [resources]);

  return { loading: locale != null ? loading : resourcesLoading };
}

export default useInitLocalization;
