import React, { ReactElement, ReactNode, useMemo, useState } from "react";

import _capitalize from "lodash/capitalize";
import { MenuInfo } from "rc-menu/lib/interface";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-media";
import { useSelector } from "react-redux";

import Button, { ButtonSizeEnum } from "@app/components/atoms/Button/Button";
import ConditionalWrapper from "@app/components/atoms/ConditionalWrapper/ConditionalWrapper";
import Dropdown from "@app/components/atoms/Dropdown/Dropdown";
import { IconChevronDown } from "@app/components/atoms/Icon/Icon";
import { Subtitle, Title } from "@app/components/atoms/Typography/Typography";
import { GLOBAL_MEDIA_QUERIES } from "@app/constants/breakpoints";
import { ThemeEnum } from "@app/constants/theme.constants";
import {
  resetPrivateChatToUser,
  setPrivateChatToUser,
} from "@app/features/video/video";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";

import SidebarChatTab from "../SidebarChatTab/SidebarChatTab";
import ChatActionModal from "../SidebarChatTab/components/ChatList/components/ChatActionModal/ChatActionModal";
import styles from "./SidebarAttendee.module.scss";

export enum ChatTargetEnum {
  EVERYONE = "everyone",
  PRACTITIONER = "practitioner",
}

const SidebarAttendee = () => {
  const { t } = useTranslation();
  const { privateChatToUser, disableChat } = useSelector(
    (state: RootState) => state.video
  );
  const classType = useSelector(
    (state: RootState) => state.sessionClass.classType
  );
  const [chatOptionsVisible, setChatOptionsVisible] = useState(false);
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const dispatch = useAppDispatch();

  const menu = useMemo(() => {
    return Object.values(ChatTargetEnum).map(option => ({
      id: option,
      value: t(`videoSession.chatTitle${_capitalize(option)}`),
    }));
  }, [t]);

  const handleToggleTarget = () => {
    if (!matches.tabletLandscape) {
      setChatOptionsVisible(true);
    }
  };

  const handleOptionsClose = () => {
    setChatOptionsVisible(false);
  };

  const setChatUserIdInRedux = (target: ChatTargetEnum) => {
    if (target === ChatTargetEnum.PRACTITIONER && classType?.practitioner) {
      dispatch(setPrivateChatToUser({ user: classType.practitioner }));
    } else {
      dispatch(resetPrivateChatToUser());
    }
    handleOptionsClose();
  };

  const handleOptionClick = (event: MenuInfo) => {
    setChatUserIdInRedux(event.key as ChatTargetEnum);
  };

  return (
    <div className={styles.container}>
      {!disableChat && (
        <div className={styles.header}>
          <Title level={3} className={styles.title}>
            {privateChatToUser
              ? t("videoSession.sidebarChatTitlePrivate")
              : t("videoSession.sidebarChatTitle")}
          </Title>
          <ConditionalWrapper
            condition={matches.tabletLandscape}
            wrapper={(children: ReactNode) => (
              <Dropdown
                items={menu}
                placement="bottomRight"
                onClickMenuItem={handleOptionClick}
                theme={ThemeEnum.WHITE}
                overlayClassName={styles.dropdown}
                activeKey={
                  privateChatToUser
                    ? ChatTargetEnum.PRACTITIONER
                    : ChatTargetEnum.EVERYONE
                }
                activeClassName={styles.active}
              >
                {children as ReactElement}
              </Dropdown>
            )}
          >
            <Button
              endIcon={<IconChevronDown />}
              label={
                privateChatToUser
                  ? t("videoSession.chatTitlePractitioner")
                  : t("videoSession.chatTitleEveryone")
              }
              size={ButtonSizeEnum.TEXT}
              className={styles.button}
              buttonContentClassName={styles.buttonContent}
              onClick={handleToggleTarget}
            />
          </ConditionalWrapper>
        </div>
      )}
      <SidebarChatTab />
      <ChatActionModal
        visible={chatOptionsVisible}
        onClose={handleOptionsClose}
        title={t("videoSession.chatTargetTitle")}
      >
        {Object.values(ChatTargetEnum).map(target => (
          <button
            className={styles.option}
            key={target}
            onClick={() => setChatUserIdInRedux(target)}
          >
            <Subtitle level={2}>
              {t(`videoSession.chatTitle${_capitalize(target)}`)}
            </Subtitle>
          </button>
        ))}
      </ChatActionModal>
    </div>
  );
};

export default SidebarAttendee;
