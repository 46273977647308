import * as React from "react";

function SvgVolume2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 1.92V22.08l-1.625-1.3L6.65 17H2V7h4.65l4.725-3.78L13 1.92zm-2 4.16l-3.375 2.7L7.35 9H4v6h3.35l.275.22L11 17.92V6.08zm8.467 3.241a7 7 0 00-1.517-2.27l1.414-1.415a9 9 0 010 12.728L17.95 16.95a6.998 6.998 0 001.517-7.629zm-4.346.558a3 3 0 010 4.242l1.414 1.415a5 5 0 000-7.072L15.121 9.88z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgVolume2 = React.memo(SvgVolume2);
export default MemoSvgVolume2;
