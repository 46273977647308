import { AxiosResponse } from "axios";
import { generatePath } from "react-router-dom";

import { api } from "@app/api/api";
import { ClassesDef } from "@app/features/classes/classes";
import {
  ClassFeedbackDef,
  ClassForceDisconnectDef,
  SessionDef,
} from "@app/types/api.types";

import { SessionEndpointsEnum } from "../constants/session.endpoints";

const createToken = (sessionId: SessionDef["id"]): Promise<AxiosResponse> => {
  const url = generatePath(SessionEndpointsEnum.TOKENS, { sessionId });
  return api.post(url);
};

const getClass = (classId: string): Promise<AxiosResponse> => {
  const url = generatePath(SessionEndpointsEnum.CLASSES, { classId });
  return api.get(url);
};

const createFeedback = (
  classId: ClassesDef["id"],
  data: ClassFeedbackDef
): Promise<AxiosResponse> => {
  const url = generatePath(SessionEndpointsEnum.CLASSES_FEEDBACK, { classId });
  return api.post(url, data);
};

const forceDisconnect = (
  sessionId: SessionDef["id"],
  data: ClassForceDisconnectDef
): Promise<AxiosResponse> => {
  const url = generatePath(SessionEndpointsEnum.FORCE_DISCONNECT, {
    sessionId,
  });
  return api.patch(url, data);
};

export const sessionApi = {
  createToken,
  getClass,
  createFeedback,
  forceDisconnect,
};
