import React from "react";

import { useTranslation } from "react-i18next";

import {
  formatCurrency,
  PaymentSessionDef,
} from "@app/features/ecommerce/ecommerce";

interface CartSummaryProps {
  invoice: PaymentSessionDef["invoice"];
}

const CartSummary = ({ invoice }: CartSummaryProps) => {
  const { t } = useTranslation();

  const getItems = () => {
    if (invoice?.items) {
      return invoice.items.map(item => (
        <li className="snipcart-cart-summary-item" key={item.name}>
          <span className="snipcart-cart-summary-item__name snipcart__font--slim">
            {item.name}
          </span>
          <span className="snipcart-cart-summary-item__quantity snipcart__font--slim" />
          <span className="snipcart-cart-summary-item__price snipcart__font--slim">
            {formatCurrency(invoice.currency, item.amount)}
          </span>
        </li>
      ));
    }
    return null;
  };

  return (
    <div className="snipcart-cart-summary">
      <section className="snipcart-cart-summary__content">
        <div className="snipcart-cart-summary__actions snipcart__box--header">
          <h1 className="snipcart-cart-summary__title snipcart__font--subtitle">
            {t("snipcart.cartSummary")}
          </h1>
        </div>
        <ul className="snipcart-cart-summary-items-list snipcart-scrollbar snipcart-cart-summary__items">
          {getItems()}
        </ul>
        <hr className="snipcart-cart-summary__separator" />
        <div className="snipcart-loading-overlay">
          <div className="snipcart-loading-overlay__content">
            <div className="snipcart-cart-summary__totals">
              <div className="snipcart-cart-summary-fees">
                <div className="snipcart-cart-summary-fees">
                  <div className="snipcart-cart-summary-fees__item snipcart-cart-summary-fees__total snipcart__font--bold snipcart__font--secondary">
                    <span className="snipcart-cart-summary-fees__title snipcart-cart-summary-fees__title--highlight snipcart__font--large">
                      {t("snipcart.cartTotalLabel")}
                    </span>
                    <span className="snipcart-cart-summary-fees__amount snipcart-cart-summary-fees__amount--highlight snipcart__font--large">
                      {invoice.amount &&
                        formatCurrency(invoice.currency, invoice.amount)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="snipcart-cart-summary__footer">
        <div className="snipcart-featured-payment-methods snipcart-featured-payment-methods--no-background">
          <h3 className="snipcart__font--secondary snipcart__font--bold snipcart-featured-payment-methods__title">
            <a
              href="https://snipcart.com/security?utm_source=cart_referral&amp;utm_medium=powered_by&amp;utm_campaign=powered_by_ref&amp;utm_term=staging.api.kaylolife.com"
              target="_blank"
              rel="noopener noreferrer"
              className="snipcart-featured-payment-methods__link"
            >
              <svg
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="snipcart-featured-payment-methods__title-icon snipcart__icon"
              >
                <path
                  d="M16.615 23.765v-4.942C16.615 10.637 23.28 4 31.5 4c8.22 0 14.885 6.637 14.885 14.823v4.942H53V60H10V23.765h6.615zm0 3.294h-3.307v29.647h36.384V27.059H16.615zm13.231 9.882h3.308v9.883h-3.308V36.94zm-9.923-13.176h23.154v-4.942c0-6.367-5.183-11.529-11.577-11.529s-11.577 5.162-11.577 11.53v4.94z"
                  fill="#313749"
                />
              </svg>
              {t("snipcart.securedBySnipcart")}
            </a>
          </h3>
        </div>
      </footer>
    </div>
  );
};

export default CartSummary;
