import React, { memo } from "react";

import { useTranslation } from "react-i18next";

import { Caption, Subtitle } from "@app/components/atoms/Typography/Typography";

import styles from "./SidebarHeaderModerator.module.scss";

interface SidebarHeaderModeratorProps {
  title: string;
}

const SidebarHeaderModerator = memo(
  ({ title }: SidebarHeaderModeratorProps) => {
    const { t } = useTranslation();

    return (
      <div className={styles.header}>
        <Caption className={styles.title}>
          {t("videoSession.sidebarModeratorTitle")}
        </Caption>
        <Subtitle level={3}>{title}</Subtitle>
      </div>
    );
  }
);

export default SidebarHeaderModerator;
