/* eslint-disable @typescript-eslint/camelcase */
import TagManager from "react-gtm-module";

import { ClassesDef, getFirstExperience } from "@app/features/classes/classes";

import { getFullName } from "../../../helpers/util.helpers";
import { GtmEvents } from "../constants/gtm.constants";
import {
  GtmEcommerceItemsDef,
  GtmItemDef,
  GtmOrderDef,
  GtmPurchaseItemsDef,
} from "../types/gtm.types";

export function registerEvent(
  eventName: GtmEvents,
  properties?: Record<string, any>
) {
  TagManager.dataLayer({
    dataLayer: { event: eventName, ...(properties && { ...properties }) },
  });
}

export function getGtmItem(product: ClassesDef): GtmItemDef {
  return {
    item_id: product.id,
    item_name: product.name,
    item_category: getFirstExperience(product)?.name,
    item_type: product.type,
    item_owner: getFullName(product.practitioner),
    price: product.price,
    currency: "GBP", // FIXME: get from API
  };
}

export function getGtmEcommerceItems(
  products: GtmItemDef[]
): GtmEcommerceItemsDef {
  return { ecommerce: { items: products } };
}

export function getGtmPurchase(order: GtmOrderDef): GtmPurchaseItemsDef {
  return { ecommerce: { purchase: order } };
}
