import { SearchFacetFiltersEnum } from "../search";
import { SearchFiltersDef } from "../types/search.types";

/**
 * Values to populate search filters based on url params
 * @param {SearchFiltersDef} selected filters from url
 */
export const formValuesMapper = (selected: SearchFiltersDef) => {
  const fields = {};
  (Object.keys(selected) as SearchFacetFiltersEnum[]).forEach(filter => {
    (fields as any)[filter] = selected[filter];
  });
  return fields;
};
