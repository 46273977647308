import * as React from "react";

function SvgSendmessage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.315 3.298l-1.703-.789.296 1.854L4.126 12l-1.218 7.637-.296 1.854 1.703-.789 16.833-7.795 1.96-.907-1.96-.907L4.315 3.298zM5.992 11l-.814-5.098L18.348 12l-13.17 6.099L5.992 13H8l3-1-3-1H5.992z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgSendmessage = React.memo(SvgSendmessage);
export default MemoSvgSendmessage;
