import React, { memo } from "react";

import isArray from "lodash/isArray";
import { Col, Container, Row } from "react-grid-system";
import { useTranslation } from "react-i18next";

import { Paragraph, Title } from "@app/components/atoms/Typography/Typography";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import Section from "@app/components/layouts/Section/Section";
import { ThemeEnum } from "@app/constants/theme.constants";
import { PractitionerDef } from "@app/features/practitioners/practitioners";

import styles from "./PractitionerDetails.module.scss";

export interface PractitionerDetailsProps {
  quote?: string;
  facts?: PractitionerDef["funFacts"];
  credentialsShort?: string;
  credentialsFull?: string;
}

const PractitionerDetails = memo(
  ({
    quote,
    facts = [],
    credentialsShort,
    credentialsFull,
  }: PractitionerDetailsProps) => {
    const { t } = useTranslation();
    // const [showMore, setShowMore] = useState<boolean>(false);

    if (!quote && !facts && !credentialsFull && !credentialsShort) return null;

    return (
      <Section theme={ThemeEnum.KHAKI} className={styles.wrapper}>
        <ContentWrapper>
          <Container fluid>
            <Row justify="center" align="center">
              <Col xs={12} lg={1} xl={0} />
              <Col xs={12} lg={5} className={styles.quoteColumn}>
                {quote && (
                  <div className={styles.quoteWrapper}>
                    <Title level={2} className={styles.quote} isGoldGradient>
                      {quote}
                    </Title>
                  </div>
                )}
              </Col>
              <Col xs={12} lg={1} />
              <Col xs={12} lg={5}>
                {facts.length > 0 && (
                  <div className={styles.funFacts}>
                    <Title
                      level={3}
                      isGoldGradient
                      className={styles.sectionTitle}
                    >
                      {t("practitioner.funFactsTitle")}
                    </Title>
                    {isArray(facts) &&
                      facts.map(fact => (
                        <Paragraph level={2} key={fact.topic}>
                          {fact.topic} <span>{fact.fact}</span>
                        </Paragraph>
                      ))}
                  </div>
                )}

                <>
                  <Title
                    level={3}
                    isGoldGradient
                    className={styles.sectionTitle}
                  >
                    {t("practitioner.credentialsTitle")}
                  </Title>
                  <Paragraph level={2}>{credentialsFull}</Paragraph>
                </>
              </Col>
            </Row>
          </Container>
        </ContentWrapper>
      </Section>
    );
  }
);

export default PractitionerDetails;
