import { RouteItemDef } from "@app/types/route.types";

import { AuthPathsEnum } from "../constants/auth.paths";
import CreateSeekerScreen from "../screens/CreateSeekerScreen/CreateSeekerScreen";
import RequestResetPasswordScreen from "../screens/RequestResetPasswordScreen/RequestResetPasswordScreen";
import ResetPasswordScreen from "../screens/ResetPasswordScreen/ResetPasswordScreen";
import SignInScreen from "../screens/SignInScreen/SignInScreen";

const SIGN_IN_SCREEN: RouteItemDef = {
  id: "sign-in",
  path: AuthPathsEnum.SIGN_IN,
  component: SignInScreen,
  metaTitle: "signIn.metaTitle",
};

const CREATE_SEEKER_SCREEN: RouteItemDef = {
  id: "create-seeker",
  path: AuthPathsEnum.CREATE_SEEKER,
  component: CreateSeekerScreen,
  metaTitle: "createAccount.metaTitle",
};

const REQUEST_RESET_PASSWORD_SCREEN: RouteItemDef = {
  id: "request-reset-password",
  path: AuthPathsEnum.REQUEST_RESET_PASSWORD,
  component: RequestResetPasswordScreen,
  metaTitle: "requestResetPassword.metaTitle",
};

const RESET_PASSWORD_SCREEN: RouteItemDef = {
  id: "reset-password",
  path: AuthPathsEnum.RESET_PASSWORD,
  component: ResetPasswordScreen,
  metaTitle: "resetPassword.metaTitle",
};

const AUTH_ROUTES = [
  SIGN_IN_SCREEN,
  CREATE_SEEKER_SCREEN,
  REQUEST_RESET_PASSWORD_SCREEN,
  RESET_PASSWORD_SCREEN,
];

export default AUTH_ROUTES;
