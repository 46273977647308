import React, { memo } from "react";

import { useTranslation } from "react-i18next";

import Button, { ButtonTypeEnum } from "@app/components/atoms/Button/Button";
import { IllustrationMediumHexagon } from "@app/components/atoms/Illustrations/Illustrations";
import { Subtitle } from "@app/components/atoms/Typography/Typography";
import Section from "@app/components/layouts/Section/Section";
import { ThemeEnum } from "@app/constants/theme.constants";
import { SearchPathsEnum } from "@app/features/search/search";

import styles from "./NoSessions.module.scss";

interface NoSessionsProps {
  title: string;
}

const NoSessions = memo(({ title }: NoSessionsProps) => {
  const { t } = useTranslation();

  return (
    <Section theme={ThemeEnum.KHAKI} className={styles.wrapper}>
      <IllustrationMediumHexagon className={styles.image} />
      <Subtitle level={2}>{title}</Subtitle>
      <Button
        label={t("myBookings.noSessionsCtaLabel")}
        buttonType={ButtonTypeEnum.PRIMARY}
        className={styles.button}
        to={SearchPathsEnum.SEARCH}
      />
    </Section>
  );
});

export default NoSessions;
