import React, { memo } from "react";

import { useTranslation } from "react-i18next";

import BottomLinkItem, {
  BottomLinkItemProps,
} from "../BottomLinkItem/BottomLinkItem";
import styles from "./BottomLinks.module.scss";

export interface BottomLinksProps {
  items: BottomLinkItemProps[];
}
const BottomLinks = memo(({ items }: BottomLinksProps) => {
  const { t } = useTranslation();
  const d = new Date();
  const year = d.getFullYear();

  return (
    <ul className={styles.bottomLinks}>
      <BottomLinkItem
        label={
          <span>
            &copy; {year} {t("default.siteName")}
          </span>
        }
      />
      {items?.map(item => (
        <BottomLinkItem key={item.link} label={item.label} link={item.link} />
      ))}
    </ul>
  );
});

export default BottomLinks;
