import React, { memo } from "react";

import { useSelector } from "react-redux";

import Loading from "@app/components/atoms/Loading/Loading";
import { useSingleEntry } from "@app/features/contentful/contentful";
import { RootState } from "@app/redux/root-reducer";

import UserSessions from "./UserSessions";

interface UserSessionsContentfulProps {
  entryID: string;
}

const UserSessionsContentful = memo(
  ({ entryID }: UserSessionsContentfulProps) => {
    const { entry, loading: contentfulLoading } = useSingleEntry(entryID);
    const user = useSelector((state: RootState) => state.auth.user);
    if (!user) return null;

    return (
      <Loading isLoading={contentfulLoading}>
        {entry && <UserSessions firstName={user.firstName} />}
      </Loading>
    );
  }
);

export default UserSessionsContentful;
