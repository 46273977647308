import React, { memo, ReactNode } from "react";

import { Link } from "react-router-dom";

import { Caption } from "@app/components/atoms/Typography/Typography";

import styles from "./BottomLinkItem.module.scss";

export interface BottomLinkItemProps {
  label: string | ReactNode;
  link?: string;
}

const BottomLinkItem = memo(({ label, link }: BottomLinkItemProps) => {
  return (
    <li className={styles.bottomLink}>
      {link && link !== "" ? (
        <Link to={link} className={styles.link}>
          <Caption className={styles.label}>{label}</Caption>
        </Link>
      ) : (
        <Caption className={styles.label}>{label}</Caption>
      )}
    </li>
  );
});

export default BottomLinkItem;
