import * as React from "react";

function SvgMoon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 12 12" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.527 2.122a3.214 3.214 0 00-2.324 3.092A3.209 3.209 0 008.406 8.43a3.193 3.193 0 002.506-1.213A4.49 4.49 0 016.484 11 4.492 4.492 0 012 6.5a4.492 4.492 0 015.527-4.378z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgMoon = React.memo(SvgMoon);
export default MemoSvgMoon;
