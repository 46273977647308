import React, { memo, ReactNode } from "react";

import cx from "classnames";
import { Container, Row, Col } from "react-grid-system";
import { Link } from "react-router-dom";

import { ReactComponent as Logo } from "@app/assets/images/logo.svg";
import { ThemeEnum } from "@app/constants/theme.constants";

import ContentWrapper from "../ContentWrapper/ContentWrapper";
import Section from "../Section/Section";
import styles from "./BlankLayout.module.scss";

interface BlankLayoutProps {
  headerTheme?: ThemeEnum;
  contentTheme?: ThemeEnum;
  withHeader?: boolean;
  children?: ReactNode;
}

const BlankLayout = memo(
  ({
    children,
    headerTheme = ThemeEnum.KHAKI,
    contentTheme = ThemeEnum.WHITE,
    withHeader = true,
  }: BlankLayoutProps) => {
    return (
      <Section className={styles.container} theme={headerTheme}>
        {withHeader && (
          <header className={styles.header}>
            <ContentWrapper>
              <Container fluid>
                <Row
                  className={styles.headerWrapper}
                  justify="start"
                  align="center"
                >
                  <Col>
                    <Link to="/">
                      <Logo />
                    </Link>
                  </Col>
                </Row>
              </Container>
            </ContentWrapper>
          </header>
        )}
        <Section
          className={cx(styles.content, { [styles.withHeader]: withHeader })}
          theme={contentTheme}
        >
          {children}
        </Section>
      </Section>
    );
  }
);

export default BlankLayout;
