import React, { memo } from "react";

import { Document } from "@contentful/rich-text-types";

import Loading from "@app/components/atoms/Loading/Loading";
import { useSingleEntry } from "@app/features/contentful/contentful";

import RichText from "./RichText";

interface RichTextContentfulProps {
  entryID: string;
  wrapContent?: boolean;
}

interface RichTextDataModel {
  content: Document;
}

const RichTextContentful = memo(
  ({ entryID, wrapContent }: RichTextContentfulProps) => {
    const { entry, loading } = useSingleEntry<RichTextDataModel>(entryID);

    return (
      <Loading isLoading={loading}>
        {entry && <RichText text={entry.content} wrapContent={wrapContent} />}
      </Loading>
    );
  }
);

export default RichTextContentful;
