import React, { useState, memo } from "react";

import cx from "classnames";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Avatar from "@app/components/atoms/Avatar/Avatar";
import Button, { ButtonSizeEnum } from "@app/components/atoms/Button/Button";
import {
  IconDot,
  IconChevronUp,
  IconChevronDown,
} from "@app/components/atoms/Icon/Icon";
import {
  Caption,
  Paragraph,
  Subtitle,
  Title,
} from "@app/components/atoms/Typography/Typography";
import { ClassesDef } from "@app/features/classes/classes";
import { RootState } from "@app/redux/root-reducer";

import styles from "./SidebarHeaderAttendee.module.scss";

interface SidebarHeaderAttendeeProps {
  classType: ClassesDef;
}

const SidebarHeaderAttendee = memo(
  ({ classType }: SidebarHeaderAttendeeProps) => {
    const [hideHeader, setHideHeader] = useState(false);
    const { t } = useTranslation();
    const { practitioner } = classType;
    const { disableChat } = useSelector((state: RootState) => state.video);

    const handleToggleHeader = () => {
      setHideHeader(!hideHeader);
    };

    return (
      <div
        className={cx(styles.container, {
          [styles.hideHeader]: hideHeader,
          [styles.chatDisabled]: disableChat,
        })}
      >
        <div className={styles.header}>
          <div className={styles.headerContent}>
            <div className={styles.practitioner}>
              <Avatar
                avatarColor={0}
                avatarImage={practitioner.image}
                className={styles.practitionerAvatar}
              />
              <Subtitle level={3}>{practitioner.pseudonym}</Subtitle>
            </div>
            <Title level={2}>{classType.name}</Title>
            <div className={styles.time}>
              <Subtitle level={3}>
                {format(new Date(classType.startAt), "p")}
              </Subtitle>
              <IconDot />
              <Subtitle level={3}>
                {t("videoSession.sidebarHeaderDurationMinutes", {
                  value: classType.duration,
                })}
              </Subtitle>
            </div>
            <Caption className={styles.date}>
              {format(new Date(classType.startAt), "EEEE, do MMMM")}
            </Caption>
            <Paragraph level={2}>{classType.description}</Paragraph>
          </div>
        </div>
        {!disableChat && (
          <Button
            startIcon={hideHeader ? <IconChevronDown /> : <IconChevronUp />}
            label={
              hideHeader
                ? t("videoSession.sidebarHeaderShowMore")
                : t("videoSession.sidebarHeaderShowLess")
            }
            size={ButtonSizeEnum.TEXT}
            className={styles.button}
            buttonContentClassName={styles.buttonContent}
            onClick={handleToggleHeader}
            fullWidth
          />
        )}
      </div>
    );
  }
);

export default SidebarHeaderAttendee;
