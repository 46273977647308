import React, { memo, useState } from "react";

import { useTranslation } from "react-i18next";
import * as yup from "yup";

import FormItem from "@app/components/atoms/FormItem/FormItem";
import { Subtitle } from "@app/components/atoms/Typography/Typography";

import styles from "./SeekerAccountForm.module.scss";

export const seekerAccountValidation = yup.object({
  firstName: yup.string().required("authInputs.firstNameErrorRequired"),
  lastName: yup.string().required("authInputs.lastNameErrorRequired"),
  practitionerReferenceCode: yup.string(),
});

interface SeekerAccountFormProps {
  hasCodeError?: boolean;
}

const SeekerAccountForm = memo(({ hasCodeError }: SeekerAccountFormProps) => {
  const { t } = useTranslation();
  const [showReferralCode, setShowReferralCode] = useState(hasCodeError);

  return (
    <>
      <FormItem
        id="firstName"
        name="firstName"
        label={t("authInputs.firstNameLabel")}
        placeholder={t("authInputs.firstNamePlaceholder")}
      />
      <FormItem
        id="lastName"
        name="lastName"
        label={t("authInputs.lastNameLabel")}
        placeholder={t("authInputs.lastNamePlaceholder")}
      />
      {(!showReferralCode && (
        <Subtitle
          className={styles.link}
          level={3}
          isGold
          onClick={() => setShowReferralCode(true)}
        >
          {t("authInputs.referralCodeCtaLabel")}
        </Subtitle>
      )) || (
        <FormItem
          id="practitionerReferenceCode"
          name="practitionerReferenceCode"
          label={t("authInputs.referralCodeLabel")}
          placeholder={t("authInputs.referralCodePlaceholder")}
        />
      )}
    </>
  );
});

export default SeekerAccountForm;
