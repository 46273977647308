import * as React from "react";

function SvgArrowLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.509 11l3.455-3.274-1.376-1.452-5.276 5-.766.726.766.726 5.276 5 1.376-1.452L6.509 13H24v-2H6.509z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgArrowLeft = React.memo(SvgArrowLeft);
export default MemoSvgArrowLeft;
