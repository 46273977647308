import * as React from "react";

function SvgCrystal(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M3 10l7-7 7 7-7 7-7-7z" stroke="#D9B677" strokeWidth={1.5} />
    </svg>
  );
}

const MemoSvgCrystal = React.memo(SvgCrystal);
export default MemoSvgCrystal;
