import React, { memo, useRef } from "react";

import { FormikHelpers, FormikState } from "formik";
import _camelCase from "lodash/camelCase";
import { use100vh } from "react-div-100vh";
import { Col, Row } from "react-grid-system";
import { useTranslation } from "react-i18next";

import Button, { ButtonSizeEnum } from "@app/components/atoms/Button/Button";
import FormItem from "@app/components/atoms/FormItem/FormItem";
import { FormModal } from "@app/components/atoms/Modal/Modal";
import { Title } from "@app/components/atoms/Typography/Typography";
import {
  formValuesMapper,
  SearchFacetFiltersEnum,
  SearchFiltersDef,
} from "@app/features/search/search";

import { FiltersDef, getFilterLabel } from "../../SearchFilters";
import styles from "./FiltersModal.module.scss";

interface FiltersModalProps {
  visible: boolean;
  filters: Partial<FiltersDef>;
  selected: SearchFiltersDef;
  onClose: () => void;
  onApplyFilters: (values: SearchFiltersDef) => void;
}

const HEADER_OFFSET_PADDING = 96; // Inner padding with modal padding (16 + 80)
const MODAL_BUTTON_HEIGHT = 40;

const FiltersModal = memo(
  ({
    visible,
    filters,
    selected,
    onClose,
    onApplyFilters,
  }: FiltersModalProps) => {
    const { t } = useTranslation();
    const headerRef = useRef<HTMLDivElement>(null);
    const height = use100vh();
    const headerHeight = headerRef?.current?.clientHeight ?? 24;
    const contentHeight = height
      ? height - (headerHeight + HEADER_OFFSET_PADDING + MODAL_BUTTON_HEIGHT)
      : "100%";

    const handleSubmit = (values: SearchFiltersDef) => {
      onApplyFilters(values);
    };

    const handleSectionClear = (
      filterKey: string,
      setFieldValue: FormikHelpers<void>["setFieldValue"]
    ) => {
      setFieldValue(filterKey, []);
    };

    const handleClearAll = (
      values: FormikState<{}>["values"],
      setValues: FormikHelpers<{}>["setValues"]
    ) => {
      const fieldValues = {};
      Object.keys(values).forEach(filter => {
        (fieldValues as any)[filter] = [];
      });
      setValues(fieldValues);
    };

    return (
      <FormModal
        visible={visible}
        submitButtonLabel={t("search.applyButtonLabel")}
        showCloseButton
        className={styles.modal}
        formikProps={{
          enableReinitialize: true,
          initialValues: formValuesMapper(selected),
          onSubmit: handleSubmit,
        }}
        onClose={onClose}
      >
        {formProps => (
          <>
            <div className={styles.header} ref={headerRef}>
              <Title level={3}>{t("search.filtersModalTitle")}</Title>
              <Button
                size={ButtonSizeEnum.TEXT}
                label={t("search.clearAllButtonTitle")}
                className={styles.clearButton}
                onClick={() =>
                  handleClearAll(formProps.values, formProps.setValues)
                }
              />
            </div>
            <div className={styles.content} style={{ height: contentHeight }}>
              {(Object.keys(filters) as SearchFacetFiltersEnum[]).map(
                filterKey => (
                  <div key={filterKey} className={styles.filterGroup}>
                    <div className={styles.groupHeader}>
                      <Title level={2}>
                        {t(`search.${_camelCase(filterKey)}Label`)}
                      </Title>
                      <Button
                        size={ButtonSizeEnum.TEXT}
                        label={t("search.clearButtonTitle")}
                        className={styles.clearButton}
                        onClick={() =>
                          handleSectionClear(filterKey, formProps.setFieldValue)
                        }
                      />
                    </div>
                    <Row>
                      {filters[filterKey]?.map(filter => (
                        <Col lg={4} className={styles.option} key={filter.id}>
                          <FormItem
                            name={filterKey}
                            label={getFilterLabel(t, filterKey, filter.value)}
                            type="checkbox"
                            hideError
                            value={filter.id.toString()}
                          />
                        </Col>
                      ))}
                    </Row>
                  </div>
                )
              )}
            </div>
          </>
        )}
      </FormModal>
    );
  }
);

export default FiltersModal;
