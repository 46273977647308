import * as React from "react";

function SvgSuccess(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7 13l3 3 7-8" stroke="currentColor" strokeWidth={2} />
      <rect
        x={3}
        y={3}
        width={18}
        height={18}
        rx={9}
        stroke="currentColor"
        strokeWidth={2}
      />
    </svg>
  );
}

const MemoSvgSuccess = React.memo(SvgSuccess);
export default MemoSvgSuccess;
