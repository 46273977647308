import React from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { closeCart, resetPayment } from "@app/features/ecommerce/ecommerce";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";

import { ErrorModal } from "../Modal/Modal";

const PaymentErrorModal = () => {
  const { t } = useTranslation();
  const cartConfirmError = useSelector(
    (state: RootState) => state.payment.cartConfirmError
  );
  const history = useHistory();
  const dispatch = useAppDispatch();

  const handleErrorSubmit = () => {
    dispatch(resetPayment());
    history.push("/");
    setTimeout(() => {
      closeCart();
    }, 0);
  };

  return (
    <ErrorModal
      visible={cartConfirmError}
      title={t("errorMessages.orderPlaceErrorTitle")}
      subtitle={t("errorMessages.orderPlaceErrorSubTitle")}
      onConfirm={handleErrorSubmit}
      buttonLabel={t("default.backToHome")}
    />
  );
};

export default PaymentErrorModal;
