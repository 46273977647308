import React, { ChangeEvent, FC, memo, ReactNode } from "react";

import cx from "classnames";

import { FormFieldDef } from "@app/types/form.types";

import { Subtitle } from "../Typography/Typography";
import styles from "./RadioButton.module.scss";

export interface RadioButtonProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  field: FormFieldDef;
  label?: ReactNode | string;
  icon?: JSX.Element;
}

const RadioButton: FC<RadioButtonProps> = memo(
  ({ field, className, id, value, name, label, icon, ...rest }) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      field.onChange(e);
    };

    const getLabel = () => {
      return typeof label === "string" ? (
        <Subtitle level={3}>{label}</Subtitle>
      ) : (
        label
      );
    };

    return (
      <div
        className={cx(styles.wrapper, className, { [styles.hasIcon]: icon })}
      >
        <input
          className={styles.radio}
          type="radio"
          name={name}
          id={id}
          value={value}
          onChange={handleChange}
          {...rest}
        />
        <label className={styles.label} htmlFor={id}>
          {!!label && getLabel()}
          {icon && <span className={styles.radioIcon}>{icon}</span>}
        </label>
      </div>
    );
  }
);

export default RadioButton;
