import React, { useState, useEffect, useCallback, useMemo } from "react";

import cx from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useUnmount } from "react-use";

import { getRandomAvatarColor } from "@app/components/atoms/Avatar/Avatar";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import { ClassesDef } from "@app/features/classes/classes";
import { setShowFeedbackModal } from "@app/features/session/session";
import { RootState } from "@app/redux/root-reducer";

import { SessionDisconnectedReasonsEnum } from "../../constants/opentok.enums";
import { GridViewSettingsEnum } from "../../constants/video.enums";
import {
  getUserIdFromStream,
  isStreamModerator,
  getClassSessions,
} from "../../helpers/video.helper";
import { initializeSession, disconnect } from "../../helpers/video.session";
import useGridViewAttendees from "../../hooks/useGridViewAttendees";
import VideoLayout from "../../layouts/VideoLayout/VideoLayout";
import { setPagination } from "../../redux/video-settings.slice";
import { setAttendeesAvatarColors } from "../../redux/video.slice";
import {
  StreamCreatedEvent,
  StreamDestroyedEvent,
  SessionDisconnectedEvent,
} from "../../types/opentok.types";
import { VideoSessionUserDef } from "../../types/session.types";
import ModeratorChat from "../ModeratorChat/ModeratorChat";
import PublisherChat from "../PublisherChat/PublisherChat";
import SubscriberChat from "../SubscriberChat/SubscriberChat";
import VideoSidebar from "../VideoSidebar/VideoSidebar";
import VideoToolbarBottom from "../VideoToolbarBottom/VideoToolbarBottom";
import VideoToolbarTop from "../VideoToolbarTop/VideoToolbarTop";
import styles from "./VideoSession.module.scss";

interface VideoSessionProps {
  classType?: ClassesDef | null;
  token?: string | null;
  isUserModerator: boolean;
  hasError: boolean;
}

const VideoSession = ({
  classType,
  token,
  isUserModerator,
  hasError,
}: VideoSessionProps) => {
  // setUpdatedCount is used to re-render the component because the Vonage session and streams are non-serializable objects, mutated outside of React
  const [, setUpdatedCount] = useState(0);
  const [attendees, setAttendees] = useState<VideoSessionUserDef[]>([]);
  const [moderator, setModerator] = useState<VideoSessionUserDef | null>(null);
  const gridView = useSelector(
    (state: RootState) => state.videoSettings.gridView
  );
  const { page, perPage } = useSelector(
    (state: RootState) => state.videoSettings.pagination
  );
  const dispatch = useDispatch();

  const { mainSession } = useMemo(() => {
    return getClassSessions(classType ?? null);
  }, [classType]);

  useGridViewAttendees(isUserModerator);

  const firstPage = page === 1;

  useEffect(() => {
    // Current user + attendees + practitioner
    const totalAttendees = 1 + attendees.length + Number(Boolean(moderator));
    dispatch(
      setPagination({
        pagination: { totalAttendees },
      })
    );
  }, [attendees, dispatch, moderator]);

  const handleStreamCreated = useCallback(
    (event: StreamCreatedEvent) => {
      const isModerator = isStreamModerator(event.stream.connection.data);
      if (isModerator) {
        setModerator({
          stream: event.stream,
          user: classType?.practitioner,
        });
      } else {
        const foundAttendee = classType?.attendees?.find(
          attendee =>
            attendee.id === getUserIdFromStream(event.stream.connection.data)
        );

        if (foundAttendee) {
          const avatarColor = getRandomAvatarColor();
          dispatch(
            setAttendeesAvatarColors({ userId: foundAttendee.id, avatarColor })
          );
        }

        setAttendees(prevAttendees => [
          ...prevAttendees,
          {
            stream: event.stream,
            ...(foundAttendee && { user: foundAttendee }),
          },
        ]);
      }
    },
    [classType, dispatch]
  );

  const handleStreamDestroyed = (event: StreamDestroyedEvent) => {
    const isModerator = isStreamModerator(event.stream.connection.data);
    if (isModerator) {
      setModerator(null);
    } else {
      setAttendees(prevAttendees =>
        prevAttendees.filter(
          attendee => attendee.stream?.streamId !== event.stream.streamId
        )
      );
    }
  };

  const handleStreamPropertyChanged = () => {
    setUpdatedCount(prevCount => prevCount + 1);
  };

  const handleSessionDisconnected = useCallback(
    (event: SessionDisconnectedEvent) => {
      if (event.reason === SessionDisconnectedReasonsEnum.FORCE_DISCONNECTED) {
        dispatch(setShowFeedbackModal({ visible: true }));
      }
    },
    [dispatch]
  );

  const vonageSession = useMemo(
    () =>
      (!!mainSession &&
        !!token &&
        initializeSession({
          sessionId: mainSession.vonageSessionId,
          token,
          onStreamCreated: handleStreamCreated,
          onStreamDestroyed: handleStreamDestroyed,
          onStreamPropertyChanged: handleStreamPropertyChanged,
          onSessionDisconnected: handleSessionDisconnected,
        })) ||
      undefined,
    [handleSessionDisconnected, handleStreamCreated, mainSession, token]
  );

  useUnmount(() => {
    if (vonageSession) {
      disconnect(vonageSession);
    }
  });

  const showSubscriber = (subscriberIndex: number) => {
    if (perPage === 0) {
      return false;
    }
    const attendeesKeys = [...attendees.keys()];

    if (firstPage) {
      // subtract current user (and host) from first page
      const firstPagePerPage = isUserModerator ? perPage - 1 : perPage - 2;
      return attendeesKeys.slice(0, firstPagePerPage).includes(subscriberIndex);
    }
    const startIndex = perPage * page - perPage - (isUserModerator ? 1 : 2);
    const endIndex = startIndex + perPage;
    return attendeesKeys.slice(startIndex, endIndex).includes(subscriberIndex);
  };

  return (
    <VideoLayout
      isClassReady={!!classType}
      toolbar={<VideoToolbarTop isConnectedToSession={!!vonageSession} />}
      bottomToolbarRight={
        !!mainSession &&
        isUserModerator && (
          <VideoToolbarBottom
            sessionId={mainSession.id}
            attendees={attendees}
          />
        )
      }
      main={
        vonageSession ? (
          <div
            className={cx({
              [styles.bottomGridView]:
                gridView === GridViewSettingsEnum.BOTTOM_GRID_VIEW,
              [styles.fullGridView]:
                gridView === GridViewSettingsEnum.FULL_GRID_VIEW,
            })}
          >
            {!isUserModerator && (
              <ModeratorChat
                practitioner={moderator}
                session={vonageSession}
                isHidden={!firstPage}
              />
            )}

            <PublisherChat
              session={vonageSession}
              isUserModerator={isUserModerator}
              isHidden={!firstPage}
            />

            {attendees.map((attendee, index) => (
              <SubscriberChat
                key={attendee.stream.streamId}
                attendee={attendee}
                session={vonageSession}
                isHidden={!showSubscriber(index)}
              />
            ))}
          </div>
        ) : (
          !hasError && <LoadingSpinner className={styles.loading} />
        )
      }
      sidebar={
        classType && (
          <VideoSidebar
            attendees={attendees}
            classType={classType}
            isUserModerator={isUserModerator}
            hasError={!vonageSession || hasError}
          />
        )
      }
    />
  );
};

export default VideoSession;
