import * as React from "react";

function SvgChevronLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.276 7L2 12l5.276 5" stroke="currentColor" strokeWidth={2} />
    </svg>
  );
}

const MemoSvgChevronLeft = React.memo(SvgChevronLeft);
export default MemoSvgChevronLeft;
