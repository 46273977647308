import { memo, useEffect } from "react";

import { setConfiguration } from "react-grid-system";
import { useMedia } from "react-media";

import { GLOBAL_MEDIA_QUERIES, ScreenSizes } from "@app/constants/breakpoints";

const GridConfiguration = memo(() => {
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

  useEffect(() => {
    // settings for react-grid-system
    setConfiguration({
      // TODO: change react-grid-system as it does not support different gutterWidth for different screen sizes
      // this check only works on initial load, not on resize
      gutterWidth: matches.tabletLandscape ? 24 : 16,
      breakpoints: [
        ScreenSizes.SMALL,
        ScreenSizes.MEDIUM,
        ScreenSizes.LARGE,
        ScreenSizes.XLARGE,
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
});

export default GridConfiguration;
