import React, { useState, useMemo, useEffect } from "react";

import { OTError, Session } from "@opentok/client";
import { useSelector } from "react-redux";

import { RootState } from "@app/redux/root-reducer";

import { VIDEO_SUBSCRIBER_PROPERTIES } from "../../constants/video.properties";
import { VideoSessionUserDef } from "../../types/session.types";
import VideoChat from "../VideoChat/VideoChat";

interface SubscriberChatProps {
  session: Session;
  attendee: VideoSessionUserDef;
  onError?: (error?: OTError) => void;
  /** Hide the subscriber in view, but still subscribe to their audio */
  isHidden?: boolean;
}

const SubscriberChat = ({
  session,
  attendee,
  onError,
  isHidden = false,
}: SubscriberChatProps) => {
  const attendeesAvatarColors = useSelector(
    (state: RootState) => state.video.attendeesAvatarColors
  );
  const [divRef, setDivRef] = useState<HTMLDivElement | null>(null);

  const subscriber = useMemo(
    () =>
      divRef
        ? session.subscribe(
            attendee.stream,
            divRef,
            {
              ...VIDEO_SUBSCRIBER_PROPERTIES,
              insertMode: "replace",
              subscribeToVideo: !isHidden,
            },
            onError
          )
        : undefined,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [divRef]
  );

  useEffect(() => {
    subscriber?.subscribeToVideo(!isHidden);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHidden]);

  return (
    <VideoChat
      ref={setDivRef}
      name={attendee.user?.pseudonym ?? attendee.stream.name}
      showAudioToggle
      hasAudio={attendee.stream.hasAudio}
      hasVideo={attendee.stream.hasVideo}
      avatarColor={attendee.user && attendeesAvatarColors[attendee.user.id]}
      avatarImage={attendee.user?.image}
      avatarWithShadow
      isHidden={isHidden}
    />
  );
};

export default SubscriberChat;
