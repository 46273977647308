import { AxiosResponse } from "axios";

import { api } from "@app/api/api";

import { ArticlesEndpointsEnum } from "../constants/articles.endpoints";
import { ArticleDef } from "../types/articles.types";

const getArticleById = (id: ArticleDef["id"]): Promise<AxiosResponse> => {
  const url = `${ArticlesEndpointsEnum.ARTICLES}/${id}`;
  return api.get(url);
};

export const articlesApi = {
  getArticleById,
};
