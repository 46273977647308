import React, { memo } from "react";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Button, {
  ButtonTypeEnum,
  ButtonSizeEnum,
} from "@app/components/atoms/Button/Button";
import { IconArrowLeft } from "@app/components/atoms/Icon/Icon";
import { setShowFeedbackModal } from "@app/features/session/session";
import { RootState } from "@app/redux/root-reducer";
import { AppPathEnums } from "@app/routes/routes.constants";

import { GridViewSettingsEnum } from "../../constants/video.enums";
import styles from "./VideoToolbarTop.module.scss";
import ProfileControl from "./components/ProfileControl/ProfileControl";
import VideoPagination from "./components/VideoPagination/VideoPagination";

interface VideoToolbarTopProps {
  isConnectedToSession: boolean;
}

const VideoToolbarTop = memo(
  ({ isConnectedToSession: hasSessionStarted }: VideoToolbarTopProps) => {
    const { t } = useTranslation();
    const gridView = useSelector(
      (state: RootState) => state.videoSettings.gridView
    );
    const dispatch = useDispatch();
    const history = useHistory();

    const handleLeaveSession = () => {
      if (hasSessionStarted) {
        dispatch(setShowFeedbackModal({ visible: true }));
      } else {
        history.push(AppPathEnums.USER_HOME);
      }
    };

    return (
      <div className={styles.toolbar}>
        <Button
          buttonType={ButtonTypeEnum.SECONDARY}
          size={ButtonSizeEnum.TEXT}
          label={t("videoSession.leaveSessionButton")}
          startIcon={<IconArrowLeft className={styles.backButtonIcon} />}
          onClick={handleLeaveSession}
          hideLabelOnMobile
        />

        {gridView === GridViewSettingsEnum.FULL_GRID_VIEW && (
          <VideoPagination />
        )}

        <div>
          <ProfileControl />
        </div>
      </div>
    );
  }
);

export default VideoToolbarTop;
