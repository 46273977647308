import React, { memo } from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Caption } from "@app/components/atoms/Typography/Typography";

import styles from "./TermsAndConditions.module.scss";

const TermsAndConditions = memo(() => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <Caption className={styles.text}>
        {t("authTermsAndConditions.text")}
      </Caption>
      <Caption>
        <Link to={t("authTermsAndConditions.termsUrl")}>
          {t("authTermsAndConditions.termsAndConditionsLink")}
        </Link>
        &{" "}
        <Link to={t("authTermsAndConditions.privacyUrl")}>
          {t("authTermsAndConditions.privacyPolicyLink")}
        </Link>
      </Caption>
    </div>
  );
});

export default TermsAndConditions;
