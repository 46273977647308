import * as React from "react";

function SvgYogaMat(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={7.5}
        cy={16.5}
        r={3.5}
        stroke="currentColor"
        strokeWidth={2}
      />
      <path
        d="M4 5.5A3.5 3.5 0 017.5 2v0A3.5 3.5 0 0111 5.5v11A3.5 3.5 0 017.5 20v0A3.5 3.5 0 014 16.5v-11z"
        stroke="currentColor"
        strokeWidth={2}
      />
      <path d="M12 8h8v12H8" stroke="currentColor" strokeWidth={2} />
    </svg>
  );
}

const MemoSvgYogaMat = React.memo(SvgYogaMat);
export default MemoSvgYogaMat;
