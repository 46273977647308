import React, { memo } from "react";

import { Document } from "@contentful/rich-text-types";

import { useSingleEntry } from "@app/features/contentful/contentful";
import { getPublishedData } from "@app/helpers/util.helpers";
import { ContentfulMedia } from "@app/types/contentful.types";
import { FaqSectionDef } from "@app/types/faq.types";

import FaqContainer from "./FaqContainer";

interface FaqContainerContentfulProps {
  entryID: string;
}

interface FaqQuestionModel {
  fields: {
    question: string;
    answer: Document;
  };
  sys: {
    id: string;
  };
}

interface FaqSectionModel {
  fields: {
    faqSection: string;
    questions: FaqQuestionModel[];
  };
  sys: {
    id: string;
  };
}

interface FaqContainerContentModel {
  title: string;
  subtitle: string;
  faqSections: FaqSectionModel[];
  headerBackground: ContentfulMedia;
}

const FaqContainerContentful = memo(
  ({ entryID }: FaqContainerContentfulProps) => {
    const { entry } = useSingleEntry<FaqContainerContentModel>(entryID);

    if (!entry) return null;

    const questionsMapper = (
      questions: FaqQuestionModel[]
    ): FaqSectionDef["entries"] => {
      return getPublishedData(questions)?.map(question => ({
        id: question?.sys.id,
        question: question?.fields?.question,
        answer: question?.fields?.answer,
      }));
    };

    const getFaqSections = (): FaqSectionDef[] => {
      return getPublishedData(entry.faqSections)?.map(section => ({
        id: section?.sys.id,
        title: section?.fields.faqSection,
        entries: questionsMapper(section?.fields?.questions),
      }));
    };

    return (
      <FaqContainer
        title={entry.title}
        subtitle={entry.subtitle}
        background={entry?.headerBackground.fields.file.url}
        sections={getFaqSections()}
      />
    );
  }
);

export default FaqContainerContentful;
