import React from "react";

import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Avatar, { AvatarSize } from "@app/components/atoms/Avatar/Avatar";
import { Caption, Subtitle } from "@app/components/atoms/Typography/Typography";
import { RootState } from "@app/redux/root-reducer";
import { ClassCommentDef } from "@app/types/api.types";

import ChatActionModal from "../ChatActionModal/ChatActionModal";
import styles from "./CommentModal.module.scss";

interface CommentModalProps {
  visible: boolean;
  comment: ClassCommentDef | null;
  onClose: () => void;
  onDeleteComment: () => void;
}

const CommentModal = ({
  comment,
  visible,
  onClose,
  onDeleteComment,
}: CommentModalProps) => {
  const { t } = useTranslation();
  const { attendeesAvatarColors } = useSelector(
    (state: RootState) => state.video
  );

  return (
    <ChatActionModal
      visible={visible}
      title={t("videoSession.commentOptionTitle")}
      onClose={onClose}
    >
      {comment && (
        <>
          <div className={styles.commentContainer}>
            <div className={styles.user}>
              <Avatar
                avatarColor={attendeesAvatarColors[comment.fromUser.id]}
                avatarImage={comment.fromUser.image}
                className={styles.avatar}
                avatarSize={AvatarSize.SMALL}
              />
              <Subtitle level={3}>{comment.fromUser.pseudonym}</Subtitle>
            </div>
            <div>
              <Caption>{format(new Date(comment.createdAt), "HH:mm")}</Caption>
            </div>
            <div className={styles.comment}>
              <Caption>{comment.message}</Caption>
            </div>
          </div>
          <button className={styles.delete} onClick={onDeleteComment}>
            <Subtitle level={2} className={styles.deleteText}>
              {t("default.delete")}
            </Subtitle>
          </button>
        </>
      )}
    </ChatActionModal>
  );
};

export default CommentModal;
