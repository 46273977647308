import React, { memo } from "react";

import DefaultLayout from "@app/components/layouts/DefaultLayout/DefaultLayout";
import Section from "@app/components/layouts/Section/Section";
import PractitionerDetails from "@app/components/renderings/PractitionerDetails/PractitionerDetails";
import PractitionerExperience from "@app/components/renderings/PractitionerExperience/PractitionerExperience";
import PractitionerProfile from "@app/components/renderings/PractitionerProfile/PractitionerProfile";
import QuotesCarousel from "@app/components/renderings/QuotesCarousel/QuotesCarousel";
import { IMAGE_TYPES } from "@app/constants/image";
import { ThemeEnum } from "@app/constants/theme.constants";
import { ExperienceDef } from "@app/features/experiences/experiences";
import { PractitionerDef } from "@app/features/practitioners/practitioners";
import { getImageUrl } from "@app/helpers/image.helpers";
import { ContentfulMedia } from "@app/types/contentful.types";
import { TestimonialDef } from "@app/types/testimonials.types";

export type ExperienceField = {
  fields: {
    name: string;
  };
  sys: {
    id: number;
  };
};

type FunField = {
  fields: {
    fact: string;
    topic: string;
  };
  sys: {
    id: number;
  };
};

export type TestimonialField = {
  fields: {
    author: string;
    quote: string;
    title: string;
  };
  sys: {
    id: number;
  };
};

export type PractitionerContentModel = Pick<
  PractitionerDef,
  | "firstName"
  | "lastName"
  | "description"
  | "shortDescription"
  | "credentials"
  | "shortCredentials"
  | "approach"
> & {
  personalQuote: string;
  image: ContentfulMedia;
  media: ContentfulMedia[];
  experiences: ExperienceField[];
  funFacts: FunField[];
  testimonials: TestimonialField[];
};

interface PractitionerPreviewProps {
  data: PractitionerContentModel;
}

export const mapExperiences = (
  experiences: ExperienceField[]
): ExperienceDef[] => {
  return experiences?.map(experience => ({
    id: experience.sys.id,
    name: experience.fields.name,
  }));
};

export const mapTestimonials = (
  testimonials: TestimonialField[]
): TestimonialDef[] => {
  return testimonials?.map(testimonial => ({
    id: testimonial.sys.id,
    ...testimonial.fields,
  }));
};

const PractitionerPreview = memo(
  ({ data: practitioner }: PractitionerPreviewProps) => {
    if (!practitioner) {
      return null;
    }

    const getImage = () => {
      return (
        practitioner.image?.fields?.file?.url &&
        getImageUrl(
          practitioner.image.fields.file.url,
          IMAGE_TYPES.PRACTITIONER
        )
      );
    };

    const mapFacts = (facts: FunField[]): PractitionerDef["funFacts"] => {
      return facts?.map(funFact => ({
        fact: funFact.fields.fact,
        topic: funFact.fields.topic,
      }));
    };

    const mapMedia = (medias: ContentfulMedia[]): PractitionerDef["media"] => {
      return medias?.map(media => ({
        url: media.fields.file.url,
      }));
    };

    return (
      <DefaultLayout>
        <Section theme={ThemeEnum.KHAKI}>
          <PractitionerProfile
            image={getImage()}
            firstName={practitioner.firstName}
            lastName={practitioner.lastName}
            shortDescription={practitioner.shortDescription}
            fullDescription={practitioner.description}
            experiences={mapExperiences(practitioner.experiences)}
          />
          <PractitionerExperience
            media={mapMedia(practitioner.media)}
            body={practitioner.approach}
          />
          <PractitionerDetails
            quote={practitioner.personalQuote}
            facts={mapFacts(practitioner.funFacts)}
            credentialsShort={practitioner.shortCredentials}
            credentialsFull={practitioner.credentials}
          />
          <QuotesCarousel
            theme={ThemeEnum.RED_ON_ORANGE}
            slideItems={mapTestimonials(practitioner.testimonials)}
          />
        </Section>
      </DefaultLayout>
    );
  }
);

export default PractitionerPreview;
