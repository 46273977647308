import { EXPERIENCE_PATH } from "@app/features/experiences/experiences";

import { convertToSlug } from "./util.helpers";

/**
 * Get experience link and append slug if present
 * @param {string} id id of experience from api
 * @param {string} slug slug url if present
 */
export const getExperienceLink = (id: number, slug?: string) => {
  let link = "";
  link = `${EXPERIENCE_PATH}/${id}`;

  if (slug) {
    link = `${link}/${convertToSlug(slug)}`;
  }

  return link;
};
