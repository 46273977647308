import React, { useCallback, useEffect, useMemo, useState } from "react";

import cx from "classnames";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-media";
import { useSelector } from "react-redux";

import Avatar, { AvatarSize } from "@app/components/atoms/Avatar/Avatar";
import Button, { ButtonSizeEnum } from "@app/components/atoms/Button/Button";
import Confirmable from "@app/components/atoms/Confirmable/Confirmable";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import {
  Caption,
  Paragraph,
  Subtitle,
} from "@app/components/atoms/Typography/Typography";
import { GLOBAL_MEDIA_QUERIES } from "@app/constants/breakpoints";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";
import { ClassCommentDef } from "@app/types/api.types";

import { getClassSessions } from "../../../../../../helpers/video.helper";
import {
  deleteCommentById,
  getComments,
} from "../../../../../../redux/video.slice";
import styles from "./ChatList.module.scss";
import CommentModal from "./components/CommentModal/CommentModal";

const GET_COMMENTS_TIMER = 5000; // 5 sec

interface ChatListProps {
  isModerator?: boolean;
}

const ChatList = ({ isModerator }: ChatListProps) => {
  const { t } = useTranslation();
  const classType = useSelector(
    (state: RootState) => state.sessionClass.classType
  );
  const {
    messages,
    attendeesAvatarColors,
    loading,
    privateChatToUser,
    disableChat,
  } = useSelector((state: RootState) => state.video);
  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch = useAppDispatch();
  const [divRef, setDivRef] = useState<HTMLDivElement | null>(null);
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const [activeComment, setActiveComment] = useState<ClassCommentDef | null>(
    null
  );
  const [commentModalVisible, setCommentModalVisible] = useState(false);

  const { mainSession } = useMemo(() => getClassSessions(classType), [
    classType,
  ]);

  const getChatComments = useCallback(() => {
    mainSession &&
      dispatch(
        getComments({
          sessionId: mainSession.id,
          ...(privateChatToUser &&
            user && {
              filters: {
                fromUserId: user.id,
                toUserId: privateChatToUser.id,
              },
            }),
        })
      );
  }, [dispatch, mainSession, privateChatToUser, user]);

  useEffect(() => {
    getChatComments();

    const interval = setInterval(() => {
      getChatComments();
    }, GET_COMMENTS_TIMER);

    return () => clearInterval(interval);
  }, [dispatch, getChatComments, mainSession]);

  // Scrolls down:
  // - on first load
  // - when a new message is added
  useEffect(() => {
    divRef?.scrollIntoView({ behavior: "smooth" });
  }, [divRef, messages.length]);

  const handleCommentModalClose = () => {
    setActiveComment(null);
    setCommentModalVisible(false);
  };

  const handleDeleteComment = (id?: ClassCommentDef["id"]) => {
    if (id) {
      dispatch(deleteCommentById(id)).then(() => {
        getChatComments();
        handleCommentModalClose();
      });
    }
  };

  const handleCommentClick = (comment: ClassCommentDef) => {
    if (!matches.desktopSmall && isModerator) {
      setActiveComment(comment);
      setCommentModalVisible(true);
    }
  };

  return (
    <div className={styles.content}>
      {!disableChat &&
        messages.map(comment => (
          <div
            className={cx(styles.userContainer, {
              [styles.hoverable]: isModerator && matches.desktopSmall,
            })}
            key={comment.id}
            onClick={() => handleCommentClick(comment)}
            role="presentation"
          >
            <div className={styles.user}>
              <Avatar
                avatarColor={attendeesAvatarColors[comment.fromUser.id]}
                avatarImage={comment.fromUser.image}
                className={styles.avatar}
                avatarSize={AvatarSize.SMALL}
              />
              <Subtitle level={3}>{comment.fromUser.pseudonym}</Subtitle>
            </div>
            <div>
              <Caption className={styles.date}>
                {format(new Date(comment.createdAt), "HH:mm")}
              </Caption>
              <Confirmable
                modalBodyText={t("videoSession.deleteCommentConfirm")}
                onConfirm={() => handleDeleteComment(comment.id)}
              >
                <Button
                  size={ButtonSizeEnum.TEXT}
                  label={t("default.delete")}
                  className={styles.delete}
                />
              </Confirmable>
            </div>
            <div className={styles.comment}>
              <Caption>{comment.message}</Caption>
            </div>
          </div>
        ))}
      {!messages.length && privateChatToUser && (
        <div className={styles.noChat}>
          <Paragraph className={styles.privatePlaceholder} level={2}>
            {isModerator
              ? t("videoSession.privateChatPractitionerPlaceholder", {
                  name: privateChatToUser.firstName,
                })
              : t("videoSession.privateChatPlaceholder")}
          </Paragraph>
        </div>
      )}
      <div ref={setDivRef} />
      <LoadingSpinner
        isSmall
        className={cx(styles.loading, { [styles.loadingHide]: !loading })}
      />
      <CommentModal
        comment={activeComment}
        visible={commentModalVisible && !!activeComment}
        onClose={handleCommentModalClose}
        onDeleteComment={() => handleDeleteComment(activeComment?.id)}
      />
    </div>
  );
};

export default ChatList;
