import * as React from "react";

function SvgEndcall(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.894 7a14.35 14.35 0 0111.836 6.196l.037.033-.008.009.054.08-.317.213-2.493 2.781-.6.67-.73-.526-3.974-2.863-.561-.404.18-.667.755-2.804a12.351 12.351 0 00-8.365.023l.75 2.78.179.668-.56.404L4.1 16.456l-.729.526-.6-.67-2.451-2.734L0 13.363l.055-.082-.047-.052.222-.2A14.35 14.35 0 0111.894 7zm6.025 3.554l-.49 1.82 2.684 1.934 1.041-1.16a12.35 12.35 0 00-3.235-2.594zM2.655 13.186a12.35 12.35 0 013.21-2.599l.481 1.787-2.685 1.934-1.006-1.122z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgEndcall = React.memo(SvgEndcall);
export default MemoSvgEndcall;
