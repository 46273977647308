import * as React from "react";

function SvgDelete(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.268 7a2 2 0 013.464 0h-3.464zM8.126 7a4.002 4.002 0 017.748 0H20v2h-2v11H6V9H4V7h4.126zM8 18V9h8v9H8z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgDelete = React.memo(SvgDelete);
export default MemoSvgDelete;
