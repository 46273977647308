import React, { memo, useState, useRef } from "react";

import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-media";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useClickAway } from "react-use";

import Avatar from "@app/components/atoms/Avatar/Avatar";
import { Subtitle, Title } from "@app/components/atoms/Typography/Typography";
import { GLOBAL_MEDIA_QUERIES } from "@app/constants/breakpoints";
import { logout } from "@app/features/auth/auth";
import { UserPathsEnum } from "@app/features/users/users";
import { RootState } from "@app/redux/root-reducer";

import styles from "./NavigationProfile.module.scss";

const NavigationProfile = memo(() => {
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const menuRef = useRef(null);
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

  const user = useSelector((state: RootState) => state.auth.user);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = async () => {
    await dispatch(logout());
    history.replace("/");
  };

  useClickAway(menuRef, () => {
    setOpenMenu(false);
  });

  return (
    <button
      ref={menuRef}
      className={styles.profile}
      onClick={() => setOpenMenu(!openMenu)}
    >
      <div className={styles.user}>
        <Subtitle className={styles.userName} level={3} isGold>
          {user?.firstName}
        </Subtitle>
        <Avatar avatarImage={user?.image} />
      </div>
      <div className={cx(styles.menuWrapper, { [styles.opened]: openMenu })}>
        <ul className={styles.menu}>
          <li className={styles.menuItem}>
            <Link to={UserPathsEnum.MY_BOOKINGS} className={styles.link}>
              {matches.tabletLandscape ? (
                <Subtitle level={3}>{t("default.myBookings")}</Subtitle>
              ) : (
                <Title level={3}>{t("default.myBookings")}</Title>
              )}
            </Link>
          </li>
          {/* TODO: Add account settings */}
          {/* <li className={styles.menuItem}>
            {matches.tabletLandscape ? (
              <Subtitle level={3}>{t("default.accountSettings")}</Subtitle>
            ) : (
              <Title level={3}>{t("default.accountSettings")}</Title>
            )}
          </li> */}
          <li className={styles.menuItem}>
            {matches.tabletLandscape ? (
              <Subtitle level={3} onClick={handleLogout}>
                {t("default.signOut")}
              </Subtitle>
            ) : (
              <Title level={3} onClick={handleLogout}>
                {t("default.signOut")}
              </Title>
            )}
          </li>
        </ul>
      </div>
    </button>
  );
});

export default NavigationProfile;
