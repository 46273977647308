import React, { memo } from "react";

import { ButtonTypeEnum } from "@app/components/atoms/Button/Button";
import { ThemeEnum, AlignmentSimpleEnum } from "@app/constants/theme.constants";
import { useSingleEntry } from "@app/features/contentful/contentful";
import { ContentfulMedia } from "@app/types/contentful.types";

import Topic from "./Topic";

interface TopicContentfulProps {
  entryID: string;
}

interface TopicDataModel {
  theme: string;
  alignment: string;
  ctaLabel: string;
  ctaLink: string;
  ctaType: string;
  description: string;
  image: ContentfulMedia;
  title: string;
}

const TopicContentful = memo(({ entryID }: TopicContentfulProps) => {
  const { entry: topicData } = useSingleEntry(entryID);

  if (!topicData) return null;

  const {
    theme,
    alignment,
    ctaLabel,
    ctaLink,
    ctaType,
    description,
    image,
    title,
  } = topicData;

  return (
    <Topic
      theme={theme as ThemeEnum}
      alignment={alignment as AlignmentSimpleEnum}
      title={title}
      description={description}
      image={image.fields.file.url}
      CTALabel={ctaLabel}
      CTALink={ctaLink}
      CTAType={ctaType as ButtonTypeEnum}
    />
  );
});

export default TopicContentful;
