import * as React from "react";

function SvgChat(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 3h18v15H9.277l-4.762 2.858L3 21.766V3zm2 2v13.234l3.486-2.092.237-.142H19V5H5zm12 8H7v-2h10v2zM7 9h10V7H7v2z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgChat = React.memo(SvgChat);
export default MemoSvgChat;
