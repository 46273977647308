import * as React from "react";

function SvgMoonOutline(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M11.629 3.19l.205.722a.75.75 0 00-.032-1.451l-.173.73zm5.283 7.925l.74.118a.75.75 0 00-1.327-.586l.587.467zM8.75 8a4.252 4.252 0 013.084-4.088l-.41-1.443A5.752 5.752 0 007.25 8h1.5zM13 12.25A4.25 4.25 0 018.75 8h-1.5A5.75 5.75 0 0013 13.75v-1.5zm3.325-1.603A4.24 4.24 0 0113 12.25v1.5a5.74 5.74 0 004.498-2.168l-1.173-.935zm-.154.349A6.252 6.252 0 0110 16.25v1.5a7.752 7.752 0 007.652-6.517l-1.48-.237zM10 16.25A6.25 6.25 0 013.75 10h-1.5A7.75 7.75 0 0010 17.75v-1.5zM3.75 10A6.25 6.25 0 0110 3.75v-1.5A7.75 7.75 0 002.25 10h1.5zM10 3.75c.502 0 .989.059 1.455.17l.347-1.46A7.769 7.769 0 0010 2.25v1.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgMoonOutline = React.memo(SvgMoonOutline);
export default MemoSvgMoonOutline;
