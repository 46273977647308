import React, { memo, useState, useEffect } from "react";

import cx from "classnames";
import { Helmet } from "react-helmet";
import { useMedia } from "react-media";

import CircularButton from "@app/components/atoms/CircularButton/CircularButton";
import {
  IconShowSessionInfo,
  IconFullScreen,
  IconShowSessionInfoMobile,
} from "@app/components/atoms/Icon/Icon";
import { GLOBAL_MEDIA_QUERIES } from "@app/constants/breakpoints";

import styles from "./VideoLayout.module.scss";

interface VideoLayoutProps {
  toolbar: React.ReactNode;
  main: React.ReactNode;
  bottomToolbarRight?: React.ReactNode;
  sidebar: React.ReactNode;
  isClassReady: boolean;
}

export const bottomToolbarLeftElement = "bottom-toolbar-left";

const VideoLayout = memo(
  ({
    toolbar,
    main,
    bottomToolbarRight,
    sidebar,
    isClassReady,
  }: VideoLayoutProps) => {
    const [isFullscreen, setIsFullscreen] = useState(true);
    const { tabletLandscape } = useMedia({
      queries: GLOBAL_MEDIA_QUERIES,
    });

    useEffect(() => {
      if (isClassReady) {
        setIsFullscreen(false);
      }
    }, [isClassReady]);

    const handleFullscreen = () => {
      setIsFullscreen(prevIsFullscreen => !prevIsFullscreen);
    };

    const getShowSessionIcon = () => {
      return tabletLandscape ? (
        <IconShowSessionInfo />
      ) : (
        <IconShowSessionInfoMobile />
      );
    };

    return (
      <div
        className={cx(styles.container, {
          [styles.isFullscreen]: isFullscreen,
        })}
      >
        <Helmet>
          <body className={styles.fullBody} />
        </Helmet>
        <div className={styles.content}>
          <div className={styles.toolbar}>{toolbar}</div>
          <div className={styles.main}>{main}</div>
          <div className={styles.bottomToolbar}>
            <div id={bottomToolbarLeftElement} />
            <div>
              {bottomToolbarRight}
              {isClassReady && (
                <CircularButton isFloating onClick={handleFullscreen}>
                  {isFullscreen ? getShowSessionIcon() : <IconFullScreen />}
                </CircularButton>
              )}
            </div>
          </div>
        </div>
        <div className={styles.sidebar}>{sidebar}</div>
      </div>
    );
  }
);

export default VideoLayout;
