import React, { FC, memo } from "react";

import cx from "classnames";

import { FormFieldDef } from "@app/types/form.types";

import { Subtitle } from "../Typography/Typography";
import styles from "./Textarea.module.scss";

export interface TextareaProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  field: FormFieldDef;
  label?: string;
}
const Textarea: FC<TextareaProps> = memo(
  ({ field, className, label, ...rest }) => {
    return (
      <>
        {label && (
          <label htmlFor={field.name} className={styles.label}>
            <Subtitle level={3}>{label}</Subtitle>
          </label>
        )}
        <textarea
          {...field}
          className={cx(styles.textarea, className)}
          {...(rest as JSX.IntrinsicElements["textarea"])}
        />
      </>
    );
  }
);

export default Textarea;
