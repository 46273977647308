import { AxiosResponse } from "axios";

import { api } from "@app/api/api";

import { SearchEndpointsEnum } from "../constants/search.endpoints";
import { SearchFiltersDef } from "../types/search.types";

const searchContent = (params?: SearchFiltersDef): Promise<AxiosResponse> => {
  return api.get(SearchEndpointsEnum.SEARCH, {
    params: {
      ...params,
    },
  });
};

const getSearchFilters = (): Promise<AxiosResponse> => {
  return api.get(SearchEndpointsEnum.SEARCH_FILTERS);
};

export const searchApi = { searchContent, getSearchFilters };
