import React from "react";

import { useLocation } from "react-router-dom";

import Loading from "@app/components/atoms/Loading/Loading";
import BlankLayout from "@app/components/layouts/BlankLayout/BlankLayout";
import DefaultLayout from "@app/components/layouts/DefaultLayout/DefaultLayout";
import StaticPageLoader from "@app/components/renderings/StaticPageLoader/StaticPageLoader";
import { ContentModel } from "@app/constants/contentful.constants";
import { LayoutsEnum } from "@app/constants/layout";
import { useEntriesByType } from "@app/features/contentful/contentful";
import useMetaTitle from "@app/hooks/useMetaTitle";

import NoMatch from "../NoMatch/NoMatch";

interface StaticPageModel {
  fields: {
    title: string;
    titleMetaTag?: string | null;
    pageUrl: string;
    layout: LayoutsEnum;
  };
  sys: {
    id: string;
  };
}

const StaticPage = () => {
  const location = useLocation();
  const { entries: staticPages, loading } = useEntriesByType<StaticPageModel[]>(
    ContentModel.STATIC_PAGE
  );

  const validPage = staticPages?.find(
    page => page.fields?.pageUrl === location.pathname
  );
  useMetaTitle(validPage?.fields.titleMetaTag ?? validPage?.fields.title);

  const Layout =
    validPage?.fields?.layout === LayoutsEnum.BLANK
      ? BlankLayout
      : DefaultLayout;

  return (
    <Loading isLoading={loading}>
      {validPage ? (
        <Layout>
          <StaticPageLoader contentfulId={validPage.sys?.id} />
        </Layout>
      ) : (
        <NoMatch />
      )}
    </Loading>
  );
};

export default StaticPage;
