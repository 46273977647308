import * as React from "react";

function SvgHelp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 12a8 8 0 11-16 0 8 8 0 0116 0zm2 0c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-9.802 1.464h-1.053a.247.247 0 01-.247-.247v-.546c0-.665.523-1.107 1.007-1.516.415-.35.8-.677.8-1.097 0-.468-.39-.871-1.04-.871-.615 0-.964.305-1.211.52-.072.063-.135.118-.193.156-.104.065-.195.065-.286-.013l-.611-.598c-.104-.091-.091-.247 0-.338 0 0 .884-1.144 2.548-1.144 1.365 0 2.522.91 2.522 2.184 0 .923-.68 1.461-1.243 1.906-.396.313-.733.58-.733.902v.429c0 .156-.091.273-.26.273zm-.533 3.666a1.07 1.07 0 01-1.066-1.066c0-.585.481-1.079 1.066-1.079.585 0 1.079.494 1.079 1.079s-.494 1.066-1.079 1.066z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgHelp = React.memo(SvgHelp);
export default MemoSvgHelp;
