import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { paymentApi } from "../api/payment.api";
import {
  ConfirmSnipcartPaymentDef,
  CreatePaymentIntentDef,
  PaymentCardDef,
  PaymentSessionDef,
} from "../types/payment.types";

interface ProductDetailState {
  paymentSession: PaymentSessionDef | null;
  paymentCards: PaymentCardDef[];
  loading: boolean;
  loadingCards: boolean;
  loadingIntent: boolean;
  error: boolean;
  cartConfirmError: boolean;
  errorIntent: boolean;
  clientSecret: string | null;
  paymentIntentId: string | null;
}

const initialState: ProductDetailState = {
  paymentSession: null,
  loading: true,
  loadingCards: true,
  loadingIntent: true,
  error: false,
  cartConfirmError: false,
  errorIntent: false,
  paymentCards: [],
  clientSecret: null,
  paymentIntentId: null,
};

export const getPaymentSession = createAsyncThunk(
  "payment/getPaymentSession",
  async (publicToken: string, { rejectWithValue }) => {
    try {
      const response = await paymentApi.getPaymentSession(publicToken);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data as string);
    }
  }
);

export const getPaymentCards = createAsyncThunk(
  "payment/getPaymentCards",
  async (_, { rejectWithValue }) => {
    try {
      const response = await paymentApi.getPaymentCards();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data as string);
    }
  }
);

export const createPaymentIntent = createAsyncThunk(
  "payment/createPaymentIntent",
  async (payload: CreatePaymentIntentDef, { rejectWithValue }) => {
    try {
      const response = await paymentApi.createPaymentIntent(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data as string);
    }
  }
);

export const confirmSnipcartPayment = createAsyncThunk(
  "payment/confirmSnipcartPayment",
  async (payload: ConfirmSnipcartPaymentDef, { rejectWithValue }) => {
    try {
      const response = await paymentApi.confirmSnipcartPayment(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data as string);
    }
  }
);

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    resetPayment: () => initialState,
    setSnipcartError(state) {
      state.cartConfirmError = true;
    },
  },
  extraReducers: builder => {
    builder.addCase(getPaymentSession.fulfilled, (state, action) => {
      state.loading = false;
      state.paymentSession = action.payload;
      state.error = false;
    });
    builder.addCase(getPaymentSession.pending, state => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getPaymentSession.rejected, state => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(getPaymentCards.fulfilled, (state, action) => {
      state.loadingCards = false;
      state.paymentCards = action.payload ?? [];
      state.error = false;
    });
    builder.addCase(getPaymentCards.pending, state => {
      state.loadingCards = true;
      state.error = false;
    });
    builder.addCase(getPaymentCards.rejected, state => {
      state.loadingCards = false;
      state.error = true;
    });
    builder.addCase(createPaymentIntent.fulfilled, (state, action) => {
      state.loadingIntent = false;
      state.clientSecret = action.payload.clientSecret;
      state.paymentIntentId = action.payload.paymentIntentId;
      state.errorIntent = false;
    });
    builder.addCase(createPaymentIntent.pending, state => {
      state.loadingIntent = true;
      state.errorIntent = false;
    });
    builder.addCase(createPaymentIntent.rejected, state => {
      state.loadingIntent = false;
      state.errorIntent = true;
    });
    builder.addCase(confirmSnipcartPayment.pending, state => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(confirmSnipcartPayment.rejected, state => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { resetPayment, setSnipcartError } = paymentSlice.actions;

export default paymentSlice.reducer;
