import React, { memo } from "react";

import { ThemeEnum } from "@app/constants/theme.constants";
import { useSingleEntry } from "@app/features/contentful/contentful";
import { TestimonialDef } from "@app/types/testimonials.types";

import QuotesCarousel from "./QuotesCarousel";

interface QuotesCarouselContentfulProps {
  entryID: string;
}

interface QuoteItemModel {
  sys: {
    id: number;
  };
  fields?: {
    quote: string;
    title: string;
    author: string;
  };
}

interface QuotesDataModel {
  theme: string;
  testimonials: QuoteItemModel[];
}
const QuotesCarouselContentful = memo(
  ({ entryID }: QuotesCarouselContentfulProps) => {
    const { entry: quotesData } = useSingleEntry<QuotesDataModel>(entryID);

    if (!quotesData) return null;

    const { theme, testimonials } = quotesData;

    const mapItems = (slideItems: QuoteItemModel[]) => {
      const items = slideItems
        // Only published items will have fields
        .filter(item => !!item.fields)
        .map((item: QuoteItemModel) => ({
          id: item.sys.id,
          title: item.fields?.title,
          quote: item.fields?.quote,
          author: item.fields?.author,
        }));
      return items as TestimonialDef[];
    };

    return (
      <QuotesCarousel
        theme={theme as ThemeEnum}
        slideItems={mapItems(testimonials)}
      />
    );
  }
);

export default QuotesCarouselContentful;
