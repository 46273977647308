import React, { memo } from "react";

import { format } from "date-fns";
import { Col, Row } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Button, { ButtonTypeEnum } from "@app/components/atoms/Button/Button";
import Image from "@app/components/atoms/Image/Image";
import Modal from "@app/components/atoms/Modal/Modal";
import {
  Caption,
  Paragraph,
  Subtitle,
} from "@app/components/atoms/Typography/Typography";
import { DateFormats } from "@app/constants/date.constants";
import { IMAGE_TYPES } from "@app/constants/image";
import { ClassesDef } from "@app/features/classes/classes";
import { getImageUrl } from "@app/helpers/image.helpers";
import { getFullName } from "@app/helpers/util.helpers";
import { RootState } from "@app/redux/root-reducer";

import styles from "./CancelSessionModal.module.scss";

interface CancelSessionModalProps {
  visible: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
  session: ClassesDef;
}

const CancelSessionModal = memo(
  ({ visible, onConfirm, onDismiss, session }: CancelSessionModalProps) => {
    const { t } = useTranslation();
    const loading = useSelector(
      (state: RootState) => state.classes.cancelLoading
    );

    const handleOnClick = (
      event: React.SyntheticEvent<Element, Event>,
      callback: () => void
    ) => {
      event.preventDefault();
      event.stopPropagation();
      callback();
    };

    return (
      <Modal visible={visible} className={styles.modal} onClose={onDismiss}>
        <div className={styles.content}>
          <Subtitle level={2} className={styles.title}>
            {t("sessionCard.cancelModalTitle")}
          </Subtitle>
          <Paragraph level={2}>{t("sessionCard.cancelModalBody")}</Paragraph>
          <div className={styles.sessionInfo}>
            <Image
              className={styles.image}
              url={getImageUrl(session.image, IMAGE_TYPES.SESSION)}
            />
            <div>
              <Subtitle level={2}>{session.name}</Subtitle>
              <Caption>
                {t("sessionCard.author", {
                  authorName: getFullName(session.practitioner),
                })}
              </Caption>
              <Caption className={styles.date}>
                {`${format(
                  new Date(session.startAt),
                  DateFormats.LONG_MODAL
                )} - ${t("product.durationInMinutes", {
                  duration: session.duration,
                })}`}
              </Caption>
            </div>
          </div>
          <Row gutterWidth={10}>
            <Col xs={12} md={6}>
              <Button
                label={t("sessionCard.cancelSessionConfirmButton")}
                buttonType={ButtonTypeEnum.SECONDARY}
                onClick={e => handleOnClick(e, onConfirm)}
                fullWidth
                className={styles.cancelButton}
                loading={loading}
              />
            </Col>
            <Col xs={12} md={6}>
              <Button
                label={t("sessionCard.cancelSessionCancelButton")}
                buttonType={ButtonTypeEnum.PRIMARY}
                onClick={e => handleOnClick(e, onDismiss)}
                fullWidth
              />
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
);

export default CancelSessionModal;
