export enum NavigationPathsEnum {
  ABOUT = "/about",
  RETREATS = "/retreats",
  JOURNAL = "/journal",
  PRIVATES = "/privatesessions",
  COMINGSOON = "/coming-soon",
}

export enum NavigationIDsEnum {
  HOME = "home",
  WELCOME = "welcome",
  EXPERIENCES = "experiences",
  PRACTITIONERS = "practitioners",
  SCHEDULE = "schedule",
  ABOUT = "about",
  RETREATS = "retreats",
  JOURNAL = "journal",
  CLASSES = "classes",
  PRIVATES = "privateSessions",
  COMINGSOON = "coming-soon",
}
