import React, { memo, useMemo, useCallback } from "react";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import FormItem from "@app/components/atoms/FormItem/FormItem";
import { FormModal } from "@app/components/atoms/Modal/Modal";
import { SelectOptionDef } from "@app/components/atoms/Select/Select";
import { RootState } from "@app/redux/root-reducer";

import { GridViewSettingsEnum } from "../../../../constants/video.enums";
import { setGridViewSetting } from "../../../../redux/video-settings.slice";

interface FormValuesDef {
  gridView: GridViewSettingsEnum;
}

interface GridViewSettingsModalProps {
  visible: boolean;
  onClose: () => void;
}

const GridViewSettingsModal = memo(
  ({ visible, onClose }: GridViewSettingsModalProps) => {
    const gridView = useSelector(
      (state: RootState) => state.videoSettings.gridView
    );
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const selectOptions = useMemo(
      () =>
        [
          {
            value: GridViewSettingsEnum.BOTTOM_GRID_VIEW,
            label: t("videoSession.bottomGridView"),
          },
          {
            value: GridViewSettingsEnum.FULL_GRID_VIEW,
            label: t("videoSession.fullGridView"),
          },
        ] as SelectOptionDef[],
      [t]
    );

    const handleClose = useCallback(() => {
      onClose();
    }, [onClose]);

    const handleSubmit = async (values: FormValuesDef) => {
      dispatch(setGridViewSetting({ gridView: values.gridView }));
      handleClose();
    };

    return (
      <FormModal<FormValuesDef>
        visible={visible}
        subtitle={t("videoSession.menuGridViewSettings")}
        submitButtonLabel={t("videoSession.gridViewSettingsSubmit")}
        showCloseButton
        onClose={handleClose}
        formikProps={{
          initialValues: {
            gridView,
          },
          onSubmit: handleSubmit,
        }}
      >
        <FormItem
          type="select"
          id="gridView"
          name="gridView"
          selectOptions={selectOptions}
          label={t("videoSession.gridViewSelectLabel")}
        />
      </FormModal>
    );
  }
);

export default GridViewSettingsModal;
