import React, { memo } from "react";

import { useTranslation } from "react-i18next";

import Button, {
  ButtonSizeEnum,
  ButtonTypeEnum,
} from "@app/components/atoms/Button/Button";

import styles from "./AuthFormButton.module.scss";

interface AuthFormButtonProps {
  /**
   * Overwrite the default submit button text
   */
  label?: string;
  /**
   * While submitting the button will be disabled and in loading state
   */
  isSubmitting?: boolean;
}

const AuthFormButton = memo(({ label, isSubmitting }: AuthFormButtonProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <Button
        label={label ?? t("default.continueButton")}
        loading={isSubmitting}
        submit
        fullWidth
        buttonType={ButtonTypeEnum.PRIMARY}
        size={ButtonSizeEnum.LARGE}
      />
    </div>
  );
});

export default AuthFormButton;
