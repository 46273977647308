import React from "react";

import cx from "classnames";

import { IconChevronDown } from "@app/components/atoms/Icon/Icon";
import { Subtitle } from "@app/components/atoms/Typography/Typography";

import styles from "./DropdownButton.module.scss";

interface DropdownButtonProps {
  label: string;
  onClick: () => void;
  className?: string;
}

const DropdownButton = ({ label, onClick, className }: DropdownButtonProps) => {
  return (
    <button
      className={cx(styles.dropdown, className)}
      onClick={onClick}
      type="button"
    >
      <Subtitle level={3}>{label}</Subtitle>
      <IconChevronDown className={styles.chevronIcon} />
    </button>
  );
};

export default DropdownButton;
