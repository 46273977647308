import { FC } from "react";

import ArticlesContentful from "@app/components/renderings/Articles/ArticlesContentful";
import BannerContentful from "@app/components/renderings/Banner/BannerContentful";
import ExperiencesContentful from "@app/components/renderings/Experiences/ExperiencesContentful";
import FaqContainerContentful from "@app/components/renderings/FaqContainer/FaqContainerContentful";
import HeroContentful from "@app/components/renderings/Hero/HeroContentful";
import HeroVideoContentful from "@app/components/renderings/HeroVideo/HeroVideoContentful";
import ImageCarouselContentful from "@app/components/renderings/ImageCarousel/ImageCarouselContentful";
import NewsletterContentful from "@app/components/renderings/Newsletter/NewsletterContentful";
import PromotedEventContentful from "@app/components/renderings/PromotedEvent/PromotedEventContentful";
import QuotesCarouselContentful from "@app/components/renderings/QuotesCarousel/QuotesCarouselContentful";
import RichTextContentful from "@app/components/renderings/RichText/RichTextContentful";
import SessionsContentful from "@app/components/renderings/Sessions/SessionsContentful";
import StaticPageLoader from "@app/components/renderings/StaticPageLoader/StaticPageLoader";
import ThreeUSPContentful from "@app/components/renderings/ThreeUSP/ThreeUSPContentful";
import TopicContentful from "@app/components/renderings/Topic/TopicContentful";
import UserSessionsContentful from "@app/components/renderings/UserSessions/UserSessionsContentful";
import { ContentModel } from "@app/constants/contentful.constants";

type DynamicSectionType = Partial<Record<ContentModel, FC<any>>>;

const DynamicSection: DynamicSectionType = {
  [ContentModel.HERO]: HeroContentful,
  [ContentModel.TOPIC_COMPONENT]: TopicContentful,
  [ContentModel.THREE_USP_COMPONENT]: ThreeUSPContentful,
  [ContentModel.EXPERIENCES]: ExperiencesContentful,
  [ContentModel.PROMOTED_EVENT]: PromotedEventContentful,
  [ContentModel.ARTICLES]: ArticlesContentful,
  [ContentModel.BANNER]: BannerContentful,
  [ContentModel.TESTIMONIALS]: QuotesCarouselContentful,
  [ContentModel.HERO_VIDEO]: HeroVideoContentful,
  [ContentModel.STATIC_PAGE]: StaticPageLoader,
  [ContentModel.STATIC_RICH_TEXT]: RichTextContentful,
  [ContentModel.UPCOMING_SESSIONS]: UserSessionsContentful,
  [ContentModel.RECOMMENDED_SESSIONS]: SessionsContentful,
  [ContentModel.NEWSLETTER]: NewsletterContentful,
  [ContentModel.FAQ_CONTAINER]: FaqContainerContentful,
  [ContentModel.IMAGE_CAROUSEL]: ImageCarouselContentful,
};

export default DynamicSection;
