import { createClient } from "contentful";

import { ENV } from "../constants/contentful.config";
import {
  ContentfulEntriesFilter,
  ContentfulEntryFilter,
  ContentfulOptionsDef,
} from "../types/contentful.types";

// TODO: Remove if connecting to middleware
const client = createClient({
  space: ENV.SPACE_ID,
  accessToken: ENV.ACCESS_TOKEN,
  environment: ENV.ENVIRONMENT,
  host: ENV.CONTENTFUL_HOST,
});

const previewClient = createClient({
  space: ENV.SPACE_ID,
  accessToken: ENV.PREVIEW_ACCESS_TOKEN,
  environment: ENV.ENVIRONMENT,
  host: ENV.PREVIEW_CONTENTFUL_HOST,
});

const getEntryById = (
  id: string,
  filter?: ContentfulEntryFilter,
  options?: ContentfulOptionsDef
) => {
  if (options?.preview) {
    return previewClient.getEntry(id, filter);
  }

  return client.getEntry(id, filter);
};

const getEntries = (payload: ContentfulEntriesFilter) => {
  const filters = {
    // eslint-disable-next-line @typescript-eslint/camelcase
    ...(payload.entryId ? { sys_id: payload.entryId } : {}),
    // eslint-disable-next-line @typescript-eslint/camelcase
    ...(payload.type ? { content_type: payload.type } : {}),
  };
  return client.getEntries(filters);
};

export const contentfulApi = {
  getEntryById,
  getEntries,
};
