import { generatePath } from "react-router-dom";

import { ArticleItemProps } from "@app/components/renderings/Articles/components/ArticleItem/ArticleItem";
import { convertToSlug } from "@app/helpers/util.helpers";

import { ARTICLE_ROUTE_PATH } from "../constants/articles.path";
import { ArticleDef } from "../types/articles.types";

export const mapCarouselItems = (
  articles: ArticleDef[]
): ArticleItemProps[] => {
  return articles?.map(article => ({
    id: article.id,
    image: article.image,
    title: article.title,
    link: generatePath(ARTICLE_ROUTE_PATH, {
      articleId: article.id,
      ...(article.slug && { slug: convertToSlug(article.slug) }),
    }),
  }));
};
