import React, { memo } from "react";

import CircularButton from "@app/components/atoms/CircularButton/CircularButton";
import { IconSearch, IconBagWithCount } from "@app/components/atoms/Icon/Icon";
import { Title } from "@app/components/atoms/Typography/Typography";
import { openCart, useSnipcartQty } from "@app/features/ecommerce/ecommerce";
import { SearchPathsEnum } from "@app/features/search/search";

import styles from "./NavigationActions.module.scss";

const NavigationActions = memo(() => {
  const counter = useSnipcartQty();

  return (
    <>
      <CircularButton
        onClick={openCart}
        className={styles.cartButton}
        borderless
      >
        <Title isGold className={styles.counter} level={3} isMobile>
          {counter}
        </Title>
        <IconBagWithCount />
      </CircularButton>
      <CircularButton
        to={SearchPathsEnum.SEARCH}
        className={styles.searchButton}
        borderless
      >
        <IconSearch />
      </CircularButton>
    </>
  );
});

export default NavigationActions;
