import React, { memo } from "react";

import { useSelector } from "react-redux";
import { useMount } from "react-use";

import Loading from "@app/components/atoms/Loading/Loading";
import { ClassTypesEnum } from "@app/constants/classes";
import { ThemeEnum } from "@app/constants/theme.constants";
import { BookedEnum, ClassesDef } from "@app/features/classes/classes";
import {
  searchContent,
  SearchFiltersDef,
  SearchTypeEnum,
} from "@app/features/search/search";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";

import Sessions from "../Sessions/Sessions";

type Props = {
  filter: Omit<SearchFiltersDef, "type">;
  title: string;
  subTitle?: string;
  theme?: ThemeEnum;
};

const excludeTypes = [ClassTypesEnum.PRIVATE_SESSION];

const SessionsByFilter = memo(({ filter, title, subTitle, theme }: Props) => {
  const getFilteredExcludes = () => {
    if (filter.classTypes) {
      return excludeTypes.filter(
        classType => !filter.classTypes?.includes(classType)
      );
    }
    return excludeTypes;
  };

  const filterObj = {
    ...filter,
    types: SearchTypeEnum.CLASSES,
    booked: BookedEnum.FALSE,
    excludeClassTypes: getFilteredExcludes(),
  };
  const data = useSelector((state: RootState) => state.search);
  const dispatch = useAppDispatch();
  const { results } = data;
  const sessions = results[JSON.stringify(filterObj)] as ClassesDef[];

  useMount(() => {
    if (filter) {
      dispatch(searchContent(filterObj));
    }
  });

  return (
    <Loading isLoading={data.loading}>
      {sessions?.length > 0 && (
        <Sessions
          theme={theme}
          title={title}
          subtitle={subTitle}
          sessions={sessions}
        />
      )}
    </Loading>
  );
});

export default SessionsByFilter;
