import * as React from "react";

function SvgCamera(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3h-.535l-.297.445L7.465 6H2v15h20V6h-5.465l-1.703-2.555L14.535 3H10zM8.832 7.555L10.535 5h2.93l1.703 2.555.297.445H20v11H4V8h4.535l.297-.445zM15 13a3 3 0 11-6 0 3 3 0 016 0zm2 0a5 5 0 11-10 0 5 5 0 0110 0z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgCamera = React.memo(SvgCamera);
export default MemoSvgCamera;
