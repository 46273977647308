import * as React from "react";

function SvgFacebook(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0c5.523 0 10 4.477 10 10 0 4.991-3.657 9.128-8.438 9.878v-6.987h2.33L14.337 10h-2.774V8.124c0-.79.388-1.562 1.63-1.562h1.261v-2.46s-1.144-.196-2.238-.196c-.286 0-.559.022-.819.065-1.815.299-2.959 1.633-2.959 3.826V10H5.899v2.89h2.54v6.988C3.657 19.128 0 14.991 0 10 0 4.477 4.477 0 10 0z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgFacebook = React.memo(SvgFacebook);
export default MemoSvgFacebook;
