import React from "react";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import { ENV } from "@app/constants/config";

export const bugsnag = Bugsnag.start({
  apiKey: ENV.BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: ENV.STAGE,
  enabledReleaseStages: ["production", "staging"],
  appVersion: ENV.VERSION,
});

const ErrorBoundary = Bugsnag.getPlugin("react")?.createErrorBoundary(React);

export default ErrorBoundary as React.FunctionComponent;
