/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import cx from "classnames";
import qs from "query-string";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-media";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import { useMount } from "react-use";

import Button, {
  ButtonSizeEnum,
  ButtonTypeEnum,
} from "@app/components/atoms/Button/Button";
import {
  IconArrowLeft,
  IconChevronDown,
  IconChevronUp,
} from "@app/components/atoms/Icon/Icon";
import Loading from "@app/components/atoms/Loading/Loading";
import BlankLayout from "@app/components/layouts/BlankLayout/BlankLayout";
import { GLOBAL_MEDIA_QUERIES } from "@app/constants/breakpoints";
import { ENV } from "@app/constants/config";
import { ThemeEnum } from "@app/constants/theme.constants";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";

import { getPaymentSession } from "../../redux/payment.slice";
import { formatCurrency } from "../../snipcart/helpers/snipcart.helpers";
import styles from "./PaymentScreen.module.scss";
import CartSummary from "./components/CartSummary/CartSummary";
import PaymentForm from "./components/PaymentForm/PaymentForm";

const stripePromise = loadStripe(ENV.STRIPE_API_KEY);

const PaymentScreen = () => {
  const { t } = useTranslation();
  const [mobileCartOpen, setMobileCartOpen] = useState(false);
  const location = useLocation<{ publicToken: string }>();
  const params = qs.parse(location.search);
  const dispatch = useAppDispatch();
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

  const loading = useSelector((state: RootState) => state.payment.loading);
  const paymentSession = useSelector(
    (state: RootState) => state.payment.paymentSession
  );

  useMount(() => {
    if (params?.publicToken) {
      dispatch(getPaymentSession(params.publicToken as string));
    }
  });

  const getBillingAddress = () => {
    const billingAddress = paymentSession?.invoice?.billingAddress;
    if (billingAddress)
      return [
        billingAddress.streetAndNumber,
        billingAddress.city,
        billingAddress.country,
        billingAddress.postalCode,
      ].join(", ");
    return "";
  };

  if (
    (!loading && !paymentSession?.invoice) ||
    paymentSession?.state === "Confirmed"
  ) {
    return <Redirect to="/" />;
  }

  return (
    <BlankLayout withHeader={false} contentTheme={ThemeEnum.WHITE}>
      <Loading isLoading={loading} theme={ThemeEnum.WHITE}>
        {paymentSession && (
          <div className="snipcart-modal__container snipcart-layout--large">
            <div className="snipcart-layout snipcart-modal">
              <header className="snipcart-cart-header snipcart-checkout__header snipcart-layout__header--fixed">
                <Button
                  className="snipcart-cart-header__close-button snipcart-modal__close"
                  buttonType={ButtonTypeEnum.PRIMARY}
                  size={ButtonSizeEnum.TEXT}
                  label={t("snipcart.continueShipping")}
                  startIcon={<IconArrowLeft />}
                  to="/"
                />
                {!matches.tabletLandscape && (
                  <div className="snipcart-modal__header-summary">
                    <div
                      className="snipcart-modal__header-summary-title snipcart__font--large snipcart__font--secondary snipcart__font--bold"
                      onClick={() => setMobileCartOpen(!mobileCartOpen)}
                      role="presentation"
                    >
                      <svg />
                      <span>
                        {formatCurrency(
                          paymentSession?.invoice.currency,
                          paymentSession?.invoice.amount
                        )}
                      </span>
                      {mobileCartOpen ? <IconChevronUp /> : <IconChevronDown />}
                    </div>
                    {mobileCartOpen && (
                      <div className="snipcart-cart-summary--small">
                        <CartSummary invoice={paymentSession.invoice} />
                      </div>
                    )}
                  </div>
                )}
              </header>
              <div>
                <div className="snipcart-checkout__content snipcart-layout__content">
                  <div className="snipcart-layout__cols">
                    <div
                      className={cx(
                        "snipcart-layout__col snipcart-layout__col--large",
                        styles.leftWrapper
                      )}
                    >
                      <div
                        id="snipcart-checkout-step-billing"
                        className="snipcart-checkout-step"
                      >
                        <div className="snipcart__box snipcart-billing-completed snipcart__box--gray">
                          <div className="snipcart-billing-completed__header snipcart__box--header">
                            <div className="snipcart__box--title">
                              <h1 className="snipcart-billing-completed__title snipcart__font--subtitle">
                                {t("snipcart.billingTitle")}
                              </h1>
                            </div>
                          </div>
                          <div className="snipcart-checkout-step__cols snipcart__font--std">
                            <div className="snipcart-checkout-step__col">
                              <div>
                                <h3 className="snipcart-billing-completed__step-title snipcart__font--secondary snipcart__font--bold">
                                  {t("snipcart.customerInformation")}
                                </h3>
                                <span className="snipcart-billing-completed__information snipcart__font--std">
                                  {paymentSession?.invoice?.billingAddress.name}
                                  <br />
                                  {paymentSession?.invoice?.email}
                                </span>
                              </div>
                            </div>
                            <div className="snipcart-checkout-step__col">
                              <div>
                                <h3 className="snipcart-billing-completed__step-title snipcart__font--secondary snipcart__font--bold">
                                  {t("snipcart.billingAddress")}
                                </h3>
                                <span className="snipcart-billing-completed__information snipcart__font--std">
                                  {getBillingAddress()}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="snipcart-checkout-step-payment"
                        className="snipcart-checkout-step"
                      >
                        <div className="snipcart__box--header">
                          <div className="snipcart__box--title">
                            <h1 className="snipcart-payment__header snipcart__font--subtitle">
                              {t("snipcart.paymentTitle")}
                            </h1>
                          </div>
                        </div>
                        <Elements stripe={stripePromise}>
                          <PaymentForm
                            snipcartPublicToken={params.publicToken as string}
                            paymentSession={paymentSession}
                          />
                        </Elements>
                      </div>
                    </div>
                    {matches.tabletLandscape && (
                      <div className="snipcart-layout__col">
                        <div>
                          <CartSummary invoice={paymentSession.invoice} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Loading>
    </BlankLayout>
  );
};

export default PaymentScreen;
