import React, { memo, useState } from "react";

import { Container, Row, Col } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-media";

import { IllustrationMediumHexagon } from "@app/components/atoms/Illustrations/Illustrations";
import Image from "@app/components/atoms/Image/Image";
import Tags from "@app/components/atoms/Tags/Tags";
import { Title, Paragraph } from "@app/components/atoms/Typography/Typography";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import Section from "@app/components/layouts/Section/Section";
import { GLOBAL_MEDIA_QUERIES } from "@app/constants/breakpoints";
import { ThemeEnum } from "@app/constants/theme.constants";
import { PractitionerDef } from "@app/features/practitioners/practitioners";
import { getExperienceTags } from "@app/helpers/practitioners.helpers";

import styles from "./PractitionerProfile.module.scss";

export interface PractitionerProfileProps {
  firstName: PractitionerDef["firstName"];
  lastName: PractitionerDef["lastName"];
  image?: PractitionerDef["image"];
  experiences?: PractitionerDef["experiences"];
  shortDescription?: PractitionerDef["shortDescription"];
  fullDescription?: PractitionerDef["description"];
}
const PractitionerProfile = memo(
  ({
    image,
    firstName,
    lastName,
    experiences,
    shortDescription,
    fullDescription,
  }: PractitionerProfileProps) => {
    const { t } = useTranslation();
    // const [showMore, setShowMore] = useState<boolean>(false);
    const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
    const name = `${firstName} ${lastName}`;

    return (
      <Section theme={ThemeEnum.KHAKI} className={styles.wrapper}>
        <IllustrationMediumHexagon className={styles.hexagon} />
        {!!image && !matches.tabletLandscape && (
          <Image className={styles.imageWrapper} url={image} alt={name} />
        )}
        <ContentWrapper>
          <Container fluid>
            <Row>
              <Col xs={12} lg={6} xl={5}>
                {!!image && matches.tabletLandscape && (
                  <div>
                    <Image
                      className={styles.imageWrapper}
                      url={image}
                      alt={name}
                    />
                  </div>
                )}
              </Col>
              <Col xs={12} xl={1} className={styles.emptyCol} />
              <Col xs={12} lg={6} xl={5} className={styles.contentWrapper}>
                <div>
                  <Title className={styles.title}>{name}</Title>
                  {!!getExperienceTags(experiences)?.length && (
                    <div className={styles.tagsWrapper}>
                      {getExperienceTags(experiences).map(tag => (
                        <Tags
                          key={tag.label}
                          className={styles.tag}
                          label={tag.label}
                          color={tag.color}
                          link={tag.link}
                        />
                      ))}
                    </div>
                  )}
                  <Title className={styles.subHeader} level={3} isGoldGradient>
                    {t("practitioner.intro", { name: firstName })}
                  </Title>

                  <>
                    <Paragraph level={2} className={styles.description}>
                      {fullDescription}
                    </Paragraph>
                  </>
                </div>
              </Col>
            </Row>
          </Container>
        </ContentWrapper>
      </Section>
    );
  }
);

export default PractitionerProfile;
