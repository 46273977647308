import React from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import PractitionerSessions from "@app/components/renderings/UserSessions/components/PractitionerSessions/PractitionerSessions";
import SeekerSessions from "@app/components/renderings/UserSessions/components/SeekerSessions/SeekerSessions";
import { ClassesFilterEnum } from "@app/constants/classes";
import {
  PermissionEnum,
  usePermissions,
} from "@app/features/permissions/permissions";
import { RootState } from "@app/redux/root-reducer";

import NoSessions from "../NoSessions/NoSessions";
import styles from "./PastSessions.module.scss";

const PastSessions = () => {
  const { t } = useTranslation();
  const { allowed } = usePermissions([PermissionEnum.PRACTITIONERS_DASHBOARD]);
  const { classes, teachings, loading } = useSelector(
    (state: RootState) => state.classes
  );

  const hasResults = () => {
    return !![...classes, ...teachings].length;
  };

  return (
    <>
      {allowed && (
        <PractitionerSessions
          title={t("myBookings.sessionsTaught")}
          filter={ClassesFilterEnum.PAST}
          className={styles.section}
          showPagination
          showAttendeesModal
        />
      )}
      <SeekerSessions
        hideCTA
        showPagination
        className={styles.section}
        filter={ClassesFilterEnum.PAST}
        title={allowed ? t("myBookings.sessionsTaken") : undefined}
        includeCancelled
      />
      {!hasResults() && !loading && (
        <NoSessions title={t("myBookings.noPastSessionsTitle")} />
      )}
    </>
  );
};

export default PastSessions;
