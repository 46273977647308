import React, { memo } from "react";

import cx from "classnames";

import { ThemeEnum, BackgroundRatioEnum } from "@app/constants/theme.constants";
import { getTheme } from "@app/helpers/util.helpers";

import styles from "./Section.module.scss";

export interface SectionProps extends React.HTMLAttributes<HTMLElement> {
  /**
   * Set theme for section, which will control section backgrounds color and nested components
   */
  theme: ThemeEnum;
  /**
   * Set the background ratio for the Khaki-To-White and White-To-Khaki background transition of sections in the page
   */
  backgroundRatio?: BackgroundRatioEnum;
  /**
   * whether the section should take of the full height of the parent
   */
  fullHeight?: boolean;
  /**
   * whether there should be spacing between sections
   */
  withSeparatorSpacing?: boolean;
  noBackgroundOverlap?: boolean;
}

const Section = memo(
  ({
    className,
    theme = ThemeEnum.WHITE,
    children,
    backgroundRatio = BackgroundRatioEnum.HALF,
    fullHeight,
    withSeparatorSpacing,
    noBackgroundOverlap = false,
    ...rest
  }: SectionProps) => {
    return (
      <section
        className={cx(styles.container, className, getTheme(theme), {
          [styles.half]: backgroundRatio === BackgroundRatioEnum.HALF,
          [styles.quarter]: backgroundRatio === BackgroundRatioEnum.QUARTER,
          [styles.third]: backgroundRatio === BackgroundRatioEnum.THIRD,
          [styles.fullHeight]: fullHeight,
          [styles.withSeparatorSpacing]: withSeparatorSpacing,
          [styles.noBackgroundOverlap]: noBackgroundOverlap,
        })}
        {...rest}
      >
        {children}
      </section>
    );
  }
);

export default Section;
