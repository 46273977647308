import React, { memo, useMemo } from "react";

import _camelCase from "lodash/camelCase";
import { useTranslation } from "react-i18next";

import FormItem from "@app/components/atoms/FormItem/FormItem";
import { SelectOptionDef } from "@app/components/atoms/Select/Select";
import { Subtitle, Caption } from "@app/components/atoms/Typography/Typography";
import { ClassFeedbackIncidents } from "@app/types/api.types";

import styles from "./FeedbackIncident.module.scss";

const FeedbackIncident = memo(() => {
  const { t } = useTranslation();

  const selectOptions = useMemo(
    () =>
      Object.values(ClassFeedbackIncidents).map(incident => ({
        value: incident,
        label: t(`incidentType.${_camelCase(incident)}`),
      })) as SelectOptionDef[],
    [t]
  );

  return (
    <>
      <Subtitle level={3} className={styles.title}>
        {t("videoSessionPractitionerFeedback.incidentTitle")}
      </Subtitle>
      <Caption className={styles.subtitle}>
        {t("videoSessionPractitionerFeedback.incidentSubtitle")}
      </Caption>

      <FormItem
        type="select"
        id="incidentType"
        name="incidentType"
        selectOptions={selectOptions}
        label={t("videoSessionPractitionerFeedback.typeOfIncidentLabel")}
        placeholder={t(
          "videoSessionPractitionerFeedback.typeOfIncidentPlaceholder"
        )}
      />
      <FormItem
        type="textarea"
        id="incidentDescription"
        name="incidentDescription"
        label={t("videoSessionPractitionerFeedback.incidentDescriptionLabel")}
        placeholder={t(
          "videoSessionPractitionerFeedback.incidentDescriptionPlaceholder"
        )}
        hideError
      />
    </>
  );
});

export default FeedbackIncident;
