import React, { memo } from "react";

import cx from "classnames";
import { isToday } from "date-fns";
import { Container } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Title } from "@app/components/atoms/Typography/Typography";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import Section from "@app/components/layouts/Section/Section";
import { ThemeEnum } from "@app/constants/theme.constants";
import {
  Permission,
  PermissionEnum,
} from "@app/features/permissions/permissions";
import { RootState } from "@app/redux/root-reducer";

import styles from "./UserSessions.module.scss";
import PractitionerSessions from "./components/PractitionerSessions/PractitionerSessions";
import SeekerSessions from "./components/SeekerSessions/SeekerSessions";

export interface UserSessionsProps {
  firstName: string;
}

const UserSessions = memo(({ firstName }: UserSessionsProps) => {
  const { t } = useTranslation();

  const { classes, teachings } = useSelector(
    (state: RootState) => state.classes
  );

  const hasResults = () => {
    const todaysTeachings = teachings?.filter(session =>
      isToday(new Date(session.startAt))
    );
    return !![...classes, ...todaysTeachings].length;
  };

  const timeOfDay = () => {
    let greeting;
    const time = new Date().getHours();
    if (time < 10) {
      greeting = t("default.morning");
    } else if (time < 18) {
      greeting = t("default.day");
    } else {
      greeting = t("default.evening");
    }
    return greeting;
  };

  return (
    <Section
      theme={ThemeEnum.KHAKI}
      className={cx(styles.wrapper, { [styles.noResults]: !hasResults() })}
    >
      <ContentWrapper>
        <Container fluid>
          <Title level={3} isGoldGradient className={styles.subtitle}>
            {t("todaySessions.greetings", {
              firstName,
              timeOfDay: timeOfDay(),
            })}
          </Title>
          <Permission
            requiredPermissions={[PermissionEnum.PRACTITIONERS_DASHBOARD]}
          >
            <PractitionerSessions
              title={t("todaySessions.title")}
              className={cx(styles.practitionerSessions, {
                [styles.noSpacing]: !classes.length,
              })}
              todayOnly
            />
          </Permission>
          <SeekerSessions
            title={t("authenticatedHome.upcomingSessionsTitle")}
            isHomePage
          />
        </Container>
      </ContentWrapper>
    </Section>
  );
});

export default UserSessions;
