import React from "react";

import qs from "query-string";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { useMount } from "react-use";

import { IconMoon, IconSun } from "@app/components/atoms/Icon/Icon";
import Tabs from "@app/components/atoms/Tabs/Tabs";
import { Title } from "@app/components/atoms/Typography/Typography";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import DefaultLayout from "@app/components/layouts/DefaultLayout/DefaultLayout";
import Section from "@app/components/layouts/Section/Section";
import { ThemeEnum } from "@app/constants/theme.constants";
import { AuthPathsEnum } from "@app/features/auth/auth";
import { RootState } from "@app/redux/root-reducer";

import styles from "./MyBookingsScreen.module.scss";
import PastSessions from "./components/PastSessions/PastSessions";
import UpcomingSessions from "./components/UpcomingSessions/UpcomingSessions";

enum TabsEnum {
  UPCOMING = "upcoming",
  PAST = "past",
}

const MyBookingsScreen = ({ history }: RouteComponentProps) => {
  const { t } = useTranslation();
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  const location = useLocation();

  useMount(() => {
    if (!isAuthenticated) {
      history.push({
        pathname: AuthPathsEnum.SIGN_IN,
        search: qs.stringify({ redirect: location.pathname }),
      });
    }
  });

  return (
    <DefaultLayout>
      <Section theme={ThemeEnum.KHAKI}>
        <ContentWrapper>
          <div className={styles.header}>
            <Title level={1}>{t("default.myBookings")}</Title>
          </div>
          <Tabs
            tabClassName={styles.tab}
            selectedTabClassName={styles.activeTab}
            items={[
              {
                id: TabsEnum.UPCOMING,
                title: (
                  <div>
                    <Title level={3} className={styles.tabTitle}>
                      {t("default.upcoming")}
                    </Title>
                    <IconSun className={styles.icon} />
                  </div>
                ),
                content: <UpcomingSessions />,
              },
              {
                id: TabsEnum.PAST,
                title: (
                  <div>
                    <Title level={3} className={styles.tabTitle}>
                      {t("default.past")}
                    </Title>
                    <IconMoon className={styles.icon} />
                  </div>
                ),
                content: <PastSessions />,
              },
            ]}
          />
        </ContentWrapper>
      </Section>
    </DefaultLayout>
  );
};

export default MyBookingsScreen;
