import { RouteItemDef } from "@app/types/route.types";

import { EcommercePathsEnum } from "../constants/ecommerce.path";
import ProductDetailsScreen from "../screens/ProductDetailsScreen/ProductDetailsScreen";

const PRODUCT_DETAILS_SCREEN: RouteItemDef = {
  id: "productDetails",
  path: EcommercePathsEnum.PRODUCT_DETAILS,
  component: ProductDetailsScreen,
};

export const PRODUCT_DETAILS_ROUTES = [PRODUCT_DETAILS_SCREEN];
