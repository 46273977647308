import { RouteItemDef } from "@app/types/route.types";

import { EcommercePathsEnum } from "../constants/ecommerce.path";
import PaymentScreen from "../screens/PaymentScreen/PaymentScreen";

const PAYMENT_SCREEN: RouteItemDef = {
  id: "payment",
  path: EcommercePathsEnum.PAYMENT,
  component: PaymentScreen,
  metaTitle: "orderConfirmation.metaTitle",
};

export const PAYMENT_ROUTES = [PAYMENT_SCREEN];
