import React, { forwardRef } from "react";

import cx from "classnames";
import { useSelector } from "react-redux";

import { RootState } from "@app/redux/root-reducer";

import { GridViewSettingsEnum } from "../../constants/video.enums";
import styles from "./VideoChat.module.scss";
import VideoOverlay, {
  VideoOverlayProps,
} from "./components/VideoOverlay/VideoOverlay";

interface VideoChatProps extends VideoOverlayProps {
  className?: string;
  isHidden?: boolean;
  fixedAspectRatio?: boolean;
  loading?: boolean;
}

const VideoChat = forwardRef<HTMLDivElement, VideoChatProps>(
  ({ className, isHidden, fixedAspectRatio, loading, ...rest }, ref) => {
    const gridView = useSelector(
      (state: RootState) => state.videoSettings.gridView
    );

    return (
      <div
        className={cx(styles.videoChat, className, {
          [styles.fixedAspectRatio]:
            gridView === GridViewSettingsEnum.FULL_GRID_VIEW ||
            fixedAspectRatio,
          [styles.isHidden]: isHidden,
        })}
      >
        <div ref={ref} />
        {!loading && <VideoOverlay {...rest} />}
      </div>
    );
  }
);

export default VideoChat;
