import * as React from "react";

function SvgBad(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.5 14H20V5H6.5L4 15h7.322v4.102L12.678 21h1.085l.813-3.525L16.5 14zM16.445 13.889V4.397"
        stroke="currentColor"
        strokeWidth={2}
      />
    </svg>
  );
}

const MemoSvgBad = React.memo(SvgBad);
export default MemoSvgBad;
