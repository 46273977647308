import React from "react";

import cx from "classnames";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";

import { ThemeEnum } from "@app/constants/theme.constants";
import { getTheme } from "@app/helpers/util.helpers";

import Button, { ButtonSizeEnum } from "../Button/Button";
import { Paragraph } from "../Typography/Typography";
import styles from "./CookieBanner.module.scss";

const CookieBanner = () => {
  const { t } = useTranslation();
  return (
    <CookieConsent
      disableStyles
      containerClasses={cx(styles.container, getTheme(ThemeEnum.WHITE))}
      contentClasses={styles.content}
      location="bottom"
      buttonText={t("cookieBanner.buttonText")}
      ButtonComponent={({ children, ...props }) => (
        <Button size={ButtonSizeEnum.SMALL} {...props} label={children} />
      )}
      cookieName="kayloAcceptCookie"
    >
      <Paragraph level={2}>{t("cookieBanner.text")}</Paragraph>
    </CookieConsent>
  );
};

export default CookieBanner;
