import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useUnmount } from "react-use";

import Loading from "@app/components/atoms/Loading/Loading";
import DefaultLayout from "@app/components/layouts/DefaultLayout/DefaultLayout";
import Section from "@app/components/layouts/Section/Section";
import PractitionerDetails from "@app/components/renderings/PractitionerDetails/PractitionerDetails";
import PractitionerExperience from "@app/components/renderings/PractitionerExperience/PractitionerExperience";
import PractitionerProfile from "@app/components/renderings/PractitionerProfile/PractitionerProfile";
import PractitionersCarousel from "@app/components/renderings/PractitionersCarousel/PractitionersCarousel";
import QuotesCarousel from "@app/components/renderings/QuotesCarousel/QuotesCarousel";
import SessionsByFilter from "@app/components/renderings/SessionsByFilter/SessionsByFilter";
import { ClassTypesEnum } from "@app/constants/classes";
import { IMAGE_TYPES } from "@app/constants/image";
import { ThemeEnum } from "@app/constants/theme.constants";
import { getImageUrl } from "@app/helpers/image.helpers";
import { testimonialsMapper } from "@app/helpers/practitioners.helpers";
import { isValidId, getFullName } from "@app/helpers/util.helpers";
import useMetaTitle from "@app/hooks/useMetaTitle";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";
import NoMatch from "@app/routes/components/NoMatch/NoMatch";

import {
  getPractitionerById,
  clearPractitioner,
} from "../../redux/practitioners.slice";

const PractitionerScreen = () => {
  const { t } = useTranslation();
  const { practitionerId } = useParams<{ practitionerId: string }>();
  const { practitioner, loading, error } = useSelector(
    (state: RootState) => state.practitioners
  );
  const dispatch = useAppDispatch();

  useMetaTitle(getFullName(practitioner), !!practitioner);

  useEffect(() => {
    if (isValidId(practitionerId)) {
      dispatch(getPractitionerById(Number(practitionerId)));
    }
  }, [dispatch, practitionerId]);

  useUnmount(() => {
    dispatch(clearPractitioner());
  });

  const getImage = () => {
    return (
      practitioner?.image &&
      getImageUrl(practitioner.image, IMAGE_TYPES.PRACTITIONER)
    );
  };

  if (error) return <NoMatch />;

  return (
    <DefaultLayout>
      <Section theme={ThemeEnum.KHAKI}>
        <Loading isLoading={loading}>
          {practitioner && (
            <>
              <PractitionerProfile
                image={getImage()}
                firstName={practitioner.firstName}
                lastName={practitioner.lastName}
                shortDescription={practitioner.shortDescription}
                fullDescription={practitioner.description}
                experiences={practitioner.experiences}
              />
              <SessionsByFilter
                title={t("practitioner.sessionsTitle", {
                  name: practitioner.firstName,
                })}
                filter={{
                  practitioners: [practitionerId],
                }}
              />
              <SessionsByFilter
                title={t("practitioner.sessions1On1Title", {
                  name: practitioner.firstName,
                })}
                filter={{
                  classTypes: [ClassTypesEnum.PRIVATE_SESSION],
                  practitioners: [practitionerId],
                }}
              />
              <PractitionerExperience
                media={practitioner.media}
                body={practitioner.approach}
              />
              <PractitionerDetails
                quote={practitioner.personalQuotes}
                facts={practitioner.funFacts}
                credentialsShort={practitioner.shortCredentials}
                credentialsFull={practitioner.credentials}
              />
              <QuotesCarousel
                theme={ThemeEnum.RED_ON_ORANGE}
                slideItems={testimonialsMapper(practitioner.testimonials)}
              />
            </>
          )}
        </Loading>
        <PractitionersCarousel />
      </Section>
    </DefaultLayout>
  );
};

export default PractitionerScreen;
