import React, { memo } from "react";

import { useMedia } from "react-media";

import clouds from "@app/assets/images/clouds.jpg";
import gradientDesktop from "@app/assets/videos/gradientHero.mp4";
import Section from "@app/components/layouts/Section/Section";
import { GLOBAL_MEDIA_QUERIES } from "@app/constants/breakpoints";
import { ThemeEnum } from "@app/constants/theme.constants";

import styles from "./AuthLayout.module.scss";

const AuthLayout = memo(({ children }) => {
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

  return (
    <div className={styles.container}>
      {matches.tabletLandscape && (
        <>
          <img className={styles.clouds} src={clouds} alt="clouds" />
          <video
            muted
            autoPlay
            playsInline
            loop
            className={styles.gradient}
            src={gradientDesktop}
          />
        </>
      )}
      <Section theme={ThemeEnum.KHAKI} className={styles.content}>
        {children}
      </Section>
    </div>
  );
});

export default AuthLayout;
