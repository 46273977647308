import * as React from "react";

function SvgVolume1(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 4V1.92l-1.625 1.3L6.65 7H2v10h4.65l4.725 3.78L13 22.08V4zM7.625 8.78L11 6.08V17.92l-3.375-2.7L7.35 15H4V9h3.35l.275-.22zM15.12 9.88a3 3 0 010 4.242l1.414 1.415a5 5 0 000-7.072L15.121 9.88z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgVolume1 = React.memo(SvgVolume1);
export default MemoSvgVolume1;
