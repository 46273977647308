import React, { FC, memo } from "react";

import cx from "classnames";

import { FormFieldDef } from "@app/types/form.types";

import styles from "./Select.module.scss";
import MultiSelect from "./components/MultiSelect/MultiSelect";

export type SelectOptionDef = { value: string; label: string };
export interface SelectProps
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  field: FormFieldDef;
  selectOptions: SelectOptionDef[];
}

const PLACEHOLDER_VALUE = "";

const Select: FC<SelectProps> = memo(
  ({ field, className, placeholder, selectOptions = [], ...rest }) => {
    return (
      <div className={cx(styles.selectWrapper, className)}>
        <select
          className={cx(styles.select, {
            [styles.placeholder]: placeholder && !field.value,
          })}
          defaultValue={placeholder ? PLACEHOLDER_VALUE : undefined}
          {...field}
          {...(rest as JSX.IntrinsicElements["select"])}
        >
          {!!placeholder && (
            <option value={PLACEHOLDER_VALUE} label={placeholder} disabled />
          )}
          {selectOptions.map(item => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
    );
  }
);

export default Select;

export { MultiSelect };
