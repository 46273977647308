import { useCallback, useEffect, useState } from "react";

import { WindowDef } from "@app/types/external.types";

function useSnipcartQty() {
  const [counter, setCounter] = useState<number>(0);
  const windowObj: WindowDef = window;

  const getCount = useCallback(() => {
    return windowObj?.Snipcart?.store.getState().cart.items.count ?? 0;
  }, [windowObj]);

  useEffect(() => {
    setCounter(getCount());
    const unSubscribeState = windowObj?.Snipcart?.store.subscribe(() => {
      setCounter(getCount());
    });

    return () => {
      unSubscribeState?.();
    };
  }, [getCount, windowObj]);

  return counter;
}

export default useSnipcartQty;
