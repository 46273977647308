import React, { memo } from "react";

import { Row, Col } from "react-grid-system";
import { useTranslation } from "react-i18next";

import { Paragraph, Title } from "@app/components/atoms/Typography/Typography";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import Section from "@app/components/layouts/Section/Section";
import { ThemeEnum } from "@app/constants/theme.constants";
import { PractitionerDef } from "@app/features/practitioners/practitioners";

import ImageCarousel from "../ImageCarousel/ImageCarousel";
import styles from "./PractitionerExperience.module.scss";

export interface PractitionerExperienceProps {
  body?: string;
  media?: PractitionerDef["media"];
}

const PractitionerExperience = memo(
  ({ media, body }: PractitionerExperienceProps) => {
    const { t } = useTranslation();

    if (!media?.length && !body) return null;

    return (
      <Section theme={ThemeEnum.KHAKI} noBackgroundOverlap withSeparatorSpacing>
        <ContentWrapper>
          {!!media?.length && (
            <ImageCarousel media={media.map(image => image.url)} />
          )}
          <Row justify="center">
            <Col xs={12} xl={8}>
              <div className={styles.content}>
                <Title isGold className={styles.title}>
                  {t("practitioner.approachTitle")}
                </Title>
                {body && <Paragraph>{body}</Paragraph>}
              </div>
            </Col>
          </Row>
        </ContentWrapper>
      </Section>
    );
  }
);

export default PractitionerExperience;
