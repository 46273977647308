import React, { useEffect } from "react";

import format from "date-fns/format";
import { Col, Row } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Loading from "@app/components/atoms/Loading/Loading";
import {
  Heading,
  Paragraph,
  Title,
} from "@app/components/atoms/Typography/Typography";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import DefaultLayout from "@app/components/layouts/DefaultLayout/DefaultLayout";
import Section from "@app/components/layouts/Section/Section";
import ArticlesByFilter from "@app/components/renderings/ArticlesByFilter/ArticlesByFilter";
import Banner from "@app/components/renderings/Banner/Banner";
import Newsletter from "@app/components/renderings/Newsletter/Newsletter";
import { ThemeEnum } from "@app/constants/theme.constants";
import { isValidId } from "@app/helpers/util.helpers";
import useMetaTitle from "@app/hooks/useMetaTitle";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";
import NoMatch from "@app/routes/components/NoMatch/NoMatch";

import { getArticleById } from "../../redux/articles.slice";
import styles from "./ArticleScreen.module.scss";
import ArticlePageComponents from "./components/ArticlePageComponents/ArticlePageComponents";

const ArticleScreen = () => {
  const { t } = useTranslation();
  const { article, loading, error } = useSelector(
    (state: RootState) => state.articles
  );
  const dispatch = useAppDispatch();
  const { articleId } = useParams<{ articleId: string }>();
  useMetaTitle(article?.title, !!article);
  useEffect(() => {
    if (isValidId(articleId)) {
      dispatch(getArticleById(Number(articleId)));
    }
  }, [dispatch, articleId]);

  if (error) return <NoMatch />;

  return (
    <DefaultLayout>
      <Section theme={ThemeEnum.WHITE}>
        <Loading isLoading={loading}>
          <Heading className={styles.header}>
            {t("default.articleHeader")}
          </Heading>
          {article && (
            <>
              <Section
                theme={ThemeEnum.WHITE}
                withSeparatorSpacing
                className={styles.articleHeader}
              >
                <ContentWrapper>
                  <Row justify="center">
                    <Col xs={12} md={8}>
                      <Title level={1}>{article.title}</Title>
                      <div className={styles.articleMeta}>
                        {!!article.author && (
                          <Paragraph level={1} className={styles.articleMeta}>
                            {t("articles.author", { author: article.author })}
                          </Paragraph>
                        )}
                        <Paragraph level={2} className={styles.articleMeta}>
                          {format(new Date(article.createdAt), "d MMMM yyyy")}
                        </Paragraph>
                      </div>
                      <Paragraph className={styles.articleMeta} level={1}>
                        {article.intro}
                      </Paragraph>
                    </Col>
                  </Row>
                </ContentWrapper>
              </Section>
              <Banner
                fullwidth={false}
                imageUrl={article.image}
                title={article.title}
              />
            </>
          )}
        </Loading>
        {article && (
          <ArticlePageComponents contentfulId={article.contentfulId} />
        )}
        {!!article?.tags?.length && (
          <ArticlesByFilter
            title={t("articles.relatedArticles")}
            filter={{ labels: article.tags.map(tag => tag.tag) }}
            theme={ThemeEnum.KHAKI}
          />
        )}
        <Newsletter />
      </Section>
    </DefaultLayout>
  );
};

export default ArticleScreen;
