import React, { memo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import FormItem from "@app/components/atoms/FormItem/FormItem";
import { FormModal } from "@app/components/atoms/Modal/Modal";
import StarRating from "@app/components/atoms/StarRating/StarRating";
import { GtmEvents, registerEvent } from "@app/features/gtm/gtm";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";
import { ClassFeedbackDef } from "@app/types/api.types";

import { createFeedback } from "../../../../redux/session-class.slice";
import FeedbackModalHeader, {
  FeedbackModalHeaderProps,
} from "../FeedbackModalHeader/FeedbackModalHeader";
import FeedbackSimple from "../FeedbackSimple/FeedbackSimple";
import styles from "./SeekerFeedbackModal.module.scss";

export interface SeekerFeedbackModalProps extends FeedbackModalHeaderProps {
  visible: boolean;
  onClose: () => void;
}
type FormValuesDef = Partial<ClassFeedbackDef>;

enum StepsEnum {
  SIMPLE = 1,
  RATING = 2,
}

const initialValues: FormValuesDef = {
  like: undefined,
  comment: undefined,
  rateClass: undefined,
  ratePractitioner: undefined,
  rateVideo: undefined,
};

const SeekerFeedbackModal = memo(
  ({
    avatarImage,
    sessionTitle,
    practitioner,
    visible,
    onClose = () => null,
  }: SeekerFeedbackModalProps) => {
    const [step, setStep] = useState<StepsEnum>(StepsEnum.SIMPLE);
    const classType = useSelector(
      (state: RootState) => state.sessionClass.classType
    );

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const handleSubmit = async (values: FormValuesDef) => {
      // If form is submitted, and user wants to skip the feedback
      if (step === StepsEnum.SIMPLE && values.like == null) {
        onClose();
        return;
      }

      if (step === StepsEnum.RATING && classType) {
        const response = await dispatch(
          createFeedback({
            classId: classType.id,
            feedback: {
              ...values,
              like: Boolean(Number(values.like)),
            },
          })
        );
        if (createFeedback.fulfilled.match(response)) {
          const { like, rateClass, ratePractitioner, rateVideo } = values;

          registerEvent(GtmEvents.RATED_FIRST, {
            feedback: { like },
          });
          registerEvent(GtmEvents.RATED_SECOND, {
            feedback: { rateClass, ratePractitioner, rateVideo },
          });
          onClose();
        }
      }
    };

    const handleClickSimpleFeedback = () => {
      setStep(StepsEnum.RATING);
    };

    return (
      <FormModal
        containerClassName={styles.feedbackModal}
        visible={visible}
        submitButtonLabel={t("videoSessionSeekerFeedback.submitButtonLabel")}
        formikProps={{
          initialValues,
          onSubmit: handleSubmit,
        }}
        maskClosable={false}
      >
        <FeedbackModalHeader
          avatarImage={avatarImage}
          sessionTitle={sessionTitle}
          practitioner={t("videoSessionSeekerFeedback.withPractitionerTitle", {
            practitioner,
          })}
        />

        <div className={styles.wrapper}>
          {
            {
              [StepsEnum.SIMPLE]: (
                <FeedbackSimple
                  title={t("videoSessionSeekerFeedback.title")}
                  subtitle={t("videoSessionSeekerFeedback.subtitle")}
                  onClick={handleClickSimpleFeedback}
                />
              ),
              [StepsEnum.RATING]: (
                <>
                  <div className={styles.ratingContainer}>
                    <StarRating
                      id="rateClass"
                      name="rateClass"
                      className={styles.rating}
                      label={t(
                        "videoSessionSeekerFeedback.overallSessionRating"
                      )}
                    />
                    <StarRating
                      id="ratePractitioner"
                      name="ratePractitioner"
                      className={styles.rating}
                      label={t("videoSessionSeekerFeedback.practitionerRating")}
                    />
                    <StarRating
                      id="rateVideo"
                      name="rateVideo"
                      label={t(
                        "videoSessionSeekerFeedback.streamQualityRating"
                      )}
                    />
                  </div>
                  <FormItem
                    type="textarea"
                    id="comment"
                    name="comment"
                    label={t(
                      "videoSessionSeekerFeedback.additionalThoughtsLabel"
                    )}
                    placeholder={t(
                      "videoSessionSeekerFeedback.additionalThoughtsPlaceholder"
                    )}
                    hideError
                  />
                </>
              ),
            }[step]
          }
        </div>
      </FormModal>
    );
  }
);

export default SeekerFeedbackModal;
