import React, { memo } from "react";

import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import * as yup from "yup";

import FormItem from "@app/components/atoms/FormItem/FormItem";
import FormMessage, {
  FormMessageType,
} from "@app/components/atoms/FormMessage/FormMessage";
import { Paragraph, Title } from "@app/components/atoms/Typography/Typography";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";

import AuthFormButton from "../../components/AuthFormButton/AuthFormButton";
import AuthBoxLayout from "../../layouts/AuthBoxLayout/AuthBoxLayout";
import { requestResetPassword } from "../../redux/auth.slice";
import styles from "./RequestResetPasswordScreen.module.scss";

interface FormValuesDef {
  email: string;
}

const validationScheme = yup.object({
  email: yup
    .string()
    .email("authInputs.emailErrorInvalid")
    .required("authInputs.emailErrorRequired"),
});

const RequestResetPasswordScreen = memo(() => {
  const requestResetPasswordState = useSelector(
    (state: RootState) => state.auth.resetPasswordState
  );
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const initialValues: FormValuesDef = {
    email: "",
  };

  const handleSubmit = async (values: FormValuesDef) => {
    await dispatch(requestResetPassword(values));
  };

  return (
    <AuthBoxLayout header={<Title>{t("requestResetPassword.title")}</Title>}>
      {requestResetPasswordState === "success" ? (
        <Paragraph level={1}>
          {t("requestResetPassword.successMessage")}
        </Paragraph>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationScheme}
        >
          {({ isSubmitting }) => (
            <Form noValidate>
              <Paragraph className={styles.text}>
                {t("requestResetPassword.text")}
              </Paragraph>
              {requestResetPasswordState === "error" && (
                <FormMessage
                  type={FormMessageType.ERROR}
                  message={t("requestResetPassword.errorMessage")}
                />
              )}
              <FormItem
                id="email"
                name="email"
                label={t("authInputs.emailLabel")}
                type="email"
                placeholder={t("authInputs.emailPlaceholder")}
                autoComplete="off"
              />
              <AuthFormButton
                label={t("requestResetPassword.submitButton")}
                isSubmitting={isSubmitting}
              />
            </Form>
          )}
        </Formik>
      )}
    </AuthBoxLayout>
  );
});

export default RequestResetPasswordScreen;
