import React, { memo, MouseEventHandler, ReactNode, useState } from "react";

import cx from "classnames";

import Loading from "../Loading/Loading";
import styles from "./Image.module.scss";

export interface ImageProps {
  url: string | null;
  alt?: string;
  className?: string;
  onClick?: MouseEventHandler;
  children?: ReactNode;
}

const Image = memo(
  ({ url, alt = "Image", className, onClick, children }: ImageProps) => {
    const [shouldImageRender, setImageRender] = useState(true);
    const [isLoading, setLoading] = useState(true);

    const handleSuccess = () => {
      setImageRender(true);
      setLoading(false);
    };

    const handleError = () => {
      setImageRender(false);
      setLoading(false);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.type === "click") {
        // FIXME: fix type
        onClick?.(e as any);
      }
    };

    return (
      <>
        <div
          className={cx(styles.container, className)}
          onClick={onClick}
          onKeyDown={handleKeyDown}
          role="link"
          tabIndex={0}
        >
          {shouldImageRender ? (
            <>
              <Loading isLoading={isLoading} isSmall>
                <div
                  className={styles.image}
                  style={{ backgroundImage: `url(${url})` }}
                  title={alt}
                />
              </Loading>
              {children && <div className={styles.content}>{children}</div>}
            </>
          ) : (
            <div className={styles.noImage}>Cannot load image!</div>
          )}
        </div>
        {/** Need to trigger fake image load since the original image is hidden behind `Loading`. */}
        <img
          className={styles.fakeImg}
          alt=""
          src={url ?? ""}
          onLoad={handleSuccess}
          onError={handleError}
        />
      </>
    );
  }
);

export default Image;
