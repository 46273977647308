import React from "react";

import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import store from "@app/redux/store";

import "@app/features/localization/localization";
import GridConfiguration from "./components/layouts/Grid/GridConfiguration";
import ErrorBoundary from "./helpers/bugsnag.helpers";
import * as serviceWorker from "./serviceWorker";

import "./index.scss";

const render = () => {
  // eslint-disable-next-line global-require
  const App = require("./App").default;

  ReactDOM.render(
    <React.StrictMode>
      <ErrorBoundary>
        <Provider store={store}>
          <GridConfiguration />
          <App />
        </Provider>
      </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById("root")
  );
};

render();

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./App", render);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
