import React, { memo, useState } from "react";

import { TFunction } from "i18next";
import _camelCase from "lodash/camelCase";
import _isArray from "lodash/isArray";
import _isEmpty from "lodash/isEmpty";
import _omit from "lodash/omit";
import _without from "lodash/without";
import { Col, Row } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-media";

import * as iconList from "@app/components/atoms/Icon/Icon";
import { GLOBAL_MEDIA_QUERIES } from "@app/constants/breakpoints";
import {
  FilterValueDef,
  SearchApiFiltersEnum,
  SearchFacetFiltersEnum,
  SearchFiltersDef,
  SearchTypeEnum,
} from "@app/features/search/search";

import Button, { ButtonSizeEnum } from "../Button/Button";
import { MultiSelect } from "../Select/Select";
import Tabs, { TabItem } from "../Tabs/Tabs";
import { Title } from "../Typography/Typography";
import styles from "./SearchFilters.module.scss";
import DropdownButton from "./components/DropdownButton/DropdownButton";
import FiltersModal from "./components/FiltersModal/FiltersModal";
import FiltersModalMobile from "./components/FiltersModalMobile/FiltersModalMobile";

export type FiltersDef = Record<SearchFacetFiltersEnum, FilterValueDef[]>;
export interface SearchFiltersProps {
  filters: FiltersDef;
  selected: SearchFiltersDef;
  onChangeFilters: (values: SearchFiltersDef) => void;
}

const {
  TIMINGS,
  EXPERIENCES,
  PRACTITIONERS,
  DURATIONS,
  PRICES,
  LEVELS,
  CLASS_TYPE,
} = SearchFacetFiltersEnum;

const { LIMIT, PAGE, TYPES, NAME } = SearchApiFiltersEnum;

export const allowedFilters = {
  classes: [
    TIMINGS,
    EXPERIENCES,
    CLASS_TYPE,
    DURATIONS,
    PRICES,
    LEVELS,
    PRACTITIONERS,
  ],
  practitioners: [EXPERIENCES],
  articles: [EXPERIENCES],
};

const getFilters = (filters: SearchFiltersDef) => {
  return _omit(filters, [LIMIT, PAGE, TYPES, NAME]);
};

export const getFilterLabel = (
  t: TFunction,
  filterKey: SearchFacetFiltersEnum,
  label: FilterValueDef["value"]
) => {
  switch (filterKey) {
    case SearchFacetFiltersEnum.EXPERIENCES:
    case SearchFacetFiltersEnum.PRACTITIONERS:
      return label;
    default:
      return t(`searchFilters.${_camelCase(label)}`);
  }
};

const SearchFilters = memo(
  ({ filters, selected, onChangeFilters }: SearchFiltersProps) => {
    const { t } = useTranslation();
    const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
    const maxFilters = matches.desktopSmall ? 5 : 3;
    const [modalOpen, setModalOpen] = useState(false);
    const validFilters = getFilters(selected);
    const validFiltersKeys = Object.keys(
      validFilters
    ) as SearchFacetFiltersEnum[];

    const filterAllowedKeys = (type: SearchTypeEnum) => {
      let filteredKeys = {};
      allowedFilters[type].forEach(key => {
        if (filters[key] && _isArray(filters[key])) {
          filteredKeys = { ...filteredKeys, [key]: filters[key] };
        }
      });
      return filteredKeys;
    };

    const handleApplyFilters = (values: SearchFiltersDef) => {
      setModalOpen(false);
      onChangeFilters(values);
    };

    const handleClearFilters = () => {
      const selectedFilters: SearchFiltersDef = {};
      validFiltersKeys.forEach(filter => {
        selectedFilters[filter] = [];
      });
      onChangeFilters(selectedFilters);
    };

    const hasActiveFilters = () => {
      return validFiltersKeys.some(filter => !_isEmpty(validFilters[filter]));
    };

    const renderFilters = (type: SearchTypeEnum) => {
      const typeFilters = filterAllowedKeys(type);
      const allowedKeys = Object.keys(typeFilters) as SearchFacetFiltersEnum[];
      const isMobile = !matches.tabletLandscape;

      return (
        <>
          <Row className={styles.filters} align="center">
            {!isMobile &&
              allowedKeys.slice(0, maxFilters).map(filterKey => (
                <Col lg={3} xl={2} key={filterKey}>
                  <MultiSelect
                    options={filters[filterKey].map(filter => ({
                      value: filter.id.toString(),
                      label: getFilterLabel(t, filterKey, filter.value),
                    }))}
                    placeholder={t(`search.${_camelCase(filterKey)}Label`)}
                    value={selected[filterKey]}
                    onChange={value =>
                      handleApplyFilters({ [filterKey]: value as string[] })
                    }
                  />
                </Col>
              ))}
            {(allowedKeys.length > maxFilters || isMobile) && (
              <Col
                xs={hasActiveFilters() ? 9 : 12}
                sm={hasActiveFilters() ? 10 : 12}
                lg={3}
                xl={2}
              >
                <DropdownButton
                  label={
                    isMobile
                      ? t("search.filtersLabelMobile")
                      : t("search.filtersLabelDesktop")
                  }
                  onClick={() => setModalOpen(true)}
                />
              </Col>
            )}
            {hasActiveFilters() && (
              <Col xs={3} sm={2} className={styles.clearContainer}>
                <Button
                  size={ButtonSizeEnum.TEXT}
                  label={t("search.clearButtonTitle")}
                  className={styles.clearButton}
                  onClick={handleClearFilters}
                />
              </Col>
            )}
          </Row>
          {isMobile ? (
            <FiltersModalMobile
              visible={modalOpen}
              filters={typeFilters}
              selected={selected}
              onClose={() => setModalOpen(false)}
              onApplyFilters={handleApplyFilters}
            />
          ) : (
            <FiltersModal
              visible={modalOpen}
              filters={typeFilters}
              selected={selected}
              onClose={() => setModalOpen(false)}
              onApplyFilters={handleApplyFilters}
            />
          )}
        </>
      );
    };

    const renderTabTitle = (type: SearchTypeEnum) => {
      let iconName;

      switch (type) {
        case SearchTypeEnum.CLASSES:
          iconName = "IconMoon";
          break;
        case SearchTypeEnum.PRACTITIONERS:
          iconName = "IconSun";
          break;
        default:
          iconName = "IconMoon";
          break;
      }

      const Icon = (iconList as any)[iconName];

      return (
        <>
          <Title level={3} className={styles.tabTitle}>
            {t(`search.${_camelCase(type)}TabTitle`)}
          </Title>
          <Icon className={styles.icon} />
        </>
      );
    };

    const tabItemsMapper = (): TabItem[] => {
      return _without(
        Object.values(SearchTypeEnum),
        SearchTypeEnum.ARTICLES
      ).map(type => ({
        id: type,
        title: renderTabTitle(type),
        content: renderFilters(type),
      }));
    };

    const getDefaultTabIndex = () => {
      const typeFromUrl = selected.types;
      return typeFromUrl
        ? Object.values(SearchTypeEnum).findIndex(type => type === typeFromUrl)
        : 0;
    };

    const handleTabClick = (index: number) => {
      // Clear other filters before changing tab
      const selectedFilters = {};
      validFiltersKeys.forEach(filter => {
        (selectedFilters as any)[filter] = [];
      });
      onChangeFilters({
        ...selectedFilters,
        types: Object.values(SearchTypeEnum)[index],
      });
    };

    return (
      <div className={styles.container}>
        <Tabs
          items={tabItemsMapper()}
          tabClassName={styles.tab}
          selectedTabClassName={styles.activeTab}
          defaultIndex={getDefaultTabIndex()}
          onSelect={handleTabClick}
        />
      </div>
    );
  }
);

export default SearchFilters;
