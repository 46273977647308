import React, { memo } from "react";

import { useTranslation } from "react-i18next";

import { ReactComponent as Circles } from "@app/assets/images/circles.svg";
import { Subtitle } from "@app/components/atoms/Typography/Typography";
import Section from "@app/components/layouts/Section/Section";
import { ThemeEnum } from "@app/constants/theme.constants";

import styles from "./NoResults.module.scss";

const NoResults = memo(() => {
  const { t } = useTranslation();

  return (
    <Section theme={ThemeEnum.KHAKI} className={styles.wrapper}>
      <Circles className={styles.image} />
      <Subtitle level={2}>{t("search.noResults")}</Subtitle>
    </Section>
  );
});

export default NoResults;
