import React, { ReactNode } from "react";

import cx from "classnames";
import { Formik, FormikConfig, Form, FormikProps, isFunction } from "formik";

import Button, { ButtonTypeEnum } from "@app/components/atoms/Button/Button";

import Modal, { ModalProps } from "../../Modal";
import styles from "./FormModal.module.scss";

export interface FormModalProps<T = {}> extends ModalProps {
  submitButtonLabel: string;
  formikProps: Omit<FormikConfig<T>, "component" | "children">;
  children: ((props: FormikProps<T>) => ReactNode) | ReactNode;
  containerClassName?: string;
}

const FormModal = <T,>({
  children,
  submitButtonLabel,
  formikProps,
  containerClassName,
  ...rest
}: FormModalProps<T>) => {
  return (
    <Modal {...rest}>
      <Formik {...formikProps}>
        {formArgs => (
          <Form noValidate className={styles.form}>
            <div className={cx(styles.container, containerClassName)}>
              {isFunction(children) ? children(formArgs) : children}
            </div>
            <Button
              label={submitButtonLabel}
              loading={formArgs.isSubmitting}
              submit
              fullWidth
              buttonType={ButtonTypeEnum.PRIMARY}
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default FormModal;
