import OT, { OTError, Session } from "@opentok/client";
import _isEmpty from "lodash/isEmpty";

import { SessionEventsEnum } from "../constants/opentok.enums";
import { ENV } from "../constants/video.config";
import {
  StreamCreatedEvent,
  StreamDestroyedEvent,
  StreamPropertyChangedEvent,
  SessionDisconnectedEvent,
} from "../types/opentok.types";

interface SessionProps {
  sessionId: string;
  token: string;
  onStreamCreated?: (event: StreamCreatedEvent) => void;
  onStreamDestroyed?: (event: StreamDestroyedEvent) => void;
  onStreamPropertyChanged?: (event: StreamPropertyChangedEvent) => void;
  onSessionDisconnected?: (event: SessionDisconnectedEvent) => void;
  onError?: (error?: OTError) => void;
}

export function initializeSession({
  sessionId,
  token,
  onStreamCreated,
  onStreamDestroyed,
  onStreamPropertyChanged,
  onSessionDisconnected,
  onError,
}: SessionProps) {
  if (!sessionId) {
    throw new Error("Missing sessionId");
  }

  if (!token) {
    throw new Error("Missing token");
  }

  const session = OT.initSession(ENV.VONAGE_API_KEY, sessionId);

  const eventHandlers = {
    ...(onStreamCreated && {
      [SessionEventsEnum.STREAM_CREATED]: onStreamCreated,
    }),
    ...(onStreamDestroyed && {
      [SessionEventsEnum.STREAM_DESTROYED]: onStreamDestroyed,
    }),
    ...(onStreamPropertyChanged && {
      [SessionEventsEnum.STREAM_PROPERTY_CHANGED]: onStreamPropertyChanged,
    }),
    ...(onSessionDisconnected && {
      [SessionEventsEnum.SESSION_DISCONNECTED]: onSessionDisconnected,
    }),
  };
  if (!_isEmpty(eventHandlers)) {
    session.on(eventHandlers);
  }

  // Connect to the session
  session.connect(token, error => {
    if (error && onError) {
      onError(error);
    }
  });
  return session;
}

export const disconnect = (session: Session) => {
  session.off();
  session.disconnect();
};
