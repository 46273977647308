import React, { memo, useState } from "react";

import { Col, Row } from "react-grid-system";
import { useTranslation } from "react-i18next";

import { Paragraph, Title } from "@app/components/atoms/Typography/Typography";
import ContentWrapper from "@app/components/layouts/ContentWrapper/ContentWrapper";
import Section from "@app/components/layouts/Section/Section";
import { ThemeEnum } from "@app/constants/theme.constants";
import { ExperienceDef } from "@app/features/experiences/experiences";

import styles from "./ExperienceAbout.module.scss";

export interface ExperienceAboutProps {
  experience: ExperienceDef;
}

const ExperienceAbout = memo(({ experience }: ExperienceAboutProps) => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState<boolean>(false);

  return (
    <Section theme={ThemeEnum.KHAKI} className={styles.container}>
      <ContentWrapper>
        <Row justify="center">
          <Col xs={12} lg={10} xl={8}>
            <Title level={1} className={styles.title}>
              {experience.tagLine}
            </Title>
            <Title level={3} isGoldGradient className={styles.subTitle}>
              {t("experience.aboutTitle")}
            </Title>
            {(experience.shortDescription || experience.description) && (
              <>
                <Paragraph level={2}>
                  {showMore || !experience.shortDescription
                    ? experience.description
                    : experience.shortDescription}
                </Paragraph>
                {experience.shortDescription && experience.description && (
                  <button onClick={() => setShowMore(!showMore)}>
                    <Paragraph level={2} className={styles.link}>
                      {showMore ? t("default.readLess") : t("default.readMore")}
                    </Paragraph>
                  </button>
                )}
              </>
            )}
          </Col>
        </Row>
      </ContentWrapper>
    </Section>
  );
});

export default ExperienceAbout;
