import React, { memo } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams, generatePath } from "react-router-dom";

import {
  ErrorModal,
  ConfirmationModal,
} from "@app/components/atoms/Modal/Modal";
import { ConfirmationModalProps } from "@app/components/atoms/Modal/components/ConfirmationModal/ConfirmationModal";
import { ErrorModalProps } from "@app/components/atoms/Modal/components/ErrorModal/ErrorModal";
import { ApiStatusCodes } from "@app/constants/api.constants";
import { ClassesDef } from "@app/features/classes/classes";
import { EcommercePathsEnum } from "@app/features/ecommerce/ecommerce";
import { TokenErrorEnum } from "@app/features/session/session";
import { RootState } from "@app/redux/root-reducer";
import { AppPathEnums } from "@app/routes/routes.constants";

interface SessionErrorModalProps {
  zoomLink?: ClassesDef["zoomLink"];
}

const SessionErrorModal = memo(({ zoomLink }: SessionErrorModalProps) => {
  const { error: classTypeError } = useSelector(
    (state: RootState) => state.sessionClass
  );
  const { error: sessionTokenError } = useSelector(
    (state: RootState) => state.sessionToken
  );
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const hasError = !!(classTypeError ?? sessionTokenError ?? zoomLink);

  const handleBackToHome = () => {
    history.push(AppPathEnums.USER_HOME);
  };

  const handleRefreshPage = () => {
    history.go(0);
  };

  const handleGoToClass = () => {
    const classLink = generatePath(EcommercePathsEnum.PRODUCT_DETAILS, {
      id,
    });
    history.push(classLink);
  };

  const handleGoToZoom = () => {
    if (zoomLink) {
      window.open(zoomLink);
    }
  };

  if (zoomLink) {
    const getDialogContent = (): Omit<ConfirmationModalProps, "visible"> => {
      return {
        title: t("videoSessionError.hasZoomVideoTitle"),
        subtitle: t("videoSessionError.hasZoomVideoText"),
        confirmButtonLabel: t("videoSessionError.hasZoomVideoButton"),
        onConfirm: handleGoToZoom,
        onClose: handleBackToHome,
      };
    };
    return <ConfirmationModal visible {...getDialogContent()} />;
  }

  const getErrorContent = (): Omit<ErrorModalProps, "visible"> => {
    if (classTypeError === ApiStatusCodes.NOT_FOUND) {
      return {
        title: t("videoSessionError.classNotFoundTitle"),
        subtitle: t("videoSessionError.classNotFoundText"),
        buttonLabel: t("videoSessionError.buttonBackToHome"),
        onConfirm: handleBackToHome,
      };
    }
    if (sessionTokenError === TokenErrorEnum.CLASS_NOT_STARTED) {
      return {
        title: t("videoSessionError.classNotStartedTitle"),
        subtitle: t("videoSessionError.classNotStartedText"),
        buttonLabel: t("videoSessionError.buttonRefresh"),
        onConfirm: handleRefreshPage,
      };
    }
    if (sessionTokenError === TokenErrorEnum.PRACTITIONER_NOT_LIVE) {
      return {
        title: t("videoSessionError.practitionerNotLiveTitle"),
        subtitle: t("videoSessionError.practitionerNotLiveText"),
        buttonLabel: t("videoSessionError.buttonRefresh"),
        onConfirm: handleRefreshPage,
      };
    }
    if (sessionTokenError === TokenErrorEnum.CLASS_ENDED) {
      return {
        title: t("videoSessionError.classEndedTitle"),
        subtitle: t("videoSessionError.classEndedText"),
        buttonLabel: t("videoSessionError.buttonBackToHome"),
        onConfirm: handleBackToHome,
      };
    }
    if (sessionTokenError === TokenErrorEnum.PAYMENT_NOT_CONFIRMED) {
      return {
        title: t("videoSessionError.paymentNotConfirmedTitle"),
        subtitle: t("videoSessionError.paymentNotConfirmedText"),
        buttonLabel: t("videoSessionError.paymentNotConfirmedButton"),
        onConfirm: handleGoToClass,
      };
    }
    return {
      title: t("videoSessionError.generalErrorTitle"),
      subtitle: t("videoSessionError.generalErrorText"),
      onConfirm: handleRefreshPage,
    };
  };

  return <ErrorModal visible={hasError} {...getErrorContent()} />;
});

export default SessionErrorModal;
