import * as React from "react";

function SvgLock(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2a3 3 0 00-3 3v2H3v6h10V7h-2V5a3 3 0 00-3-3zm2 5V5a2 2 0 10-4 0v2h4zM5 8H4v4h8V8H5z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSvgLock = React.memo(SvgLock);
export default MemoSvgLock;
