import React, { memo, ReactNode, useCallback, useState } from "react";

import cx from "classnames";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import Button, { ButtonSizeEnum } from "@app/components/atoms/Button/Button";
import {
  IconArrowLeft,
  IconArrowRight,
  IconArrowLeftAlt,
  IconArrowRightAlt,
} from "@app/components/atoms/Icon/Icon";

import styles from "./SwiperCarousel.module.scss";
import "swiper/swiper.scss";

SwiperCore.use([Navigation, Pagination]);

export interface SwiperBreakpoints {
  [key: string]: { slidesPerView: number };
}

export enum ArrowTypes {
  DEFAULT = "default",
  ALTERNATIVE = "alternative",
}

export enum NavigationTypes {
  DEFAULT = "navButtonsDefault",
  CENTER = "navButtonsCenter",
}

export interface SwiperCarouselProps {
  enableLoop?: boolean;
  centered?: boolean;
  showNavArrows?: boolean;
  showPagination?: boolean;
  swiperBreakpoints: SwiperBreakpoints;
  swiperClassname?: string;
  navClassname?: string;
  arrowType?: ArrowTypes;
  navigationType?: NavigationTypes;
  children?: ReactNode;
}

const SwiperCarousel = memo(
  ({
    enableLoop = false,
    centered = false,
    swiperBreakpoints,
    showNavArrows = false,
    showPagination = false,
    arrowType = ArrowTypes.DEFAULT,
    navigationType = NavigationTypes.DEFAULT,
    swiperClassname,
    navClassname,
    children,
  }: SwiperCarouselProps) => {
    const [swiperElem, setSwiperElem] = useState<SwiperCore>();
    const paginationOptions = {
      clickable: true,
      currentClass: ".paginationBar",
    };

    const prevSlide = useCallback(() => {
      if (swiperElem) {
        swiperElem.slidePrev();
      }
    }, [swiperElem]);

    const nextSlide = useCallback(() => {
      if (swiperElem) {
        swiperElem.slideNext();
      }
    }, [swiperElem]);

    return (
      <div className={styles.swiperWrapper}>
        {showNavArrows && (
          <div
            className={cx(
              styles.navButtons,
              navClassname,
              styles[navigationType]
            )}
          >
            <Button
              className={styles.navButton}
              onClick={prevSlide}
              endIcon={
                arrowType === ArrowTypes.ALTERNATIVE ? (
                  <IconArrowLeftAlt />
                ) : (
                  <IconArrowLeft />
                )
              }
              size={ButtonSizeEnum.TEXT}
            />
            <Button
              className={styles.navButton}
              onClick={nextSlide}
              endIcon={
                arrowType === ArrowTypes.ALTERNATIVE ? (
                  <IconArrowRightAlt />
                ) : (
                  <IconArrowRight />
                )
              }
              size={ButtonSizeEnum.TEXT}
            />
          </div>
        )}

        <Swiper
          className={cx(styles.swiperContainer, swiperClassname)}
          slidesPerView="auto"
          loop={enableLoop}
          centeredSlides={centered}
          breakpoints={swiperBreakpoints}
          pagination={showPagination ? paginationOptions : false}
          onSwiper={swiper => setSwiperElem(swiper)}
        >
          {children}
        </Swiper>
      </div>
    );
  }
);

export default SwiperCarousel;
export { SwiperSlide };
